import { type ReactNode, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';

import memoizeOne from 'memoize-one';
import invariant from 'tiny-invariant';

import { triggerPostMoveFlash } from '@atlaskit/pragmatic-drag-and-drop-flourish/trigger-post-move-flash';
import type { Instruction, ItemMode } from '@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item';
import * as liveRegion from '@atlaskit/pragmatic-drag-and-drop-live-region';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';

import { DependencyContext, TreeContext, type TreeContextValue } from './TreeContext';
import { TreeItem } from './TreeItem';
import { type TreeItem as TreeItemType, getInitialTreeState, tree, treeStateReducer } from './tree';

// Hashmap of id -> element
function createTreeItemRegistry() {
    const registry = new Map<string | number, { element: HTMLElement }>();

    const registerTreeItem = ({
        itemId,
        element,
    }: {
        itemId: string | number;
        element: HTMLElement;
    }): (() => void) => {
        registry.set(itemId, { element });
        return () => {
            registry.delete(itemId);
        };
    };

    return { registry, registerTreeItem };
}

export interface TreeComponents {
    Preview: React.ComponentType<{ item: TreeItemType }>;
}

interface DnDTreeProps {
    // A node with children will be flatted into the target node as a child
    flattenChildren?: boolean;
    maxDepth?: number;
    treeData: TreeItemType[];
    onChange?: (treeData: TreeItemType[]) => void;
    renderItem?: (
        item: TreeItemType,
        level: number,
        index: number,
        itemRef: React.RefObject<HTMLDivElement>,
    ) => ReactNode;
    indentPerLevel?: number;
    passRef?: boolean;
    expanded?: boolean;
    components?: TreeComponents;
}

export function PreviewDefault({ item }: { item: TreeItemType }) {
    return <div className="bg-bg-overlay-primary p-sm rounded">{item.name}</div>;
}

const defaultComponets = {
    Preview: PreviewDefault,
};

export const DnDTree = ({
    maxDepth,
    treeData,
    onChange,
    renderItem,
    indentPerLevel = 32,
    passRef,
    expanded,
    components,
    flattenChildren,
}: DnDTreeProps) => {
    const [state, updateState] = useReducer(treeStateReducer, null, getInitialTreeState(treeData));
    const ref = useRef<HTMLDivElement>(null);
    const { extractInstruction } = useContext(DependencyContext);

    const componentsRef = useRef(components ? { ...defaultComponets, ...components } : defaultComponets);

    const [{ registry, registerTreeItem }] = useState(createTreeItemRegistry);

    const { data, lastAction } = state;
    const lastStateRef = useRef<TreeItemType[]>(data);

    useEffect(() => {
        updateState({ type: 'hydrate', data: treeData });
    }, [treeData]);

    useEffect(() => {
        lastStateRef.current = data;
    }, [data]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (
            state.lastAction &&
            (state.lastAction.type === 'instruction' || state.lastAction.type === 'reparent-flatten-children')
        ) {
            // XXX - create stable ref
            onChange?.(state.data);
        }
    }, [state]);

    useEffect(() => {
        if (lastAction === null) {
            return;
        }

        if (lastAction.type === 'instruction') {
            const { element } = registry.get(lastAction.itemId) ?? {};
            if (element) {
                triggerPostMoveFlash(element);
            }

            return;
        }
    }, [lastAction, registry]);

    useEffect(() => {
        return () => {
            liveRegion.cleanup();
        };
    }, []);

    /**
     * Returns the items that the item with `itemId` can be moved to.
     *
     * Uses a depth-first search (DFS) to compile a list of possible targets.
     */
    const getMoveTargets = useCallback(({ itemId }: { itemId: string | number }) => {
        const data = lastStateRef.current;

        const targets = [];

        const searchStack = Array.from(data);
        while (searchStack.length > 0) {
            const node = searchStack.pop();

            if (!node) {
                continue;
            }

            /**
             * If the current node is the item we want to move, then it is not a valid
             * move target and neither are its children.
             */
            if (node.id === itemId) {
                continue;
            }

            /**
             * Draft items cannot have children.
             */
            if (node.isDraft) {
                continue;
            }

            targets.push(node);

            if (node.children) {
                node.children.forEach(childNode => searchStack.push(childNode));
            }
        }
        return targets;
    }, []);

    const getChildrenOfItem = useCallback((itemId: string | number) => {
        const data = lastStateRef.current;

        /**
         * An empty string is representing the root
         */
        if (itemId === '') {
            return data;
        }

        const item = tree.find(data, itemId);
        invariant(item);
        return item.children ?? [];
    }, []);

    const context = useMemo<TreeContextValue>(
        () => ({
            dispatch: updateState,
            uniqueContextId: Symbol('unique-id'),
            // memoizing this function as it is called by all tree items repeatedly
            // An ideal refactor would be to update our data shape
            // to allow quick lookups of parents
            getPathToItem: memoizeOne(
                (targetId: string | number) => tree.getPathToItem({ current: lastStateRef.current, targetId }) ?? [],
            ),
            getMoveTargets,
            getChildrenOfItem,
            registerTreeItem,
            components: componentsRef.current,
        }),
        [getChildrenOfItem, getMoveTargets, registerTreeItem],
    );

    useEffect(() => {
        invariant(ref.current);
        return combine(
            monitorForElements({
                canMonitor: ({ source }) => source.data.uniqueContextId === context.uniqueContextId,
                onDrop(args) {
                    const { location, source } = args;
                    // didn't drop on anything
                    if (!location.current.dropTargets.length) {
                        return;
                    }

                    if (source.data.type === 'tree-item') {
                        const itemId = source.data.id as string | number;

                        const target = location.current.dropTargets[0];
                        const targetId = target.data.id as string;
                        const instruction: Instruction | null = extractInstruction(target.data);

                        const targetType = lastStateRef.current.find(item => item.id === targetId)?.type;
                        // Can't drag a file onto another file. It needs to go into a directory
                        if (targetType === 'file' && !instruction?.type?.includes('reorder')) {
                            return;
                        }

                        // if maxDepth is set, check if the depth of the target is greater than maxDepth
                        if (maxDepth !== undefined && instruction) {
                            const path = context.getPathToItem(targetId);
                            const sourceTreeItem = lastStateRef.current.find(item => item.id === itemId);
                            const sourceType = sourceTreeItem?.type;
                            // If the source is a directory and we have maxDepth set, we don't want to allow it to move
                            // into another directory where the directory could not be empty and exceed the maxDepth.
                            const sourceIsDirectory = sourceType === 'directory' || tree.hasChildren(sourceTreeItem);
                            let depth = sourceIsDirectory ? 1 : 0;

                            // when making a child, account for +1 in depth
                            depth += instruction?.type === 'make-child' ? path.length + 1 : path.length;

                            if (depth > maxDepth) {
                                // prevent a parent node from being moved into it's own children
                                if (flattenChildren && !path.includes(itemId)) {
                                    return updateState({
                                        type: 'reparent-flatten-children',
                                        itemId,
                                        targetId,
                                    });
                                } else {
                                    return;
                                }
                            }
                        }

                        if (instruction !== null) {
                            updateState({
                                type: 'instruction',
                                instruction,
                                itemId,
                                targetId,
                            });
                        }
                    }
                },
            }),
        );
    }, [context, extractInstruction, maxDepth, flattenChildren]);

    return (
        <TreeContext.Provider value={context}>
            <div className="flex flex-col" id="tree" ref={ref}>
                {data.map((item, index, array) => {
                    const type: ItemMode = (() => {
                        if (item.children?.length && item.isOpen) {
                            return 'expanded';
                        }

                        if (index === array.length - 1) {
                            return 'last-in-group';
                        }

                        return 'standard';
                    })();

                    return (
                        <TreeItem
                            passRef={passRef}
                            item={item}
                            renderItem={renderItem}
                            level={0}
                            key={item.id}
                            mode={type}
                            index={index}
                            indentPerLevel={indentPerLevel}
                            expanded={expanded}
                        />
                    );
                })}
            </div>
        </TreeContext.Provider>
    );
};
