import { CnslActionSlug } from 'lib/actions/slugs';
import type { HydratedAppMatrixRow } from 'lib/ai/app_matrix_row';
import type {
    AppPolicyMatrixPost,
    AppPolicyPost,
    AppPolicyPostWrapper,
    Approval,
    DefaultPolicySettings,
    PolicyActionTemplate,
    PolicyDetailsResponse,
    PolicyStep,
} from 'lib/models/app_policy';
import type { AppPolicyDurationType, AppPolicyStepTypeType, AppPolicyVisibilityType } from 'lib/prisma/enums';
import { AppPolicyDuration, AppPolicyReviewStrategy, AppPolicyStepType, AppPolicyVisibility } from 'lib/prisma/enums';
import type { AppPolicyStep, CnslApp } from 'lib/prisma/types';
import { isEqual, omit } from 'lodash-es';
import { nanoid } from 'nanoid';
import * as v from 'valibot';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const PStepSelectedOptionSchema = v.object({
    label: v.string(),
    value: v.string(),
    logo: v.optional(v.string()),
    config: v.optional(v.record(v.string(), v.string())),
});
export type PStepSelectedOption = v.InferOutput<typeof PStepSelectedOptionSchema>;

export interface PStep {
    id: string;
    template: PolicyActionTemplate;
    selectedOption?: PStepSelectedOption;
}

const pStepToPolicyStep = (step: PStep, type: AppPolicyStepTypeType): PolicyStep => {
    return {
        type,
        key: step.template.action.slug,
        provider: step.template.action.provider,
        data: step.selectedOption,
        label: step.template.action.name,
        actionId: step.template.action.id,
    };
};

const getActionValue = (config: unknown) => {
    const result = v.safeParse(PStepSelectedOptionSchema, config);
    if (result.success) {
        return result.output;
    }
    return undefined;
};
const PolicyStepToPStep = (step: AppPolicyStep, template: PolicyActionTemplate): PStep => {
    return {
        id: step.id,
        template,
        selectedOption: getActionValue(step.actionConfig),
    };
};
export type PolicyStepperKey = 'app' | 'details' | 'approvals' | 'grants' | 'revokes';
interface Store {
    policy: Partial<AppPolicyPost> | null;
    policyId: string | null;
    app: CnslApp | null;
    actionTemplates: PolicyActionTemplate[];
    initialized: boolean;
    dirty: boolean;
    revocationPSteps: PStep[];
    provisioningPSteps: PStep[];
    approvals: Approval[];
    name: string;
    description: string;
    duration: AppPolicyDurationType;
    durationOptions: Set<AppPolicyDurationType>;
    visibility: AppPolicyVisibilityType;
    visibleToGroupIds: Set<string>;
    setApp: (app: CnslApp | null) => void;
    setActionTemplates: (templates: PolicyActionTemplate[]) => void;
    setName: (name: string) => void;
    setDescription: (description: string) => void;
    setDuration: (duration: AppPolicyDurationType) => void;
    updateDurationOptions: (opt: AppPolicyDurationType, add: boolean) => void;
    setVisibility: (visibility: AppPolicyVisibilityType) => void;
    updateVisibileGroupIds: (id: string, add: boolean) => void;
    initFromDefaults: (settings: DefaultPolicySettings) => void;
    initWithPolicy: (details: PolicyDetailsResponse, templates: PolicyActionTemplate[]) => void;
    invertGrantSteps: (steps: PStep[]) => void;
    setInitialSteps: (actionTemplates: PolicyActionTemplate[]) => void;
    psteps: (type: AppPolicyStepTypeType) => PStep[];
    addPStep: (step: PStep, type: AppPolicyStepTypeType) => void;
    addApproval: (approval: Approval) => void;
    removeApproval: (id: string) => void;
    setApprovals: (approvals: Approval[]) => void;
    updateApproval: (approval: Approval) => void;
    removePStep: (id: string, type: AppPolicyStepTypeType) => void;
    updatePStep: (step: PStep, type: AppPolicyStepTypeType) => void;
    setPSteps: (steps: PStep[], type: AppPolicyStepTypeType) => void;
    requestBody: (appId: string) => AppPolicyPostWrapper;
    reset: () => void;
    markDirty: () => void;
    markClean: () => void;
    valid: () => boolean;
    getValueForPersistence: (id: string) => AppMatrixPolicy;
    initFromExisting: (existing: AppMatrixPolicy) => void;
}

export const usePolicyCreationStore = create<Store>((set, get) => ({
    policy: null,
    policyId: null,
    app: null,
    initialized: false,
    dirty: false,
    revocationPSteps: [],
    provisioningPSteps: [],
    actionTemplates: [],
    approvals: [],
    name: '',
    description: '',
    duration: AppPolicyDuration.INDEFINITE,
    visibility: AppPolicyVisibility.PUBLIC,
    visibleToGroupIds: new Set<string>(),
    durationOptions: new Set(),
    setInitialSteps(actionTemplates: PolicyActionTemplate[]) {
        set(store => {
            if (store.provisioningPSteps.length > 0 || store.revocationPSteps.length > 0) {
                return {};
            }
            const provisioningPSteps: PStep[] = [];
            const revocationPSteps: PStep[] = [];
            const firstProvisioningStep = actionTemplates.find(a => a.type === AppPolicyStepType.PROVISION);
            const firstRevocationStep = actionTemplates.find(a => a.type === AppPolicyStepType.REVOKE);
            if (firstProvisioningStep) {
                provisioningPSteps.push({ id: `step-${Date.now()}`, template: firstProvisioningStep });
            }
            if (firstRevocationStep) {
                revocationPSteps.push({ id: `step-${Date.now()}`, template: firstRevocationStep });
            }
            return {
                actionTemplates,
                provisioningPSteps,
                revocationPSteps,
            };
        });
    },
    setActionTemplates(templates: PolicyActionTemplate[]) {
        set({ actionTemplates: templates });
    },
    initFromDefaults(settings: DefaultPolicySettings) {
        const { initialized } = get();
        if (initialized) {
            return;
        }

        set(() => {
            return {
                policy: {
                    visibility: settings.defaultPolicyVisibility,
                    duration: settings.defaultPolicyDuration,
                    durationOptions: settings.defaultPolicyDurationOptions,
                    visibleToGroupIds: [],
                },
                visibility: settings.defaultPolicyVisibility,
                visibleToGroupIds: new Set(settings.defaultPolicyVisibleToGroupIds),
                duration: settings.defaultPolicyDuration,
                durationOptions: new Set(settings.defaultPolicyDurationOptions),
                approvals: [
                    {
                        id: nanoid(),
                        groupIds: settings.defaultPolicyGroupReviewerIds,
                        userIds: settings.defaultPolicyUserReviewerIds,
                        strategy: settings.defaultPolicyReviewStrategy,
                    },
                ],
                initialized: true,
            };
        });
    },
    initFromExisting: (existing: AppMatrixPolicy) => {
        set({
            policy: existing.policy,
            policyId: null,
            app: existing.app,
            provisioningPSteps: existing.provisioningPSteps,
            revocationPSteps: existing.revocationPSteps,
            name: existing.name,
            description: existing.description,
            visibility: existing.visibility,
            visibleToGroupIds: new Set(existing.visibleToGroupIds),
            duration: existing.duration,
            durationOptions: new Set(existing.durationOptions),
            initialized: true,
            dirty: false,
            approvals: existing.approvals,
        });
    },
    initWithPolicy: (details: PolicyDetailsResponse, templates: PolicyActionTemplate[]) => {
        const provisioning: PStep[] = [];
        details.steps.provisioning.forEach(step => {
            const template = templates.find(t => t.action.id === step.actionId);
            if (template) {
                provisioning.push(PolicyStepToPStep(step, template));
            }
        });
        const revocation: PStep[] = [];
        details.steps.revocation.forEach(step => {
            const template = templates.find(t => t.action.id === step.actionId);
            if (template) {
                revocation.push(PolicyStepToPStep(step, template));
            }
        });

        const { policy } = details;

        let approvals = get().approvals;

        const mapped = details.reviewSteps.map(a => {
            const groupIds: string[] = [];
            const userIds: string[] = [];

            a.reviewers.forEach(r => {
                if (r.userId) {
                    userIds.push(r.userId);
                }
                if (r.groupId) {
                    groupIds.push(r.groupId);
                }
            });

            let escalation: Approval['escalation'] | undefined;
            if (a.escalateTo) {
                escalation = {
                    strategy: a.escalateTo.strategy,
                    userIds: a.escalateTo.reviewers.map(r => r.userId ?? ''),
                    groupIds: a.escalateTo.reviewers.map(r => r.groupId ?? ''),
                };
            }

            return {
                id: a.id,
                groupIds,
                userIds,
                strategy: a.strategy,
                escalation,
            };
        });

        approvals = mapped;

        set(() => {
            return {
                policy: {
                    name: policy.name,
                    description: policy.description,
                    reviewStrategy: policy.reviewStrategy,
                    visibility: policy.visibility,
                    duration: policy.duration,
                    durationOptions: policy.durationOptions,
                    userReviewers: details.userReviewers.map(u => u.id),
                    groupReviewers: details.groupReviewers.map(g => g.id),
                    visibleToGroupIds: details.visibleToGroupIds,
                },
                policyId: details.policy.id,
                app: details.app,
                name: policy.name,
                description: policy.description ?? '',
                visibility: policy.visibility,
                visibleToGroupIds: new Set(details.visibleToGroupIds),
                duration: policy.duration,
                durationOptions: new Set(policy.durationOptions),
                existing: true,
                step: 4,
                initialized: true,
                provisioningPSteps: provisioning,
                revocationPSteps: revocation,
                approvals,
                dirty: false,
            };
        });
    },
    markDirty() {
        set({ dirty: true });
    },
    markClean() {
        set({ dirty: false });
    },
    psteps: (type: AppPolicyStepTypeType) => {
        const store = get();

        return type === AppPolicyStepType.PROVISION ? store.provisioningPSteps : store.revocationPSteps;
    },
    addPStep: (step: PStep, type: AppPolicyStepTypeType) => {
        set(state => {
            const steps = state.psteps(type);
            steps.push(step);
            if (type === AppPolicyStepType.PROVISION) {
                state.invertGrantSteps(steps);
                return { provisioningPSteps: steps, dirty: true };
            } else {
                return { revocationPSteps: steps, dirty: true };
            }
        });
    },
    removePStep: (id: string, type: AppPolicyStepTypeType) => {
        set(state => {
            const steps = state.psteps(type);
            const filtered = steps.filter(s => s.id !== id);
            if (type === AppPolicyStepType.PROVISION) {
                state.invertGrantSteps(filtered);
                return { provisioningPSteps: filtered, dirty: true };
            } else {
                return { revocationPSteps: filtered, dirty: true };
            }
        });
    },
    updatePStep: (step: PStep, type: AppPolicyStepTypeType) => {
        set(state => {
            const steps = state.psteps(type);
            const idx = steps.findIndex(s => s.id === step.id);
            if (idx === -1) {
                state.addPStep(step, type);
                return {};
            }
            steps[idx] = step;

            if (type === AppPolicyStepType.PROVISION) {
                state.invertGrantSteps(steps);
                return { provisioningPSteps: steps, dirty: true };
            } else {
                return { revocationPSteps: steps, dirty: true };
            }
        });
    },
    invertGrantSteps: (steps: PStep[]) => {
        const inversions: Record<string, string> = {
            [CnslActionSlug.AddToGroup]: CnslActionSlug.RemoveFromGroup,
            [CnslActionSlug.ForwardAccessRequest]: CnslActionSlug.ForwardAccessRequest,
        };

        set(state => {
            if (state.policyId) {
                return {};
            }
            const revokes: PStep[] = [];
            steps.forEach(s => {
                const inv = inversions[s.template.action.slug];

                if (inv) {
                    const template = state.actionTemplates.find(
                        t => t.action.slug === inv && t.type === AppPolicyStepType.REVOKE,
                    );
                    if (template) {
                        revokes.push({ ...s, template });
                    }
                }
            });
            if (revokes.length > 0) {
                return { revocationPSteps: revokes, dirty: true };
            }
            return {};
        });
    },
    setPSteps: (steps: PStep[], type: AppPolicyStepTypeType) => {
        if (type === AppPolicyStepType.PROVISION) {
            set({ provisioningPSteps: steps });
        } else {
            set({ revocationPSteps: steps });
        }
    },
    addApproval: (approval: Approval) => {
        set(state => {
            state.approvals.push(approval);
            return { approvals: state.approvals, dirty: true };
        });
    },
    removeApproval: (id: string) => {
        set(state => {
            state.approvals = state.approvals.filter(a => a.id !== id);
            return { approvals: state.approvals, dirty: true };
        });
    },
    setApprovals: (approvals: Approval[]) => {
        set({ approvals });
    },
    updateApproval: (approval: Approval) => {
        set(state => {
            const idx = state.approvals.findIndex(a => a.id === approval.id);
            if (idx === -1) {
                return {};
            }
            state.approvals[idx] = approval;
            return { approvals: state.approvals, dirty: true };
        });
    },
    valid: () => {
        const { name, description } = get();
        return !!name && !!description;
    },
    requestBody: (appId: string) => {
        const store = get();
        const p: AppPolicyPostWrapper = {
            policy: {
                ...(store.policy as AppPolicyPost),
                name: store.name,
                description: store.description,
                reviewStrategy: AppPolicyReviewStrategy.APP_OWNERS,
                appId,
                approvals: store.approvals,
                duration: store.duration,
                durationOptions: Array.from(store.durationOptions),
                visibility: store.visibility,
                visibleToGroupIds: Array.from(store.visibleToGroupIds),
                userReviewers: [],
                groupReviewers: [],
                provisioningSteps: store.provisioningPSteps.map(s => pStepToPolicyStep(s, AppPolicyStepType.PROVISION)),
                revocationSteps: store.revocationPSteps.map(s => pStepToPolicyStep(s, AppPolicyStepType.REVOKE)),
            },
        };
        return p;
    },
    setName: (name: string) => {
        set({ name, dirty: true });
    },
    setDescription: (description: string) => {
        set({ description, dirty: true });
    },
    setDuration: (duration: AppPolicyDurationType) => {
        set({ duration, dirty: true });
        if (duration !== AppPolicyDuration.USER_DEFINED) {
            set({ durationOptions: new Set() });
        }
    },
    setVisibility: (visibility: AppPolicyVisibilityType) => {
        const payload: Partial<Store> = {
            visibility,
            dirty: true,
        };
        if (visibility !== AppPolicyVisibility.GROUPS) {
            payload.visibleToGroupIds = new Set();
        }
        set(payload);
    },
    updateVisibileGroupIds: (id, add) => {
        set(state => {
            const list = new Set(state.visibleToGroupIds);
            if (add) {
                list.add(id);
            } else {
                list.delete(id);
            }
            const payload: Partial<Store> = { visibleToGroupIds: list, dirty: true };
            if (list.size > 0) {
                payload.visibility = AppPolicyVisibility.GROUPS;
            }
            return payload;
        });
    },
    updateDurationOptions: (opt: AppPolicyDurationType, add: boolean) => {
        set(state => {
            const newOptions = new Set(state.durationOptions);
            if (add) {
                newOptions.add(opt);
            } else {
                newOptions.delete(opt);
            }
            return { durationOptions: newOptions, dirty: true };
        });
    },
    setApp: (app: CnslApp | null) => {
        set({ app });
    },
    reset: () => {
        set({
            policy: null,
            policyId: null,
            app: null,
            name: '',
            description: '',
            duration: AppPolicyDuration.INDEFINITE,
            durationOptions: new Set(),
            visibility: AppPolicyVisibility.PUBLIC,
            revocationPSteps: [],
            provisioningPSteps: [],
            initialized: false,
            approvals: [],
            dirty: false,
        });
    },
    getValueForPersistence: (id: string): AppMatrixPolicy => {
        const store = get();
        const {
            policy,
            policyId,
            app,
            actionTemplates,
            initialized,
            dirty,
            revocationPSteps,
            provisioningPSteps,
            approvals,
            name,
            description,
            duration,
            durationOptions,
            visibility,
            visibleToGroupIds,
        } = store;
        return {
            id,
            policy,
            policyId,
            app,
            actionTemplates,
            initialized,
            dirty,
            revocationPSteps,
            provisioningPSteps,
            approvals,
            name,
            description,
            duration,
            durationOptions: Array.from(durationOptions),
            visibility,
            visibleToGroupIds: Array.from(visibleToGroupIds),
            status: null,
        };
    },
}));

/* Being explicit w type here so on update it forces us to handle potentialmigration */
export type AppMatrixPolicy = {
    id: string;
    status: 'accepted' | 'rejected' | null;
    policy: Partial<AppPolicyPost> | null;
    policyId: string | null;
    app: CnslApp | null;
    actionTemplates: PolicyActionTemplate[];
    initialized: boolean;
    dirty: boolean;
    revocationPSteps: PStep[];
    provisioningPSteps: PStep[];
    approvals: Approval[];
    name: string;
    description: string;
    duration: AppPolicyDurationType;
    durationOptions: AppPolicyDurationType[];
    visibility: AppPolicyVisibilityType;
    visibleToGroupIds: string[];
};
type AppMatrixPolicyValuesStore = {
    map: Record<string, AppMatrixPolicy>;
    set: (policy: AppMatrixPolicy) => void;
    setStatus: (id: string, status: 'accepted' | 'rejected' | null) => void;
    isValid: (id: string) => boolean;
    initFromCsv: (rows: HydratedAppMatrixRow[], actionTemplates: PolicyActionTemplate[]) => void;
    requestBody: () => AppPolicyMatrixPost;
};
export const useAppMatrixPolicyValuesStore = create<AppMatrixPolicyValuesStore>()(
    persist(
        (set, get) => ({
            map: {},
            initFromCsv: (rows: HydratedAppMatrixRow[], actionTemplates: PolicyActionTemplate[]) => {
                const existing = get().map;
                const allIds = new Set(rows.map(r => r.id));
                Object.keys(existing).forEach(id => {
                    if (!allIds.has(id)) {
                        delete existing[id];
                    }
                });
                rows.forEach(r => {
                    if (!existing[r.id]) {
                        const approvals: Approval[] = [];
                        const provisioningPSteps: PStep[] = [];
                        const revocationPSteps: PStep[] = [];
                        if (r.processed_row?.reviewUsers?.length) {
                            approvals.push({
                                id: r.id,
                                strategy: AppPolicyReviewStrategy.EXPLICIT,
                                userIds: r.processed_row.reviewUsers,
                                groupIds: [],
                            });
                        }
                        if (r.processed_row?.reviewGroups?.length) {
                            approvals.push({
                                id: r.id,
                                strategy: AppPolicyReviewStrategy.EXPLICIT,
                                userIds: [],
                                groupIds: r.processed_row.reviewGroups,
                            });
                        }
                        if (r.hydrated.group) {
                            const provisionAction = actionTemplates.find(
                                t =>
                                    t.action.slug === CnslActionSlug.AddToGroup &&
                                    t.type === AppPolicyStepType.PROVISION,
                            );
                            const revokeAction = actionTemplates.find(
                                t =>
                                    t.action.slug === CnslActionSlug.RemoveFromGroup &&
                                    t.type === AppPolicyStepType.REVOKE,
                            );
                            if (provisionAction) {
                                provisioningPSteps.push({
                                    id: `step-${nanoid()}`,
                                    template: provisionAction,
                                    selectedOption: {
                                        label: r.hydrated.group.name || 'Unnamed Group',
                                        value: r.hydrated.group.id,
                                        logo: r.hydrated.group.logo || undefined,
                                    },
                                });
                            }

                            if (revokeAction) {
                                revocationPSteps.push({
                                    id: `step-${nanoid()}`,
                                    template: revokeAction,
                                    selectedOption: {
                                        label: r.hydrated.group.name || 'Unnamed Group',
                                        value: r.hydrated.group.id,
                                        logo: r.hydrated.group.logo || undefined,
                                    },
                                });
                            }
                        }
                        const manualProvisionAction = actionTemplates.find(
                            t =>
                                t.action.slug === CnslActionSlug.ManualProvision &&
                                t.type === AppPolicyStepType.PROVISION,
                        );
                        const manualRevokeAction = actionTemplates.find(
                            t => t.action.slug === CnslActionSlug.ManualRevoke && t.type === AppPolicyStepType.REVOKE,
                        );
                        if (
                            !r.processed_row?.groupProvisioningOnly &&
                            provisioningPSteps.length === 0 &&
                            manualProvisionAction
                        ) {
                            provisioningPSteps.push({
                                id: `step-${nanoid()}`,
                                template: manualProvisionAction,
                            });
                        }
                        if (
                            !r.processed_row?.groupProvisioningOnly &&
                            revocationPSteps.length === 0 &&
                            manualRevokeAction
                        ) {
                            revocationPSteps.push({
                                id: `step-${nanoid()}`,
                                template: manualRevokeAction,
                            });
                        }
                        existing[r.id] = {
                            id: r.id,
                            status: null,
                            policy: null,
                            policyId: null,
                            app: r.hydrated.app || null,
                            actionTemplates: [],
                            initialized: false,
                            dirty: false,
                            revocationPSteps,
                            provisioningPSteps,
                            approvals,
                            name: r.processed_row?.policyName || '',
                            description: r.processed_row?.description || '',
                            duration: AppPolicyDuration.INDEFINITE,
                            durationOptions: [],
                            visibility: AppPolicyVisibility.PUBLIC,
                            visibleToGroupIds: [],
                        };
                    }
                });
                set({ map: existing });
            },
            set: (formPolicy: AppMatrixPolicy) => {
                // do not remove this check. It is used to prevent re-render loop
                const current = get().map[formPolicy.id];
                // check without status since that variable isn't tracked by the form
                if (isEqual(omit(formPolicy, 'status'), omit(current, 'status'))) {
                    return;
                }

                set({ map: { ...get().map, [formPolicy.id]: { ...(current || {}), ...omit(formPolicy, 'status') } } });
            },
            setStatus: (id: string, status: 'accepted' | 'rejected' | null) => {
                const policy = get().map[id];
                if (!policy) {
                    return;
                }
                set({ map: { ...get().map, [id]: { ...policy, status } } });
            },
            isValid: (id: string) => {
                const current = get().map[id];
                if (!current) {
                    return false;
                }

                return (
                    !!current.name &&
                    !!current.description &&
                    current.provisioningPSteps.length > 0 &&
                    current.revocationPSteps.length > 0 &&
                    !!current.app
                );
            },
            requestBody: () => {
                const store = get();
                const successPolicies = Object.values(store.map).filter(
                    p => store.isValid(p.id) && p.status === 'accepted',
                );
                const policies = successPolicies.map(p => ({
                    policy: {
                        name: p.name,
                        description: p.description,
                        appId: p.app!.id,
                        visibility: p.visibility,
                        visibleToGroupIds: p.visibleToGroupIds,
                        userReviewers: p.policy?.userReviewers || [],
                        groupReviewers: p.policy?.groupReviewers || [],
                        reviewStrategy: AppPolicyReviewStrategy.APP_OWNERS,
                        duration: p.duration,
                        durationOptions: p.durationOptions,
                        provisioningSteps: p.provisioningPSteps.map(s =>
                            pStepToPolicyStep(s, AppPolicyStepType.PROVISION),
                        ),
                        revocationSteps: p.revocationPSteps.map(s => pStepToPolicyStep(s, AppPolicyStepType.REVOKE)),
                        approvals: p.approvals,
                    },
                    matrixRowId: p.id,
                }));
                const body: AppPolicyMatrixPost = {
                    success: policies,
                    reject: Object.keys(store.map).filter(id => store.map[id].status === 'rejected'),
                };
                return body;
            },
        }),
        {
            name: 'app-matrix-policy-values',
        },
    ),
);
