import { ResLink } from '@/components/ResLink';
import type { CnslApp } from 'lib/prisma/types';
import { ResourceOption } from '../ResourceOption';

export const AppReference = ({
    app,
    isActive,
    onClick,
}: { app: CnslApp; isActive?: boolean; onClick?: () => void }) => {
    return (
        <ResourceOption
            title={<ResLink size="sm" src={app.logo} label={app.name ?? ''} />}
            isActive={isActive}
            onClick={onClick}
        />
    );
};
