import { valibotResolver } from '@hookform/resolvers/valibot';
import { type CnslPlaybookVersion, type PlaybookResponse, PlaybookUpdateSchema } from 'lib/models/playbook';
import { PlaybookState } from 'lib/prisma/enums';
import type { Playbook } from 'lib/prisma/types';
import { BookCopy, BookDown, BookText, Box, Dot, Ellipsis, Save, Trash2, Undo } from 'lucide-react';
import { type ReactNode, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Brow, type Crumb } from '@/components/Brow';
import { Button } from '@/components/ui/button';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
    usePlaybookDelete,
    usePlaybookPublish,
    usePlaybookUnpublish,
    usePlaybookUpdate,
} from '@/hooks/mutations/usePlaybook';
import {
    usePlaybook,
    usePlaybookActions,
    usePlaybookInstructionReferences,
    usePlaybooks,
} from '@/hooks/queries/usePlaybook';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@ui/tabs';
import { getProviderAssets } from 'lib/3p';
import { AllResourcesTab } from './AllResourcesTab';
import { PlaybookDetailsForm } from './PlaybookDetailsForm';
import { PlaybookHistory } from './PlaybookHistory';
import { PlaybookReferences } from './PlaybookReferences';
import { ResourceOption } from './ResourceOption';
import { SectionLabel } from './SectionLabel';
import { ReferencesSections } from './reference/ReferencesSections';
import { DetailSidebar } from './sidebar/DetailSidebar';
import { usePlaybookDetailsStore } from './store/usePlaybookDetailsStore';
import { mapReference } from './utils';

import { ModifiedTime } from '@/components/ModifiedTime';
import { TiptapSchemaInstanceCounter } from '@/lib/tiptap';
import type { PlaybookInstructionsNode } from 'lib/models/tiptap';
import { nanoid } from 'nanoid';
import SettingsTab from './SettingsTab';
import { IntroDialog } from './dialogs/IntroDialog';
import { useLoadOptions } from './useLoadOptions';

const FormActionMenu = ({ playbook }: PlaybookResponse) => {
    const onUnPublish = usePlaybookUnpublish(playbook.slug);
    const onDelete = usePlaybookDelete(playbook.slug);
    return (
        <DropdownMenu>
            <DropdownMenuTrigger className="flex items-center" asChild>
                <Button mode="borderless" size="sm">
                    <Ellipsis />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent side="bottom" align="end">
                {playbook.state === PlaybookState.PUBLISHED && (
                    <DropdownMenuItem
                        onSelect={() => {
                            onUnPublish.mutate();
                        }}
                        className="gap-sm"
                    >
                        <BookDown /> Unpublish Playbook
                    </DropdownMenuItem>
                )}
                <DropdownMenuItem
                    onSelect={() => {
                        onDelete.mutate();
                    }}
                    className="gap-sm text-body-red-primary"
                    data-testid="delete-playbook"
                >
                    <Trash2 />
                    Delete Playbook
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );
};

const FormSaveButton = ({
    onPublish,
    isDisabled,
    label,
}: { onPublish: () => void; isDisabled: boolean; label?: ReactNode }) => (
    <Button
        variant="blue"
        mode="dark"
        size="sm"
        type="submit"
        onClick={() => onPublish()}
        disabled={isDisabled}
        data-testid="publish-changes"
    >
        {label ? (
            label
        ) : (
            <>
                <Save className="size-3.5" />
                Publish Changes
            </>
        )}
    </Button>
);

export const addIdToNodes = ({ data }: { data: PlaybookInstructionsNode[] }) => {
    data.forEach(node => {
        node.id = node.id ?? nanoid();
        if (node.children) {
            node.children = node.children.map(d => ({ ...d, id: d.id ?? nanoid() }));
        }
    });

    return { data };
};

const PlaybookDetailComponent = ({ playbook }: PlaybookResponse) => {
    const [recentVersion] = playbook.versions;

    // state
    const [activeHistoryVersion, setActiveHistoryVersion] = useState<CnslPlaybookVersion | undefined>();
    const [disableForm, setDisableForm] = useState(false);

    // store
    const options = usePlaybookDetailsStore(state => state.options);
    const setActiveOption = usePlaybookDetailsStore(state => state.setActiveOption);
    const setSaveForm = usePlaybookDetailsStore(state => state.setSaveForm);
    const setReferences = usePlaybookDetailsStore(state => state.setReferences);

    // mutations
    const playbookUpdate = usePlaybookUpdate(playbook.slug);
    const playbookPublish = usePlaybookPublish(playbook.slug);

    // queries
    const { loaded: optionsLoaded } = useLoadOptions();
    const { data: playbookData } = usePlaybooks();
    const { data: actionsData } = usePlaybookActions();
    const { data: referencesData, isLoading: isReferencesLoading } = usePlaybookInstructionReferences();

    useEffect(() => {
        if (referencesData) {
            setReferences(referencesData);
        }
    }, [referencesData, setReferences]);

    const form = useForm({
        resolver: valibotResolver(PlaybookUpdateSchema),
        defaultValues: {
            ...playbook,
            name: recentVersion.name,
            description: recentVersion.description,
            instructionsData: addIdToNodes(recentVersion.instructionsData),
            references: recentVersion.references,
        },
        disabled: playbook.isConsoleOwned || disableForm,
    });

    const counterUtil = useMemo(() => {
        return new TiptapSchemaInstanceCounter(node => node?.attrs?.id);
    }, []);

    // clear out active option (opens drawer) when page loads
    useEffect(() => {
        setActiveOption(undefined);
    }, [setActiveOption]);

    // Used to programmatically save the form (e.g. when adding an action to playbook)
    useEffect(() => {
        setSaveForm(() => {
            const v = form.getValues();
            playbookUpdate.mutate(v, {
                onSuccess: p => {
                    // updated references are generated on the BE, so need to update form with them
                    form.setValue('references', p.playbook.versions[0].references);

                    // @ts-expect-error -
                    const dirty = counterUtil.count(form.getValues('instructionsData'));
                    if (dirty) {
                        // @ts-expect-error -
                        form.setValue('instructionsData', counterUtil.clone());
                    }
                },
            });
        });
    }, [playbookUpdate.mutate, form, setSaveForm, counterUtil]);

    const onPublish = form.handleSubmit((playbook: Playbook) => {
        if (playbook.name === '') {
            form.setError('name', {
                type: 'required',
                message: 'Title required for publishing',
            });
            return;
        }
        /* Important that we cancel since slug might have changed. Handled in effect but there's still lag before cache invalidation */

        playbookPublish.mutate(playbook, {
            onSuccess: p => {
                form.setValue('references', p.playbook.versions[0].references);
            },
        });
    });

    if (!referencesData || !optionsLoaded || isReferencesLoading) return null;

    const browBreadcrumbs: Crumb[] = [
        {
            label: (
                <div className="gap-sm flex items-center">
                    <BookCopy />
                    <span>Playbooks</span>
                </div>
            ),
            href: '/playbooks',
        },
        {
            label: (
                <div className="gap-sm flex items-center">
                    <BookText className="shrink-0" />
                    <span className="line-clamp-1">{playbook.name || 'New Playbook'}</span>
                </div>
            ),
            bold: true,
            seperator: <Dot />,
        },
    ];

    if (playbook.state === PlaybookState.DRAFT) {
        browBreadcrumbs.push({
            label: <div className="text-body-subtle">Draft</div>,
        });
    } else {
        browBreadcrumbs.push({
            label: <div className="text-body-blue-primary">Published</div>,
        });
    }

    const playbookReferences = mapReference({
        references: recentVersion.references,
        schema: recentVersion.instructionsData,
        actions: actionsData?.actions ?? [],
        playbooks: playbookData?.playbooks ?? [],
    });

    // XXX - these are users, groups, apps, and slack channels. We used to call these references, but now we have a different concept of references
    const allReferences = new PlaybookReferences(referencesData.references);
    const linkedReferences = allReferences.loadInstructionData(recentVersion.instructionsData);

    const handleActiveVersion = (v?: CnslPlaybookVersion) => {
        setActiveHistoryVersion(v);

        form.reset({
            ...playbook,
            name: v ? v.name : recentVersion.name,
            instructionsData: v ? v.instructionsData : recentVersion.instructionsData,
        });
    };

    const disableSave =
        (recentVersion.state === PlaybookState.PUBLISHED &&
            playbook.state === PlaybookState.PUBLISHED &&
            !activeHistoryVersion &&
            !form.formState.isDirty) ||
        form.formState.disabled;

    return (
        <>
            <Brow
                data={browBreadcrumbs}
                actions={[
                    <PlaybookHistory
                        key="history-btn"
                        activeVersion={activeHistoryVersion}
                        setActiveVersion={handleActiveVersion}
                        slug={playbook.slug}
                        disabled={disableSave}
                    />,
                    <FormSaveButton
                        key="save-btn"
                        onPublish={onPublish}
                        label={
                            activeHistoryVersion ? (
                                <>
                                    <Undo />
                                    Revert to this version
                                </>
                            ) : undefined
                        }
                        isDisabled={disableSave}
                    />,
                    <FormActionMenu key="action-menu-btn" playbook={playbook} />,
                ]}
            />
            <div className="flex grow overflow-hidden relative">
                <div className="w-[350px] shrink-0 bg-bg border-r-[0.5px] border-grey overflow-hidden">
                    <Tabs defaultValue="linkedResources" className="flex flex-col h-full">
                        <TabsList className="px-lg">
                            <TabsTrigger value="linkedResources">Linked resources</TabsTrigger>
                            <TabsTrigger value="allResources">All resources</TabsTrigger>
                            <TabsTrigger value="settings">Settings</TabsTrigger>
                        </TabsList>
                        <TabsContent value="linkedResources" className="overflow-hidden flex flex-col">
                            <div className="p-md flex flex-col gap-md grow overflow-scroll">
                                <div className="mb-lg">
                                    <SectionLabel label="Actions" />
                                    {playbookReferences.actions.map(action => {
                                        const p = getProviderAssets(action.provider);
                                        return (
                                            <ResourceOption
                                                key={`${action.referenceId}`}
                                                onClick={() => {
                                                    const a = options?.find(o => o.id === action.id);
                                                    setActiveOption(
                                                        a ? { ...a, referenceId: action.referenceId } : undefined,
                                                    );
                                                }}
                                                isDefaultAction={action.isDefaultAction}
                                                description={action?.description}
                                                title={action.name}
                                                provider={p.name}
                                                src={p.logo}
                                                icon={action.provider === 'custom' ? <Box /> : undefined}
                                            />
                                        );
                                    })}
                                    {playbookReferences.actions.length === 0 && (
                                        <div className="text-body-subtle px-md py-sm">No linked actions</div>
                                    )}
                                </div>
                                <div>
                                    <SectionLabel label="Playbooks" />
                                    {playbookReferences.playbooks.map(playbook => {
                                        return (
                                            <ResourceOption
                                                key={playbook.id}
                                                onClick={() => {
                                                    const p = options?.find(o => o.id === playbook.id);
                                                    setActiveOption(p);
                                                }}
                                                title={playbook.name}
                                                description={playbook.description}
                                                icon={<BookText />}
                                            />
                                        );
                                    })}
                                    {playbookReferences.playbooks.length === 0 && (
                                        <div className="text-body-subtle p-md">No linked Playbooks</div>
                                    )}
                                </div>
                                <ReferencesSections references={linkedReferences} />
                            </div>
                        </TabsContent>
                        <TabsContent value="allResources" className="overflow-hidden flex flex-col">
                            <div className="grow overflow-scroll">
                                <AllResourcesTab references={allReferences} />
                            </div>
                        </TabsContent>
                        <TabsContent value="settings" className="overflow-hidden flex flex-col grow">
                            <div className="grow">
                                <div className="m-lg">{playbook && <SettingsTab playbook={playbook} />}</div>
                            </div>
                        </TabsContent>
                    </Tabs>
                </div>
                <DetailSidebar form={form} />
                <div className="bg-bg-overlay flex grow shrink flex-col items-center justify-start size-full overflow-auto px-12">
                    <div className="shrink max-w-[760px] w-full flex flex-col py-12">
                        <ModifiedTime
                            versionUpdatedBy={recentVersion?.updatedBy ?? undefined}
                            versionCreatedAt={recentVersion?.createdAt}
                            versionNumber={recentVersion?.version}
                        />
                        <div className="border-subtle-selected flex flex-col min-h-[983px] border-[0.5px] bg-bg p-6 md:p-12 shadow-lg relative">
                            <PlaybookDetailsForm
                                historical={!!activeHistoryVersion}
                                form={form}
                                slug={playbook.slug}
                                setDisableForm={setDisableForm}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <IntroDialog />
        </>
    );
};

export const PlaybookDetails = () => {
    const { slug = '' } = useParams();
    const { data } = usePlaybook(slug);

    if (!data) {
        return null;
    }

    return <PlaybookDetailComponent playbook={data.playbook} />;
};
