import { cn } from '@lib/styling';
import { type VariantProps, cva } from 'class-variance-authority';
import { TrendingDown, TrendingUp } from 'lucide-react';

const trendingIndicator = cva('', {
    variants: {
        color: {
            green: 'text-bg-green-secondary',
            red: 'text-bg-red-secondary',
            neutral: 'text-body',
        },
    },
    defaultVariants: {
        color: 'neutral',
    },
});

interface TrendingIndicatorProps extends VariantProps<typeof trendingIndicator> {
    value: number | undefined;
    direction: 'up' | 'down';
    placeholder?: string;
}

export const TrendingIndicator = ({ direction, color, value, placeholder = '--' }: TrendingIndicatorProps) => {
    const TendingIcon = direction === 'up' ? TrendingUp : TrendingDown;

    const preferPlaceholder = value === undefined || !Number.isFinite(value) || Number.isNaN(value);

    const formatted = preferPlaceholder
        ? placeholder
        : new Intl.NumberFormat(window.navigator.language, {
              style: 'percent',
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
              signDisplay: 'never',
          }).format(value);

    return (
        <div className="flex items-center space-x-1">
            <TendingIcon className={cn(trendingIndicator({ color }))} />
            <span className={cn(trendingIndicator({ color }))}>{formatted}</span>
        </div>
    );
};
