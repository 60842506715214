import { Checkbox } from '@/components/ui/checkbox';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/lib/styling';
import type { NestableDirectory } from 'lib/models/directory';

type DirectoryWithChecked = Omit<Extract<NestableDirectory[number], { type: 'directory' }>, 'children'> & {
    checked: boolean;
    children: DirectoryWithChecked[];
};

type FileWithChecked = Extract<NestableDirectory[number], { type: 'file' }> & {
    checked: boolean;
};

export type NestableDirectoryWithChecked = DirectoryWithChecked | FileWithChecked;

const CheckboxNode = ({
    level,
    checked,
    onChange,
    name,
    childrenNodes,
    id,
}: {
    level: number;
    checked: boolean;
    onChange: (id: string | number, checked: boolean) => void;
    name: string;
    childrenNodes: NestableDirectoryWithChecked[];
    id: string | number;
}) => {
    return (
        <div className="w-full">
            <Tooltip>
                <TooltipTrigger asChild>
                    <label
                        style={{ paddingLeft: (level + 1) * 16 }}
                        className={cn('flex items-center gap-md py-sm rounded-[6px] hover:bg-bg-overlay')}
                    >
                        <Checkbox checked={checked} onCheckedChange={checked => onChange(id, checked as boolean)} />
                        <span className="font-medium">{name}</span>
                    </label>
                </TooltipTrigger>
                <TooltipContent>{name}</TooltipContent>
            </Tooltip>
            {childrenNodes?.length > 0 &&
                childrenNodes.map(child => {
                    const childrenNodes = 'children' in child ? child.children : [];
                    return (
                        <CheckboxNode
                            key={child.id}
                            level={level + 1}
                            checked={child.checked}
                            onChange={onChange}
                            name={child.name}
                            childrenNodes={childrenNodes}
                            id={child.id}
                        />
                    );
                })}
        </div>
    );
};

const hasChildren = (data: NestableDirectoryWithChecked): data is DirectoryWithChecked => {
    return 'children' in data ? data?.children.length > 0 : false;
};

export const NestedCheckboxTree = ({
    data,
    onChange,
}: { data: NestableDirectoryWithChecked[]; onChange: (selectedIds: (string | number)[]) => void }) => {
    const handleChange = (id: string | number, checked: boolean) => {
        const updatedTree = structuredClone(data);
        const selectedIds: (string | number)[] = [];

        const tranverse = (
            tree: NestableDirectoryWithChecked[] | NestableDirectoryWithChecked,
            parentChecked?: boolean,
        ) => {
            const handleUpdate = (node: NestableDirectoryWithChecked) => {
                if (node.id === id) {
                    node.checked = checked;
                    if (hasChildren(node)) {
                        node.children.forEach(c => {
                            tranverse(c, node.checked);
                        });
                    }

                    if (node.checked) {
                        selectedIds.push(node.id);
                    }

                    return;
                }

                if (parentChecked !== undefined) {
                    node.checked = parentChecked;
                }

                if (hasChildren(node)) {
                    node.children.forEach(c => {
                        tranverse(c, parentChecked);
                    });
                }

                if (node.checked) {
                    selectedIds.push(node.id);
                }
            };

            if (Array.isArray(tree)) {
                tree.forEach(handleUpdate);
            } else {
                handleUpdate(tree);
            }
        };

        tranverse(updatedTree);
        onChange(selectedIds);
    };

    return (
        <div className="gap-sm flex flex-col items-center">
            {data.map(d => {
                const childrenNodes = 'children' in d ? d.children : [];

                return (
                    <CheckboxNode
                        key={d.id}
                        level={0}
                        checked={d.checked}
                        onChange={handleChange}
                        name={d.name}
                        childrenNodes={childrenNodes}
                        id={d.id}
                    />
                );
            })}
        </div>
    );
};
