import { useQuery } from '@/lib/hooks/useQuery';
import type { IntegrationId } from 'lib/integration';
import type { DirectoryProviderSelectedType, KnowledgeBaseResponseType, NestableDirectory } from 'lib/models/directory';

export const useArticleDirectory = () => {
    return useQuery<KnowledgeBaseResponseType>({
        url: `/api/v1/directory`,
    });
};

export const useArticleExternalDirectory = ({ provider }: { provider?: IntegrationId }) => {
    return useQuery<{ directory: NestableDirectory }>({
        url: `/api/v1/external-directory/${provider}`,
        enabled: Boolean(provider),
    });
};

export const useArticleExternalDirectorySelected = ({ provider }: { provider?: IntegrationId }) => {
    return useQuery<{ selected: DirectoryProviderSelectedType[] }>({
        url: `/api/v1/directory/${provider}/selected`,
        enabled: Boolean(provider),
    });
};
