import { useOrgSettings, useOrgSettingsUpsert } from '@hooks/queries/useOrgSettings';
import { Switch } from '@ui/switch';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'sonner';

import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { SETTINGS_ORGANIZATION_MEMBERS } from '@/routes/paths';
import { SandboxSettingKey, TestingModeSettingKey } from 'lib/models/org_settings';
import { Link } from 'react-router-dom';
import { EscalationSetting } from './EscalationSetting';
import { OrgChartSettings } from './OrgChartSetting';

const General = () => {
    const { data: testModeSetting, isLoading: isTestModeLoading } = useOrgSettings(TestingModeSettingKey);
    const { data: sandboxSetting, isLoading: isSandboxLoading } = useOrgSettings(SandboxSettingKey);
    const upsertSetting = useOrgSettingsUpsert(TestingModeSettingKey);
    const [isChecked, setIsChecked] = useState(testModeSetting?.enabled);

    const isLoading = isTestModeLoading || isSandboxLoading;
    const isSandboxEnabled = sandboxSetting?.enabled || false;

    const toggleTestMode = useCallback(
        async (enabled: boolean) => {
            setIsChecked(enabled); // Immediately update UI
            try {
                await upsertSetting.mutateAsync({ enabled });
            } catch (_error) {
                setIsChecked(!enabled); // Revert UI if mutation fails
                toast.error('Failed to update test mode');
            }
        },
        [upsertSetting], // Removed queryClient from dependencies
    );

    // Update local state when query data changes
    useEffect(() => {
        if (testModeSetting) {
            setIsChecked(testModeSetting.enabled);
        }
    }, [testModeSetting]);

    return (
        <div className="flex flex-col gap-xl">
            <div className="flex items-start gap-lg">
                {isSandboxEnabled ? (
                    <Tooltip>
                        <TooltipTrigger>
                            <Switch checked={true} disabled={true} />
                        </TooltipTrigger>
                        <TooltipContent>
                            <p>
                                You are currently running Console in a sandbox environment. To enable Console for your
                                entire organization, please reach out to your contact at Console.
                            </p>
                        </TooltipContent>
                    </Tooltip>
                ) : (
                    <Switch checked={isChecked} onCheckedChange={toggleTestMode} disabled={isLoading} />
                )}
                <div>
                    <h2 className="font-medium">Enable test mode</h2>
                    <p className="text-xs text-body-subtle-hover">
                        Enabling test mode will restrict Slack bot access to{' '}
                        <Link
                            className="font-medium text-body-blue-primary hover:text-body-blue-primary-hover"
                            to={SETTINGS_ORGANIZATION_MEMBERS}
                        >
                            Console members
                        </Link>
                    </p>
                </div>
            </div>
            <EscalationSetting />
            <OrgChartSettings />
        </div>
    );
};

export default General;
