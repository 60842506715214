import { ChevronDown, CircleDashed, CircleX, Search, UserRoundSearch, X } from 'lucide-react';
import { useMemo, useState } from 'react';
import type { UseFormReturn } from 'react-hook-form';

import { ResLink } from '@/components/ResLink';
import { UserAvatar } from '@/components/UserAvatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { FormField, Label } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useGroups } from '@/hooks/queries/useGroups';
import { useUsers } from '@/hooks/queries/useUsers';
import { CnslGroupIcon } from '@/lib/iconConstants';
import { cn } from '@lib/styling';
import { CnslActionSlug } from 'lib/actions/slugs';
import type { ActionApprovalConfig, RichPlaybook } from 'lib/models/playbook';
import { usePlaybookDetailsStore } from '../store/usePlaybookDetailsStore';

enum OptionsTypes {
    NO_APPROVAL_REQUIRED = 'NO_APPROVAL_REQUIRED',
    REQUESTERS_MANAGER = 'REQUESTERS_MANAGER',
    GROUP_OWNER = 'GROUP_OWNER',
    EXPLICIT = 'EXPLICIT',
}

interface ApprovalOption {
    type: OptionsTypes;
    label: string;
    Icon: React.ElementType;
    top: boolean;
}

const getApprovalOptions = (withGroupOwner: boolean) => {
    const approvalOptions: ApprovalOption[] = [
        {
            type: OptionsTypes.NO_APPROVAL_REQUIRED,
            label: 'No approval required',
            Icon: CircleDashed,
            top: true,
        },
        {
            type: OptionsTypes.REQUESTERS_MANAGER,
            label: "Requester's manager",
            Icon: UserRoundSearch,
            top: true,
        },
        {
            type: OptionsTypes.GROUP_OWNER,
            label: 'Group owner',
            Icon: CnslGroupIcon,
            top: true,
        },
        {
            type: OptionsTypes.EXPLICIT,
            label: 'Users',
            Icon: UserRoundSearch,
            top: false,
        },
        {
            type: OptionsTypes.EXPLICIT,
            label: 'Groups',
            Icon: CnslGroupIcon,
            top: false,
        },
    ];
    return approvalOptions.filter(opt => (withGroupOwner ? true : opt.type !== OptionsTypes.GROUP_OWNER));
};

const LIST_LIMIT = 15;

const extractTypeFromState = (config: ActionApprovalConfig) => {
    if (config.requestersManager) {
        return OptionsTypes.REQUESTERS_MANAGER;
    }
    if (config.groupOwner) {
        return OptionsTypes.GROUP_OWNER;
    }
    if (config.userIds.length > 0 || config.groupIds.length > 0) {
        return OptionsTypes.EXPLICIT;
    }
    return OptionsTypes.NO_APPROVAL_REQUIRED;
};

const groupOwnerActionSlugs = new Set<string>([CnslActionSlug.AddToGroup, CnslActionSlug.RemoveFromGroup]);

interface PlaybookActionApprovalsDropdownProps {
    form: UseFormReturn<RichPlaybook>;
    actionSlug?: string;
}
export const PlaybookActionApprovalsDropdown = ({ form, actionSlug }: PlaybookActionApprovalsDropdownProps) => {
    const activeOption = usePlaybookDetailsStore(state => state.activeOption);

    const userList = useUsers();
    const groupList = useGroups();
    const users = userList.data?.items ?? [];
    const groups = groupList.data?.items ?? [];

    let actionIdx: number | undefined;
    if (activeOption?.type === 'action') {
        actionIdx = form.getValues().references.actionReference.findIndex(ref => ref.id === activeOption?.referenceId);
    }

    if (actionIdx === -1 || actionIdx === undefined) return null;

    return (
        <div className="flex flex-col gap-md">
            <div>
                <Label className="text-body-grey-primary">Require approval</Label>
                <span className="text-body-subtle">Sends an approval request before taking this action</span>
            </div>

            <FormField
                control={form.control}
                name={`references.actionReference.${actionIdx}.config.approvals`}
                render={({ field }) => {
                    // field.value is not pulling the correct value when the actionIdx changes
                    const approvals = form.getValues().references.actionReference[actionIdx].config.approvals;

                    const [searchTermUser, setSearchTermUser] = useState('');
                    const [searchTermGroup, setSearchTermGroup] = useState('');

                    const selectedUsers = users.filter(user => approvals.userIds.includes(user.id));
                    const unselectedUsers = users.filter(user => !approvals.userIds.includes(user.id));
                    const filteredUsers =
                        searchTermUser.length > 0
                            ? unselectedUsers
                                  .filter(user => user.displayName.toLowerCase().includes(searchTermUser.toLowerCase()))
                                  .slice(0, LIST_LIMIT)
                            : unselectedUsers.slice(0, LIST_LIMIT);

                    const selectedGroups = groups.filter(g => approvals.groupIds.includes(g.id));
                    const unselectedGroups = groups.filter(g => !approvals.groupIds.includes(g.id));
                    const filteredGroups =
                        searchTermGroup.length > 0
                            ? unselectedGroups
                                  .filter(g => g.name?.toLowerCase().includes(searchTermGroup.toLowerCase()))
                                  .slice(0, LIST_LIMIT)
                            : unselectedGroups.slice(0, LIST_LIMIT);

                    const explicitSelections = selectedUsers
                        .map(user => ({
                            type: 'users',
                            id: user.id,
                            displayName: user.displayName,
                            avatar: user.avatar,
                        }))
                        .concat(
                            selectedGroups.map(group => ({
                                type: 'groups',
                                id: group.id,
                                displayName: group.name ?? '',
                                avatar: group.logo ?? '',
                            })),
                        );

                    const explicitSelectionLabel = useMemo(() => {
                        const groupCount = approvals.groupIds.length;
                        const userCount = approvals.userIds.length;
                        if (groupCount > 0 && userCount === 0) {
                            return `${groupCount} Group${groupCount > 1 ? 's' : ''}`;
                        }
                        if (userCount > 0 && groupCount === 0) {
                            return `${userCount} User${userCount > 1 ? 's' : ''}`;
                        }
                        return `${userCount} User${userCount > 1 ? 's' : ''} and ${groupCount} Group${groupCount > 1 ? 's' : ''}`;
                    }, [approvals]);

                    const onUserSelect = (userId: string, add: boolean) => {
                        const userIds = approvals.userIds;
                        if (add) {
                            userIds.push(userId);
                        } else {
                            userIds.splice(userIds.indexOf(userId), 1);
                        }
                        field.onChange({ ...approvals, requestersManager: false, userIds });
                    };

                    const onGroupSelect = (groupId: string, add: boolean) => {
                        const groupIds = approvals.groupIds;
                        if (add) {
                            groupIds.push(groupId);
                        } else {
                            groupIds.splice(groupIds.indexOf(groupId), 1);
                        }
                        field.onChange({ ...approvals, requestersManager: false, groupIds });
                    };

                    const handleClearSearch = (open: boolean) => {
                        if (open) {
                            setSearchTermUser('');
                            setSearchTermGroup('');
                        }
                    };

                    const clearExplicit = () => {
                        field.onChange({ userIds: [], groupIds: [], requestersManager: false, groupOwner: false });
                    };

                    const handleTopSelect = (type: OptionsTypes) => {
                        if (type === OptionsTypes.NO_APPROVAL_REQUIRED) {
                            clearExplicit();
                        } else if (type === OptionsTypes.REQUESTERS_MANAGER) {
                            field.onChange({ userIds: [], groupIds: [], requestersManager: true, groupOwner: false });
                        } else if (type === OptionsTypes.GROUP_OWNER) {
                            field.onChange({ userIds: [], groupIds: [], groupOwner: true, requestersManager: false });
                        }
                    };

                    const handleClear = () => {
                        clearExplicit();
                    };

                    const approvalType = extractTypeFromState(approvals);
                    const approvalOptions = getApprovalOptions(groupOwnerActionSlugs.has(actionSlug ?? ''));

                    const currentSelection = approvalOptions.find(opt => opt.type === approvalType) as ApprovalOption;

                    const topLevels = approvalOptions.filter(opt => opt.top);
                    const userOpt = approvalOptions.find(opt => opt.label === 'Users');
                    const groupOpt = approvalOptions.find(opt => opt.label === 'Groups');

                    const displayedSelections = explicitSelections.slice(0, 3);
                    const leftoverSelectionCount = explicitSelections.length - displayedSelections.length;
                    return (
                        <>
                            <DropdownMenu>
                                <div className="flex items-center">
                                    <DropdownMenuTrigger asChild>
                                        <Button className="flex w-full items-center justify-between rounded-r-none">
                                            {currentSelection.type === OptionsTypes.EXPLICIT ? (
                                                <div className="flex items-center">
                                                    {displayedSelections.map((selection, idx) => (
                                                        <div key={selection.id} className={idx > 0 ? '-ml-sm' : ''}>
                                                            <ResLink
                                                                entity={selection.type as 'apps' | 'groups'}
                                                                src={selection.avatar}
                                                                label={selection.displayName}
                                                                avatarOnly
                                                                size="sm"
                                                            />
                                                        </div>
                                                    ))}
                                                    {leftoverSelectionCount > 0 && (
                                                        <div className="-ml-sm size-4 text-[7px] flex items-center justify-center rounded-full text-white bg-bg-grey-secondary z-10">
                                                            <span>+</span>
                                                            <span>{leftoverSelectionCount}</span>
                                                        </div>
                                                    )}
                                                    <p className={cn(displayedSelections.length > 0 ? 'ml-sm' : '')}>
                                                        {explicitSelectionLabel}
                                                    </p>
                                                </div>
                                            ) : (
                                                <div className="gap-x-sm flex items-center">
                                                    <currentSelection.Icon />
                                                    {currentSelection.label}
                                                </div>
                                            )}
                                            <ChevronDown />
                                        </Button>
                                    </DropdownMenuTrigger>
                                    <Button onClick={handleClear} className="rounded-l-none">
                                        <X />
                                    </Button>
                                </div>

                                <DropdownMenuContent side="bottom" align="start" className="w-[275px]">
                                    {topLevels.map(item => (
                                        <DropdownMenuItem
                                            key={item.type}
                                            className="gap-x-sm flex items-center"
                                            onSelect={() => handleTopSelect(item.type)}
                                        >
                                            <item.Icon />
                                            {item.label}
                                        </DropdownMenuItem>
                                    ))}
                                    <DropdownMenuSeparator />

                                    {userOpt && (
                                        <DropdownMenuSub onOpenChange={handleClearSearch}>
                                            <DropdownMenuSubTrigger className="flex items-center">
                                                <div className="flex w-full items-center justify-between">
                                                    <div className="gap-x-sm flex items-center">
                                                        <userOpt.Icon />
                                                        {userOpt.label}
                                                    </div>
                                                    {approvals.userIds.length > 0 && (
                                                        <Badge className="mr-sm">
                                                            {approvals.userIds.length} selected
                                                        </Badge>
                                                    )}
                                                </div>
                                            </DropdownMenuSubTrigger>
                                            <DropdownMenuSubContent
                                                className="w-[275px] max-h-[300px] overflow-y-scroll"
                                                collisionPadding={10}
                                            >
                                                <div className="bg-bg-overlay pl-sm relative flex items-center rounded-sm border-grey border-[0.5px]">
                                                    <Search className="opacity-50" />
                                                    <Input
                                                        value={searchTermUser}
                                                        placeholder="Users"
                                                        onChange={e => setSearchTermUser(e.currentTarget.value)}
                                                        className="bg-bg-overlay border-none"
                                                    />
                                                    {searchTermUser.length > 0 && (
                                                        <button className="right-sm absolute">
                                                            <CircleX onClick={() => handleClearSearch(true)} />
                                                        </button>
                                                    )}
                                                </div>
                                                {selectedUsers.length > 0 && <DropdownMenuSeparator />}
                                                {selectedUsers.map(user => (
                                                    <DropdownMenuCheckboxItem
                                                        textValue="**" // hack so that typing in search doesn't grab the item
                                                        key={user.id}
                                                        className="dropdown-item gap-x-sm flex items-center hover:bg-bg-overlay"
                                                        checked={approvals.userIds.includes(user.id)}
                                                        side="right"
                                                        onSelect={e => e.preventDefault()}
                                                        onCheckedChange={checked => onUserSelect(user.id, checked)}
                                                        tabIndex={0}
                                                    >
                                                        <div className="gap-md ml-md flex items-center dropdown-item">
                                                            <UserAvatar user={user} size="sm" />
                                                            <span>{user.displayName}</span>
                                                        </div>
                                                    </DropdownMenuCheckboxItem>
                                                ))}
                                                <DropdownMenuSeparator />

                                                {filteredUsers.map(user => (
                                                    <DropdownMenuCheckboxItem
                                                        textValue="**" // hack so that typing in search doesn't grab the item
                                                        key={user.id}
                                                        className="gap-x-sm flex items-center hover:bg-bg-overlay"
                                                        checked={approvals.userIds.includes(user.id)}
                                                        side="right"
                                                        onSelect={e => e.preventDefault()}
                                                        onCheckedChange={checked => onUserSelect(user.id, checked)}
                                                    >
                                                        <div className="gap-md ml-md flex items-center">
                                                            <UserAvatar user={user} />
                                                            <span>{user.displayName}</span>
                                                        </div>
                                                    </DropdownMenuCheckboxItem>
                                                ))}
                                                {filteredUsers.length === 0 && searchTermUser.length > 0 && (
                                                    <DropdownMenuItem disabled>
                                                        No users found for "{searchTermUser}"
                                                    </DropdownMenuItem>
                                                )}
                                            </DropdownMenuSubContent>
                                        </DropdownMenuSub>
                                    )}

                                    {groupOpt && (
                                        <DropdownMenuSub onOpenChange={handleClearSearch}>
                                            <DropdownMenuSubTrigger className="flex items-center">
                                                <div className="flex w-full items-center justify-between">
                                                    <div className="gap-x-sm flex items-center">
                                                        <groupOpt.Icon />
                                                        {groupOpt.label}
                                                    </div>
                                                    {approvals.groupIds.length > 0 && (
                                                        <Badge className="mr-sm">
                                                            {approvals.groupIds.length} selected
                                                        </Badge>
                                                    )}
                                                </div>
                                            </DropdownMenuSubTrigger>
                                            <DropdownMenuSubContent
                                                className="w-[275px] max-h-[300px] overflow-y-scroll"
                                                collisionPadding={10}
                                            >
                                                <div className="bg-bg-overlay pl-sm relative flex items-center rounded-sm border-grey border-[0.5px]">
                                                    <Search className="opacity-50" />
                                                    <Input
                                                        value={searchTermGroup}
                                                        placeholder="Groups"
                                                        onChange={e => setSearchTermGroup(e.currentTarget.value)}
                                                        className="bg-bg-overlay border-none"
                                                        autoFocus
                                                    />
                                                    {searchTermGroup.length > 0 && (
                                                        <button className="right-sm absolute">
                                                            <CircleX onClick={() => handleClearSearch(true)} />
                                                        </button>
                                                    )}
                                                </div>
                                                {selectedGroups.length > 0 && <DropdownMenuSeparator />}
                                                {selectedGroups.map(group => (
                                                    <DropdownMenuCheckboxItem
                                                        textValue="**" // hack so that typing in search doesn't grab the item
                                                        key={group.id}
                                                        className="gap-x-sm flex items-center"
                                                        checked={approvals.groupIds.includes(group.id)}
                                                        side="right"
                                                        onSelect={e => e.preventDefault()}
                                                        onCheckedChange={checked => onGroupSelect(group.id, checked)}
                                                    >
                                                        <div className="gap-md ml-md flex items-center">
                                                            <ResLink
                                                                label={group.name ?? ''}
                                                                entity="groups"
                                                                src={group.logo}
                                                            />
                                                        </div>
                                                    </DropdownMenuCheckboxItem>
                                                ))}
                                                <DropdownMenuSeparator />

                                                {filteredGroups.map(group => (
                                                    <DropdownMenuCheckboxItem
                                                        textValue="**" // hack so that typing in search doesn't grab the item
                                                        key={group.id}
                                                        className="gap-x-sm flex items-center"
                                                        checked={approvals.groupIds.includes(group.id)}
                                                        side="right"
                                                        onSelect={e => e.preventDefault()}
                                                        onCheckedChange={checked => onGroupSelect(group.id, checked)}
                                                    >
                                                        <div className="gap-md ml-md flex items-center">
                                                            <ResLink
                                                                label={group.name ?? ''}
                                                                entity="groups"
                                                                src={group.logo}
                                                            />
                                                        </div>
                                                    </DropdownMenuCheckboxItem>
                                                ))}
                                                {filteredGroups.length === 0 && searchTermGroup.length > 0 && (
                                                    <DropdownMenuItem disabled>
                                                        No groups found for "{searchTermGroup}"
                                                    </DropdownMenuItem>
                                                )}
                                            </DropdownMenuSubContent>
                                        </DropdownMenuSub>
                                    )}
                                </DropdownMenuContent>
                            </DropdownMenu>
                            {approvalType === OptionsTypes.EXPLICIT && (
                                <p className="text-body-subtle mt-sm">
                                    {explicitSelections.map(s => s.displayName).join(', ')}
                                </p>
                            )}
                        </>
                    );
                }}
            />
        </div>
    );
};
