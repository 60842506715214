import { cn } from '@/lib/styling';
import type { HTMLAttributes } from 'react';

interface CircleIndicatorProps extends HTMLAttributes<HTMLDivElement> {
    color: string;
}

export const CircleIndicator = ({ className, color, ...props }: CircleIndicatorProps) => {
    return <div className={cn('size-2 rounded-full shrink-0', className, color)} {...props} />;
};
