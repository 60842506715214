import type { CnslRequestList } from 'lib/models/request';
import { Conversationsource, RequestConversationType, RequestStatus } from 'lib/prisma/enums';

import { ResLink } from '@/components/ResLink';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { renderEventMessage } from '@/lib/cnsl_events';
import { fmtDateFull } from '@/lib/dates';
import { cn } from '@/lib/styling';
import { BookText, ChevronsUpDown, Scale } from 'lucide-react';
import type {
    RequestAppAccess,
    RequestEvents,
    RequestPlaybookInvocation,
    RequestRequestConversation,
    ReviewAppAccess,
    RunAppAccessGrant,
} from '../details/Main';
import { AppAcessEntryDetails } from './AppAcessEntryDetails';

interface TimelineEntryProps {
    entry:
        | RequestPlaybookInvocation
        | RequestRequestConversation
        | RequestEvents
        | RequestAppAccess
        | ReviewAppAccess
        | RunAppAccessGrant;
    isLastEntry: boolean;
    request: CnslRequestList;
}

export enum TimelineEntryType {
    PlaybookInvocation = 'playbookInvocation',
    RequestConversation = 'requestConversation',
    Event = 'event',
    RequestAppAccess = 'requestAppAccess',
    ReviewAppAccess = 'reviewAppAccess',
    RunAppAccessGrant = 'runAppAccessGrant',
}

function getIconForSource(source: keyof typeof Conversationsource) {
    if (source === Conversationsource.SLACK) {
        return <img src="/3p/slack-logo.png" alt="Slack Logo" className="size-[10px]" />;
    } else if (source === Conversationsource.ZENDESK) {
        return <img src="/3p/zendesk-logo.png" alt="Zendesk Logo" className="size-[10px]" />;
    }
    return null;
}

export const TimelineEntry = ({ entry, isLastEntry, request }: TimelineEntryProps) => {
    const hideContent =
        entry.entityType !== TimelineEntryType.RequestAppAccess &&
        entry.entityType !== TimelineEntryType.RequestConversation;

    return (
        <div>
            <div className="gap-xl flex items-center justify-between">
                <div className="gap-sm flex items-center overflow-visible">
                    {entry.entityType === TimelineEntryType.PlaybookInvocation ? (
                        <>
                            <ResLink
                                className="text-sm"
                                bold
                                label="Console"
                                fallback={
                                    <div className="bg-bg border-grey flex size-6 items-center justify-center rounded-full border">
                                        <BookText className="size-3.5" />
                                    </div>
                                }
                            />
                            <span className="text-body-subtle-hover">{`selected the ${entry.playbook_version.name} Playbook`}</span>
                            <Dialog>
                                <DialogTrigger asChild>
                                    <Button size="sm" mode="borderless">
                                        <ChevronsUpDown className="size-3.5" />
                                    </Button>
                                </DialogTrigger>
                                <DialogContent className="sm:max-w-[425px]">
                                    <DialogHeader>
                                        <DialogTitle>Reasoning</DialogTitle>
                                    </DialogHeader>
                                    <div className="p-lg pt-0 overflow-y-scroll">
                                        {'reasoning' in entry && entry.reasoning
                                            ? entry.reasoning
                                            : 'No reason provided'}
                                    </div>
                                </DialogContent>
                            </Dialog>
                        </>
                    ) : entry.entityType === TimelineEntryType.Event ? (
                        renderEventMessage(entry).rich
                    ) : entry.entityType === TimelineEntryType.RequestAppAccess ? (
                        <>
                            <ResLink
                                entity="users"
                                id={request.requester.id}
                                bold
                                label={request.requester.displayName}
                                src={request.requester.avatar ?? ''}
                                badge={<img src="/3p/slack-logo.png" alt="Slack Logo" className="size-[10px]" />}
                                className="text-sm"
                            />
                            <span className="text-body-subtle-hover">Requested access to {entry.policy.app.name}</span>
                        </>
                    ) : entry.entityType === TimelineEntryType.ReviewAppAccess ? (
                        <>
                            <ResLink
                                entity="users"
                                id={entry.reviewer?.id ?? ''}
                                bold
                                label={entry.reviewer?.displayName ?? ''}
                                src={entry.reviewer?.avatar ?? ''}
                                badge={<img src="/3p/slack-logo.png" alt="Slack Logo" className="size-[10px]" />}
                                className="text-sm"
                            />
                            <span className="text-body-subtle-hover">
                                {entry.status === 'APPROVED' ? 'Granted' : 'Denied'} access to {entry.policy.app.name}
                            </span>
                        </>
                    ) : entry.entityType === TimelineEntryType.RunAppAccessGrant ? (
                        <>
                            <ResLink
                                className="text-sm"
                                bold
                                label="Console"
                                badge={
                                    <ResLink
                                        entity="apps"
                                        id={entry.policy.app.id}
                                        bold
                                        label={entry.policy.app.name}
                                        src={entry.policy.app.logo ?? ''}
                                        avatarOnly
                                        size="sm"
                                    />
                                }
                                fallback={
                                    <div className="bg-bg border-grey flex size-6 items-center justify-center rounded-full border">
                                        <Scale className="size-3.5" />
                                    </div>
                                }
                            />
                            <span className="text-body-subtle-hover">{`Ran grant flow for ${entry.policy.name} policy`}</span>
                        </>
                    ) : (
                        <>
                            {entry.type === 'CNSL_MESSAGE' ? (
                                <ResLink
                                    className="text-sm"
                                    bold
                                    label="Console"
                                    badge={<img src="/3p/slack-logo.png" alt="Slack Logo" className="size-[10px]" />}
                                    fallback={
                                        <div className="bg-bg border-grey flex size-6 items-center justify-center rounded-full border">
                                            <img src="/console-icon.svg" alt="Console Logo" className="size-[12px]" />
                                        </div>
                                    }
                                />
                            ) : (
                                <ResLink
                                    entity="users"
                                    id={entry.user.email}
                                    bold
                                    label={entry.user.displayName}
                                    src={entry.user.avatar ?? ''}
                                    badge={getIconForSource(entry.source)}
                                    className="text-sm"
                                />
                            )}
                            <span className="text-body-subtle-hover">
                                {entry.type === RequestConversationType.SLASH_COMMAND_MESSAGE
                                    ? 'initiated a request'
                                    : 'replied'}
                            </span>
                        </>
                    )}
                </div>
                <div className="text-body-subtle text-xs w-[140px]">{fmtDateFull(entry.createdAt)}</div>
            </div>
            <div className="relative flex">
                {(!isLastEntry || (request.resolvedAt && request.status !== RequestStatus.TICKET_CREATED)) && (
                    <div className="border-grey my-sm relative left-[11.5px] self-stretch border-[0.5px] " />
                )}
                {hideContent ? (
                    <div className="mt-sm mb-xl ml-2xl rounded-[6px] py-xs" />
                ) : (
                    <div
                        className={cn(
                            'mt-sm mb-xl ml-2xl whitespace-pre-line rounded-[6px] text-sm leading-6 py-md',
                            entry.entityType === TimelineEntryType.RequestConversation
                                ? 'bg-bg-blue-primary border-[0.5px] border-blue px-lg'
                                : '',
                        )}
                    >
                        {entry.entityType === TimelineEntryType.RequestAppAccess ? (
                            <AppAcessEntryDetails pr={entry} />
                        ) : (
                            // NOTE(Neal): Handling backwards compatibility for old `data` format which wasn't nested in an object
                            `${typeof entry.data === 'string' ? entry.data : (entry.data as { message: string }).message}`
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
