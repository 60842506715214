import { fmtDateFull } from '@/lib/dates';
import { Tooltip, TooltipContent, TooltipTrigger } from '@ui/tooltip';
import type { MaybeDatable } from 'lib/dates/types';
import type { HTMLAttributes } from 'react';

import { formatDistanceToNow } from 'date-fns';

const formatRelativeDate = (date: MaybeDatable): string => {
    if (!date) return '';
    try {
        const d = new Date(date);
        return formatDistanceToNow(d, { addSuffix: true });
    } catch (_error) {
        return '';
    }
};

interface RelativeDateProps extends HTMLAttributes<HTMLDivElement> {
    date: MaybeDatable;
    prefix?: string;
    suffix?: string;
}

export const RelativeDate = ({ date, prefix, suffix, ...props }: RelativeDateProps) => {
    return (
        <Tooltip>
            <TooltipTrigger className="cursor-default">
                <div {...props}>
                    {prefix ? `${prefix} ` : ''}
                    {formatRelativeDate(date)}
                    {suffix ? ` ${suffix}` : ''}
                </div>
            </TooltipTrigger>
            <TooltipContent side="bottom">
                <div className="text-xs">{fmtDateFull(date)}</div>
            </TooltipContent>
        </Tooltip>
    );
};
