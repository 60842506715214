import type { SingleCnslGroup } from 'lib/models/group';

import { useQuery } from '@/lib/hooks/useQuery';

export const useGroup = ({ slug, enabled }: { slug: string; enabled?: boolean }) => {
    return useQuery<{ group: SingleCnslGroup }>({
        url: `/api/v1/groups/${slug}`,
        enabled,
    });
};
