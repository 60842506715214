import { type ColumnSizingState, type Header, passiveEventSupported, type useReactTable } from '@tanstack/react-table';

/**
 * Switching the table to fixed layout was causing the table resize too quickly when using default tanstack/react-table resize handler.
 * This is a modified version of header.getResizeHandler that throttles the resize event and supports passive event listeners when possible.
 */

export const getResizeHandler = <TData>(
    table: ReturnType<typeof useReactTable<TData>>,
    header: Header<TData, unknown>,
    throttle = 1 / 2,
) => {
    const column = table.getColumn(header.column.id);
    const canResize = column?.getCanResize();

    const isTouchStartEvent = (e: unknown): e is TouchEvent => (e as TouchEvent).type === 'touchstart';

    return (e: unknown) => {
        if (!column || !canResize) {
            return;
        }

        // biome-ignore lint/suspicious/noExplicitAny: coered in isTouchStartEvent
        (e as any).persist?.();

        if (isTouchStartEvent(e)) {
            // lets not respond to multiple touches (e.g. 2 or 3 fingers)
            if (e.touches && e.touches.length > 1) {
                return;
            }
        }

        const startSize = header.getSize();

        const columnSizingStart: [string, number][] = header
            ? header.getLeafHeaders().map(d => [d.column.id, d.column.getSize()])
            : [[column.id, column.getSize()]];

        const clientX = isTouchStartEvent(e) ? Math.round(e.touches[0]!.clientX) : (e as MouseEvent).clientX;

        const newColumnSizing: ColumnSizingState = {};

        const updateOffset = (eventType: 'move' | 'end', clientXPos?: number) => {
            if (typeof clientXPos !== 'number') {
                return;
            }

            table.setColumnSizingInfo(old => {
                const deltaDirection = table.options.columnResizeDirection === 'rtl' ? -1 : 1;
                const deltaOffset = (clientXPos - (old?.startOffset ?? 0)) * deltaDirection * throttle;
                const deltaPercentage = Math.max(deltaOffset / (old?.startSize ?? 0), -0.999999);

                old.columnSizingStart.forEach(([columnId, headerSize]) => {
                    newColumnSizing[columnId] =
                        Math.round(Math.max(headerSize + headerSize * deltaPercentage, 0) * 100) / 100;
                });

                return {
                    ...old,
                    deltaOffset,
                    deltaPercentage,
                };
            });

            if (table.options.columnResizeMode === 'onChange' || eventType === 'end') {
                table.setColumnSizing(old => ({
                    ...old,
                    ...newColumnSizing,
                }));
            }
        };

        const onMove = (clientXPos?: number) => updateOffset('move', clientXPos);

        const onEnd = (clientXPos?: number) => {
            updateOffset('end', clientXPos);

            table.setColumnSizingInfo(old => ({
                ...old,
                isResizingColumn: false,
                startOffset: null,
                startSize: null,
                deltaOffset: null,
                deltaPercentage: null,
                columnSizingStart: [],
            }));
        };

        const contextDocument = document;

        const mouseEvents = {
            moveHandler: (e: MouseEvent) => onMove(e.clientX),
            upHandler: (e: MouseEvent) => {
                contextDocument?.removeEventListener('mousemove', mouseEvents.moveHandler);
                contextDocument?.removeEventListener('mouseup', mouseEvents.upHandler);
                onEnd(e.clientX);
            },
        };

        const touchEvents = {
            moveHandler: (e: TouchEvent) => {
                if (e.cancelable) {
                    e.preventDefault();
                    e.stopPropagation();
                }
                onMove(e.touches[0]!.clientX);
                return false;
            },
            upHandler: (e: TouchEvent) => {
                contextDocument?.removeEventListener('touchmove', touchEvents.moveHandler);
                contextDocument?.removeEventListener('touchend', touchEvents.upHandler);
                if (e.cancelable) {
                    e.preventDefault();
                    e.stopPropagation();
                }
                onEnd(e.touches[0]?.clientX);
            },
        };

        const passiveIfSupported = passiveEventSupported() ? { passive: true } : false;

        if (isTouchStartEvent(e)) {
            contextDocument?.addEventListener('touchmove', touchEvents.moveHandler, passiveIfSupported);
            contextDocument?.addEventListener('touchend', touchEvents.upHandler, passiveIfSupported);
        } else {
            contextDocument?.addEventListener('mousemove', mouseEvents.moveHandler, passiveIfSupported);
            contextDocument?.addEventListener('mouseup', mouseEvents.upHandler, passiveIfSupported);
        }

        table.setColumnSizingInfo(old => ({
            ...old,
            startOffset: clientX,
            startSize,
            deltaOffset: 0,
            deltaPercentage: 0,
            columnSizingStart,
            isResizingColumn: column.id,
        }));
    };
};
