import { getProviderAssets } from 'lib/3p';

import { useIntegrationSettings } from '@/hooks/queries/useIntegrationSettings';
import type { MentionActionAttributesType } from 'lib/tiptap';
import { groupBy } from 'lodash-es';
import { BookText, Box } from 'lucide-react';
import { nanoid } from 'nanoid';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { ResourceOption } from './ResourceOption';
import { SectionLabel } from './SectionLabel';
import { usePlaybookDetailsStore } from './store/usePlaybookDetailsStore';
import { getFilteredOptions } from './utils';

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const ActionList = forwardRef((props: { items: any[]; command: (p: unknown) => void }, ref) => {
    const { data } = useIntegrationSettings();
    const statusMap = data?.status ?? {};

    const [selectedIndex, setSelectedIndex] = useState(0);
    const saveForm = usePlaybookDetailsStore(state => state.saveForm);

    // used for scrolling the selected element into view
    const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

    // Scroll the selected element into view
    useEffect(() => {
        if (itemRefs.current[selectedIndex]) {
            itemRefs.current[selectedIndex].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    }, [selectedIndex]);

    const groupByProvider = groupBy(props.items, 'provider');

    Object.keys(groupByProvider).map(provider => {
        const filteredActions = getFilteredOptions(groupByProvider[provider]);
        if (filteredActions.length > 0) {
            groupByProvider[provider] = filteredActions;
        } else {
            delete groupByProvider[provider];
        }
    });

    const selectItem = (index: number) => {
        const item = props.items[index];

        if (item) {
            props.command({
                id: item.id,
                referenceType: item.type,
                referenceId: nanoid(),
            } satisfies MentionActionAttributesType);
            saveForm?.();
        }
    };

    const upHandler = () => {
        setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
    };

    const downHandler = () => {
        setSelectedIndex((selectedIndex + 1) % props.items.length);
    };

    const enterHandler = () => {
        selectItem(selectedIndex);
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => setSelectedIndex(0), [props.items]);

    useImperativeHandle(ref, () => ({
        onKeyDown: ({ event }: { event: KeyboardEvent }) => {
            if (event.key === 'ArrowUp') {
                event.preventDefault();
                event.stopPropagation();
                upHandler();
                return true;
            }

            if (event.key === 'ArrowDown') {
                event.preventDefault();
                event.stopPropagation();
                downHandler();
                return true;
            }

            if (event.key === 'Enter') {
                event.preventDefault();
                event.stopPropagation();
                enterHandler();
                return true;
            }

            return false;
        },
    }));

    const noResults = Object.keys(groupByProvider).length === 0;

    if (noResults) {
        return (
            <div className="h-64 overflow-scroll bg-bg-white z-40">
                <div className="flex flex-col p-lg">
                    <SectionLabel label="No results" />
                </div>
            </div>
        );
    }

    return (
        <div className="h-64 overflow-y-scroll bg-bg rounded-md shadow-xl border-[0.5px] border-grey">
            <div className="flex flex-col p-sm w-64">
                {Object.keys(groupByProvider).map(provider => {
                    if (!groupByProvider[provider].length) return null;
                    const p = getProviderAssets(provider);
                    const connected = statusMap[provider];

                    return (
                        <div key={provider} className="mb-lg">
                            <SectionLabel label={p.name} connected={connected} />
                            {groupByProvider[provider].map(option => {
                                const index = props.items.findIndex(a => a.id === option.id);

                                return (
                                    <div
                                        key={option.id}
                                        ref={el => {
                                            itemRefs.current[index] = el;
                                        }}
                                    >
                                        <ResourceOption
                                            title={option.name}
                                            description={option.description}
                                            src={p.logo}
                                            connected={connected}
                                            icon={
                                                option.type === 'playbook' ? (
                                                    <BookText className="shrink-0" />
                                                ) : provider === 'custom' ? (
                                                    <Box className="shrink-0" />
                                                ) : undefined
                                            }
                                            isActive={index === selectedIndex}
                                            onClick={connected === false ? undefined : () => selectItem(index)}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
});
