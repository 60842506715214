import { AppStatusSelect } from '@/components/apps/AppStatusSelect';
import type { SingleCnslApp } from 'lib/models/app';
import { capitalize } from 'lodash-es';
import { CalendarDays, KeyRound, NotebookTabs } from 'lucide-react';

import { KV } from '@/components/KV';
import { ResLink } from '@/components/ResLink';
import { prettyAccessType } from '@/lib/apps';
import { fmtDateFull } from '@/lib/dates';
import { cn } from '@/lib/styling';
import { ProviderAssets } from 'lib/3p';

import { AccessRequestHistory } from './AccessRequestHistory';
import { AppOwnersSelect } from './AppOwnersSelect';

interface OverviewProps {
    app: SingleCnslApp;
    mode?: 'details' | 'policies';
}

export const Overview = ({ app, mode = 'details' }: OverviewProps) => {
    const providerConfig = ProviderAssets[app.provider as keyof typeof ProviderAssets];
    const heading = mode === 'policies' ? app.name : 'Overview';
    const headingIcon =
        mode === 'policies' ? (
            <ResLink entity="apps" id={app.slug} avatarOnly size="xl" rounded="square" />
        ) : (
            <NotebookTabs className="size-6" />
        );

    return (
        <div
            className={cn(
                'mx-2xl gap-4xl flex flex-col min-[1260px]:m-auto',
                mode === 'policies' ? '' : 'max-w-screen-sm py-4xl',
            )}
        >
            <div className="gap-lg flex flex-col">
                <div className="gap-md flex items-center">
                    {headingIcon}
                    <h1 className="text-xl">{heading}</h1>
                </div>
                <div
                    className={cn(
                        'grid',
                        mode === 'policies' ? 'xl:grid-cols-2 gap-x-lg justify-between' : 'grid-cols-1',
                    )}
                >
                    <KV
                        label={<div>Status</div>}
                        value={<AppStatusSelect trigger="kv" status={app.status} slug={app.slug} />}
                    />
                    <KV label={<div>Owner</div>} value={<AppOwnersSelect trigger="kv" slug={app.slug} />} />
                    <KV
                        label={<div>Source</div>}
                        value={
                            <div className="gap-md px-md py-sm flex items-center">
                                {providerConfig ? (
                                    <span className="gap-md flex items-center">
                                        <img src={providerConfig.logo} alt={providerConfig.name} className="size-4" />
                                        <span>{providerConfig.name}</span>
                                    </span>
                                ) : (
                                    capitalize(app?.provider)
                                )}
                            </div>
                        }
                    />
                    <KV
                        label={<div>Access type</div>}
                        value={
                            <div className="gap-md px-md py-sm flex items-center">
                                <KeyRound />
                                {prettyAccessType(app.authType ?? '')}
                            </div>
                        }
                    />
                    <KV
                        label={<div>First detected</div>}
                        value={
                            <div className="gap-md px-md py-sm flex items-center">
                                <CalendarDays />
                                {fmtDateFull(app.addedAt ?? app.createdAt)}
                            </div>
                        }
                    />
                </div>
                {mode === 'details' && (
                    <div>
                        <AccessRequestHistory slug={app.slug} />
                    </div>
                )}
            </div>
        </div>
    );
};
