import type { MentionOptions } from '@tiptap/extension-mention';
import { ReactRenderer } from '@tiptap/react';
import { getPathVarMentionConfig } from 'lib/tiptap';
import { type ReactNode, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import tippy, { type GetReferenceClientRect, type Instance } from 'tippy.js';
import { useEditorParameterStore } from './useEditorParameterStore';

const PathPopover = forwardRef((props: { items: ReactNode[]; command: (_: unknown) => void }, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    // used for scrolling the selected element into view
    const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

    // Scroll the selected element into view
    useEffect(() => {
        if (itemRefs.current[selectedIndex]) {
            itemRefs.current[selectedIndex].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    }, [selectedIndex]);

    const selectItem = (index: number) => {
        const item = props.items[index];

        if (item) {
            props.command({ id: item });
        }
    };

    const upHandler = () => {
        setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
    };

    const downHandler = () => {
        setSelectedIndex((selectedIndex + 1) % props.items.length);
    };

    const enterHandler = () => {
        selectItem(selectedIndex);
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => setSelectedIndex(0), [props.items]);

    useImperativeHandle(ref, () => ({
        onKeyDown: ({ event }: { event: KeyboardEvent }) => {
            if (event.key === 'ArrowUp') {
                upHandler();
                return true;
            }

            if (event.key === 'ArrowDown') {
                downHandler();
                return true;
            }

            if (event.key === 'Enter') {
                enterHandler();
                return true;
            }

            return false;
        },
    }));

    const noResults = props.items.length === 0;

    if (noResults) {
        return (
            <div className="max-h-64 overflow-y-scroll bg-bg rounded-md shadow-xl border-[0.5px] border-grey min-w-[120px] p-md text-body-subtle">
                No results
            </div>
        );
    }

    return (
        <div className="max-h-64 overflow-y-scroll bg-bg rounded-md shadow-xl border-[0.5px] border-grey min-w-[120px]">
            <div className="flex flex-col p-sm">
                {props.items.map((i, index) => (
                    <div
                        key={index}
                        ref={el => {
                            itemRefs.current[index] = el;
                        }}
                        className={`p-sm cursor-pointer rounded-sm uppercase hover:bg-bg-overlay ${index === selectedIndex ? 'bg-bg-overlay' : ''}`}
                        onClick={() => selectItem(index)}
                    >
                        {i}
                    </div>
                ))}
            </div>
        </div>
    );
});

export const actionDetailMentionConfig: Partial<MentionOptions> = {
    ...getPathVarMentionConfig({ allowLabel: true }),
    suggestion: {
        ...getPathVarMentionConfig({ allowLabel: true }).suggestion,
        items: ({ query }: { query: string }) => {
            const { parameters } = useEditorParameterStore.getState();

            return parameters.filter(p => p.toLowerCase().includes(query.toLowerCase()));
        },
        render: () => {
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            let component: ReactRenderer<any>;
            let popup: Instance<unknown>[];
            return {
                onStart: props => {
                    component = new ReactRenderer(PathPopover, {
                        props,
                        editor: props.editor,
                    });

                    if (!props.clientRect) {
                        return;
                    }

                    popup = tippy('body', {
                        getReferenceClientRect: props.clientRect as GetReferenceClientRect,
                        appendTo: () => document.body,
                        content: component.element,
                        showOnCreate: true,
                        interactive: true,
                        trigger: 'manual',
                        placement: 'bottom-start',
                    });
                },
                onUpdate(props) {
                    component.updateProps(props);

                    if (!props.clientRect) {
                        return;
                    }

                    popup[0].setProps({
                        getReferenceClientRect: props.clientRect,
                    });
                },

                onKeyDown(props) {
                    if (props.event.key === 'Escape') {
                        popup[0].hide();

                        return true;
                    }

                    return component.ref?.onKeyDown(props);
                },

                onExit() {
                    popup[0].destroy();
                    component.destroy();
                },
            };
        },
    },
};
