import { useMutation } from '@/lib/hooks/useMutation';

import { type DefaultError, useQueryClient } from '@tanstack/react-query';
import type { IntegrationId } from 'lib/integration';
import type { DirectoryProviderSelectedType } from 'lib/models/directory';

export const useArticleExternalDirectorySelectedPost = ({ provider }: { provider: IntegrationId }) => {
    const queryClient = useQueryClient();
    return useMutation<{ selected: DirectoryProviderSelectedType }, DefaultError, { selectedIds: (string | number)[] }>(
        {
            url: `/api/v1/directory/${provider}/selected`,
            method: 'POST',
            onSuccess: async () => {
                await queryClient.refetchQueries({ queryKey: ['/api/v1/directory'] });
                await queryClient.refetchQueries({ queryKey: [`/api/v1/articles`] });
            },
            errorToast: {
                message: 'Failed to update selected articles',
            },
        },
    );
};
