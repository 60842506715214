import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/styling';
import { CircleCheck } from 'lucide-react';
import type { ReactNode } from 'react';

export const OnboardingStep = ({
    step,
    title,
    description,
    content,
    completed,
}: { step: number; title: string; description: string; content: ReactNode; completed: boolean }) => {
    return (
        <div className="bg-bg flex flex-col p-lg gap-md">
            <div className="flex items-center justify-between">
                <Badge
                    variant={completed ? 'green' : 'grey'}
                    mode={completed ? 'dark' : 'light'}
                >{`Step ${step}`}</Badge>
                {completed ? (
                    <CircleCheck className="size-6 text-body-green-primary" stroke="white" fill="currentColor" />
                ) : (
                    <CircleCheck className="size-6 text-steel-600" stroke="white" fill="currentColor" />
                )}
            </div>
            <div className="flex flex-col">
                <h3 className={cn(completed ? 'text-body-grey-primary' : 'text-body-subtle-hover')}>{title}</h3>
                <p className="text-body-subtle">{description}</p>
            </div>
            <div>{content}</div>
        </div>
    );
};
