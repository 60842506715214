import { cn } from '@/lib/styling';
import type React from 'react';

interface RequestReferenceBadgeProps {
    label: string;
    icon: React.ReactNode;
    className?: string;
}

export const RequestReferenceBadge: React.FC<RequestReferenceBadgeProps> = ({ label, icon, className }) => {
    return (
        <div className="flex items-center justify-center">
            <div
                className={cn(
                    'inline-flex items-center font-medium bg-bg-subtle text-xs text-fg border border-grey whitespace-nowrap rounded-md px-2 py-1',
                    className,
                )}
            >
                <span className="flex items-center justify-center mr-1">{icon}</span>
                <span>{label}</span>
            </div>
        </div>
    );
};
