import { Select, type TriggerVariant } from '@ui/select';
import type { OptionProps, SingleValueProps } from 'react-select';
import { components as SelectPrimitiveComponents } from 'react-select';

import { AppStatus } from '@/components/AppStatus';
import { useAppStatusPut } from '@/hooks/mutations/apps/useAppsStatusPut';
import { appStatusOptions } from '@/lib/apps';

const Option = <T,>(props: OptionProps<{ label: string; value: T; color: string }>) => {
    const { children, ...restProps } = props;
    const data = restProps.data;

    return (
        <SelectPrimitiveComponents.Option {...restProps}>
            <div className="flex items-center">
                <AppStatus color={data.color} label={data.label} />
            </div>
        </SelectPrimitiveComponents.Option>
    );
};

const Value = <T,>(props: SingleValueProps<{ label: string; value: T; color: string }>) => {
    const { children, ...restProps } = props;
    const data = restProps.data;

    return (
        <SelectPrimitiveComponents.SingleValue {...restProps}>
            <div className="flex items-center">
                <AppStatus color={data.color} label={data.label} />
            </div>
        </SelectPrimitiveComponents.SingleValue>
    );
};

export const AppStatusSelect = ({
    status,
    slug,
    trigger = 'default',
}: { status: string; slug: string; trigger?: TriggerVariant }) => {
    const statusMutation = useAppStatusPut({ slug });

    return (
        <Select
            isMulti={false}
            trigger={trigger}
            components={{ Option, SingleValue: Value }}
            value={appStatusOptions.find(({ value }) => value === status)}
            options={appStatusOptions}
            onChange={option => {
                if (option) {
                    statusMutation.mutate({ status: option.value });
                }
            }}
        />
    );
};
