import type {
    PlaybookListResponse,
    PlaybookResponse,
    PlaybookRewriteResponse,
    PlaybookTemplateResponse,
    PlaybookVersionsResponse,
} from 'lib/models/playbook';

import { useQuery } from '@/lib/hooks/useQuery';
import type { ActionsResponse, PlaybookReferencesResponse } from 'lib/actions/actions';
import type { ApiError } from 'lib/models/error';

export const usePlaybookActions = () => {
    return useQuery<ActionsResponse>({
        url: `/api/v1/playbooks/actions`,
    });
};

export const usePlaybookTemplates = () => {
    return useQuery<PlaybookTemplateResponse>({
        url: `/api/v1/playbooks/templates`,
    });
};

export const usePlaybook = (key: string) => {
    return useQuery<PlaybookResponse>({
        url: `/api/v1/playbooks/${key}`,
    });
};

export const usePlaybookVersions = (key: string) => {
    return useQuery<PlaybookVersionsResponse>({
        url: `/api/v1/playbooks/${key}/versions`,
    });
};

export const usePlaybooks = () => {
    return useQuery<PlaybookListResponse>({
        url: `/api/v1/playbooks`,
    });
};

export const usePlaybookInstructionReferences = () => {
    return useQuery<PlaybookReferencesResponse>({
        url: `/api/v1/playbooks/references`,
        refetchOnMount: true,
    });
};

export const usePlaybookRewrite = (slug: string, model?: 'o1-preview') => {
    const modelParam = model ? `model=${model}` : '';
    return useQuery<PlaybookRewriteResponse | ApiError>({
        url: `/api/v1/playbooks/${slug}/rewrite${modelParam ? `?${modelParam}` : ''}`,
        enabled: false,
    });
};
