import { useLocation, useParams } from 'react-router-dom';

import { ResLink } from '@/components/ResLink';
import { useGroup } from '@/hooks/queries/useGroup';
import { CnslGroupIcon, CnslUserIcon } from '@/lib/iconConstants';
import { Details } from '@/pages/layouts/Details';
import { DetailsCrumbIconWrapper } from '@/pages/layouts/components/DetailsCrumbIconWrapper';
import type { BrowConfig } from '@/pages/layouts/types/DetailsConfig';
import { SkeletonDetails } from '@ui/skeleton';

import { getProviderAssets } from 'lib/3p';
import { Users } from './Users';

const entity = 'groups';

export const GroupDetails = () => {
    const currentPath = useLocation().pathname;
    const { slug = '' } = useParams();
    const { data, isLoading } = useGroup({ slug });
    const group = data?.group;

    if (isLoading) {
        return <SkeletonDetails />;
    }

    if (!group) {
        return null;
    }

    const config = [
        {
            name: 'Members',
            pathname: '',
            count: group.group_users.length,
            Icon: CnslUserIcon,
            component: <Users group={group} />,
            matchPathExactly: true,
        },
    ];

    const basePath = `/${entity}/${slug}`;

    const crumbs = [
        { label: <DetailsCrumbIconWrapper label="Groups" icon={<CnslGroupIcon />} />, href: `/${entity}` },
        {
            label: (
                <ResLink
                    id={slug}
                    label={group?.name ?? ''}
                    src={getProviderAssets(group.provider).logo}
                    rounded="square"
                    size="sm"
                    bold={basePath === currentPath}
                />
            ),
            href: basePath,
        },
    ];

    config.forEach(c => {
        c.pathname = `${basePath}${c.pathname}`;
        if (c.pathname === currentPath && c.pathname !== basePath) {
            crumbs.push({
                label: <DetailsCrumbIconWrapper label={c.name} icon={<c.Icon />} bold={true} />,
                href: c.pathname,
            });
        }
    });

    const browConfig: BrowConfig = {
        breadcrumbs: crumbs,
    };
    return (
        <Details
            config={config}
            headerConfig={{
                src: getProviderAssets(group.provider).logo,
                title: group.name ?? 'Group Name',
                subtitle: group.description ?? '',
                fallback: group.name?.[0],
                rounded: 'square',
            }}
            browConfig={browConfig}
        />
    );
};
