import type { ColumnDef } from '@tanstack/react-table';
import type { SingleCnslUser, UserProviderProfile } from 'lib/models/cnsl_user';

import { AppProvider } from '@/components/AppProvider';
import { TableEmptyState } from '@/components/TableEmptyState';
import { Button } from '@/components/ui/button';
import { Table } from '@/components/ui/table/table';
import { fmtDateFull } from '@/lib/dates';
import { useNavigate } from 'react-router-dom';

const columns: ColumnDef<UserProviderProfile>[] = [
    {
        accessorKey: 'provider',
        header: () => 'App name',
        cell: props => (
            <span className="font-medium">
                <AppProvider size="default" provider={props.row.original.provider} />
            </span>
        ),
    },
    {
        accessorKey: 'email',
        header: () => 'Account',
        cell: props => props.row.original.cnsl_user.email,
    },
    {
        accessorKey: 'createdAt',
        header: () => 'Date detected',
        cell: props => <div className="truncate">{fmtDateFull(props.row.original.createdAt)}</div>,
    },
];

const EmptyState = () => {
    const navigate = useNavigate();

    return (
        <TableEmptyState
            title="Accounts"
            description="No connected accounts found for this user"
            imageSrc="/emptyStates/empty-accounts.svg"
        >
            <Button onClick={() => navigate('/settings/organization/integrations/okta')}>
                <img src="/3p/okta-logo.png" alt="Okta logo" className="size-4" />
                Connect Okta
            </Button>
        </TableEmptyState>
    );
};

export const Accounts = ({ user }: { user: SingleCnslUser }) => {
    const providers = user.providerProfiles ?? [];

    return <Table data={providers} columns={columns} emptyState={<EmptyState />} />;
};
