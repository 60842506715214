import { ResLink } from '@/components/ResLink';
import { useUser } from '@/hooks/queries/useUser';
import { CnslAppIcon, CnslGroupIcon, CnslUserIcon } from '@/lib/iconConstants';
import { initials } from '@/lib/initials';
import { Details } from '@/pages/layouts/Details';
import { DetailsCrumbIconWrapper } from '@/pages/layouts/components/DetailsCrumbIconWrapper';
import type { DetailsConfig } from '@/pages/layouts/types/DetailsConfig';
import { APPS, GROUPS } from '@/routes/paths';
import { Accounts } from '@pages/users/details/Accounts';
import { Apps } from '@pages/users/details/Apps';
// import { Events } from '@pages/users/details/Events';
import { Groups } from '@pages/users/details/Groups';
import { Overview } from '@pages/users/details/Overview';
import { SkeletonDetails } from '@ui/skeleton';
import type { SingleCnslUser } from 'lib/models/cnsl_user';
import { Link2, NotebookTabs } from 'lucide-react';
import { useLocation, useParams } from 'react-router-dom';

const entity = 'users';

export const UserDetails = () => {
    const { slug = '' } = useParams();
    const { data, isLoading } = useUser({ email: slug });
    const { pathname } = useLocation();

    const user = data?.user;

    if (isLoading) {
        return <SkeletonDetails />;
    }

    if (!user) {
        return null;
    }

    const providers = user.providerProfiles ?? [];

    const config: DetailsConfig<{ user: SingleCnslUser }> = [
        {
            name: 'Overview',
            pathname: '',
            Icon: NotebookTabs,
            component: <Overview user={user} />,
            matchPathExactly: true,
        },
        {
            name: 'Accounts',
            count: providers.length,
            pathname: '/accounts',
            Icon: Link2,
            component: <Accounts user={user} />,
        },
        { name: 'Apps', count: user.apps.length, pathname: APPS, Icon: CnslAppIcon, component: <Apps user={user} /> },
        {
            name: 'Groups',
            count: user.groups.length,
            pathname: GROUPS,
            Icon: CnslGroupIcon,
            component: <Groups user={user} />,
        },
        // { name: 'Events', pathname: EVENTS, Icon: MousePointerClick, component: <Events user={user} /> },
    ];

    const basePath = `/${entity}/${slug}`;
    config.forEach(c => {
        c.pathname = `${basePath}${c.pathname}`;
    });

    const crumbs = [
        { label: <DetailsCrumbIconWrapper label="Users" icon={<CnslUserIcon />} />, href: `/${entity}` },
        { label: <ResLink size="sm" id={user.id} src={user.avatar} label={user.displayName} />, href: basePath },
    ];

    if (basePath !== pathname) {
        const c = config.find(c => c.pathname === pathname);
        if (c) {
            crumbs.push({
                label: <DetailsCrumbIconWrapper label={c.name} icon={<c.Icon />} bold={true} />,
                href: c.pathname,
            });
        }
    }

    return (
        <Details
            config={config}
            headerConfig={{
                src: user?.avatar ?? initials(user.displayName),
                title: user.displayName,
                subtitle: user.department,
            }}
            browConfig={{
                breadcrumbs: crumbs,
            }}
        />
    );
};
