import { useCopyToClipboard } from '@hooks/useCopyToClipboard';
import { Tooltip, TooltipContent, TooltipTrigger } from '@ui/tooltip';
import { Check as CheckIcon, Copy as CopyIcon } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'sonner';

import { cn } from '@/lib/styling';

import { Button, type ButtonProps } from './ui/button';

interface CopyProps extends ButtonProps {
    text: string;
    className?: string;
    buttonClassName?: string;
    quote?: boolean;
}

export const Copy = ({ text, className = '', mode = 'borderless', buttonClassName, quote }: CopyProps) => {
    const [, copy] = useCopyToClipboard();
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        void copy(text).then(success => {
            if (success) {
                toast.success('Copied to clipboard');
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 2000);
            } else {
                toast.error('Failed to copy to clipboard');
            }
        });
    };

    const renderText = quote ? `"${text}"` : text;

    return (
        <div className={cn('gap-md text-body-subtle-hover flex items-center', className)}>
            {renderText}
            <Tooltip>
                <TooltipTrigger asChild>
                    <Button
                        mode={mode}
                        size="sm"
                        className={cn('w-6 rounded-full', buttonClassName)}
                        onClick={handleCopy}
                    >
                        {isCopied ? <CheckIcon /> : <CopyIcon />}
                    </Button>
                </TooltipTrigger>
                <TooltipContent>{isCopied ? 'Copied!' : 'Copy to clipboard'}</TooltipContent>
            </Tooltip>
        </div>
    );
};
