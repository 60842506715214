import { Button } from '@/components/ui/button';
import { X } from 'lucide-react';

import type { ReactNode } from 'react';
import { usePlaybookDetailsStore } from '../store/usePlaybookDetailsStore';

export const Details = ({
    header,
    description,
    content,
}: { header: ReactNode; description: ReactNode; content?: ReactNode }) => {
    const setActiveOption = usePlaybookDetailsStore(state => state.setActiveOption);
    const setActiveReference = usePlaybookDetailsStore(state => state.setActiveReference);

    const handleClose = () => {
        setActiveOption(undefined);
        setActiveReference(undefined);
    };
    return (
        <div className="flex flex-col">
            <div className="flex justify-between p-lg border-b-[0.5px] border-grey">
                <div className="flex flex-col gap-md">
                    <div className="flex items-center gap-md text-base font-medium">{header}</div>
                    {description && <span className="text-sm text-body-subtle-hover">{description}</span>}
                </div>
                <Button size="sm" mode="borderless" onClick={handleClose}>
                    <X />
                </Button>
            </div>
            {content && <div className="py-lg">{content}</div>}
        </div>
    );
};
