import { create } from 'zustand';

interface ParameterStore {
    parameters: string[];
    setParameters: (parameters: string[]) => void;
}

/**
 * This is used by the search dropdown Tiptal Mention extension
 */
export const useEditorParameterStore = create<ParameterStore>(set => ({
    parameters: [],
    setParameters: (parameters: string[]) => set({ parameters }),
}));
