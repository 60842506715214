import type { GetAvailibleMembersResponse } from 'lib/models/members';
import { Plus } from 'lucide-react';
import qs from 'qs';
import { useState } from 'react';

import { Button } from '@/components/ui/button';
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog';
import { SelectAsync } from '@/components/ui/select';
import { UserResLinkOption, UserResLinkValueContainer } from '@/components/ui/select/UserResLinkComponents';
import { useMemberAdd } from '@/hooks/queries/useMembership';
import { baseFetch } from '@/lib/baseFetch';
import { getHeaders } from '@/lib/getHeaders';
import type { CnslUser } from 'lib/prisma/types';

const AddMember = ({ disabled }: { disabled: boolean }) => {
    const [selectedUsers, setSelectedUsers] = useState<CnslUser[]>([]);
    const addMembers = useMemberAdd();

    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button disabled={disabled}>
                    <Plus />
                    Add member
                </Button>
            </DialogTrigger>

            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Add Members</DialogTitle>
                </DialogHeader>
                <div className="p-lg pb-xl">
                    <SelectAsync
                        isMulti
                        closeMenuOnSelect={false}
                        components={{
                            Option: UserResLinkOption,
                            ValueContainer: UserResLinkValueContainer,
                            MultiValue: () => null,
                        }}
                        loadOptions={async (search, _options, additional) => {
                            const headers = getHeaders();

                            const res = await baseFetch<GetAvailibleMembersResponse>(
                                `/api/v1/members/available?${qs.stringify({ search, cursor: additional?.cursor })}`,
                                { headers },
                            );

                            return {
                                options: res.items.map(value => ({
                                    label: value.displayName,
                                    avatar: value.avatar,
                                    value,
                                    email: value.email,
                                })),
                                hasMore: !!res.pagination.nextCursor,
                                additional: {
                                    cursor: res.pagination.nextCursor,
                                },
                            };
                        }}
                        onChange={i => setSelectedUsers(i.map(u => u.value))}
                    />
                </div>
                <DialogFooter>
                    <DialogClose asChild>
                        <Button mode="borderless">Cancel</Button>
                    </DialogClose>
                    <DialogTrigger asChild>
                        <Button
                            variant="blue"
                            mode="dark"
                            disabled={!selectedUsers.length}
                            type="submit"
                            onClick={() => {
                                addMembers.mutate({ userIds: selectedUsers.map(u => u.id) });
                            }}
                        >
                            Add Members
                        </Button>
                    </DialogTrigger>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default AddMember;
