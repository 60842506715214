import { cn } from '@/lib/styling';
import type { CnslRequestList } from 'lib/models/request';
import { getReferences } from 'lib/requests/utils';
import { BookText, Brain, ScrollText } from 'lucide-react';
import { RequestReferenceBadge } from './RequestReferenceBadge';

interface RequestReferenceProps extends React.HTMLAttributes<HTMLDivElement> {
    request: CnslRequestList;
}
export const RequestReference = ({ request, className, ...props }: RequestReferenceProps) => {
    const references = getReferences(request);
    return (
        <div className={cn('flex gap-sm', className)} {...props}>
            {references.playbookInvocation && <RequestReferenceBadge label="Playbook" icon={<BookText />} />}
            {references.provisioningRequests && <RequestReferenceBadge label="App Access" icon={<ScrollText />} />}
            {references.knowledgeBaseReference && <RequestReferenceBadge label="Knowledge Base" icon={<Brain />} />}
        </div>
    );
};
