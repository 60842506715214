import type { ColumnDef } from '@tanstack/react-table';
import type { SingleCnslUser, UserApp } from 'lib/models/cnsl_user';
import { Info } from 'lucide-react';

import { AppProvider } from '@/components/AppProvider';
import { AppStatus } from '@/components/AppStatus';
import { ResLink } from '@/components/ResLink';
import { TableEmptyState } from '@/components/TableEmptyState';
import { Button } from '@/components/ui/button';
import { Table } from '@/components/ui/table/table';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { appStatusConfig } from '@/lib/apps';
import { fmtDateFull } from '@/lib/dates';
import { getCssColor } from 'lib/requests/utils';
import { useNavigate } from 'react-router-dom';

const columns: ColumnDef<UserApp>[] = [
    {
        accessorKey: 'name',
        header: () => 'Application',
        cell: props => (
            <ResLink
                className="pl-lg h-full"
                entity="apps"
                id={props.row.original.slug}
                label={props.row.original.name ?? ''}
                src={props.row.original.logo ?? ''}
                rounded="square"
                bold
            />
        ),
        meta: {
            expand: true,
        },
    },
    {
        accessorKey: 'status',
        header: () => 'Status',
        cell: props => {
            const { color, label } = appStatusConfig(props.row.original.status);
            return <AppStatus color={getCssColor(color, 'bg')} label={label} />;
        },
    },
    {
        accessorKey: 'provider',
        header: () => 'Source',
        cell: props => <AppProvider provider={props.row.original.provider} />,
    },
    {
        accessorKey: 'addedAt',
        header: () => 'Access granted',
        cell: props => {
            return (
                <div className="flex items-center justify-between truncate">
                    {fmtDateFull(props.row.original.createdAt)}
                    {props.row.original.provider !== 'okta' && (
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Info className="text-body-subtle hover:text-body-grey-primary cursor-help transition duration-100 ease-out" />
                            </TooltipTrigger>
                            <TooltipContent>Approximation based on when Console detected membership</TooltipContent>
                        </Tooltip>
                    )}
                </div>
            );
        },
    },
];

const EmptyState = () => {
    const navigate = useNavigate();

    return (
        <TableEmptyState title="Apps" description="No apps found for this user" imageSrc="/emptyStates/empty-apps.svg">
            <Button onClick={() => navigate('/settings/organization/integrations/okta')}>
                <img src="/3p/okta-logo.png" alt="Okta logo" className="size-4" />
                Connect Okta
            </Button>
            <Button onClick={() => navigate('/settings/organization/integrations/google')}>
                <img src="/3p/google-logo.png" alt="Google logo" className="size-4" />
                Connect Google
            </Button>
        </TableEmptyState>
    );
};

export const Apps = ({ user }: { user: SingleCnslUser }) => {
    return <Table data={user.apps} columns={columns} emptyState={<EmptyState />} />;
};
