import { ResLink } from '@/components/ResLink';
import { cn } from '@/lib/styling';
import { PlaybookState, type PlaybookStateType } from 'lib/prisma/enums';
import type { CnslUser, PlaybookVersion } from 'lib/prisma/types';
import { BookPlus } from 'lucide-react';

export const microhash = (s: string) => {
    let hash = 0;
    for (const char of s) {
        hash = (hash << 5) - hash + char.charCodeAt(0);
        hash |= 0;
    }
    return Math.abs(hash);
};

export const consoleScheme = ['border-grey', 'bg-bg-elevated', 'bg-bg-overlay', 'text-body-grey-primary'];
export const pastelSchemes = [
    ['border-red', 'bg-bg-red-primary-hover', 'bg-bg-red-primary', 'text-body-red-primary'],
    ['border-orange', 'bg-bg-orange-primary-hover', 'bg-bg-orange-primary', 'text-body-orange-primary'],
    ['border-yellow', 'bg-bg-yellow-primary-hover', 'bg-bg-yellow-primary', 'text-body-yellow-primary'],
    ['border-green', 'bg-bg-green-primary-hover', 'bg-bg-green-primary', 'text-body-green-primary'],
    ['border-blue', 'bg-bg-blue-primary-hover', 'bg-bg-blue-primary', 'text-body-blue-primary'],
    ['border-purple', 'bg-bg-purple-primary-hover', 'bg-bg-purple-primary', 'text-body-purple-primary'],
];

export const PlaybookCard = ({
    id,
    state,
    name,
    onClick,
    creator,
    recentVersion,
}: {
    id: string;
    state: PlaybookStateType;
    name: string;
    onClick: () => void;
    creator: CnslUser | null;
    recentVersion: PlaybookVersion;
}) => {
    const [border, spine, bg, title] = creator ? pastelSchemes[microhash(id) % pastelSchemes.length] : consoleScheme;
    return (
        <div className="playbook-container relative" onClick={onClick}>
            {/* Page */}
            <div className="playbook-page bg-bg h-48 w-36 rounded-sm rounded-r-lg absolute -z-10" />
            {/* Cover */}
            <div
                className={cn(
                    'playbook-cover flex h-48 w-36 rounded-l-sm rounded-r-lg border-[0.5px] transition ease-out duration-200 hover:shadow-lg shadow cursor-pointer overflow-hidden',
                    border,
                )}
            >
                <div className={cn('h-full w-6 shrink-0 border-r-[0.5px]', border, spine)} />
                <div className={cn('flex w-[120px] shrink-0 flex-col justify-between p-lg', bg)}>
                    <div className="gap-sm flex flex-col">
                        <div className={cn('text-xs', state === PlaybookState.PUBLISHED ? title : 'text-black-40')}>
                            {state === PlaybookState.PUBLISHED ? 'Published' : 'Draft'}
                        </div>
                        <h4
                            className={cn(
                                'line-clamp-5',
                                state === PlaybookState.DRAFT ? 'text-black-40' : 'text-body-grey-primary',
                            )}
                        >
                            {name || recentVersion.name || 'No title'}
                        </h4>
                    </div>
                    {creator ? (
                        <ResLink
                            label={creator.displayName}
                            src={creator.avatar}
                            size="sm"
                            className="gap-sm"
                            entity="users"
                            id={creator.email}
                        />
                    ) : (
                        <div className="gap-sm flex items-center">
                            <img src="/console-icon.svg" alt="Console Logo" className="size-[12px]" />
                            <div className="text-xs">Console</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export const NewPlaybookCard = ({ onClick }: { onClick: () => void }) => {
    return (
        <div className="playbook-container relative group cursor-pointer" onClick={onClick} data-testid="new-playbook">
            {/* Page */}
            <div className="playbook-page bg-bg h-48 w-36 rounded-sm rounded-r-lg absolute -z-10" />
            {/* Cover */}
            <div className="playbook-cover text-body-subtle border-grey bg-bg-surface gap-sm flex h-48 w-36 items-center justify-center rounded-sm rounded-r-lg border border-dashed group-hover:border-solid group-hover:bg-bg-overlay transition duration-500 ease-out group-hover:border-[0.5px] group-hover:text-body-grey-primary p-4 text-xs">
                <BookPlus />
                New Playbook
            </div>
        </div>
    );
};
