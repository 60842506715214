import { ResLink } from '@/components/ResLink';
import { useElapsedTime } from '@/hooks/useElapsedTime';
import { format, formatDistance, isToday } from 'date-fns';
import { useEffect, useState } from 'react';

import { fmtDateFull } from '@/lib/dates';
import type { CnslUser } from 'lib/prisma/types';

const getLastEditedText = (createdAt?: Date) => {
    if (!createdAt) return '';

    const isVersionToday = isToday(new Date(createdAt));

    const lastEditedDate = isVersionToday ? format(new Date(createdAt), 'h:mm a') : fmtDateFull(createdAt);
    return isVersionToday ? `Last edited today at ${lastEditedDate} by` : `Last edited ${lastEditedDate} by`;
};

const getCreatedText = (createdAt?: Date) => {
    const [date, setDate] = useState(new Date());
    const { elapsedTime } = useElapsedTime({ updateInterval: 20 });

    useEffect(() => {
        if (elapsedTime) {
            setDate(new Date());
        }
    }, [elapsedTime]);

    if (!createdAt) return '';
    return `Created ${formatDistance(new Date(createdAt), date, { addSuffix: true })}`;
};

// Used by articles and playbooks to display who last edited and who created it
export const ModifiedTime = ({
    versionUpdatedBy,
    versionCreatedAt,
    versionNumber,
}: {
    versionUpdatedBy?: CnslUser;
    versionCreatedAt?: Date;
    versionNumber?: number;
}) => {
    const sayCreated = versionNumber === 0;
    const lastEdited = getLastEditedText(versionCreatedAt);

    return (
        <div className="text-body-subtle flex gap-sm items-center py-sm w-full text-[10px]">
            {sayCreated ? getCreatedText(versionCreatedAt) : lastEdited}
            {versionUpdatedBy && (
                <ResLink
                    label={versionUpdatedBy.displayName}
                    src={versionUpdatedBy.avatar}
                    size="sm"
                    className="gap-sm hover:text-body-grey-primary duration-150 ease-out transition-all"
                    entity="users"
                    id={versionUpdatedBy.email}
                />
            )}
        </div>
    );
};
