import { ResLink } from '@/components/ResLink';
import { TableEmptyState } from '@/components/TableEmptyState';
import type { ColumnDef } from '@tanstack/react-table';
import { Button } from '@ui/button';
import {
    Drawer,
    DrawerContent,
    DrawerDescription,
    DrawerHeader,
    DrawerOverlay,
    DrawerTitle,
    DrawerTrigger,
} from '@ui/drawer';
import { Table } from '@ui/table/table';
import { CircleDashed, Ellipsis, Trash2, UserRoundSearch } from 'lucide-react';
import { type ReactNode, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Confirmation } from '@/components/Confirmation';
import { Stacked } from '@/components/Stacked';
import { CreatePolicyBtn } from '@/components/policies/CreatePolicyBtn';
import { usePolicyDelete } from '@/hooks/mutations/policies/usePolicyDelete';
import { CnslPolicyIcon } from '@/lib/iconConstants';
import { LIMIT_DEFAULT, THRESHOLD_DEFAULT } from '@/lib/paginationConstants';
import { PolicyDetailsDrawerContent } from '@/pages/policies/details';
import { POLICY_DETAILS_V2 } from '@/routes/paths';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@ui/dropdown-menu';
import { type PolicyListItem, prettyReviewStrategy } from 'lib/models/app_policy';
import { AppPolicyReviewStrategy, type AppPolicyReviewStrategyType } from 'lib/prisma/enums';
import type { CnslApp } from 'lib/prisma/types';

export const PolicyApproversCell = ({ policy }: { policy: PolicyListItem }) => {
    type Reviewer = {
        label: string;
        entity: 'users' | 'groups' | null;
        icon: ReactNode;
    };
    type ReviewStepConfig = {
        reviewers: Reviewer[];
        strategy: AppPolicyReviewStrategyType;
    };
    const reviewStepMap: Record<string, ReviewStepConfig> = {};

    policy.reviewSteps.forEach(s => {
        const reviewers: Reviewer[] = [];
        if (s.strategy === AppPolicyReviewStrategy.APP_OWNERS) {
            policy.app.owners.forEach(owner => {
                reviewers.push({
                    label: owner.cnsl_user?.displayName ?? '',
                    entity: 'users',
                    icon: (
                        <ResLink
                            size="sm"
                            src={owner.cnsl_user?.avatar ?? ''}
                            label={owner.cnsl_user?.displayName ?? ''}
                            avatarOnly
                            entity={'users'}
                        />
                    ),
                });
            });
        } else if (s.strategy !== AppPolicyReviewStrategy.EXPLICIT) {
            reviewers.push({
                label: prettyReviewStrategy(s.strategy),
                icon:
                    s.strategy === AppPolicyReviewStrategy.NO_REVIEW_REQUIRED ? <CircleDashed /> : <UserRoundSearch />,
                entity: null,
            });
        }

        reviewStepMap[s.id] = {
            reviewers,
            strategy: s.strategy,
        };
    });
    policy.reviewers.forEach(r => {
        const stepId = r.stepId ?? '';
        const label = r.cnsl_user ? r.cnsl_user.displayName : (r.cnsl_group?.name ?? '');
        const entity = r.cnsl_user ? 'users' : 'groups';

        const val: Reviewer = {
            label,
            entity,
            icon: (
                <ResLink
                    size="sm"
                    src={r.cnsl_user ? r.cnsl_user.avatar : r.cnsl_group?.logo}
                    label={label}
                    avatarOnly
                    entity={entity}
                />
            ),
        };

        if (reviewStepMap[stepId]) {
            reviewStepMap[stepId].reviewers.push(val);
        }
    });

    const makeLabel = (config: ReviewStepConfig) => {
        const { reviewers } = config;

        const parts: string[] = [];
        if (reviewers.length === 1) {
            parts.push(reviewers[0].label);
        } else {
            const counts = reviewers.reduce(
                (acc: { users: number; groups: number }, r: Reviewer) => {
                    if (r.entity === 'users') {
                        acc.users += 1;
                    } else {
                        acc.groups += 1;
                    }
                    return acc;
                },
                { users: 0, groups: 0 },
            );
            const userLabel = counts.users === 1 ? 'user' : 'users';
            const groupLabel = counts.groups === 1 ? 'group' : 'groups';

            if (counts.users) {
                parts.push(`${counts.users} ${userLabel}`);
            }
            if (counts.groups) {
                parts.push(`${counts.groups} ${groupLabel}`);
            }
        }
        const label = parts.join(' and ');
        if (config.strategy === AppPolicyReviewStrategy.APP_OWNERS) {
            return `${label} (App Owner${reviewers.length > 1 ? 's' : ''})`;
        }
        return label;
    };

    const filteredReviewStepMap = Object.fromEntries(
        Object.entries(reviewStepMap).filter(([_, config]) => config.reviewers.length > 0),
    );

    return (
        <div className="flex items-center gap-sm">
            {Object.entries(filteredReviewStepMap).map(([stepId, config]) => (
                <div key={stepId} className="p-sm rounded-[5px] border-[0.5px] border-grey flex gap-sm flex-shrink-0">
                    <Stacked
                        items={config.reviewers}
                        getTooltipContent={item => item?.label ?? ''}
                        renderItem={reviewer => reviewer.icon}
                    />
                    <span className="font-medium text-xs">{makeLabel(config)}</span>
                </div>
            ))}
        </div>
    );
};

const PolicyDrawer = ({ policy }: { policy: PolicyListItem }) => {
    const { policyId } = useParams<{ policyId: string }>();
    const [isOpen, setIsOpen] = useState(policyId === policy.id);
    const navigate = useNavigate();
    const handleOpenChange = (open: boolean) => {
        if (!open) {
            setIsOpen(false);
            navigate(POLICY_DETAILS_V2.replace(':slug', policy.app.slug).replace('/:policyId', ''));
        }
    };

    return (
        <Drawer open={isOpen} onOpenChange={handleOpenChange}>
            <DrawerTrigger asChild>
                <Link
                    to={POLICY_DETAILS_V2.replace(':slug', policy.app.slug).replace(':policyId', policy.id)}
                    className="size-full flex items-center"
                >
                    {policy.name}
                </Link>
            </DrawerTrigger>
            <DrawerOverlay blur={false} lighten />
            <DrawerContent className="w-1/2 min-w-[300px]">
                <DrawerHeader className="px-2xl">
                    <DrawerTitle className="flex items-center gap-md ">
                        <CnslPolicyIcon />
                        Policy Details
                    </DrawerTitle>
                    <DrawerDescription>View and edit policy settings</DrawerDescription>
                </DrawerHeader>
                <PolicyDetailsDrawerContent policyId={policy.id} />
            </DrawerContent>
        </Drawer>
    );
};

export const getDefaultColumns = (mode: 'page' | 'drawer'): ColumnDef<PolicyListItem>[] => {
    return [
        {
            header: () => <div>Access Policy</div>,
            accessorKey: 'name',
            cell: info => {
                const policy = info.row.original;

                return mode === 'drawer' ? (
                    <PolicyDrawer policy={policy} />
                ) : (
                    <Link to={`/policies/${policy.id}`}>{policy.name}</Link>
                );
            },
        },
        {
            header: 'Reviewer',
            accessorKey: 'reviewer',
            cell: props => {
                const policy = props.row.original;
                if ('reviewSteps' in policy) {
                    return <PolicyApproversCell policy={policy} />;
                }
                return null;
            },
        },

        {
            header: () => 'Description',
            accessorKey: 'description',
            cell: info => <span className="line-clamp-1">{info.row.original.description}</span>,
        },
        {
            header: () => '',
            id: 'actions',
            size: 0,
            meta: { expand: true },
            cell: info => {
                const [showPop, setShowPop] = useState(false);
                const policy = info.row.original;
                const slug = policy.app.slug;
                const del = usePolicyDelete({ policyId: info.row.original.id, appSlug: slug });

                return (
                    <DropdownMenu open={showPop}>
                        <DropdownMenuTrigger asChild>
                            <Button
                                mode="borderless"
                                onClick={() => setShowPop(true)}
                                className="size-full rounded-none text-body-subtle hover:text-body-grey-primary"
                            >
                                <Ellipsis />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent side="bottom" align="end" onInteractOutside={() => setShowPop(false)}>
                            <Confirmation
                                ctaLabel="Delete Policy"
                                title={`Delete "${info.row.original.name}" policy?`}
                                subtitle="Once this policy is deleted, it cannot be recovered"
                                onClose={() => setShowPop(false)}
                                onConfirm={() => del.mutate({ policyId: info.row.original.id, appSlug: slug })}
                            >
                                <DropdownMenuItem className="text-body-red-primary">
                                    <Trash2 />
                                    Delete policy
                                </DropdownMenuItem>
                            </Confirmation>
                        </DropdownMenuContent>
                    </DropdownMenu>
                );
            },
        },
    ];
};

const EmptyState = ({ app }: { app?: CnslApp }) => (
    <TableEmptyState
        title="Access Policies"
        description="Create your first access policy to get started"
        imageSrc="/emptyStates/empty-policies.svg"
    >
        <CreatePolicyBtn size="default" app={app} />
    </TableEmptyState>
);

export const Policies = ({ slug, app, mode }: { slug: string; app?: CnslApp; mode: 'page' | 'drawer' }) => {
    const columns = getDefaultColumns(mode);
    return (
        <Table
            columns={columns}
            pagination={{
                queryKey: [slug, 'policies'],
                limit: LIMIT_DEFAULT,
                threshold: THRESHOLD_DEFAULT,
                pathname: `/api/v1/apps/${slug}/policies`,
            }}
            emptyState={<EmptyState app={app} />}
        />
    );
};
