import { PolicyHistory } from '@/components/apps/PolicyHistory';
import type { ColumnDef } from '@tanstack/react-table';
import { Table } from '@ui/table/table';
import type { SingleCnslApp } from 'lib/models/app';

import { Confirmation } from '@/components/Confirmation';
import { ResLink } from '@/components/ResLink';
import { TableEmptyState } from '@/components/TableEmptyState';
import { Button } from '@/components/ui/button';
import { useAppUserDelete } from '@/hooks/mutations/apps/useAppUserDelete';
import { fmtDateFull } from '@/lib/dates';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@ui/dropdown-menu';
import { Ellipsis, Trash2 } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const getColumns = (app: SingleCnslApp): ColumnDef<SingleCnslApp['app_users'][number]>[] => [
    {
        accessorKey: 'name',
        id: 'name',
        header: () => 'Name',
        cell: props => (
            <ResLink
                className="pl-lg h-full"
                entity="users"
                id={props.row.original.cnsl_user.email}
                label={props.row.original.cnsl_user.displayName}
                src={props.row.original.cnsl_user.avatar ?? ''}
                bold
            />
        ),
        meta: {
            expand: true,
        },
    },
    {
        accessorKey: 'title',
        header: () => 'Title',
        cell: props => <div className="truncate">{props.row.original.cnsl_user.title}</div>,
    },
    {
        accessorKey: 'department',
        header: () => 'Department',
        cell: props => <div className="truncate">{props.row.original.cnsl_user.department}</div>,
    },
    {
        accessorKey: 'policies',
        header: () => 'Policies',
        cell: props => <PolicyHistory history={props.row.original.cnsl_user} app={app} />,
    },
    {
        accessorKey: 'addedAt',
        header: () => 'Access granted',
        cell: props => <div className="truncate">{fmtDateFull(props.row.original.addedAt)}</div>,
    },
    {
        header: () => '',
        id: 'actions',
        size: 0,
        meta: { expand: true },
        cell: info => {
            const [showPop, setShowPop] = useState(false);
            const del = useAppUserDelete({
                slug: app.slug,
                userId: info.row.original.cnsl_user.id,
            });

            return (
                info.row.original.provider === 'manual' && (
                    <DropdownMenu open={showPop}>
                        <DropdownMenuTrigger asChild>
                            <Button mode="borderless" onClick={() => setShowPop(true)}>
                                <Ellipsis />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent side="bottom" align="end" onInteractOutside={() => setShowPop(false)}>
                            <Confirmation
                                ctaLabel="Remove user"
                                title={`Remove "${info.row.original.cnsl_user.displayName}" from app?`}
                                subtitle="Once this user is removed, it cannot be recovered."
                                onClose={() => setShowPop(false)}
                                onConfirm={() => del.mutate()}
                            >
                                <DropdownMenuItem className="text-body-red-primary">
                                    <Trash2 className="mr-2 size-4" />
                                    Remove user
                                </DropdownMenuItem>
                            </Confirmation>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            );
        },
    },
];

const EmptyState = () => {
    const navigate = useNavigate();

    return (
        <TableEmptyState
            title="Users"
            description="No users found for this app"
            imageSrc="/emptyStates/empty-users.svg"
        >
            <Button onClick={() => navigate('/settings/organization/integrations/okta')}>
                <img src="/3p/okta-logo.png" alt="Okta logo" className="size-4" />
                Connect Okta
            </Button>
            <Button onClick={() => navigate('/settings/organization/integrations/google')}>
                <img src="/3p/google-logo.png" alt="Google logo" className="size-4" />
                Connect Google
            </Button>
        </TableEmptyState>
    );
};

export const Users = ({ app }: { app: SingleCnslApp }) => {
    return <Table data={app.app_users} columns={getColumns(app)} emptyState={<EmptyState />} />;
};

export default Users;
