import { SelectAsync, type TriggerVariant } from '@ui/select';
import type { AppOwnersListItem } from 'lib/models/app';
import type { PaginatedListResponse } from 'lib/models/pagination';
import type { CnslUser } from 'lib/prisma/types';
import qs from 'qs';

import {
    type CnslUserOption,
    UserResLinkOption,
    UserResLinkValueContainer,
} from '@/components/ui/select/UserResLinkComponents';
import { useAppsOwnersPost } from '@/hooks/mutations/apps/useAppsOwnersPost';
import { useAppOwners } from '@/hooks/queries/useApps';
import { baseFetch } from '@/lib/baseFetch';
import { getHeaders } from '@/lib/getHeaders';
import { useEffect, useState } from 'react';

export const AppOwnersSelect = ({
    slug,
    owners,
    trigger = 'default',
}: { slug: string; owners?: AppOwnersListItem[]; trigger?: TriggerVariant }) => {
    const { data } = useAppOwners({ slug }, { disabled: !!owners });
    const updateAppOwners = useAppsOwnersPost({ slug });
    const [ownerOptions, setOwnerOptions] = useState<readonly CnslUserOption[]>([]);

    useEffect(() => {
        const options = ((owners || data?.items) ?? []).reduce((prev, curr) => {
            if (curr.cnsl_user) {
                prev.push({
                    label: curr.cnsl_user.displayName || curr.cnsl_user.email,
                    avatar: curr.cnsl_user.avatar,
                    value: curr.cnsl_user,
                });
            }
            return prev;
        }, [] as CnslUserOption[]);
        setOwnerOptions(options);
    }, [owners, data]);

    if (!data && !owners) {
        return null;
    }

    return (
        <SelectAsync
            isMulti
            value={ownerOptions}
            trigger={trigger}
            components={{
                Option: UserResLinkOption,
                MultiValue: () => null,
                ValueContainer: UserResLinkValueContainer,
            }}
            placeholder="Not set"
            loadOptions={async (search, _options, additional) => {
                const res = await baseFetch<PaginatedListResponse<CnslUser>>(
                    `/api/v1/users/search?${qs.stringify({ search, cursor: additional?.cursor })}`,
                    { headers: getHeaders() },
                );

                return {
                    options: res.items.map(value => ({
                        label: value.displayName || value.email,
                        avatar: value.avatar,
                        value,
                    })),
                    hasMore: !!res.pagination.nextCursor,
                    additional: {
                        cursor: res.pagination.nextCursor,
                    },
                };
            }}
            onChange={i => {
                if (i) {
                    const ids = i.map(({ value }) => value.id);
                    updateAppOwners.mutate({ owners: ids });
                    setOwnerOptions(i);
                }
            }}
        />
    );
};
