import type { AppSelectChangePayload } from '@/components/AppSelect';
import { ResLink } from '@/components/ResLink';
import { Stacked } from '@/components/Stacked';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Table } from '@/components/ui/table/table';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { useAppMatrixProcess } from '@/hooks/mutations/useAppMatrix';
import { useAppMatrix } from '@/hooks/queries/useAppMatrix';
import { useGroups } from '@/hooks/queries/useGroups';
import { useDefaultPolicyConfig, usePolicyActionTemplates } from '@/hooks/queries/usePolicies';
import { useUsers } from '@/hooks/queries/useUsers';
import { type AppMatrixPolicy, useAppMatrixPolicyValuesStore, usePolicyCreationStore } from '@/stores/usePolicy';
import type { ColumnDef } from '@tanstack/react-table';
import { CnslActionSlug } from 'lib/actions/slugs';
import type { HydratedAppMatrixRow } from 'lib/ai/app_matrix_row';
import type { CnslApp, CnslGroup, CnslUser } from 'lib/prisma/types';
import { capitalize } from 'lodash-es';
import {
    ArrowLeft,
    ArrowRight,
    Check,
    Info,
    PencilLine,
    ScrollText,
    TableProperties,
    Undo,
    UserRoundSearch,
    X,
} from 'lucide-react';
import { useEffect, useState } from 'react';
import { PolicyDetailsApp, PolicyTabs, PolicyTitleDescription } from '../details';

const ColumnChipContainer = ({ children }: { children: React.ReactNode }) => {
    return (
        <div className="flex gap-sm overflow-x-auto w-full relative">
            <div className="bg-gradient-to-l from-bg to-transparent absolute top-0 right-0 w-10 h-full group-hover:from-bg-elevated [[data-rowHover=true]_&]:from-bg-overlay" />
            {children}
        </div>
    );
};

const ColumnChip = ({ children }: { children: React.ReactNode }) => {
    return (
        <div className="h-6 rounded-[5px] border-grey border-[0.5px] px-[5px] py-sm text-xs font-medium flex items-center gap-sm shrink-0">
            {children}
        </div>
    );
};

const GroupsAndUserChipsContainer = ({ userIds, groupIds }: { userIds: string[]; groupIds: string[] }) => {
    const { data: users, isLoading: usersLoading } = useUsers({ userIds: userIds, enabled: userIds.length > 0 });
    const { data: groups, isLoading: groupsLoading } = useGroups(groupIds);

    if (usersLoading || groupsLoading) {
        return null;
    }

    return <GroupsAndUsersChip users={users?.items || []} groups={groups?.items || []} />;
};

const GroupsAndUsersChip = ({ users, groups }: { users: CnslUser[]; groups: CnslGroup[] }) => {
    const count = users.length + groups.length;
    if (count === 0) {
        return null;
    }
    const isMultiple = count > 1;
    const pluralize = (count: number) => (count > 1 ? 's' : '');
    const userStr = users.length ? `${users.length} user${pluralize(users.length)}` : '';
    const groupStr = groups.length ? `${groups.length} group${pluralize(groups.length)}` : '';
    const selectedLabel = [userStr, groupStr].filter(Boolean).join(' and ');
    const values = [
        ...users.map(user => ({ label: user.displayName, avatar: user.avatar, id: user.id })),
        ...groups.map(group => ({ label: group.name || 'Unnamed Group', avatar: group.logo, id: group.id })),
    ];

    return (
        <ColumnChip>
            {isMultiple ? (
                <>
                    <Stacked
                        items={values}
                        renderItem={(item, index) => (
                            <ResLink avatarOnly label={item.label} key={index} src={item.avatar} size="sm" />
                        )}
                        getTooltipContent={item => item.label}
                    />{' '}
                    <Tooltip>
                        <TooltipTrigger>{selectedLabel}</TooltipTrigger>
                        <TooltipContent>{values.map(item => item.label).join(', ')}</TooltipContent>
                    </Tooltip>
                </>
            ) : (
                <ResLink label={values[0].label} src={values[0].avatar} size="sm" />
            )}
        </ColumnChip>
    );
};

export const MatrixModal = ({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: (open: boolean) => void }) => {
    const { data: matrixData, isLoading } = useAppMatrix();
    return isLoading ? (
        <></>
    ) : (
        <MatrixModalContent matrixData={matrixData!.rows || []} isOpen={isOpen} setIsOpen={setIsOpen} />
    );
};

const MatrixModalContent = ({
    matrixData,
    isOpen,
    setIsOpen,
}: { matrixData: HydratedAppMatrixRow[]; isOpen: boolean; setIsOpen: (open: boolean) => void }) => {
    const config = useDefaultPolicyConfig();
    const { data: templateData, isLoading: templatesLoading } = usePolicyActionTemplates();
    const { mutate: processMatrix } = useAppMatrixProcess();
    const [selectedRow, setSelectedRow] = useState<AppMatrixPolicy | null>(null);
    const store = usePolicyCreationStore();
    const persistedStore = useAppMatrixPolicyValuesStore();

    const formValue = selectedRow?.id ? store.getValueForPersistence(selectedRow.id) : null;
    if (formValue) {
        persistedStore.set(formValue);
    }

    const hydratedRows = Object.values(persistedStore.map) || [];

    const jump = (direction: 'next' | 'previous') => {
        const currentIndex = hydratedRows.findIndex(row => row.id === selectedRow?.id);
        const newIndex = direction === 'next' ? currentIndex + 1 : currentIndex - 1;
        const jumpRow = hydratedRows[newIndex];
        if (jumpRow) {
            store.initFromExisting(jumpRow);
            setSelectedRow(jumpRow);
        }
    };

    useEffect(() => {
        if (matrixData && !templatesLoading && templateData?.actionTemplates) {
            persistedStore.initFromCsv(matrixData, templateData.actionTemplates);
        }
    }, [matrixData, persistedStore.initFromCsv, templateData?.actionTemplates, templatesLoading]);

    useEffect(() => {
        if (!selectedRow) {
            store.reset();
        }
    }, [store.reset, selectedRow]);

    const columns: ColumnDef<AppMatrixPolicy>[] = [
        {
            id: 'policy',
            header: () => 'Policy',
            cell: props => {
                const app = props.row.original.app;
                return (
                    <div className="flex gap-md">
                        {app && <ResLink avatarOnly entity={'apps'} src={app.logo} label={app.name} size="sm" />}
                        <div>{props.row.original.name}</div>
                    </div>
                );
            },
        },
        {
            id: 'approvals',
            header: () => 'Approvals',
            cell: props => {
                const approvals = props.row.original.approvals || [];
                return (
                    <ColumnChipContainer>
                        {approvals.map(approval => (
                            <div key={approval.id}>
                                {approval.strategy === 'REQUESTERS_MANAGER' && (
                                    <ColumnChip>
                                        <UserRoundSearch />
                                        Requester's Manager
                                    </ColumnChip>
                                )}
                                {approval.strategy === 'APP_OWNERS' && (
                                    <ColumnChip>
                                        <UserRoundSearch /> App Owners
                                    </ColumnChip>
                                )}
                                {approval.strategy === 'EXPLICIT' && (
                                    <GroupsAndUserChipsContainer
                                        userIds={approval.userIds || []}
                                        groupIds={approval.groupIds || []}
                                    />
                                )}
                            </div>
                        ))}
                    </ColumnChipContainer>
                );
            },
        },
        {
            id: 'access',
            header: () => 'Access',
            // maxSize: 100,
            enableResizing: false,
            cell: props => {
                const groups = [
                    ...(props.row.original.revocationPSteps || []),
                    ...(props.row.original.provisioningPSteps || []),
                ];
                return (
                    <ColumnChipContainer>
                        {groups.map(group => {
                            if (group.selectedOption) {
                                return (
                                    <ColumnChip key={group.id}>
                                        <img
                                            src={group.selectedOption!.logo}
                                            alt={group.selectedOption!.label}
                                            className="w-4 h-4"
                                        />
                                        <div className="min-w-max">{group.selectedOption!.label}</div>
                                    </ColumnChip>
                                );
                            }
                            const textSlugs = [CnslActionSlug.ManualProvision, CnslActionSlug.ManualRevoke];
                            if (textSlugs.includes(group.template.action.slug as CnslActionSlug)) {
                                return <ColumnChip key={group.id}>{group.template.action.name}</ColumnChip>;
                            }
                            return null;
                        })}
                    </ColumnChipContainer>
                );
            },
        },
        {
            id: 'actions',
            cell: props => {
                const status = props.row.original.status;
                return (
                    <div className="flex justify-end gap-sm">
                        {status ? (
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button
                                        mode="borderless"
                                        size="sm"
                                        onClick={() => persistedStore.setStatus(props.row.original.id, null)}
                                    >
                                        <Undo />
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>Undo</TooltipContent>
                            </Tooltip>
                        ) : (
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button
                                        mode="borderless"
                                        size="sm"
                                        className="text-body-subtle"
                                        onClick={() => persistedStore.setStatus(props.row.original.id, 'rejected')}
                                    >
                                        <X />
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>Reject policy</TooltipContent>
                            </Tooltip>
                        )}
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Button
                                    mode="borderless"
                                    size="sm"
                                    className="text-body-subtle"
                                    onClick={() => {
                                        store.initFromExisting(props.row.original);
                                        setSelectedRow(props.row.original);
                                    }}
                                >
                                    <PencilLine />
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>Edit policy</TooltipContent>
                        </Tooltip>
                        {status ? (
                            <div className="flex items-center gap-sm px-sm text-xs font-medium">
                                {status === 'accepted' ? <Check color="green" /> : <X color="red" />}
                                {capitalize(status)}
                            </div>
                        ) : (
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button
                                        disabled={!persistedStore.isValid(props.row.original.id)}
                                        onClick={() => persistedStore.setStatus(props.row.original.id, 'accepted')}
                                        size="sm"
                                    >
                                        <Check color="green" /> Approve
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>Approve policy</TooltipContent>
                            </Tooltip>
                        )}
                    </div>
                );
            },
        },
    ];

    const onAppSelect = (v?: AppSelectChangePayload) => {
        if (v && 'app' in v) {
            store.setApp(v.app as CnslApp);
        }
    };

    const persistedRecord = selectedRow?.id ? persistedStore.map[selectedRow.id] : null;

    const onOpenChange = (open: boolean) => {
        setIsOpen(open);
        if (!open) {
            const body = persistedStore.requestBody();
            if (body.success.length + body.reject.length > 0) {
                processMatrix(body);
            }
        }
    };

    const selectedIndex = selectedRow ? matrixData.findIndex(row => row.id === selectedRow.id) + 1 : 0;
    const totalPolicyCount = matrixData.length;

    return (
        <Dialog open={isOpen} onOpenChange={onOpenChange}>
            <DialogContent className="sm:max-w-[90vw]">
                {selectedRow ? (
                    <>
                        <DialogHeader>
                            <DialogTitle>
                                <ScrollText className="size-5" />
                                Review draft policies
                            </DialogTitle>
                        </DialogHeader>
                        <div className="flex justify-between bg-bg-overlay px-lg py-md">
                            <Button
                                variant="grey"
                                mode="borderless"
                                onClick={() => setSelectedRow(null)}
                                className="text-body-subtle-hover"
                            >
                                <TableProperties />
                                Back to all drafts
                            </Button>
                            <div className="flex items-center gap-lg">
                                {/* These buttons need to navigate to the previous and next policy */}
                                <Button
                                    mode="borderless"
                                    size="sm"
                                    className="text-body-subtle hover:text-body-grey-primary"
                                    onClick={() => jump('previous')}
                                >
                                    <ArrowLeft />
                                </Button>
                                <span className="font-medium">
                                    {selectedIndex}/{totalPolicyCount}
                                </span>{' '}
                                <Button
                                    mode="borderless"
                                    size="sm"
                                    className="text-body-subtle hover:text-body-grey-primary"
                                    onClick={() => jump('next')}
                                >
                                    <ArrowRight />
                                </Button>
                            </div>
                            <div className="flex gap-2">
                                {!persistedRecord?.status ? (
                                    <>
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <Button
                                                    onClick={() => persistedStore.setStatus(selectedRow.id, 'rejected')}
                                                >
                                                    <X />
                                                </Button>
                                            </TooltipTrigger>
                                            <TooltipContent>Reject policy</TooltipContent>
                                        </Tooltip>
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <Button
                                                    disabled={!persistedStore.isValid(selectedRow.id)}
                                                    onClick={() => persistedStore.setStatus(selectedRow.id, 'accepted')}
                                                >
                                                    <Check color="green" />
                                                    Accept
                                                </Button>
                                            </TooltipTrigger>
                                            <TooltipContent>Accept policy</TooltipContent>
                                        </Tooltip>
                                    </>
                                ) : (
                                    <>
                                        <Button onClick={() => persistedStore.setStatus(selectedRow.id, null)}>
                                            <Undo />
                                        </Button>
                                        <Button disabled={true} mode="borderless">
                                            {persistedRecord.status === 'accepted' ? (
                                                <Check color="green" />
                                            ) : (
                                                <X color="red" />
                                            )}
                                            {capitalize(persistedRecord.status)}
                                        </Button>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col gap-lg p-2xl min-h-[625px]">
                            <PolicyTitleDescription />
                            <PolicyDetailsApp onChange={onAppSelect} app={store.app} />
                            <PolicyTabs appSlug={store.app?.slug} settings={config.data?.settings} />
                        </div>
                    </>
                ) : (
                    <>
                        <DialogHeader>
                            <DialogTitle>
                                <ScrollText className="size-5" />
                                Review draft policies
                            </DialogTitle>
                        </DialogHeader>
                        <div className="flex flex-col gap-sm bg-bg-overlay px-lg py-md border-grey border-y-[0.5px]">
                            <h3 className="flex items-center gap-md">
                                <Info />
                                Reviewing drafts
                            </h3>
                            <p className="text-body-subtle-hover">
                                To make changes to a draft, click on the arrow on the right side of the draft to enter
                                the editing view. Once you exit this draft review wizard, approved drafts will be
                                published and rejected drafts will be deleted.
                            </p>
                        </div>
                        <Table columns={columns} data={hydratedRows} />
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};
