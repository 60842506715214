import { usePlaybookInstructionReferences } from '@/hooks/queries/usePlaybook';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { PlaybookReferences } from './PlaybookReferences';
import { SectionLabel } from './SectionLabel';
import { AppReference } from './reference/AppReference';
import { GroupReference } from './reference/GroupReference';
import { SlackChannelReference } from './reference/SlackChannelReference';
import { UserReference } from './reference/UserReference';
import { usePlaybookDetailsStore } from './store/usePlaybookDetailsStore';

export const ReferenceList = forwardRef((props: { command: (p: unknown) => void }, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const saveForm = usePlaybookDetailsStore(state => state.saveForm);

    const queryString = usePlaybookDetailsStore(state => state.queryString);

    const { data } = usePlaybookInstructionReferences();
    const references = data?.references;

    if (!references) return null;

    const playbookReferencesAll = new PlaybookReferences(references);
    const playbookReferences = playbookReferencesAll.search(queryString, 10);
    const items = playbookReferences.flatten();

    useEffect(() => {
        if (queryString) {
            setSelectedIndex(0);
        }
    }, [queryString]);

    // used for scrolling the selected element into view
    const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

    // Scroll the selected element into view
    useEffect(() => {
        if (itemRefs.current[selectedIndex]) {
            itemRefs.current[selectedIndex].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    }, [selectedIndex]);

    const selectItem = (index: number) => {
        const item = playbookReferences.flatten()[index];
        if (item) {
            props.command(playbookReferences.mapToCommand(item));
            saveForm?.();
        }
    };

    useImperativeHandle(ref, () => ({
        onKeyDown: ({ event }: { event: KeyboardEvent }) => {
            if (event.key === 'ArrowUp') {
                event.preventDefault();
                event.stopPropagation();
                setSelectedIndex((selectedIndex + items.length - 1) % items.length);
                return true;
            }

            if (event.key === 'ArrowDown') {
                event.preventDefault();
                event.stopPropagation();
                setSelectedIndex((selectedIndex + 1) % items.length);
                return true;
            }

            if (event.key === 'Enter') {
                event.preventDefault();
                event.stopPropagation();
                selectItem(selectedIndex);
                return true;
            }

            return false;
        },
    }));

    if (playbookReferences.isEmpty()) {
        return (
            <div className="overflow-y-scroll bg-bg rounded-md shadow-xl border-[0.5px] border-grey">
                <div className="flex flex-col p-sm">
                    <SectionLabel label="No Results" />
                </div>
            </div>
        );
    }

    return (
        <div className="h-64 overflow-y-scroll bg-bg rounded-md shadow-xl border-[0.5px] border-grey">
            <div className="flex flex-col p-sm w-64">
                {playbookReferences.users.length ? (
                    <div>
                        <SectionLabel label="Users" />
                        {playbookReferences.users.map((user, i) => {
                            const index = playbookReferences.getUserIndex(i);
                            return (
                                <div
                                    key={user.id}
                                    ref={el => {
                                        itemRefs.current[index] = el;
                                    }}
                                >
                                    <UserReference
                                        user={user}
                                        isActive={index === selectedIndex}
                                        onClick={() => selectItem(index)}
                                    />
                                </div>
                            );
                        })}
                    </div>
                ) : null}
                {playbookReferences.groups.length ? (
                    <div>
                        <SectionLabel label="Groups" />
                        {playbookReferences.groups.map((group, i) => {
                            const index = playbookReferences.getGroupIndex(i);
                            return (
                                <div
                                    key={group.id}
                                    ref={el => {
                                        itemRefs.current[index] = el;
                                    }}
                                >
                                    <GroupReference
                                        group={group}
                                        isActive={index === selectedIndex}
                                        onClick={() => selectItem(index)}
                                    />
                                </div>
                            );
                        })}
                    </div>
                ) : null}
                {playbookReferences.apps.length ? (
                    <div>
                        <SectionLabel label="Apps" />
                        {playbookReferences.apps.map((app, i) => {
                            const index = playbookReferences.getAppIndex(i);
                            return (
                                <div
                                    key={app.id}
                                    ref={el => {
                                        itemRefs.current[index] = el;
                                    }}
                                >
                                    <AppReference
                                        app={app}
                                        isActive={index === selectedIndex}
                                        onClick={() => selectItem(index)}
                                    />
                                </div>
                            );
                        })}
                    </div>
                ) : null}
                {playbookReferences.slackChannels.length ? (
                    <div>
                        <SectionLabel label="Slack Channels" />
                        {playbookReferences.slackChannels.map((channel, i) => {
                            const index = playbookReferences.getChannelsIndex(i);
                            return (
                                <div
                                    key={channel.id}
                                    ref={el => {
                                        itemRefs.current[index] = el;
                                    }}
                                >
                                    <SlackChannelReference
                                        channel={channel}
                                        isActive={index === selectedIndex}
                                        onClick={() => selectItem(index)}
                                    />
                                </div>
                            );
                        })}
                    </div>
                ) : null}
            </div>
        </div>
    );
});
