import { Checkbox } from '@/components/ui/checkbox';
import { SelectAsync } from '@/components/ui/select';
import { GroupOrUserResLinkOption, ReslinkValue } from '@/components/ui/select/UserResLinkComponents';
import { usePlaybookUpdatePermissions } from '@/hooks/mutations/usePlaybook';
import { baseFetch } from '@/lib/baseFetch';
import { getHeaders } from '@/lib/getHeaders';
import type { PlaybookResponse } from 'lib/models/playbook';
import qs from 'qs';
import { useState } from 'react';
import type { GroupBase } from 'react-select';
import type { LoadOptions } from 'react-select-async-paginate';

const entryToOption = (entry: { id: string; type: 'user' | 'group'; avatar: string | null; name: string }) => {
    return {
        type: entry.type,
        label: entry.name,
        value: entry.id,
        avatar: entry.avatar,
    };
};

const SettingsTab = ({ playbook }: PlaybookResponse) => {
    const { mutate: updatePlaybookPermissions } = usePlaybookUpdatePermissions(playbook.slug);
    const [isRestricted, setIsRestrictPlaybook] = useState(playbook.isRestricted);
    const [selection, setSelection] = useState<ReturnType<typeof entryToOption> | undefined>(
        playbook.groupRestricted
            ? {
                  value: playbook.groupRestricted.id,
                  type: 'group',
                  label: playbook.groupRestricted.name ?? '',
                  avatar: playbook.groupRestricted.logo ?? '',
              }
            : playbook.userRestricted
              ? {
                    value: playbook.userRestricted.id,
                    type: 'user',
                    label: playbook.userRestricted.displayName ?? '',
                    avatar: playbook.userRestricted.avatar ?? '',
                }
              : undefined,
    );

    const toggleRestricted = () => {
        const newValue = !isRestricted;
        setIsRestrictPlaybook(newValue);
        updatePlaybookPermissions({
            isRestricted: newValue,
            groupRestrictedId: newValue && selection?.type === 'group' ? selection.value : null,
            userRestrictedId: newValue && selection?.type === 'user' ? selection.value : null,
        });
    };

    const toggleSelection = (option: ReturnType<typeof entryToOption> | null) => {
        if (!option) {
            return;
        }

        setSelection(option);
        updatePlaybookPermissions({
            isRestricted,
            groupRestrictedId: option.type === 'group' ? option.value : null,
            userRestrictedId: option.type === 'user' ? option.value : null,
        });
    };

    return (
        <div className="flex flex-col gap-md">
            <div className="flex gap-md">
                <Checkbox checked={isRestricted} onCheckedChange={toggleRestricted} className="mt-1" />

                <div className="flex flex-col">
                    <div className="font-medium">Restrict Playbook</div>
                    <div className="text-xs text-body-subtle">Limit who can invoke this playbook</div>
                </div>
            </div>
            {isRestricted && (
                <SelectAsync
                    isMulti={false}
                    value={selection}
                    placeholder="Select A User or Group"
                    isClearable={false}
                    components={{
                        Option: GroupOrUserResLinkOption,
                        SingleValue: ReslinkValue,
                    }}
                    loadOptions={
                        (async (search: string, _, additional) => {
                            const res = await baseFetch<{
                                options: {
                                    id: string;
                                    type: 'user' | 'group';
                                    avatar: string | null;
                                    name: string;
                                }[];
                            }>(`/api/v1/groups/with-users?${qs.stringify({ search, cursor: additional?.cursor })}`, {
                                headers: getHeaders(),
                            });
                            return {
                                options: res.options.map(entryToOption),
                                hasMore: res.options.length > 0,
                                additional: {
                                    cursor:
                                        res.options.length > 0 ? res.options[res.options.length - 1].name : undefined,
                                },
                            };
                        }) as LoadOptions<
                            ReturnType<typeof entryToOption>,
                            GroupBase<ReturnType<typeof entryToOption>>,
                            { cursor: string | undefined }
                        >
                    }
                    onChange={toggleSelection}
                />
            )}
        </div>
    );
};

export default SettingsTab;
