import type { AvatarProps } from '@/components/ui/avatar/avatar';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar/avatar';
import { initials } from '@/lib/initials';

export interface UserAvatarProps {
    user: {
        avatar: string | null;
        displayName?: string;
        username?: string;
        email: string;
    } | null;
}

export const UserAvatar = ({ user, ...avatarProps }: UserAvatarProps & AvatarProps) => {
    const str = user?.displayName || user?.username || user?.email || '';
    const i = initials(str);
    return (
        <Avatar {...avatarProps}>
            <AvatarImage src={user?.avatar || undefined} alt={i} />
            <AvatarFallback>{i}</AvatarFallback>
        </Avatar>
    );
};
