import { usePolicyCreationStore } from '@/stores/usePolicy';
import { triggerPostMoveFlash } from '@atlaskit/pragmatic-drag-and-drop-flourish/trigger-post-move-flash';
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { reorderWithEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/reorder-with-edge';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { AppPolicyReviewStrategy } from 'lib/prisma/enums';
import { useEffect } from 'react';
import { flushSync } from 'react-dom';

import { nanoid } from 'nanoid';
import { PolicyStepApproval } from './PolicyStepApproval';
import { PolicyStepListHeading } from './PolicyStepListHeading';
import { isApprovalData } from './StepData';

export function PolicyStepApprovalList({ appSlug }: { appSlug: string }) {
    const store = usePolicyCreationStore();
    const approvals = store.approvals;

    const addApproval = () => {
        store.addApproval({
            id: nanoid(),
            strategy: AppPolicyReviewStrategy.APP_OWNERS,
            userIds: [],
            groupIds: [],
        });
    };

    const { setApprovals } = store;

    useEffect(() => {
        return monitorForElements({
            canMonitor({ source }) {
                return isApprovalData(source.data);
            },
            onDrop({ location, source }) {
                const target = location.current.dropTargets[0];
                if (!target) {
                    return;
                }

                const sourceData = source.data;
                const targetData = target.data;

                if (!isApprovalData(sourceData) || !isApprovalData(targetData)) {
                    return;
                }

                const indexOfSource = approvals.findIndex(a => a.id === sourceData.id);
                const indexOfTarget = approvals.findIndex(a => a.id === targetData.id);

                if (indexOfTarget < 0 || indexOfSource < 0) {
                    return;
                }

                const closestEdgeOfTarget = extractClosestEdge(targetData);

                // Using `flushSync` so we can query the DOM straight after this line
                flushSync(() => {
                    setApprovals(
                        reorderWithEdge({
                            list: approvals,
                            startIndex: indexOfSource,
                            indexOfTarget,
                            closestEdgeOfTarget,
                            axis: 'vertical',
                        }),
                    );
                });
                // Being simple and just querying for the task after the drop.
                // We could use react context to register the element in a lookup,
                // and then we could retrieve that element after the drop and use
                // `triggerPostMoveFlash`. But this gets the job done.
                const element = document.querySelector(`[data-approval-id="${sourceData.id}"]`);
                if (element instanceof HTMLElement) {
                    triggerPostMoveFlash(element);
                }
            },
        });
    }, [approvals, setApprovals]);

    return (
        <div>
            <div className="gap-lg flex flex-col">
                <PolicyStepListHeading
                    heading="Approvals"
                    description="Set up multiple approvers or even multiple approval steps for added security"
                    onAdd={addApproval}
                />

                {approvals.map((approval, idx) => (
                    <PolicyStepApproval
                        key={approval.id}
                        approval={approval}
                        index={idx}
                        count={approvals.length}
                        onDelete={store.removeApproval}
                        appSlug={appSlug}
                    />
                ))}
            </div>
        </div>
    );
}
