import { ResLink } from '@/components/ResLink';
import { Button } from '@/components/ui/button';
import { usePlaybookVersions } from '@/hooks/queries/usePlaybook';
import { useEventListener } from '@/hooks/useEventListener';
import { fmtDateFull } from '@/lib/dates';
import { cn } from '@/lib/styling';
import { PLAYBOOKS, type PLAYBOOK_DETAILS_TYPE } from '@/routes/paths';
import type { CnslPlaybookVersion } from 'lib/models/playbook';
import { HistoryIcon, Undo, X } from 'lucide-react';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate, useParams } from 'react-router-dom';

export const PlaybookHistory = ({
    slug,
    activeVersion,
    setActiveVersion,
    disabled,
}: {
    slug: string;
    activeVersion?: CnslPlaybookVersion;
    setActiveVersion?: (v?: CnslPlaybookVersion) => void;
    disabled?: boolean;
}) => {
    const { version } = useParams();
    const navigate = useNavigate();
    const [isHistoryOpen, setIsHistoryOpen] = useState(version != null);

    const { data } = usePlaybookVersions(slug);

    const versions = data?.versions;

    const handleActiveVersion = (v: CnslPlaybookVersion) => {
        navigate(`${PLAYBOOKS}/${slug}/${v.version}` as PLAYBOOK_DETAILS_TYPE);
    };

    useEffect(() => {
        if (
            version &&
            versions &&
            setActiveVersion &&
            (!activeVersion || activeVersion.version !== Number.parseInt(version))
        ) {
            const v = versions.find(v => v.version === Number.parseInt(version));
            if (v) {
                setActiveVersion(v);
            }
        }
    }, [activeVersion, setActiveVersion, version, versions]);

    const handleDrawerOpen = () => {
        const drawerState = !isHistoryOpen;
        if (!drawerState && setActiveVersion) {
            navigate(`${PLAYBOOKS}/${slug}`);
            setActiveVersion(undefined);
        }
        setIsHistoryOpen(drawerState);
    };

    const handleDrawerClose = () => {
        setIsHistoryOpen(false);
        navigate(`${PLAYBOOKS}/${slug}` as PLAYBOOK_DETAILS_TYPE);
        setActiveVersion && setActiveVersion(undefined);
    };

    useEventListener('keydown', event => {
        if (event.key === 'Escape') {
            setIsHistoryOpen(false);
        }
    });

    return (
        <>
            <Button
                mode="borderless"
                className={isHistoryOpen ? 'bg-bg-grey-primary' : ''}
                size="sm"
                onClick={handleDrawerOpen}
                disabled={disabled}
            >
                <HistoryIcon className="size-3.5" />
                History
            </Button>
            {createPortal(
                <div
                    key="history-drawer"
                    className={cn(
                        'z-10 transition-all duration-100 ease-out absolute top-[55px] w-[250px] right-md bottom-md bg-bg border-l-[0.5px] border-grey shadow-rpane rounded-br-md',
                        isHistoryOpen ? 'w-[350px] grow shrink-0 overflow-y-scroll' : 'w-0 overflow-hidden',
                    )}
                >
                    <div className="shrink-0 w-[350px]">
                        <div className="flex justify-between p-lg border-b-[0.5px] border-grey">
                            <div>
                                <div className="flex items-center gap-md text-base font-medium">
                                    <HistoryIcon />
                                    History
                                </div>
                            </div>
                            <Button
                                size="sm"
                                mode="borderless"
                                onClick={() => {
                                    handleDrawerClose();
                                }}
                            >
                                <X />
                            </Button>
                        </div>
                        <div className="flex flex-col">
                            <div className="bg-bg-blue-primary text-body-blue-primary border-b-[0.5px] border-grey p-lg flex flex-col">
                                <span className="font-medium">To revert to the previous version:</span>
                                <span>
                                    Select your desired version, click the blue “Revert” button, then publish those
                                    changes
                                </span>
                            </div>
                            <div className="flex flex-col p-md">
                                {versions &&
                                    versions.map(v => {
                                        if (!v.updatedBy)
                                            return <div className="p-md text-body-subtle">No history found</div>;

                                        const isActive = activeVersion?.version === v.version;

                                        return (
                                            <div
                                                key={v.version}
                                                className={cn(
                                                    'flex items-center cursor-pointer hover:bg-bg-overlay rounded-[6px] gap-md px-md py-[6px] border-[0.5px] w-full',
                                                    isActive ? 'border-grey bg-bg-overlay' : 'border-transparent',
                                                )}
                                                onClick={() => handleActiveVersion(v)}
                                            >
                                                <ResLink
                                                    avatarOnly
                                                    src={v.updatedBy.avatar}
                                                    label={v.updatedBy.displayName}
                                                />

                                                <div className="flex w-full flex-col">
                                                    <div className="truncate self-stretch">{`${v.updatedBy.displayName} made edits`}</div>
                                                    <div className="text-body-subtle self-stretch text-xs">
                                                        {fmtDateFull(v.createdAt)}
                                                    </div>
                                                </div>
                                                {isActive && <Undo />}
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </div>,
                document.body,
            )}
        </>
    );
};
