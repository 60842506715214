import { LIMIT_DEFAULT } from '@/lib/paginationConstants';
import { useMutation } from '@lib/hooks/useMutation';
import { type DefaultError, type InfiniteData, useQueryClient } from '@tanstack/react-query';
import type { AppPostSchema, CnslAppList, SingleCnslApp } from 'lib/models/app';
import { cloneDeep } from 'lodash-es';
import { toast } from 'sonner';

export const useAppPost = () => {
    const errToastMsg = 'Problem creating app';
    const queryClient = useQueryClient();

    return useMutation<SingleCnslApp, DefaultError, AppPostSchema>({
        url: `/api/v1/apps`,
        method: 'POST',

        errorToast: {
            message: errToastMsg,
        },
        onSuccess: async app => {
            if (app.id) {
                // update local cache with the newly created app
                queryClient.setQueriesData<InfiniteData<CnslAppList[]>>({ queryKey: ['apps'] }, old => {
                    if (old) {
                        const update = cloneDeep(old);

                        // flatten the pages into a single array
                        const all = update.pages.flat();
                        // add the new app to the array
                        all.push(app);
                        // sort the array by name
                        const sorted = all.sort((a, b) => {
                            const aName = a.name ?? '';
                            const bName = b.name ?? '';
                            return aName.localeCompare(bName);
                        });
                        // re-chunk them into pages
                        const newPages = [];
                        for (let i = 0; i < sorted.length; i += LIMIT_DEFAULT) {
                            newPages.push(sorted.slice(i, i + LIMIT_DEFAULT));
                        }

                        update.pages = newPages;

                        return update;
                    } else {
                        return old;
                    }
                });
                toast.success('App created');
            } else {
                toast.error(errToastMsg);
            }
        },
    });
};
