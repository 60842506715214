import { KV } from '@/components/KV';
import { ResLink } from '@/components/ResLink';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import type { RequestEvents } from '@/pages/requests/details/Main';
import { ProviderAssets } from 'lib/3p';
import { CnslActionSlug } from 'lib/actions/slugs';
import { IntegrationId } from 'lib/integration';
import { ActionInvocationDetails, CreateTicketEventDetails } from 'lib/models';
import { EventType } from 'lib/prisma/enums';
import { capitalize } from 'lodash-es';
import { ArrowUpRight, ChevronsUpDown } from 'lucide-react';
import { safeParse } from 'valibot';

const getLabel = (prefix: string, str: string) => {
    const strr = prefix ? `${prefix} ${str}` : str;
    return capitalize(strr.split('_').join(' '));
};

const isObject = (x: Node) => typeof x === 'object' && !Array.isArray(x) && x !== null;

type Node = object | undefined | null;

function traverseDetails(details?: Node) {
    const results: { label: string; value: string }[] = [];

    const traverse = (node: Node, prefix: string) => {
        if (typeof node === 'object' && !Array.isArray(node) && node !== null) {
            Object.entries(node).forEach(([key, value]) => {
                if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
                    traverse(value, prefix ? `${prefix} ${key}` : key);
                } else if (Array.isArray(value) && value.length > 0) {
                    results.push({ label: getLabel(prefix, key), value: value.join(', ') });
                } else if (value !== null && value !== undefined && value !== '' && !Array.isArray(value)) {
                    results.push({ label: getLabel(prefix, key), value: `${value}` });
                }
            });
        } else if (Array.isArray(node)) {
            node.forEach(n => {
                if (isObject(n)) {
                    traverse(n, prefix);
                }
            });
        }
        return;
    };

    traverse(details, '');

    return results;
}

export function renderEventMessage(event: RequestEvents) {
    switch (event.type) {
        case EventType.CREATE_TICKET:
            return renderCreateTicketEventMessage(event);
        case EventType.ACTION_INVOCATION:
            return renderActionInvocationEventMessage(event);
        default:
            return {
                simple: JSON.stringify(event.details),
                rich: JSON.stringify(event.details),
            };
    }
}

function renderCreateTicketEventMessage(entry: RequestEvents) {
    const details = safeParse(CreateTicketEventDetails, entry.details);
    if (details.success) {
        const simple = `created ticket with ID: ${details.output.ticket.id}`;
        const rich = (
            <>
                <ResLink
                    entity="users"
                    id={entry.actor?.email ?? ''}
                    bold
                    label={entry.actor?.displayName ?? ''}
                    src={entry.actor?.avatar ?? ''}
                    badge={<img src="/3p/slack-logo.png" alt="Slack Logo" className="size-[10px]" />}
                    className="text-sm"
                />
                <span className="text-body-subtle-hover shrink-0">{simple}</span>
                {entry.request?.externalURL ? (
                    <Button asChild className="p-0">
                        <a
                            href={entry.request?.externalURL}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center gap-md"
                        >
                            <img
                                src={ProviderAssets[entry.request?.provider as IntegrationId].logo}
                                alt={`${entry.request?.provider} Logo`}
                                className="size-4"
                            />
                            {(entry.request?.fields?.subject as string) && (
                                <>
                                    <span className="truncate max-w-[400px] font-normal">
                                        {entry.request?.fields?.subject as string}
                                    </span>
                                    <div className="w-[1px] h-4 bg-bg-grey-primary rounded-[1px]" />
                                </>
                            )}
                            <span>View Ticket</span>
                            <ArrowUpRight className="h-4 w-4" />
                        </a>
                    </Button>
                ) : (
                    <Dialog>
                        <DialogTrigger asChild>
                            <Button size="sm" mode="borderless">
                                <ChevronsUpDown className="size-3.5" />
                            </Button>
                        </DialogTrigger>
                        <DialogContent className="sm:max-w-[425px]">
                            <DialogHeader>
                                <DialogTitle>Details</DialogTitle>
                            </DialogHeader>
                            <div className="p-lg pt-0 overflow-y-scroll">
                                {traverseDetails(entry.details as object).map(d => (
                                    <KV key={`$d.label-$d.value`} {...d} />
                                ))}
                            </div>
                        </DialogContent>
                    </Dialog>
                )}
            </>
        );
        return { simple, rich };
    }
    return {
        simple: null,
        rich: null,
    };
}

function renderActionInvocationEventMessage(entry: RequestEvents) {
    const details = safeParse(ActionInvocationDetails, entry.details);

    if (
        details.success &&
        details.output.provider === IntegrationId.Console &&
        details.output.slug === CnslActionSlug.Escalate
    ) {
        const simple = `invoked action: ${details.output.slug} from provider: ${details.output.provider}`;
        const rich = (
            <>
                <ResLink
                    className="text-sm"
                    bold
                    label="Console"
                    badge={<img src="/3p/slack-logo.png" alt="Slack Logo" className="size-[10px]" />}
                    fallback={
                        <div className="bg-bg border-grey flex size-6 items-center justify-center rounded-full border">
                            <img src="/console-icon.svg" alt="Console Logo" className="size-[12px]" />
                        </div>
                    }
                />
                <span className="text-body-subtle-hover shrink-0">prompted the user to file a ticket</span>
            </>
        );
        return { simple, rich };
    } else if (details.success) {
        const simple = `invoked action: ${details.output.slug} from provider: ${details.output.provider}`;
        const rich = (
            <>
                <ResLink
                    entity="users"
                    id={entry.actor?.email ?? ''}
                    bold
                    label={entry.actor?.displayName ?? ''}
                    src={entry.actor?.avatar ?? ''}
                    badge={<img src="/3p/slack-logo.png" alt="Slack Logo" className="size-[10px]" />}
                    className="text-sm"
                />
                <span className="text-body-subtle-hover shrink-0">{simple}</span>
                <Dialog>
                    <DialogTrigger asChild>
                        <Button size="sm" mode="borderless">
                            <ChevronsUpDown className="size-3.5" />
                        </Button>
                    </DialogTrigger>
                    <DialogContent className="sm:max-w-[425px]">
                        <DialogHeader>
                            <DialogTitle>Details</DialogTitle>
                        </DialogHeader>
                        <div className="p-lg pt-0 overflow-y-scroll">
                            {traverseDetails(entry.details as object).map(d => (
                                <KV key={`$d.label-$d.value`} {...d} />
                            ))}
                        </div>
                    </DialogContent>
                </Dialog>
            </>
        );
        return { simple, rich };
    }

    return {
        simple: null,
        rich: null,
    };
}
