import type { ColumnDef } from '@tanstack/react-table';
import { Table } from '@ui/table/table';
import type { SingleCnslGroup } from 'lib/models/group';

import { Confirmation } from '@/components/Confirmation';
import { ResLink } from '@/components/ResLink';
import { TableEmptyState } from '@/components/TableEmptyState';
import { Button } from '@/components/ui/button';
import { useGroupsUsersDel } from '@/hooks/mutations/groups/useGroupsUsersDel';
import { fmtDateFull } from '@/lib/dates';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@ui/dropdown-menu';
import { Ellipsis, Trash2 } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const columns: ColumnDef<SingleCnslGroup['group_users'][number]>[] = [
    {
        accessorKey: 'name',
        id: 'name',
        header: () => 'Name',
        cell: props => (
            <ResLink
                className="pl-lg h-full"
                entity="users"
                id={props.row.original.cnsl_user.email}
                label={props.row.original.cnsl_user.displayName}
                src={props.row.original.cnsl_user.avatar ?? ''}
                bold
            />
        ),
        meta: {
            expand: true,
        },
    },
    {
        accessorKey: 'title',
        header: () => 'Title',
        cell: props => props.row.original.cnsl_user.title,
    },
    {
        accessorKey: 'department',
        header: () => 'Department',
        cell: props => props.row.original.cnsl_user.department,
    },
    {
        accessorKey: 'updatedAt',
        header: () => 'Last updated',
        cell: props => {
            const dateField = props.row.original.providerUpdatedAt ?? props.row.original.updatedAt;
            return <div className="truncate">{fmtDateFull(dateField)}</div>;
        },
    },
    {
        header: () => '',
        id: 'actions',
        size: 0,
        meta: { expand: true },
        cell: info => {
            const [showPop, setShowPop] = useState(false);
            const del = useGroupsUsersDel({
                slug: info.row.original.cnsl_group.slug,
                userId: info.row.original.cnsl_user.id,
            });

            return (
                <DropdownMenu open={showPop}>
                    <DropdownMenuTrigger asChild>
                        <Button mode="borderless" onClick={() => setShowPop(true)}>
                            <Ellipsis />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent side="bottom" align="end" onInteractOutside={() => setShowPop(false)}>
                        <Confirmation
                            ctaLabel="Remove user"
                            title={`Remove "${info.row.original.cnsl_user.displayName}" from group?`}
                            subtitle="Once this user is removed, it cannot be recovered"
                            onClose={() => setShowPop(false)}
                            onConfirm={() => del.mutate()}
                        >
                            <DropdownMenuItem className="text-body-red-primary">
                                <Trash2 className="mr-2 size-4" />
                                Remove user
                            </DropdownMenuItem>
                        </Confirmation>
                    </DropdownMenuContent>
                </DropdownMenu>
            );
        },
    },
];

const EmptyState = () => {
    const navigate = useNavigate();

    return (
        <TableEmptyState
            title="Users"
            description="No users found in this group"
            imageSrc="/emptyStates/empty-users.svg"
        >
            <Button onClick={() => navigate('/settings/organization/integrations/okta')}>
                <img src="/3p/okta-logo.png" alt="Okta logo" className="size-4" />
                Connect Okta
            </Button>
            <Button onClick={() => navigate('/settings/organization/integrations/google')}>
                <img src="/3p/google-logo.png" alt="Google logo" className="size-4" />
                Connect Google
            </Button>
        </TableEmptyState>
    );
};

export const Users = ({ group }: { group: SingleCnslGroup }) => {
    return <Table data={group.group_users} columns={columns} emptyState={<EmptyState />} />;
};

export default Users;
