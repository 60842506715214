import { getHeaders } from '@/lib/getHeaders';
import { SelectAsync } from '@ui/select';
import type { GetAvailibleMembersResponse } from 'lib/models/members';
import type { CnslUser } from 'lib/prisma/types';
import { CircleUserRound } from 'lucide-react';
import qs from 'qs';

import { baseFetch } from '@/lib/baseFetch';

import type { GroupBase } from 'react-select';
import type { LoadOptions } from 'react-select-async-paginate';
import { UserResLinkOption, UserResLinkValueContainer } from '../ui/select/UserResLinkComponents';

interface UserOption {
    label: string;
    value: CnslUser;
    avatar: string | null;
}

const userToOption = (value: CnslUser) => ({
    label: value.displayName || value.email,
    avatar: value.avatar,
    value,
});

export const UsersFilter = ({
    users,
    onChange,
    placeholderName = 'Users',
}: { users: CnslUser[]; onChange: (users: UserOption[]) => void; placeholderName?: string }) => {
    return (
        <SelectAsync
            placeholder={
                <div className="flex items-center gap-sm">
                    <CircleUserRound className="size-3.5 text-body-subtle" />
                    {placeholderName}
                </div>
            }
            isSearchable={false}
            isMulti
            closeMenuOnSelect={false}
            value={users.map(userToOption)}
            trigger="small"
            components={{
                Option: UserResLinkOption,
                ValueContainer: UserResLinkValueContainer,
                MultiValue: () => null,
            }}
            loadOptions={
                (async (search, _, additional) => {
                    const { cursor } = additional || {};
                    const res = await baseFetch<GetAvailibleMembersResponse>(
                        `/api/v1/users?${qs.stringify({ search, ...(cursor ? { cursor } : {}) })}`,
                        { headers: getHeaders() },
                    );
                    return {
                        options: res.items.map(userToOption),
                        hasMore: !!res.pagination.nextCursor,
                        additional: {
                            cursor: res.pagination.nextCursor,
                        },
                    };
                }) as LoadOptions<
                    ReturnType<typeof userToOption>,
                    GroupBase<ReturnType<typeof userToOption>>,
                    { cursor: string | undefined }
                >
            }
            // @ts-expect-error - fix later
            onChange={onChange}
        />
    );
};
