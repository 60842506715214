import { useQuery } from '@/lib/hooks/useQuery';
import type { HydratedAppMatrixRow } from 'lib/ai/app_matrix_row';

export const useAppMatrix = () => {
    return useQuery<{ rows: HydratedAppMatrixRow[] }>({
        url: `/api/v1/app-matrix`,
    });
};

export const useAppMatrixJobStatus = (jobId: string, options?: { enabled: boolean; refetchInterval?: number }) => {
    return useQuery<{ status: number }>({
        url: `/api/v1/app-matrix/${jobId}/status`,
        ...options,
    });
};
