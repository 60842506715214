import Placeholder from '@tiptap/extension-placeholder';
import { Extension, type JSONContent } from '@tiptap/react';
import type { RichPlaybook } from 'lib/models/playbook';
import { GripVertical, Plus } from 'lucide-react';
import type { UseFormReturn } from 'react-hook-form';

import { DnDTree } from '@/components/dnd-tree';
import { type TreeItem, tree } from '@/components/dnd-tree/tree';
import { Editor, contentIsEmpty } from '@/components/tiptap/Editor';
import { useTiptapEditors } from '@/components/tiptap/useTiptapEditors';
import { Button } from '@/components/ui/button';
import { FormControl, FormField, FormItem } from '@/components/ui/form/form';
import { usePlaybookUpdate } from '@/hooks/mutations/usePlaybook';
import { usePlaybookInstructionReferences } from '@/hooks/queries/usePlaybook';
import type { PlaybookInstructionsTree } from 'lib/models/tiptap';
import { configureExtensions } from 'lib/tiptap';
import { nanoid } from 'nanoid';
import { usePlaybookDetailsStore } from './store/usePlaybookDetailsStore';
import { mentionActionConfig, mentionReferenceConfig } from './utils';

const indentPerLevel = 22;

// Stops the default behavior of enter in the editor
const BlockEnter = Extension.create({
    name: 'blockEnter',
    addKeyboardShortcuts: () => {
        return {
            Enter: () => {
                return true;
            },
        };
    },
});

const BlockUndoAndRedo = Extension.create({
    name: 'blockUndoAndRedo',
    addKeyboardShortcuts: () => {
        return {
            'Mod-z': () => {
                return true;
            },
            'Mod-y': () => {
                return true;
            },
        };
    },
});

export const playbookExtensions = configureExtensions(
    [
        Placeholder.configure({
            placeholder: `Start writing, or press '#' to add an action, '@' to tag a resource`,
        }),
        BlockEnter,
        BlockUndoAndRedo,
    ],
    [mentionActionConfig, mentionReferenceConfig],
);

export function Preview({ item, inline, level = 1 }: { item: TreeItem; inline?: boolean; level?: number }) {
    const editors = useTiptapEditors.getState().editors;
    const editor = editors[item.id];

    if (inline && editor?.getHTML()) {
        // biome-ignore lint/security/noDangerouslySetInnerHtml: html is coming from tiptap
        return <div dangerouslySetInnerHTML={{ __html: editor?.getHTML() }} />;
    }

    return (
        <div className="max-h-[48px] max-w-[485px] overflow-hidden p-sm rounded-md bg-bg-blue-secondary text-body-blue-secondary backdrop-blur-lg rotate-[0.5deg]">
            <div className="tiptap line-clamp-2">
                {/* biome-ignore lint/security/noDangerouslySetInnerHtml: html is coming from tiptap */}
                {editor?.getHTML() && <div dangerouslySetInnerHTML={{ __html: editor?.getHTML() }} />}
                <div className={`ml-${(indentPerLevel * level * 8) / indentPerLevel}`}>
                    {item.children?.map(child => (
                        <Preview key={child.id} item={child} inline level={level + 1} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export const numberToLetter = (number?: number) => {
    if (number === undefined || Number.isNaN(number)) return '';
    const n = number % 26;
    return String.fromCharCode(n + 65).toLowerCase(); // 'A' is 65 in ASCII
};

const getNodeAbove = (values: RichPlaybook, path: number[]) => {
    const isChild = path?.length === 2;

    if (isChild) {
        const nextChildIndex = path[1] - 1;

        if (nextChildIndex >= 0) {
            return values.instructionsData?.data[path[0]]?.children?.[nextChildIndex];
        } else {
            const nextParentIndex = path[0];
            if (nextParentIndex >= 0) {
                return values.instructionsData?.data[nextParentIndex];
            }
            return;
        }
    } else {
        const nextParentIndex = path[0] - 1;
        if (tree.hasChildren(values.instructionsData?.data[nextParentIndex])) {
            const childIndex = values.instructionsData?.data[nextParentIndex]?.children?.length - 1;
            return values.instructionsData?.data[nextParentIndex]?.children?.[childIndex];
        } else if (nextParentIndex >= 0) {
            return values.instructionsData?.data[nextParentIndex];
        }
        return;
    }
};

const getNodeBelow = (values: RichPlaybook, path: number[]) => {
    const isChild = path?.length === 2;
    if (isChild) {
        const nextChildIndex = path[1] + 1;

        const numChildren = values.instructionsData.data?.[path[0]]?.children?.length ?? 0;

        if (nextChildIndex < numChildren) {
            return values.instructionsData?.data[path[0]]?.children?.[nextChildIndex];
        } else {
            const nextParentIndex = path[0] + 1;
            if (nextParentIndex >= 0) {
                return values.instructionsData?.data[nextParentIndex];
            }
            return;
        }
    } else if (tree.hasChildren(values.instructionsData?.data[path[0]])) {
        return values.instructionsData?.data[path[0]]?.children?.[0];
    } else {
        return values.instructionsData?.data[path[0] + 1];
    }
};

const handleAddNodeBelow = (
    form: UseFormReturn<RichPlaybook>,
    path: number[],
    playbookUpdate: ReturnType<typeof usePlaybookUpdate>,
) => {
    const values = structuredClone(form.getValues());
    const id = nanoid();

    if (path.length === 2) {
        // If it's a child node
        if (values.instructionsData.data[path[0]].children) {
            values.instructionsData.data[path[0]].children?.splice(path[1] + 1, 0, {
                id,
                schema: null,
                children: [],
            });
        }
    } else {
        // If it's a parent node
        values.instructionsData.data.splice(path[0] + 1, 0, {
            id,
            schema: null,
            children: [],
        });
    }

    form.setValue('instructionsData', values.instructionsData);
    playbookUpdate.mutate(values);
};

export const PlaybookInstructionsData = ({
    form,
    historical,
    slug,
    disabled,
}: {
    form: UseFormReturn<RichPlaybook>;
    historical?: boolean;
    slug: string;
    disabled?: boolean;
}) => {
    const options = usePlaybookDetailsStore(state => state.options);
    const setActiveOption = usePlaybookDetailsStore(state => state.setActiveOption);
    const setActiveReference = usePlaybookDetailsStore(state => state.setActiveReference);

    const playbookUpdate = usePlaybookUpdate(slug);
    const { data } = usePlaybookInstructionReferences();

    const saveVersion = (data?: PlaybookInstructionsTree) => {
        if (!playbookUpdate.isPending || !data) {
            const values = form.getValues();
            if (data) {
                playbookUpdate.mutate(
                    { ...values, instructionsData: data },
                    {
                        onSuccess: () => {
                            form.setValue('instructionsData', data);
                        },
                    },
                );
            } else {
                const values = form.getValues();
                playbookUpdate.mutate(values);
            }
        }
    };

    const handleEditorClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const target = event.target as HTMLDivElement;
        const id = target.dataset.id;

        const references = data?.references;

        if (target.className.includes('mention-action') && id) {
            const o = options.find(o => o.id === id);
            setActiveOption(o ? { ...o, referenceId: target.dataset.referenceid } : undefined);
        } else if (target.className.includes('mention-reference') && id && references) {
            const [entity, entityId] = id.split(':');
            const reference = references[entity as 'USER' | 'APP' | 'GROUP' | 'SLACK_CHANNEL'].find(
                r => r.id === entityId,
            );
            setActiveReference(reference);
        }
    };

    const renderItem = (item: TreeItem, _level: number, _index: number, itemRef: React.RefObject<HTMLDivElement>) => {
        const path = tree.getIndexPathToItem({
            current: form.getValues('instructionsData')?.data ?? [],
            targetId: item.id,
        });

        if (!path) return null;

        const isChild = path?.length === 2;

        const name = !isChild
            ? `instructionsData.data.${path[0]}.schema`
            : `instructionsData.data.${path[0]}.children.${path[1]}.schema`;

        const rowNumber = Number(path[0]) + 1;

        const letter = numberToLetter(Number(path[1]));

        const handleEditorKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
            const nodeAbove = path[0] !== 0;
            const values = structuredClone(form.getValues());

            if (event.key === 'z' && event.metaKey) {
                const undo = useTiptapEditors.getState().undo();

                if (undo && undo.type === 'node-update') {
                    const { editorId, state, cursorPosition } = undo;
                    const editors = useTiptapEditors.getState().editors;
                    const editor = editors[editorId];
                    editor?.commands?.setContent(state as JSONContent);
                    editor?.commands?.focus(cursorPosition);
                }
            } else if (event.key === 'y' && event.metaKey) {
                const redo = useTiptapEditors.getState().redo();

                if (redo && redo?.type === 'node-update') {
                    const { editorId, state, cursorPosition } = redo;
                    const editors = useTiptapEditors.getState().editors;
                    const editor = editors[editorId];
                    editor?.commands?.setContent(state as JSONContent);
                    editor?.commands?.focus(cursorPosition);
                }
            } else if (event.key === 'ArrowUp') {
                event.preventDefault();
                event.stopPropagation();

                const editors = useTiptapEditors.getState().editors;

                if (event.metaKey) {
                    const id = values.instructionsData?.data[0]?.id;

                    if (id) {
                        editors[id]?.commands?.focus('end');
                    }
                } else {
                    const nodeAbove = getNodeAbove(values, path);
                    if (nodeAbove) {
                        editors[nodeAbove.id]?.commands?.focus('end');
                    }
                }
            } else if (event.key === 'ArrowDown') {
                event.preventDefault();
                event.stopPropagation();

                const editors = useTiptapEditors.getState().editors;

                if (event.metaKey) {
                    const lastNode = values.instructionsData?.data[values.instructionsData?.data.length - 1];
                    if (tree.hasChildren(lastNode)) {
                        const id = lastNode.children[lastNode.children.length - 1].id;
                        editors[id]?.commands?.focus('start');
                    } else {
                        editors[lastNode.id]?.commands?.focus('end');
                    }
                } else {
                    const nodeBelow = getNodeBelow(values, path);
                    if (nodeBelow) {
                        editors[nodeBelow.id]?.commands?.focus('end');
                    }
                }
            } else if (event.key === 'Tab' && !isChild && !event.shiftKey && nodeAbove) {
                event.preventDefault();
                event.stopPropagation();

                const node = values.instructionsData.data.splice(path[0], 1)[0];
                const children = node.children ?? [];
                delete node.children;

                values.instructionsData.data[path[0] - 1]?.children?.push(...[node, ...children]);

                form.setValue('instructionsData', values.instructionsData);
                playbookUpdate.mutate(values, {
                    onSuccess: () => {
                        const editors = useTiptapEditors.getState().editors;
                        editors[node.id]?.commands?.focus('end');
                    },
                });
            } else if (event.key === 'Tab' && event.shiftKey && isChild) {
                event.preventDefault();
                event.stopPropagation();

                const restOfChildren = values.instructionsData.data?.[path[0]]?.children?.splice(
                    path[1] + 1,
                    (values.instructionsData.data?.[path[0]]?.children?.length ?? path[1]) - path[1],
                );

                const nodeToMove = values.instructionsData.data?.[path[0]]?.children?.splice(path[1], 1)[0];
                if (nodeToMove) {
                    values.instructionsData.data.splice(path[0] + 1, 0, {
                        ...nodeToMove,
                        children: restOfChildren,
                    });

                    form.setValue('instructionsData', values.instructionsData);
                    playbookUpdate.mutate(values, {
                        onSuccess: () => {
                            const editors = useTiptapEditors.getState().editors;
                            editors[nodeToMove.id]?.commands?.focus('end');
                        },
                    });
                }
            } else if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault();
                event.stopPropagation();

                const parentNode = tree.getNodeByPath(values.instructionsData.data, path);

                if (parentNode) {
                    const id = nanoid();

                    values.instructionsData.data = tree.insertAfter(values.instructionsData.data, parentNode.id, {
                        id,
                        schema: null,
                        children: [],
                    });

                    form.setValue('instructionsData', values.instructionsData);
                    playbookUpdate.mutate(values, {
                        onSuccess: () => {
                            const editors = useTiptapEditors.getState().editors;
                            editors[id]?.commands?.focus('start');
                        },
                    });
                }
            } else if (event.key === 'Backspace') {
                const node = tree.getNodeByPath(values.instructionsData.data, path);
                const editors = useTiptapEditors.getState().editors;

                if (node?.id && editors[node.id] && contentIsEmpty(editors[node.id])) {
                    values.instructionsData.data = tree.remove(values.instructionsData.data, node.id);

                    if (!values.instructionsData.data.length) {
                        return;
                    }

                    event.preventDefault();
                    event.stopPropagation();

                    const parentNode = getNodeAbove(values, path);
                    if (node?.children?.length && parentNode) {
                        values.instructionsData.data = tree.insertAfter(
                            values.instructionsData.data,
                            parentNode.id,
                            node?.children,
                        );
                    }

                    form.setValue('instructionsData', values.instructionsData);
                    playbookUpdate.mutate(values, {
                        onSuccess: () => {
                            if (parentNode) {
                                const editors = useTiptapEditors.getState().editors;
                                editors[parentNode.id]?.commands?.focus('end');
                            }
                        },
                    });
                }
            }
        };

        return (
            <div
                className="flex items-start gap-sm cursor-default group mb-sm"
                style={{ width: 'calc(100% + 32px)', marginLeft: '-32px' }}
            >
                <div className="flex gap-sm items-start h-fit cursor-default" ref={itemRef}>
                    <div className="flex invisible group-hover:visible">
                        <Button
                            mode="borderless"
                            size="sm"
                            className="size-5 px-0 text-body-subtle"
                            onClick={() => handleAddNodeBelowWithForm(path)}
                        >
                            <Plus />
                        </Button>
                        <Button
                            mode="borderless"
                            size="sm"
                            className="size-5 p-0 ml-[-2px] text-body-subtle cursor-grab"
                            tabIndex={-1}
                        >
                            <GripVertical />
                        </Button>
                    </div>
                    <div className="text-sm font-medium">{letter ? `${letter}.` : `${rowNumber}.`}</div>
                </div>

                <FormField
                    control={form.control}
                    //@ts-expect-error - this is a dynamic name
                    name={name}
                    render={({ field: { onBlur, ...field }, fieldState }) => (
                        <FormItem className="flex grow flex-col">
                            <FormControl className="h-full">
                                <Editor
                                    id={`${item.id}`}
                                    className="text-sm"
                                    controlled={historical}
                                    disabled={field.disabled || historical || disabled}
                                    content={field.value as JSONContent}
                                    extensions={playbookExtensions}
                                    onChange={d => {
                                        field.onChange({ target: { value: d } });
                                    }}
                                    onClick={handleEditorClick}
                                    onBlur={() => {
                                        onBlur();
                                        if (fieldState.isDirty) {
                                            saveVersion();
                                        }
                                    }}
                                    onKeyDown={handleEditorKeyDown}
                                />
                            </FormControl>
                        </FormItem>
                    )}
                />
            </div>
        );
    };

    const handleTreeChange = (tree: TreeItem[]) => {
        // XXX - tree coming from dnd has extra stuff. Typing needs to be made more generic for the Tree at some point
        saveVersion({ data: tree } as PlaybookInstructionsTree);
    };

    const handleAddNodeBelowWithForm = (path: number[]) => handleAddNodeBelow(form, path, playbookUpdate);

    return (
        <DnDTree
            expanded
            passRef
            flattenChildren
            treeData={form.getValues('instructionsData')?.data ?? []}
            maxDepth={1}
            indentPerLevel={indentPerLevel}
            renderItem={renderItem}
            onChange={handleTreeChange}
            components={{
                Preview,
            }}
        />
    );
};
