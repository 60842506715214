import { useMutation } from '@lib/hooks/useMutation';
import type { DefaultError } from '@tanstack/react-query';
import type { AppOwnersListItem, AppOwnersPost } from 'lib/models/app';

export const useAppsOwnersPost = ({ slug }: { slug: string }) => {
    return useMutation<{ owners: AppOwnersListItem[] }, DefaultError, AppOwnersPost>({
        url: `/api/v1/apps/${slug}/owners`,
        method: 'POST',
    });
};
