import { Avatar, AvatarFallback, AvatarImage } from '@ui/avatar';

import { initials } from '@/lib/initials';

import type { NavBarHeaderConfig } from './types/DetailsConfig';

export const DetailsNavbarHeader = ({ src, title, subtitle, fallback, rounded }: NavBarHeaderConfig) => {
    return (
        <div className="gap-lg z-10 flex flex-col items-center pt-6">
            <Avatar size="xl" className="size-24 text-4xl">
                <AvatarImage src={src} rounded={rounded} />
                <AvatarFallback>{fallback ?? initials(title)}</AvatarFallback>
            </Avatar>
            <div className="flex w-full flex-col text-center">
                <h1 className="text-xl">{title}</h1>
                <p className="text-body-subtle-hover text-base">{subtitle}</p>
            </div>
        </div>
    );
};
