import type { ColumnDef } from '@tanstack/react-table';

import type { CnslUser } from 'lib/prisma/types';

import { Brow } from '@/components/Brow';
import { ResLink } from '@/components/ResLink';
import { TableEmptyState } from '@/components/TableEmptyState';
import { UsersFilter } from '@/components/filters/UsersFilter';
import { Button } from '@/components/ui/button';
import { Select } from '@/components/ui/select';
import { GenericValuesContainer } from '@/components/ui/select/GenericOptions';
import { useUsers, useUsersQP } from '@/hooks/queries/useUsers';
import { CnslUserIcon } from '@/lib/iconConstants';
import { LIMIT_DEFAULT, THRESHOLD_DEFAULT } from '@/lib/paginationConstants';
import { Table } from '@ui/table/table';

import type { ListUsersQP } from 'lib/models/cnsl_user';
import { Building } from 'lucide-react';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

const columns: ColumnDef<CnslUser & { manager: CnslUser | null }>[] = [
    {
        accessorKey: 'name',
        id: 'name',
        header: () => 'Name',
        cell: props => (
            <ResLink
                entity="users"
                className="pl-lg h-full"
                id={props.row.original.email}
                label={props.row.original.displayName}
                src={props.row.original.avatar ?? ''}
                bold
            />
        ),
        meta: {
            expand: true,
        },
    },
    {
        accessorKey: 'email',
        header: () => 'Email address',
        cell: info => info.getValue(),
    },
    {
        accessorKey: 'title',
        header: () => 'Title',
        cell: info => info.getValue(),
    },
    {
        accessorKey: 'department',
        header: () => 'Department',
        cell: info => info.getValue(),
    },
    {
        accessorKey: 'manager',
        id: 'manager',
        header: () => 'Manager',
        cell: props => {
            const manager = props.row.original.manager;
            if (!manager) return null;
            return (
                <ResLink
                    entity="users"
                    className="pl-lg h-full"
                    id={manager.email}
                    label={manager.displayName}
                    src={manager.avatar ?? ''}
                    bold
                />
            );
        },
        meta: {
            expand: true,
        },
    },
];

const pagination = {
    queryKey: ['users'],
    limit: LIMIT_DEFAULT,
    threshold: THRESHOLD_DEFAULT,
    pathname: '/api/v1/users',
};

const EmptyState = () => {
    const navigate = useNavigate();

    return (
        <TableEmptyState
            title="Users"
            description="Integrate with your HRIS to see your users"
            imageSrc="/emptyStates/empty-users.svg"
        >
            <Button onClick={() => navigate('/settings/organization/integrations/okta')}>
                <img src="/3p/okta-logo.png" alt="Okta logo" className="size-4" />
                Connect Okta
            </Button>
            <Button onClick={() => navigate('/settings/organization/integrations/bob')}>
                <img src="/3p/bob-logo.png" alt="Bob logo" className="size-4" />
                Connect Bob
            </Button>
        </TableEmptyState>
    );
};

export const Users = () => {
    const [appCount, setAppCount] = useState<number | null>(null);
    const [rawFilters, setFilters] = useLocalStorage<NonNullable<ListUsersQP>>('USERS_FILTERS', {});
    const filters = rawFilters ?? {};
    const { data } = useUsersQP();
    const departmentOptions = (data?.departments ?? []).map(d => ({ label: d, value: d }));
    const [managers, setManagers] = useState<CnslUser[]>([]);
    const [onMountUsersFilter, _] = useState<string[]>(filters.manager ?? []);
    const { data: managersData } = useUsers({
        userIds: onMountUsersFilter.length ? onMountUsersFilter : undefined,
        enabled: !!onMountUsersFilter.length,
    });
    const canRenderUsersFilter = onMountUsersFilter.length ? !!managersData?.items : true;

    useEffect(() => {
        if (managersData?.items) {
            setManagers(managersData.items);
        }
    }, [managersData]);

    return (
        <>
            <Brow title="Users" icon={<CnslUserIcon />} {...(appCount !== null ? { resourceCount: appCount } : {})} />
            <div className="flex justify-between items-center border-b-[0.5px] p-md border-grey">
                <div className="gap-md px-md flex items-center ">
                    <div>
                        <Select
                            placeholder={
                                <div className="flex items-center gap-sm">
                                    <Building className="size-3.5" />
                                    Department
                                </div>
                            }
                            trigger="small"
                            isClearable
                            closeMenuOnSelect={false}
                            isMulti={true}
                            options={departmentOptions}
                            components={{ ValueContainer: GenericValuesContainer, MultiValue: () => null }}
                            value={
                                filters.department
                                    ? filters.department.map(s => departmentOptions.find(o => o.value === s)!)
                                    : null
                            }
                            onChange={o => {
                                setFilters({
                                    ...filters,
                                    department: o.length
                                        ? o
                                              .filter((d): d is (typeof departmentOptions)[number] => !!d)
                                              .map(d => d.value)
                                        : undefined,
                                });
                            }}
                        />
                    </div>
                    {canRenderUsersFilter && (
                        <div>
                            <UsersFilter
                                placeholderName="Manager"
                                users={managers}
                                onChange={selectedManagers => {
                                    const newManagers = selectedManagers.map(o => o.value);
                                    setManagers(newManagers);
                                    setFilters({ ...filters, manager: newManagers.map(u => u.id) });
                                }}
                            />
                        </div>
                    )}
                </div>
                {!!Object.values(filters)
                    .filter((i: string[] | undefined): i is string[] => !!i)
                    .flat().length && (
                    <Button
                        mode="borderless"
                        size="sm"
                        onClick={() => {
                            setFilters({});
                            setManagers([]);
                        }}
                    >
                        Clear all
                    </Button>
                )}
            </div>
            <Table
                columns={columns}
                pagination={{ ...pagination, filters: Object.keys(filters).length ? qs.stringify(filters) : undefined }}
                onPaginationCountChange={c => setAppCount(c)}
                emptyState={<EmptyState />}
            />
        </>
    );
};
