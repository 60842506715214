import { Button } from '@/components/ui/button';
import { Progress } from '@/components/ui/progress';
import { useOnboardingStatusUpdate } from '@/hooks/mutations/useOnboardingStatus';
import { useOrgOnboarding } from '@/hooks/queries/useOrgOnboarding';
import { ONBOARDING } from '@/routes/paths';
import { Tooltip, TooltipContent, TooltipTrigger } from '@ui/tooltip';
import { OrgOnboardingStatus } from 'lib/prisma/enums';
import { Goal, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import { type OnboardingStep, useOnboardingSteps } from './useOnboardinSteps';
import { getLastUncompletedStep, getTotalSteps, numberOfCompletedSteps } from './utils';

const CompletedCard = () => {
    const onboardingStatus = useOnboardingStatusUpdate();

    return (
        <div className="bg-white rounded-sm p-md">
            <div className="flex items-center justify-between">
                <Link to={ONBOARDING}>
                    <div className="flex items-center gap-sm">
                        <Goal />
                        Completed
                    </div>
                </Link>
                <Button
                    size="sm"
                    mode="borderless"
                    onClick={() => onboardingStatus.mutate({ status: OrgOnboardingStatus.HIDDEN })}
                >
                    <X />
                </Button>
            </div>
            <div className="pt-sm">
                <Progress value={100} className="w-full h-2 text-body-green-500" />
            </div>
        </div>
    );
};

const ProgressCardContent = ({
    currentStep,
    completedSteps,
    totalSteps,
}: { currentStep?: OnboardingStep; completedSteps: number; totalSteps: number }) => (
    <div className="bg-bg transition-all duration-150 ease-out rounded-[6px] p-md shadow-sm hover:shadow flex flex-col gap-md hover:scale-98 mb-lg">
        <div className="flex items-center justify-between gap-md">
            <div className="bg-bg-blue-secondary rounded-[6px] flex items-center justify-center p-sm">
                <Goal className="shrink-0 text-white" />
            </div>
            <Tooltip>
                <TooltipTrigger asChild>
                    {currentStep && (
                        <p className="font-medium line-clamp-1 w-full">{`#${currentStep.step} ${currentStep.title}`}</p>
                    )}
                </TooltipTrigger>
                <TooltipContent>{currentStep && <p>{`#${currentStep.step} ${currentStep.title}`}</p>}</TooltipContent>
            </Tooltip>
            <div className="bg-bg rounded-sm px-sm border-grey border-[0.5px] h-[18px] flex items-center font-medium">{`${completedSteps}/${totalSteps}`}</div>
        </div>
        <Progress value={(completedSteps / totalSteps) * 100} className="w-full h-sm" />
    </div>
);

export const ProgressCard = () => {
    const { data } = useOrgOnboarding();
    const steps = useOnboardingSteps();

    if (!data?.onboarding || data.onboarding.status === OrgOnboardingStatus.HIDDEN) {
        return null;
    }

    if (data.onboarding.status === OrgOnboardingStatus.COMPLETED) {
        return <CompletedCard />;
    }

    const completedSteps = numberOfCompletedSteps(data?.onboarding);
    const currentStep = getLastUncompletedStep(steps);
    const totalSteps = getTotalSteps(data?.onboarding);

    return (
        <Link to={ONBOARDING}>
            <ProgressCardContent currentStep={currentStep} completedSteps={completedSteps} totalSteps={totalSteps} />
        </Link>
    );
};
