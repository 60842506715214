import { useLocation, useParams } from 'react-router-dom';

import { Brow } from '@/components/Brow';
import { NavMenu } from '@/components/NavMenu';
import { Separator } from '@/components/ui/separator';

import { DetailsNavbarHeader } from './DetailsNavHeader';
import type { BrowConfig, DetailsConfig, NavBarHeaderConfig } from './types/DetailsConfig';

const DetailsBrow = ({ breadcrumbs, actions }: BrowConfig) => {
    return <Brow data={breadcrumbs} actions={actions} />;
};

const DetailsNavbar = <T,>({
    config,
    headerConfig,
}: { config: DetailsConfig<T>; headerConfig: NavBarHeaderConfig }) => {
    return (
        <div className="border-grey bg-bg-overlay gap-xl sticky top-0 flex w-[300px] shrink-0 flex-col border-r-[0.5px] p-6">
            <DetailsNavbarHeader {...headerConfig} />
            <Separator />
            <NavMenu items={config} />
        </div>
    );
};

const DetailsContent = <T,>({ config }: { config: DetailsConfig<T> }) => {
    const { pathname } = useLocation();
    const { slug } = useParams();

    const c = config.find(c => c.pathname === pathname);

    const { component } = c ?? {};
    if (!component || !slug) {
        return null;
    }

    return <div className="grow">{component}</div>;
};

export const Details = <T,>({
    config,
    headerConfig,
    browConfig,
}: { config: DetailsConfig<T>; headerConfig?: NavBarHeaderConfig; browConfig: BrowConfig }) => {
    return (
        <div className="flex size-full flex-col">
            <DetailsBrow breadcrumbs={browConfig.breadcrumbs} actions={browConfig.actions} />
            <div className="relative flex grow overflow-y-scroll">
                {headerConfig && <DetailsNavbar config={config} headerConfig={headerConfig} />}
                <DetailsContent config={config} />
            </div>
        </div>
    );
};
