import type { Editor as TiptapEditor } from '@tiptap/core';
import { EditorContent, type Extensions, type JSONContent, useEditor } from '@tiptap/react';

import isEqual from 'lodash-es/isEqual';
import { useEffect } from 'react';

import { cn } from '@/lib/styling';

import { useTiptapEditors } from './useTiptapEditors';
import './Editor.css';
import { debounce } from 'lodash-es';

export const contentIsEmpty = (editor?: TiptapEditor) => {
    if (!editor) return false;

    const html = editor.getHTML();
    const emptyHTML = '<p></p>';

    return html === emptyHTML;
};

const pushToHistoryDebounced = debounce((id: string, editor: TiptapEditor) => {
    const { pushHistory } = useTiptapEditors.getState();

    pushHistory({
        type: 'node-update',
        editorId: id,
        state: editor.getJSON(),
        cursorPosition: editor.view.state.selection.$anchor.pos,
    });
}, 250);

export const Editor = ({
    id,
    content,
    extensions = [],
    onChange,
    disabled,
    controlled,
    onClick,
    className,
    onBlur,
    onKeyDown,
}: {
    extensions: Extensions;
    onChange?: (r: JSONContent) => void;
    id?: string;
    content?: JSONContent | null;
    className?: string;
    disabled?: boolean;
    controlled?: boolean;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    onBlur?: React.FocusEventHandler<HTMLDivElement>;
    onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
}) => {
    const { register, unregister, pushHistory } = useTiptapEditors(s => s);

    const editor = useEditor(
        {
            extensions,
            content,
            onUpdate: ({ editor }) => {
                onChange && onChange(editor.getJSON());

                if (id) {
                    pushToHistoryDebounced(id, editor);
                }
            },
        },
        [controlled],
    );

    useEffect(() => {
        if (editor) {
            editor.setEditable(!disabled);
        }
    }, [editor, disabled]);

    useEffect(() => {
        if (editor && id) {
            register(id, editor);
        }

        return () => {
            pushToHistoryDebounced.cancel();

            if (id) {
                unregister(id);
            }
        };
    }, [editor, id, register, unregister]);

    useEffect(() => {
        if (controlled && editor && content) {
            // XXX - if we don't focus then the cursor placement is lost on content update
            editor.chain().focus().setContent(content).run();
        }
    }, [content, editor, controlled]);

    useEffect(() => {
        if (editor) {
            // check if content is being updated from outside (e.g. user navigates to another page)
            if (!content || isEqual(editor.getJSON(), content)) {
                return;
            }

            editor.commands.setContent(content);
        }
    }, [editor, content]);

    if (!editor) return null;

    const handleMouseUp = () => {
        if (id) {
            pushHistory({
                type: 'node-update',
                editorId: id,
                state: editor.getJSON(),
                cursorPosition: editor.view.state.selection.$anchor.pos,
            });
        }
    };

    // input below EditorContent has tabIndex 0
    return (
        <EditorContent
            tabIndex={-1}
            onMouseDown={onClick}
            onMouseUp={handleMouseUp}
            onKeyDown={onKeyDown}
            className={cn('h-full', !editor.isEditable ? 'text-body-subtle cursor-not-allowed' : '', className)}
            editor={editor}
            onBlur={onBlur}
            onClick={onClick}
        />
    );
};
