import type { ReactNode } from 'react';

import { Label } from './ui/form';

interface KVProps {
    label: ReactNode;
    value: ReactNode;
}

export const KV: React.FC<KVProps> = ({ label, value }) => (
    <div className="gap-md flex h-8 items-start">
        <Label className="text-body-subtle w-[150px] shrink-0 font-medium">{label}</Label>
        <div className="flex flex-wrap gap-md items-start">{value}</div>
    </div>
);
