import FlaggedFeature from '@/components/FlaggedFeature';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { CsvDnd } from '@/pages/policies/AppMatrix/CsvDnd';
import { MatrixModal } from '@/pages/policies/AppMatrix/MatrixModal';
import { FlagKey } from 'lib/flags';
import { CloudUpload, MoreHorizontal } from 'lucide-react';
import { useState } from 'react';
import { Button } from '../ui/button';

export const ShowMore = () => {
    const [showCsvModal, setShowCsvModal] = useState(false);
    const [showMatrixModal, setShowMatrixModal] = useState(false);

    return (
        <FlaggedFeature flag={FlagKey.AppMatrix}>
            <>
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button mode="borderless">
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        <DropdownMenuItem onClick={() => setShowCsvModal(true)}>
                            <CloudUpload className="h-4 w-4" />
                            Import access policies
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>

                <CsvDnd
                    showModal={showCsvModal}
                    setShowModal={setShowCsvModal}
                    onFinish={() => setShowMatrixModal(true)}
                />
                <MatrixModal isOpen={showMatrixModal} setIsOpen={setShowMatrixModal} />
            </>
        </FlaggedFeature>
    );
};
