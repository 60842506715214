import { usePolicyActionTemplates } from '@/hooks/queries/usePolicies';
import { usePolicyCreationStore } from '@/stores/usePolicy';
import { triggerPostMoveFlash } from '@atlaskit/pragmatic-drag-and-drop-flourish/trigger-post-move-flash';
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { reorderWithEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/reorder-with-edge';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { useEffect } from 'react';
import { flushSync } from 'react-dom';

import { AppPolicyStepType } from 'lib/prisma/enums';
import { PolicyStepAction } from './PolicyStepAction';
import { PolicyStepListHeading } from './PolicyStepListHeading';
import { isStepData } from './StepData';
import type { PolicyStepProps } from './types';

export function PolicyStepActionsList({ type }: PolicyStepProps) {
    if (!type) {
        return;
    }
    const { data } = usePolicyActionTemplates();
    const actions = data ? data.actionTemplates.filter(a => a.type === type) : [];

    const store = usePolicyCreationStore();
    const addStep = () => {
        store.addPStep({ id: `step-${Date.now()}`, template: actions[0] }, type);
    };
    const steps = store.psteps(type);

    const removeAction = (id: string) => {
        store.removePStep(id, type);
    };

    const { setPSteps } = store;

    useEffect(() => {
        return monitorForElements({
            canMonitor({ source }) {
                return isStepData(source.data);
            },
            onDrop({ location, source }) {
                const target = location.current.dropTargets[0];
                if (!target) {
                    return;
                }

                const sourceData = source.data;
                const targetData = target.data;

                if (!isStepData(sourceData) || !isStepData(targetData)) {
                    return;
                }

                const indexOfSource = steps.findIndex(task => task.id === sourceData.actionId);
                const indexOfTarget = steps.findIndex(task => task.id === targetData.actionId);

                if (indexOfTarget < 0 || indexOfSource < 0) {
                    return;
                }

                const closestEdgeOfTarget = extractClosestEdge(targetData);

                // Using `flushSync` so we can query the DOM straight after this line
                flushSync(() => {
                    setPSteps(
                        reorderWithEdge({
                            list: steps,
                            startIndex: indexOfSource,
                            indexOfTarget,
                            closestEdgeOfTarget,
                            axis: 'vertical',
                        }),
                        type,
                    );
                });
                // Being simple and just querying for the task after the drop.
                // We could use react context to register the element in a lookup,
                // and then we could retrieve that element after the drop and use
                // `triggerPostMoveFlash`. But this gets the job done.
                const element = document.querySelector(`[data-step-id="${sourceData.taskId}"]`);
                if (element instanceof HTMLElement) {
                    triggerPostMoveFlash(element);
                }
            },
        });
    }, [steps, setPSteps, type]);

    return (
        <div className="gap-lg flex flex-col">
            <PolicyStepListHeading
                heading={type === AppPolicyStepType.PROVISION ? 'Grant access' : 'Revoke access'}
                description={
                    type === AppPolicyStepType.PROVISION
                        ? 'Define how you would like to grant access to users'
                        : 'Define how you would like to revoke access from users'
                }
                onAdd={addStep}
            />
            {steps.map((step, idx) => (
                <PolicyStepAction
                    key={`${step.id}-${type}`}
                    step={step}
                    index={idx}
                    count={steps.length}
                    onDelete={removeAction}
                    type={type}
                    templates={actions}
                />
            ))}
        </div>
    );
}
