import { Button } from '@/components/ui/button';
import { POLICY_CREATE } from '@/routes/paths';
import { usePolicyCreationStore } from '@/stores/usePolicy';
import type { CnslApp } from 'lib/prisma/types';
import { Plus } from 'lucide-react';
import { Link } from 'react-router-dom';
export const CreatePolicyBtn = ({ size = 'sm', app }: { size?: 'default' | 'sm'; app?: CnslApp | null }) => {
    const store = usePolicyCreationStore();
    return (
        <Button
            size={size}
            onClick={() => {
                store.reset();
                store.setApp(app ?? null);
            }}
        >
            <Plus />
            <Link to={POLICY_CREATE}>Add policy</Link>
        </Button>
    );
};
