import type { ColumnDef, ColumnResizeMode } from '@tanstack/react-table';
import { Table } from '@ui/table/table';
import React from 'react';

interface Person {
    firstName: string;
    lastName: string;
    age: number;
    visits: number;
    status: string;
    progress: number;
}

const defaultData: Person[] = [
    {
        firstName: 'tanner',
        lastName: 'linsley',
        age: 24,
        visits: 100,
        status: 'In Relationship',
        progress: 50,
    },
    {
        firstName: 'tandy',
        lastName: 'miller',
        age: 40,
        visits: 40,
        status: 'Single',
        progress: 80,
    },
    {
        firstName: 'joe',
        lastName: 'dirte',
        age: 45,
        visits: 20,
        status: 'Complicated',
        progress: 10,
    },
];

const defaultColumns: ColumnDef<Person>[] = [
    {
        accessorKey: 'firstName',
        cell: info => info.getValue(),
        footer: props => props.column.id,
    },
    {
        accessorFn: row => row.lastName,
        id: 'lastName',
        cell: info => info.getValue(),
        header: () => <span>Last Name</span>,
        footer: props => props.column.id,
    },
    {
        accessorKey: 'age',
        header: () => 'Age',
        footer: props => props.column.id,
    },
    {
        accessorKey: 'visits',
        header: () => <span>Visits</span>,
        footer: props => props.column.id,
    },
    {
        accessorKey: 'status',
        header: 'Status',
        footer: props => props.column.id,
    },
    {
        accessorKey: 'progress',
        header: 'Profile Progress',
        footer: props => props.column.id,
    },
];

const TableResizable = () => {
    const [data] = React.useState(() => [...defaultData]);
    const [columns] = React.useState<typeof defaultColumns>(() => [...defaultColumns]);

    const opts = {
        columnResizeMode: 'onChange',
    } as { columnResizeMode: ColumnResizeMode };

    const reactTableOpts = { ...opts, debugTable: true, debugHeaders: true, debugColumns: true };

    return (
        <div className="w-full">
            <Table data={data} columns={columns} reactTableOpts={reactTableOpts} />
        </div>
    );
};

export default TableResizable;
