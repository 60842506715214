import { getTime } from 'date-fns';
import type { CnslRequestList } from 'lib/models/request';
import { RequestStatus, type RequestStatusType } from 'lib/prisma/enums';
import { BookText, Brain, CalendarDays, Check, Circle, ScrollText, Ticket, X } from 'lucide-react';

import { CircleIndicator } from '@/components/CircleIndicator';
import { KV } from '@/components/KV';
import { ResLink } from '@/components/ResLink';
import { Badge } from '@/components/ui/badge';
import { fmtDateFull } from '@/lib/dates';
import { getCssColor, getKnowledgeBaseReferences, getPlaybookSlugs, getProvisioningRequests } from 'lib/requests/utils';

import { Select } from '@/components/ui/select';
import { useRequestCategoryUpdate } from '@/hooks/mutations/requests/useRequestCategory';
import { useFlatCategories } from '@/hooks/queries/useCategories';
import { getDescription, getStatus } from 'lib/requests/utils';

import { CnslOnly } from '@/components/CnslOnly';
import { RequestReferenceBadge } from '@/components/RequestReferenceBadge';
import { CategoryOption, CategoryValuesContainer } from '@/components/ui/select/CategoryOption';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/styling';
import { CnslActionSlug } from 'lib/actions/slugs';
import { IntegrationId } from 'lib/integration';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { TimelineEntry } from '../components/TimelineEntry';

export enum TimelineEntryType {
    PlaybookInvocation = 'playbookInvocation',
    RequestConversation = 'requestConversation',
    Event = 'event',
    RequestAppAccess = 'requestAppAccess',
    ReviewAppAccess = 'reviewAppAccess',
    RunAppAccessGrant = 'runAppAccessGrant',
}
export type RequestPlaybookInvocation = CnslRequestList['playbook_invocation'][number] & {
    entityType: TimelineEntryType.PlaybookInvocation;
};
export type RequestRequestConversation = CnslRequestList['request_conversation'][number] & {
    entityType: TimelineEntryType.RequestConversation;
};
export type RequestEvents = CnslRequestList['events'][number] & { entityType: TimelineEntryType.Event };
export type RequestAppAccess = CnslRequestList['provisioningRequests'][number] & {
    entityType: TimelineEntryType.RequestAppAccess;
};
export type ReviewAppAccess = CnslRequestList['provisioningRequests'][number] & {
    entityType: TimelineEntryType.ReviewAppAccess;
};
export type RunAppAccessGrant = CnslRequestList['provisioningRequests'][number] & {
    entityType: TimelineEntryType.RunAppAccessGrant;
};

const flattenConversation = (
    playbook_invocation: CnslRequestList['playbook_invocation'],
    request_conversation: CnslRequestList['request_conversation'],
    events: CnslRequestList['events'],
    provisioningRequests: CnslRequestList['provisioningRequests'],
) => {
    // explode the provisioningRequests into AppAccess request, reviews, and runGrants
    const appAccessRequests: RequestAppAccess[] = provisioningRequests.map(
        (p): RequestAppAccess => ({ entityType: TimelineEntryType.RequestAppAccess, ...p }),
    );
    const reviews: ReviewAppAccess[] = provisioningRequests
        .filter(p => p.reviewedAt)
        .map(p => ({
            entityType: TimelineEntryType.ReviewAppAccess,
            ...p,
            createdAt: p.reviewedAt ?? p.createdAt,
            id: `${p.id}-review`,
        }));
    const runGrants: RunAppAccessGrant[] = provisioningRequests
        .filter(p => p.status === 'APPROVED')
        .map(p => ({
            entityType: TimelineEntryType.RunAppAccessGrant,
            ...p,
            createdAt: p.reviewedAt ?? p.createdAt,
            id: `${p.id}-run-grant`,
        }));

    return [
        ...playbook_invocation.map(
            (p): RequestPlaybookInvocation => ({ entityType: TimelineEntryType.PlaybookInvocation, ...p }),
        ),
        ...request_conversation.map(
            (p): RequestRequestConversation => ({ entityType: TimelineEntryType.RequestConversation, ...p }),
        ),
        ...appAccessRequests,
        ...reviews,
        ...runGrants,
        ...events.map((p): RequestEvents => ({ entityType: TimelineEntryType.Event, ...p })),
    ].sort((a, b) => getTime(a.createdAt) - getTime(b.createdAt));
};

export const TimelineEntryResolution = ({
    request,
    isLastEntry,
}: { request: RequestEvents['request']; isLastEntry: boolean }) => {
    return (
        <>
            <div className="gap-xl flex items-center justify-between">
                <div className="gap-sm flex-1 items-center overflow-visible">
                    <Resolution request={request as CnslRequestList} />
                </div>
            </div>
            <div className="relative flex">
                {!isLastEntry && (
                    <div className="border-grey my-sm relative left-[11.5px] self-stretch border-[0.5px]" />
                )}
                <div className="mt-sm mb-xl ml-2xl rounded-[6px] py-xs" />
            </div>
        </>
    );
};

export const Resolution = ({ request }: { request: CnslRequestList }) => {
    const config: Record<RequestStatusType, { icon: React.ElementType; label: string }> = {
        [RequestStatus.COMPLETED]: {
            icon: Check,
            label: 'Request Completed',
        },
        [RequestStatus.CANCELED]: {
            icon: X,
            label: 'Request Canceled',
        },
        [RequestStatus.TICKET_CREATED]: {
            icon: Ticket,
            label: 'Ticket Created',
        },
        [RequestStatus.IN_PROGRESS]: {
            icon: Circle,
            label: 'Request In Progress',
        },
    };

    const opts = config[request.status];
    const color = getCssColor(getStatus(request)?.color ?? 'grey', 'bg');

    return request.resolvedAt ? (
        <div className="gap-xl flex items-center justify-between">
            <div className="gap-md flex items-center text-sm font-medium">
                <div
                    className={cn(
                        'text-body-white mx-[2px] flex size-5 items-center justify-center rounded-full',
                        color,
                    )}
                >
                    <opts.icon className="size-3.5 text-body-white" />
                </div>
                {opts.label}
            </div>
            <div className="text-body-subtle text-xs w-[140px]">{fmtDateFull(request.resolvedAt)}</div>
        </div>
    ) : null;
};

export const Main = ({ request }: { request: CnslRequestList }) => {
    const { data, isLoading } = useFlatCategories();
    const { mutate: updateRequestCategory } = useRequestCategoryUpdate(request.id, request.slug);
    const timelineEntries = flattenConversation(
        request.playbook_invocation,
        request.request_conversation,
        request.events,
        request.provisioningRequests,
    );

    const requestStatus = getStatus(request);
    const color = getCssColor(requestStatus?.color ?? 'grey', 'bg');

    const categoryOptions = useMemo(() => {
        return data?.categories.map(c => ({ label: c.name, value: c, color: c.color })) || [];
    }, [data?.categories]);

    const playbooks = getPlaybookSlugs(request);
    const policies = getProvisioningRequests(request);
    const knowledgeBaseReferences = getKnowledgeBaseReferences(request);
    const getTemporalLink = (id: string) => {
        if (window.location.hostname === 'localhost') {
            return `http://127.0.0.1:8233/namespaces/default/workflows/execute-request-agent-${id}`;
        } else if (window.location.hostname.includes('staging.console.co')) {
            return `https://cloud.temporal.io/namespaces/default.drp2l/workflows/execute-request-agent-${id}`;
        } else if (window.location.hostname.includes('console.co')) {
            return `https://cloud.temporal.io/namespaces/prod1.drp2l/workflows/execute-request-agent-${id}`;
        }
        return '';
    };
    const getLangsmithLink = (id: string) => {
        if (window.location.hostname.includes('staging.console.co')) {
            return `https://smith.langchain.com/o/b63808df-bc49-4df4-a915-e060e1258863/projects/p/d9cb8398-3311-4710-9404-ca750033e10c?filter%22%3A%22and%28eq%28metadata_key%2C+%5C%22requestId%5C%22%29%2C+eq%28metadata_value%2C+%5C%22cm3ehaoky0064s3ueomthg9y5%5C%22%29%29%22%7D=&columnVisibilityModel=%7B%22feedback_stats%22%3Afalse%2C%22reference_example%22%3Afalse%2C%22start_time%22%3Afalse%2C%22latency%22%3Afalse%2C%22in_dataset%22%3Afalse%2C%22last_queued_at%22%3Afalse%2C%22first_token_time%22%3Afalse%2C%22tags%22%3Afalse%2C%22metadata%22%3Afalse%2C%22reference_example_id%22%3Afalse%7D&timeModel=%7B%22duration%22%3A%227d%22%7D&searchModel=%7B%22filter%22%3A%22and%28eq%28metadata_key%2C+%5C%22requestId%5C%22%29%2C+eq%28metadata_value%2C+%5C%22${id}%5C%22%29%29%22%7D`;
        } else if (window.location.hostname.includes('console.co')) {
            return `https://smith.langchain.com/o/b63808df-bc49-4df4-a915-e060e1258863/projects/p/bf1732e3-ce49-4352-b364-9ee0f51294f3?columnVisibilityModel=%7B%22feedback_stats%22%3Afalse%2C%22reference_example%22%3Afalse%2C%22start_time%22%3Afalse%2C%22latency%22%3Afalse%2C%22in_dataset%22%3Afalse%2C%22last_queued_at%22%3Afalse%2C%22first_token_time%22%3Afalse%2C%22tags%22%3Afalse%2C%22metadata%22%3Afalse%2C%22reference_example_id%22%3Afalse%7D&timeModel=%7B%22duration%22%3A%227d%22%7D&searchModel=%7B%22filter%22%3A%22and%28eq%28metadata_key%2C+%5C%22requestId%5C%22%29%2C+eq%28metadata_value%2C+%5C%22${id}%5C%22%29%29%22%7D`;
        }
        return '';
    };

    return (
        <div className="mx-2xl gap-4xl py-4xl flex max-w-screen-sm flex-col items-start overflow-scroll min-[960px]:m-auto">
            <div className="gap-lg flex flex-col">
                <div className="gap-md flex flex-col items-start justify-start">
                    <Badge>
                        <div className="gap-sm flex items-center">
                            <CircleIndicator color={color} />
                            <span>{requestStatus?.label}</span>
                        </div>
                    </Badge>
                    <h1 className="text-xl">{getDescription(request)}</h1>
                </div>
                <div className="flex flex-col">
                    <CnslOnly>
                        <KV label={<div>Request ID</div>} value={<div>{request.id}</div>} />
                        <KV
                            label={<div>Temporal Workflow</div>}
                            value={<Link to={getTemporalLink(request.id)}>Temporal Cloud</Link>}
                        />
                        <KV
                            label={<div>LangSmith</div>}
                            value={<Link to={getLangsmithLink(request.id)}>LangSmith</Link>}
                        />
                    </CnslOnly>
                    <KV
                        label={<div>Requester</div>}
                        value={
                            <ResLink
                                size="sm"
                                entity="users"
                                id={request.requester.email}
                                label={request.requester.displayName}
                                src={request.requester.avatar ?? ''}
                            />
                        }
                    />
                    <KV
                        label={<div>Requested</div>}
                        value={
                            <div className="gap-md py-sm flex items-center">
                                <CalendarDays />
                                {fmtDateFull(request.createdAt)}
                            </div>
                        }
                    />
                    {!isLoading && (
                        <KV
                            label={<div>Category</div>}
                            value={
                                <Select
                                    placeholder="No category"
                                    isMulti={false}
                                    components={{ Option: CategoryOption, ValueContainer: CategoryValuesContainer }}
                                    defaultValue={
                                        request.category
                                            ? categoryOptions.find(c => c.value.id === request.category?.id)
                                            : undefined
                                    }
                                    className="border-none shadow-none"
                                    options={categoryOptions}
                                    onChange={e => {
                                        if (e?.value) {
                                            updateRequestCategory({ categoryId: e.value.id });
                                        }
                                    }}
                                />
                            }
                        />
                    )}
                    <KV
                        label={<div>References</div>}
                        value={[
                            ...playbooks.map((p, index) => (
                                <Link key={index} to={`/playbooks/${p.slug}`}>
                                    <RequestReferenceBadge label={p.name} icon={<BookText />} />
                                </Link>
                            )),
                            ...policies.map((p, index) => (
                                <Link key={index} to={`/policies/${p.id}`}>
                                    <RequestReferenceBadge label={p.name} icon={<ScrollText />} />
                                </Link>
                            )),
                            ...knowledgeBaseReferences.map((p, index) => {
                                if (p === null) return null;
                                return p.slug ? (
                                    <Link key={index} to={`/articles/${p.slug}`}>
                                        <RequestReferenceBadge label={p.name} icon={<Brain />} />
                                    </Link>
                                ) : (
                                    <a key={index} href={p.link} target="_blank" rel="noopener noreferrer">
                                        <RequestReferenceBadge label={p.name} icon={<Brain />} />
                                    </a>
                                );
                            }),
                        ]}
                    />
                </div>
            </div>

            <Separator />

            <div className="gap-lg flex w-full flex-col pb-24">
                <h1 className="text-base">Timeline</h1>
                <div className="flex w-full flex-col">
                    <div className="w-full">
                        {timelineEntries.map((entry, index) => (
                            <>
                                <TimelineEntry
                                    key={entry.id}
                                    entry={entry}
                                    isLastEntry={index === timelineEntries.length - 1}
                                    request={request}
                                />
                                {entry.entityType === TimelineEntryType.Event &&
                                    typeof entry.details === 'object' &&
                                    entry.details !== null &&
                                    'provider' in entry.details &&
                                    'slug' in entry.details &&
                                    entry.details.provider === IntegrationId.Console &&
                                    entry.details.slug === CnslActionSlug.Escalate &&
                                    entry.request?.status === RequestStatus.TICKET_CREATED && (
                                        <TimelineEntryResolution
                                            isLastEntry={index === timelineEntries.length - 1}
                                            request={entry.request}
                                        />
                                    )}
                            </>
                        ))}
                    </div>
                    {request.status !== RequestStatus.TICKET_CREATED && <Resolution request={request} />}
                </div>
            </div>
        </div>
    );
};
