import { useAppMatrix } from '@/hooks/queries/useAppMatrix';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { MatrixModal } from '@/pages/policies/AppMatrix/MatrixModal';
import { FlagKey } from 'lib/flags';
import { Bell } from 'lucide-react';
import { useState } from 'react';
import { Badge } from '../ui/badge';
export const PolicyDraftsBtn = () => {
    const [showModal, setShowModal] = useState(false);
    const { data: matrixData, isLoading } = useAppMatrix();
    const isAppMatrixEnabled = useFeatureFlag(FlagKey.AppMatrix);
    if (isLoading || !matrixData?.rows || matrixData.rows.length === 0 || !isAppMatrixEnabled) {
        return null;
    }
    return (
        <>
            <Badge className="cursor-pointer" variant="yellow" onClick={() => setShowModal(true)}>
                <Bell />
                {matrixData.rows.length} draft{matrixData.rows.length > 1 ? 's' : ''} need review
            </Badge>
            <MatrixModal isOpen={showModal} setIsOpen={setShowModal} />
        </>
    );
};
