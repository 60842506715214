import { Button } from '@/components/ui/button';
import { cn } from '@/lib/styling';
import { forwardRef } from 'react';

interface IconButtonProps {
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    children: React.ReactNode;
    disabled?: boolean;
    className?: string;
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
    ({ onClick, children, disabled, className }, ref) => {
        return (
            <Button
                ref={ref}
                mode="borderless"
                size="sm"
                className={cn(
                    'text-body-subtle hover:text-body-subtle-hover hover:bg-bg-grey-primary-hover size-6 rounded-full bg-transparent',
                    className,
                )}
                onClick={onClick}
                disabled={disabled}
            >
                {children}
            </Button>
        );
    },
);
