import { SkeletonTable } from '@ui/skeleton';
import { Flag } from 'lucide-react';
import { useLocation, useParams } from 'react-router-dom';

import { useRequest } from '@/hooks/queries/useRequest';
import { Details } from '@/pages/layouts/Details';
import { DetailsCrumbIconWrapper } from '@/pages/layouts/components/DetailsCrumbIconWrapper';
import type { BrowConfig } from '@/pages/layouts/types/DetailsConfig';

import { getDescription } from 'lib/requests/utils';

import { Main } from './Main';

const entity = 'requests';

export const RequestDetails = () => {
    const currentPath = useLocation().pathname;
    const { slug = '' } = useParams();
    const { data, isLoading } = useRequest({ slug });
    const request = data?.request;

    if (isLoading) {
        return <SkeletonTable />;
    }

    if (!request) {
        return null;
    }

    const config = [
        { name: 'Requests', pathname: '', Icon: Flag, component: <Main {...request} />, matchPathExactly: true },
    ];

    const basePath = `/${entity}/${slug}`;

    const crumbs = [
        { label: <DetailsCrumbIconWrapper label="Requests" icon={<Flag />} />, href: `/${entity}` },
        { label: <div>{getDescription(request.request) ?? ''}</div>, href: basePath },
    ];

    config.forEach(c => {
        c.pathname = `${basePath}${c.pathname}`;
        if (c.pathname === currentPath && c.pathname !== basePath) {
            crumbs.push({
                label: <DetailsCrumbIconWrapper label={c.name} icon={<c.Icon />} bold={true} />,
                href: c.pathname,
            });
        }
    });

    const browConfig: BrowConfig = {
        breadcrumbs: crumbs,
    };
    return <Details config={config} browConfig={browConfig} />;
};
