import { useMutation } from '@/lib/hooks/useMutation';

import { type DefaultError, useQueryClient } from '@tanstack/react-query';
import type { CreateSingleDirectory } from 'lib/models/directory';

export const useArticleDirectoryCreate = () => {
    const queryClient = useQueryClient();
    return useMutation<{ success: boolean }, DefaultError, CreateSingleDirectory>({
        url: `/api/v1/directory`,
        method: 'POST',
        onSuccess: async () => await queryClient.refetchQueries({ queryKey: ['/api/v1/directory'] }),
    });
};
