import FlaggedFeature from '@/components/FlaggedFeature';
import { IconButton } from '@/components/IconButton';
import { DnDTree } from '@/components/dnd-tree';
import type { TreeItem } from '@/components/dnd-tree/tree';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { useArticleDirectoryCreate } from '@/hooks/mutations/articles/useArticleDirectoryCreate';
import { useArticleDirectoryUpdate } from '@/hooks/mutations/articles/useArticleDirectoryUpdate';
import { useArticleCreate } from '@/hooks/mutations/useArticle';
import { useArticleDirectory } from '@/hooks/queries/articles/useArticleDirectory';
import { useArticles } from '@/hooks/queries/useArticle';
import { useIntegrationSettings } from '@/hooks/queries/useIntegrationSettings';
import { cn } from '@/lib/styling';
import { ARTICLES, type ARTICLE_DETAILS_TYPE } from '@/routes/paths';
import { getProviderAssets } from 'lib/3p';
import { FlagKey } from 'lib/flags/keys';
import { IntegrationId } from 'lib/integration';
import { dbIdToIntegrationId } from 'lib/integrationHelpers';
import type { Article } from 'lib/models/article';
import type { SingleLayerDirectory } from 'lib/models/directory';
import type { IntegrationIdType as EnumIntegrationIdType } from 'lib/prisma/enums';
import { ArticleState } from 'lib/prisma/enums';
import { IntegrationId as EnumIntegrationId } from 'lib/prisma/enums';
import { ChevronDown, ChevronRight, FilePlus, FolderClosed, FolderOpen, FolderPlus, Plus, Search } from 'lucide-react';
import { ArrowUpRight, FileUp, Pencil } from 'lucide-react';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SyncArticleDialog } from './SyncArticleDialog';
import { getStatusDot } from './utils';

function GroupIcon({ isOpen }: { isOpen: boolean }) {
    const Icon = isOpen ? FolderOpen : FolderClosed;
    return <Icon className="text-body-subtle size-[14px]" />;
}

function Icon({ item }: { item: TreeItem }) {
    if (item.type === 'file') {
        return null;
    }
    return <GroupIcon isOpen={item.isOpen ?? false} />;
}

function ChevronIcon({ item }: { item: TreeItem }) {
    if (item.type === 'directory') {
        return item.isOpen ? (
            <ChevronDown className="text-body-subtle" />
        ) : (
            <ChevronRight className="text-body-subtle" />
        );
    }
    return null;
}

const IntegrationTree = ({
    integration,
    directory,
    renderItem,
    handleTreeChange,
}: {
    integration: EnumIntegrationIdType;
    directory: SingleLayerDirectory;
    renderItem: (item: TreeItem) => React.ReactNode;
    handleTreeChange: (integration: EnumIntegrationIdType, tree: TreeItem[]) => void;
}) => {
    const [isOpen, setIsOpen] = useState(true);
    const [isCreateDirectoryOpen, setIsCreateDirectoryOpen] = useState(false);
    const [newDirName, setNewDirName] = useState('');
    const createDirectory = useArticleDirectoryCreate();
    const provider = dbIdToIntegrationId(integration);
    const { logo, name } = getProviderAssets(provider);
    return (
        <div key={integration} className="flex flex-col">
            <div className="flex justify-between items-center">
                <div className="flex items-center gap-md">
                    <img src={logo} alt={name} className="size-4" />
                    <div className="font-medium">{name}</div>
                    <Badge>{directory.flatMap(i => (i.type === 'directory' ? i.children : i)).length}</Badge>
                </div>
                <div className="flex items-center gap-md">
                    <Popover open={isCreateDirectoryOpen} onOpenChange={setIsCreateDirectoryOpen}>
                        <PopoverTrigger asChild>
                            <IconButton>
                                <FolderPlus />
                            </IconButton>
                        </PopoverTrigger>
                        <PopoverContent align="start">
                            <div className="p-md flex gap-md items-center">
                                <Input
                                    placeholder="New folder"
                                    onChange={e => setNewDirName(e.target.value)}
                                    onKeyDown={e => {
                                        if (e.key === 'Enter') {
                                            createDirectory.mutate({ name: newDirName, integration });
                                            setIsCreateDirectoryOpen(false);
                                            setNewDirName('');
                                        } else if (e.key === 'Escape') {
                                            setIsCreateDirectoryOpen(false);
                                            setNewDirName('');
                                        }
                                    }}
                                />
                                <Button
                                    mode="dark"
                                    variant="blue"
                                    className="shrink-0"
                                    disabled={!newDirName}
                                    onClick={() => {
                                        createDirectory.mutate({ name: newDirName, integration });
                                        setNewDirName('');
                                        setIsCreateDirectoryOpen(false);
                                    }}
                                >
                                    Create
                                </Button>
                            </div>
                        </PopoverContent>
                    </Popover>
                    <div className="w-[1px] h-4 bg-bg-grey-primary" />
                    <IconButton onClick={() => setIsOpen(!isOpen)}>
                        {isOpen ? (
                            <ChevronDown className="text-body-subtle" />
                        ) : (
                            <ChevronRight className="text-body-subtle" />
                        )}
                    </IconButton>
                </div>
            </div>
            <div className="ml-lg">
                {isOpen && (
                    <DnDTree
                        treeData={directory ?? []}
                        maxDepth={1}
                        indentPerLevel={22}
                        renderItem={renderItem}
                        onChange={tree => handleTreeChange(integration, tree)}
                    />
                )}
            </div>
        </div>
    );
};

export const ArticlesSidebar = () => {
    const { data: settingsStatusData } = useIntegrationSettings();
    const settingsStatus = settingsStatusData?.status || {};

    // state for creating new directories and for opening sync article dialog
    const [syncArticleDialog, setSyncArticleDialog] = useState<IntegrationId | undefined>();
    const [search, setSearch] = useState('');

    const navigate = useNavigate();
    const location = useLocation();

    // article and directory state
    const { data, isLoading: isArticleLoading } = useArticles();
    const articles = useMemo(
        () => new Map(data?.articles.filter(v => v.internal_article).map(v => [v.internal_article?.id, v])),
        [data],
    );
    const externalArticles = useMemo(
        () => new Map(data?.articles.filter(v => v.external_article).map(v => [v.external_article?.externalId, v])),
        [data],
    );
    const { data: directories, isLoading: isDirectoryLoading } = useArticleDirectory();

    const createArticle = useArticleCreate();
    const updateDirectory = useArticleDirectoryUpdate({ id: directories?.id });

    if (isDirectoryLoading || isArticleLoading) return null;

    const { logo: zdLogo, name: zdName } = getProviderAssets(IntegrationId.Zendesk);
    const { logo: cfLogo, name: cfName } = getProviderAssets(IntegrationId.Confluence);
    const { logo: notionLogo, name: notionName } = getProviderAssets(IntegrationId.Notion);

    const renderItem = (item: TreeItem) => {
        const { name, logo } = getProviderAssets(item.integration ? dbIdToIntegrationId(item.integration) : '');
        const article = 'internalId' in item ? articles.get(item?.internalId as string) : undefined;
        let externalArticle: Article | undefined;
        if (!article && 'externalLink' in item) {
            externalArticle = externalArticles.get(item.externalLink as string);
        }

        const [isHovered, setIsHovered] = useState(false);

        if (search && item.type === 'file' && !item.name?.toLowerCase().includes(search.toLowerCase())) {
            return null;
        }

        return (
            <div
                className={cn(
                    'flex gap-lg justify-between items-center py-sm cursor-pointer px-md rounded-[6px] overflow-hidden truncate h-7 mb-[2px]',
                    location.pathname === `${ARTICLES}/${article?.internal_article?.slug}`
                        ? 'bg-bg-grey-primary'
                        : 'hover:bg-bg-grey-primary',
                    item.type === 'directory' && 'mt-md',
                )}
                onClick={() => {
                    if (article) {
                        navigate(`${ARTICLES}/${article.internal_article?.slug}` as ARTICLE_DETAILS_TYPE);
                    } else if (externalArticle) {
                        window.open(externalArticle.external_article?.link, '_blank');
                    }
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div className="flex items-center gap-md overflow-hidden">
                    <Icon item={item} />
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <p
                                className={cn(
                                    'overflow-hidden text-left text-ellipsis',
                                    item.type !== 'file' ? 'text-body-subtle font-medium text-xs' : '',
                                )}
                            >
                                {item.name || 'No title'}
                            </p>
                        </TooltipTrigger>
                        <TooltipContent className="text-left">{item.name || 'No title'}</TooltipContent>
                    </Tooltip>
                </div>
                {item.type === 'file' && (
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <div className="flex items-center justify-center size-4 shrink-0">
                                {article ? (
                                    getStatusDot(article.internal_article?.state)
                                ) : isHovered ? (
                                    <img src={logo} alt={name} className="size-3" />
                                ) : (
                                    <ArrowUpRight className="size-3 text-body-subtle" />
                                )}
                            </div>
                        </TooltipTrigger>
                        <TooltipContent className="text-left">
                            {article?.internal_article?.state === ArticleState.DRAFT ? (
                                <div className="flex items-center gap-md">
                                    <Pencil className="size-3" />
                                    <div>Draft article</div>
                                </div>
                            ) : article?.internal_article?.state === ArticleState.PUBLISHED ? (
                                <div className="flex items-center gap-md">
                                    <FileUp className="size-3" />
                                    <div>Published article</div>
                                </div>
                            ) : (
                                <div className="flex items-center gap-md">Synced from {name}</div>
                            )}
                        </TooltipContent>
                    </Tooltip>
                )}
                <ChevronIcon item={item} />
            </div>
        );
    };

    const handleTreeChange = (integration: EnumIntegrationIdType, tree: TreeItem[]) => {
        updateDirectory.mutate({
            ...(directories?.directory ?? {}),
            [integration]: tree as SingleLayerDirectory,
        });
    };

    return (
        <div className="w-[300px] shrink-0 border-r-[0.5px] border-grey bg-bg-overlay flex flex-col">
            {/* Header */}
            <div className="flex flex-col p-md border-grey border-b-[0.5px]">
                <div className="flex justify-between items-center p-md">
                    <h2 className="text-base">Articles</h2>
                    <div className="flex items-center gap-sm">
                        <Popover>
                            <PopoverTrigger asChild>
                                <IconButton>
                                    <Plus />
                                </IconButton>
                            </PopoverTrigger>
                            <PopoverContent align="start" className="w-[250px]">
                                <div className="flex flex-col w-full">
                                    <div className="flex flex-col border-grey border-b-[0.5px] p-sm w-full">
                                        <Button
                                            mode="borderless"
                                            className="h-12 w-full justify-start"
                                            onClick={() => {
                                                createArticle.mutate({});
                                            }}
                                        >
                                            <FilePlus />
                                            <div className="flex flex-col text-left">
                                                New article
                                                <p className="text-xs text-body-subtle mt-[-2px]">
                                                    Creates an article in Console
                                                </p>
                                            </div>
                                        </Button>
                                    </div>
                                    <FlaggedFeature flag={FlagKey.KnowledgeBaseZendeskSync}>
                                        {settingsStatus.zendesk && (
                                            <div className="p-sm pb-0 w-full">
                                                <Button
                                                    mode="borderless"
                                                    className="text-left w-full justify-start"
                                                    onClick={() => {
                                                        setSyncArticleDialog(IntegrationId.Zendesk);
                                                    }}
                                                >
                                                    <img src={zdLogo} alt={zdName} className="size-4" />
                                                    Sync article from Zendesk
                                                </Button>
                                            </div>
                                        )}
                                        {settingsStatus.confluence && (
                                            <div className="p-sm pb-0 w-full">
                                                <Button
                                                    mode="borderless"
                                                    className="text-left w-full justify-start"
                                                    onClick={() => {
                                                        setSyncArticleDialog(IntegrationId.Confluence);
                                                    }}
                                                >
                                                    <img src={cfLogo} alt={cfName} className="size-4" />
                                                    Sync article from Confluence
                                                </Button>
                                            </div>
                                        )}
                                        {settingsStatus.notion && (
                                            <div className="p-sm pt-0 w-full">
                                                <Button
                                                    mode="borderless"
                                                    className="text-left w-full justify-start"
                                                    onClick={() => {
                                                        setSyncArticleDialog(IntegrationId.Notion);
                                                    }}
                                                >
                                                    <img src={notionLogo} alt={notionName} className="size-4" />
                                                    Sync article from Notion
                                                </Button>
                                            </div>
                                        )}
                                    </FlaggedFeature>
                                </div>
                            </PopoverContent>
                        </Popover>
                    </div>
                </div>
                <div className="p-md">
                    <Input icon={<Search />} placeholder="Search articles" onChange={e => setSearch(e.target.value)} />
                </div>
            </div>

            {syncArticleDialog && (
                <SyncArticleDialog onClose={() => setSyncArticleDialog(undefined)} provider={syncArticleDialog} />
            )}
            {/* Tree */}
            <div className="p-md overflow-y-auto gap-lg flex flex-col">
                {Object.entries(directories?.directory ?? {})
                    .sort(([a], [b]) => {
                        if (a === EnumIntegrationId.CONSOLE) return -1;
                        if (b === EnumIntegrationId.CONSOLE) return 1;
                        return 0;
                    })
                    .map(([integration, directory]) => (
                        <IntegrationTree
                            key={integration}
                            integration={integration as EnumIntegrationIdType}
                            directory={directory}
                            renderItem={renderItem}
                            handleTreeChange={handleTreeChange}
                        />
                    ))}
            </div>
        </div>
    );
};
