import { badgeVariants } from '@ui/badge';
import type { VariantProps } from 'class-variance-authority';
import type * as React from 'react';

import { cn } from '@/lib/styling';

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, mode, ...props }: BadgeProps) {
    return <div className={cn(badgeVariants({ variant, mode }), className)} {...props} />;
}

export { Badge };
