import { APPS_NEEDING_REVIEW } from '@/lib/apiRoutes';
import { useMutation } from '@lib/hooks/useMutation';
import { type DefaultError, type InfiniteData, useQueryClient } from '@tanstack/react-query';
import type { AppStatusPut, CnslAppList } from 'lib/models/app';
import { cloneDeep } from 'lodash-es';
export const useAppStatusPut = ({ slug }: { slug: string }) => {
    const queryClient = useQueryClient();
    return useMutation<AppStatusPut, DefaultError, AppStatusPut>({
        url: `/api/v1/apps/${slug}/status`,
        method: 'PUT',
        onSuccess: async ({ status }) => {
            queryClient.refetchQueries({ queryKey: [`/api/v1/apps/${slug}`] });
            queryClient.refetchQueries({ queryKey: [APPS_NEEDING_REVIEW] });
            // update local cache instead of refetching apps. Table is paginated
            queryClient.setQueriesData<InfiniteData<CnslAppList[]>>({ queryKey: ['apps'] }, old => {
                if (old) {
                    const update = cloneDeep(old);
                    update.pages.forEach(page => {
                        page.forEach(app => {
                            if (app.slug === slug) {
                                app.status = status;
                            }
                        });
                    });

                    return update;
                } else {
                    return old;
                }
            });
        },
    });
};
