import type { ColumnDef } from '@tanstack/react-table';
import { Table } from '@ui/table/table';
import type { RequestFilters } from 'lib/models/cnsl_request';
import type { CnslRequestList } from 'lib/models/request';
import { useEffect, useMemo, useState } from 'react';
import type { DateRange } from 'react-day-picker';
import { Link } from 'react-router-dom';

import { Flag } from 'lucide-react';
import qs from 'qs';

import { Brow } from '@/components/Brow';
import { CircleIndicator } from '@/components/CircleIndicator';
import { DatePickerWithRange } from '@/components/RangeDatePicker';
import { RequestCategoryChip } from '@/components/RequestCategoryChip';
import { RequestReference } from '@/components/RequestReference';
import { ResLink } from '@/components/ResLink';
import { TableEmptyState } from '@/components/TableEmptyState';
import { CategoriesFilter } from '@/components/filters/CategoryFilter';
import { FilterBar } from '@/components/filters/FilterBar';
import { UsersFilter } from '@/components/filters/UsersFilter';
import { Button } from '@/components/ui/button';
import { Select } from '@/components/ui/select';
import type { CategoryOption } from '@/components/ui/select/CategoryOption';
import { StatusOption, StatusValue as StatusValueComponent } from '@/components/ui/select/StatusOptions';
import { useFlatCategories } from '@/hooks/queries/useCategories';
import { useWimpyOrgOnboarding } from '@/hooks/queries/useOrgOnboarding';
import { useUsers } from '@/hooks/queries/useUsers';
import { fmtDateFull } from '@/lib/dates';
import { LIMIT_DEFAULT, THRESHOLD_DEFAULT } from '@/lib/paginationConstants';
import { getSlackOAuthUrl } from '@/lib/slack';
import { useIntegrationSettingsStatus } from '@hooks/queries/integrations/useIntegrationSettingsStatus';
import { IntegrationId } from 'lib/integration';
import type { CnslUser } from 'lib/prisma/types';
import { getCssColor, getDescription, getStatus, statusOptions } from 'lib/requests/utils';
import isEmpty from 'lodash-es/isEmpty';
import { useLocalStorage } from 'react-use';
import { ResolutionGraph } from './components/ResolutionGraph';
const columns: ColumnDef<CnslRequestList>[] = [
    {
        accessorKey: 'request',
        header: () => 'Request',
        cell: props => {
            const requestStatus = getStatus(props.row.original);
            const color = getCssColor(requestStatus?.color ?? 'grey', 'bg');

            return (
                <Link to={`/requests/${props.row.original.slug}`} className="flex gap-md px-lg items-center">
                    <CircleIndicator color={color} />
                    <div className="flex flex-col size-full py-sm truncate">
                        <p className="w-full truncate font-medium">{getDescription(props.row.original)}</p>
                        <span className="text-body-subtle mt-[-2px] text-xs">{requestStatus?.label}</span>
                    </div>
                </Link>
            );
        },
        meta: {
            expand: true,
        },
    },
    {
        accessorKey: 'requester',
        header: () => 'Requester',
        cell: props => (
            <ResLink
                entity="users"
                id={props.row.original.requester.email}
                label={props.row.original.requester.displayName}
                src={props.row.original.requester.avatar ?? ''}
                chip
            />
        ),
    },
    {
        accessorKey: 'category',
        header: () => 'Category',
        cell: props => <RequestCategoryChip category={props.row.original.category} className="px-md" />,
        meta: {
            expand: true,
        },
    },
    {
        accessorKey: 'reference',
        header: () => 'Reference',
        cell: props => {
            return <RequestReference request={props.row.original} className="px-md h-full" />;
        },
        meta: {
            expand: true,
        },
    },
    {
        accessorKey: 'createdAt',
        header: () => 'Created at',
        cell: props => fmtDateFull(props.row.original.createdAt),
    },
];

interface UserOption {
    label: string;
    value: CnslUser;
    avatar: string | null;
}

const getRange = (date?: { gte?: string; lte?: string }) => {
    return {
        to: date?.lte ? new Date(date.lte) : undefined,
        from: date?.gte ? new Date(date.gte) : undefined,
    };
};

const pagination = {
    queryKey: ['requests'],
    limit: LIMIT_DEFAULT,
    threshold: THRESHOLD_DEFAULT,
    pathname: '/api/v1/requests',
};

const EmptyState = () => {
    const { data: integrationStatus, isLoading } = useIntegrationSettingsStatus();
    const isSlackConnected = integrationStatus?.status?.[IntegrationId.Slack] ?? false;

    const slackPath = getSlackOAuthUrl();

    const navigateToSlackPath = () => {
        window.location.href = slackPath;
    };

    return (
        <TableEmptyState
            title="Requests"
            description="Connect Slack to begin submitting requests with /help"
            imageSrc="/emptyStates/empty-requests.svg"
            isLoading={isLoading}
            isConnected={isSlackConnected}
            connectedDescription="Use /help in Slack to submit a request"
        >
            {!isSlackConnected && (
                <Button onClick={navigateToSlackPath}>
                    <img src="/3p/slack-logo.png" alt="Slack logo" className="size-4" />
                    Connect Slack
                </Button>
            )}
        </TableEmptyState>
    );
};

export const Requests = () => {
    const [appCount, setAppCount] = useState<number | null>(null);
    const [rawFilters, setFilters] = useLocalStorage<RequestFilters>('REQUESTS_FILTER_V3', {});
    const filters = rawFilters ?? {};
    const stringifiedFilters = qs.stringify(filters);
    const [onMountUsersFilter, _] = useState<string[]>(filters.requester ?? []);
    const [onMountCategoriesFilter, __] = useState<(string | null)[]>(filters.category ?? []);

    const { data: categoriesData, isLoading: isCategoriesLoading } = useFlatCategories();
    const [users, setUsers] = useState<CnslUser[]>([]);
    const { data: usersData } = useUsers({
        userIds: onMountUsersFilter.length ? onMountUsersFilter : undefined,
        enabled: !!onMountUsersFilter.length,
    });
    const { data: onboardingData } = useWimpyOrgOnboarding();
    const showResolutionGraph = onboardingData?.onboarding?.createdRequest === true;

    const canRenderUsersFilter = onMountUsersFilter.length ? !!usersData?.items : true;
    const canRenderCategoriesFilter = onMountCategoriesFilter.length ? !isCategoriesLoading : true;

    useEffect(() => {
        if (usersData?.items) {
            setUsers(usersData.items);
        }
    }, [usersData]);

    const handleCategoriesChange = (selectedCategories: CategoryOption[]) => {
        setFilters({ ...filters, category: selectedCategories.map(c => c.value?.id || null) });
    };

    const handleUsersChange = (selectedUsers: UserOption[]) => {
        const newUsers = selectedUsers.map(o => o.value);
        setUsers(newUsers);
        setFilters({ ...filters, requester: newUsers.map(u => u.id) });
    };

    const handleDateRangeChange = (range?: DateRange) => {
        const fs = {
            ...filters,
            createdAt: range
                ? {
                      lte: range?.to ? range.to.toISOString() : undefined,
                      gte: range?.from ? range.from.toISOString() : undefined,
                  }
                : undefined,
        };

        setFilters(fs);
    };

    const statusSelect = (
        <Select
            placeholder={
                <div className="flex items-center gap-sm">
                    <Flag className="size-3.5" />
                    Status
                </div>
            }
            isClearable
            isMulti
            options={statusOptions}
            trigger="small"
            components={{
                Option: StatusOption,
                ValueContainer: StatusValueComponent,
                MultiValue: () => null,
            }}
            value={filters.status ? filters.status.map(s => statusOptions.find(o => o.value === s)!) : null}
            onChange={o => {
                const fs = { ...filters };

                if (o === null || o.length === 0) {
                    delete fs.status;
                } else {
                    fs.status = o.map(o => o.value);
                }

                setFilters(fs);
            }}
        />
    );

    const handleClearFilters = !isEmpty(filters)
        ? () => {
              setFilters({});
              setUsers([]);
          }
        : undefined;

    const categoryOptions = useMemo(() => {
        const categories = categoriesData?.categories || [];
        const options = categories.map(c => ({ label: c.name, value: c, color: c.color }));
        return [{ label: 'Uncategorized', value: null, color: '' }, ...options];
    }, [categoriesData?.categories]);

    const categoryOptionsMap = useMemo(() => {
        return new Map(categoryOptions.map(c => [c.value?.id || null, c]));
    }, [categoryOptions]);

    return (
        <>
            <Brow title="Requests" icon={<Flag />} {...(appCount !== null ? { resourceCount: appCount } : {})} />

            {showResolutionGraph && (
                <ResolutionGraph filters={filters} className="p-xl border-b border-bg-grey-primary" />
            )}

            <FilterBar
                filters={[
                    canRenderUsersFilter ? (
                        <UsersFilter key="users" users={users} onChange={handleUsersChange} />
                    ) : (
                        <span key="fragment" />
                    ),
                    <DatePickerWithRange
                        key="date"
                        date={getRange(filters?.createdAt)}
                        onChange={handleDateRangeChange}
                    />,
                    statusSelect,
                    canRenderCategoriesFilter ? (
                        <CategoriesFilter
                            key="categories"
                            categoryOptions={categoryOptions}
                            value={
                                filters.category?.map(c => categoryOptionsMap.get(c)).filter(c => c !== undefined) || []
                            }
                            onChange={handleCategoriesChange}
                        />
                    ) : (
                        <span key="categories-fragment" />
                    ),
                ]}
                onClearFilters={handleClearFilters}
            />
            <Table
                columns={columns}
                pagination={{ ...pagination, filters: stringifiedFilters }}
                onPaginationCountChange={c => setAppCount(c)}
                emptyState={<EmptyState />}
            />
        </>
    );
};
