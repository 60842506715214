import { ResLink } from '@/components/ResLink';
import type { CnslGroup } from 'lib/prisma/types';
import { ResourceOption } from '../ResourceOption';

export const GroupReference = ({
    group,
    isActive,
    onClick,
}: { group: CnslGroup; isActive?: boolean; onClick?: () => void }) => {
    return (
        <ResourceOption
            title={<ResLink size="sm" src={group.logo} label={group.name ?? ''} />}
            isActive={isActive}
            onClick={onClick}
        />
    );
};
