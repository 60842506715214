import { valibotResolver } from '@hookform/resolvers/valibot';
import { ArticleUpdateSchema, type InternalArticle } from 'lib/models/article';
import { ArticleState } from 'lib/prisma/enums';
import { Brain, Dot, Ellipsis, FileDown, FilePen, FileText, FileUp, Trash2 } from 'lucide-react';
import { type ReactNode, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Brow, type Crumb } from '@/components/Brow';
import { Button } from '@/components/ui/button';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
    useArticleDelete,
    useArticlePublish,
    useArticleUnpublish,
    useArticleUpdate,
} from '@/hooks/mutations/useArticle';
import { useArticle } from '@/hooks/queries/useArticle';

import { cn } from '@/lib/styling';
import { useQueryClient } from '@tanstack/react-query';

import { ModifiedTime } from '@/components/ModifiedTime';
import { useParams } from 'react-router-dom';
import { ArticleDetailsForm } from './ArticleDetailsForm';
import { ArticlesSidebar } from './ArticlesSidebar';

const FormActionMenu = ({ state, slug }: { slug?: InternalArticle['slug']; state?: InternalArticle['state'] }) => {
    const onUnPublish = useArticleUnpublish(slug);
    const onDelete = useArticleDelete(slug);
    return (
        <DropdownMenu>
            <DropdownMenuTrigger className="flex items-center" asChild>
                <Button mode="borderless" size="sm">
                    <Ellipsis />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent side="bottom" align="end">
                {state === ArticleState.PUBLISHED && (
                    <DropdownMenuItem
                        onSelect={() => {
                            onUnPublish.mutate();
                        }}
                        className="gap-sm"
                    >
                        <FileDown /> Unpublish Article
                    </DropdownMenuItem>
                )}
                <DropdownMenuItem
                    onSelect={() => {
                        onDelete.mutate();
                    }}
                    className="gap-sm text-body-red-primary"
                    data-testid="delete-article"
                >
                    <Trash2 />
                    Delete Article
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );
};

const FormSaveButton = ({
    onPublish,
    isDisabled,
    label,
}: { onPublish: () => void; isDisabled: boolean; label?: ReactNode }) => (
    <Button
        variant="blue"
        mode="dark"
        size="sm"
        type="submit"
        onClick={() => onPublish()}
        disabled={isDisabled}
        data-testid="publish-changes"
    >
        {label ? (
            label
        ) : (
            <>
                <FileUp className="size-3.5" />
                Publish Changes
            </>
        )}
    </Button>
);

const ArticleDetailComponent = ({ slug }: { slug: string }) => {
    // queries
    const { data, isLoading } = useArticle(slug);
    const article = data?.article;
    const recentVersion = article?.versions?.[0];

    const queryClient = useQueryClient();

    // mutations
    const articleUpdate = useArticleUpdate(article?.slug);
    const articlePublish = useArticlePublish(article?.slug);

    const form = useForm<InternalArticle>({
        resolver: valibotResolver(ArticleUpdateSchema),
        defaultValues: {
            ...article,
            data: recentVersion?.data,
            name: recentVersion?.name,
            description: recentVersion?.description,
        },
    });

    /**
     * Reset form when the user navigates to a different article
     */
    useEffect(() => {
        const recentVersion = data?.article?.versions?.[0];
        if (recentVersion) {
            const article = data?.article;

            form.reset({
                ...article,
                name: recentVersion.name,
                description: recentVersion?.description,
                data: recentVersion.data,
            });
        }
    }, [data, form.reset]);

    const saveVersion = (refetchDirectories?: boolean) => {
        const values = form.getValues();
        articleUpdate.mutate(values, {
            onSuccess: () => {
                if (refetchDirectories) {
                    queryClient.refetchQueries({ queryKey: [`/api/v1/directory`] });
                }
            },
        });
    };

    const onPublish = form.handleSubmit((article: InternalArticle) => {
        if (article.name === '') {
            form.setError('name', { type: 'required', message: 'Title required for publishing' });
            return;
        }
        articlePublish.mutate(article);
    });

    const browBreadcrumbs: Crumb[] = [
        {
            label: (
                <div className="gap-sm flex items-center">
                    <Brain className="shrink-0" />
                    <span className="line-clamp-1">Knowledge Base</span>
                </div>
            ),
            href: '/articles',
        },
        {
            label: (
                <div className={cn('gap-sm flex items-center', !article?.name && 'text-body-subtle')}>
                    {article?.name ? <FileText className="shrink-0" /> : <FilePen className="shrink-0" />}
                    <span className="line-clamp-1">{article?.name || 'No title'}</span>
                </div>
            ),
            bold: true,
            seperator: <Dot />,
        },
    ];

    if (article?.state === ArticleState.DRAFT) {
        browBreadcrumbs.push({
            label: <div className="text-body-subtle">Draft</div>,
        });
    } else {
        browBreadcrumbs.push({
            label: <div className="text-body-blue-primary">Published</div>,
        });
    }

    const disableSave =
        isLoading ||
        (article?.state === ArticleState.PUBLISHED &&
            recentVersion?.state === ArticleState.PUBLISHED &&
            !form.formState.isDirty);

    return (
        <>
            <Brow
                data={browBreadcrumbs}
                actions={[
                    <FormSaveButton key="save-btn" onPublish={onPublish} isDisabled={disableSave} />,
                    <FormActionMenu key="action-menu-btn" slug={article?.slug} state={article?.state} />,
                ]}
            />
            <div className="flex grow overflow-hidden relative">
                <ArticlesSidebar />
                <div className="flex justify-center size-full overflow-y-scroll px-2xl">
                    <div className="py-12 max-w-[640px] h-fit flex flex-col grow gap-xl">
                        <ModifiedTime
                            versionUpdatedBy={recentVersion?.updatedBy ?? undefined}
                            versionCreatedAt={recentVersion?.createdAt}
                            versionNumber={recentVersion?.version}
                        />
                        <ArticleDetailsForm form={form} saveVersion={saveVersion} />
                    </div>
                </div>
            </div>
        </>
    );
};

export const ArticleDetails = () => {
    const { slug } = useParams();
    return <ArticleDetailComponent slug={slug as string} />;
};
