import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { Check } from 'lucide-react';
import type { GroupBase, OptionProps, SingleValueProps, ValueContainerProps } from 'react-select';
import { components as SelectPrimitiveComponents } from 'react-select';

import { ResLink } from '@/components/ResLink';
import { Stacked } from '@/components/Stacked';

export const AppResLinkValuesContainer = <
    Option extends { label: string; value: string; logo: string } | null,
    IsMulti extends boolean = true,
    Group extends GroupBase<Option> = GroupBase<Option>,
>({
    children,
    ...props
}: ValueContainerProps<Option, IsMulti, Group>) => {
    const values = props.getValue();
    const isSelected = values.length > 0;
    const isOne = values.length === 1;
    return (
        <SelectPrimitiveComponents.ValueContainer {...props}>
            {isSelected && (
                <div className="gap-md flex items-center">
                    {isOne ? (
                        <ResLink
                            label={values[0]!.label}
                            src={values[0]!.logo}
                            id={values[0]!.value}
                            size="sm"
                            entity="apps"
                            disableLink
                        />
                    ) : (
                        <>
                            <Stacked
                                items={values as Option[]}
                                renderItem={(item, index) => {
                                    if (!item) return null;
                                    return (
                                        <ResLink
                                            avatarOnly
                                            label={item.label}
                                            key={index}
                                            src={item.logo}
                                            id={item.value}
                                            size="sm"
                                            entity="apps"
                                            disableLink
                                        />
                                    );
                                }}
                                getTooltipContent={item => item?.label ?? ''}
                            />
                            <Tooltip>
                                <TooltipTrigger>
                                    <div>{`${values.length} selected`}</div>
                                </TooltipTrigger>
                                <TooltipContent>
                                    {props
                                        .getValue()
                                        .map((item: Option) => item?.label)
                                        .join(', ')}
                                </TooltipContent>
                            </Tooltip>
                        </>
                    )}
                </div>
            )}
            {children}
        </SelectPrimitiveComponents.ValueContainer>
    );
};

export const AppResLinkSingleValue = (props: SingleValueProps<{ label: string; value: string; logo: string }>) => {
    const { children, ...restProps } = props;
    const data = restProps.data;

    return (
        <SelectPrimitiveComponents.SingleValue {...restProps}>
            <ResLink label={data.label} src={data.logo} size="sm" entity="apps" id={data.value} disableLink />
        </SelectPrimitiveComponents.SingleValue>
    );
};

export const AppResLinkOption = <
    Option extends { label: string; value: string; logo: string },
    IsMulti extends boolean = true,
    Group extends GroupBase<Option> = GroupBase<Option>,
>(
    props: OptionProps<Option, IsMulti, Group>,
) => {
    const { children, ...restProps } = props;
    const data = restProps.data;
    return (
        <SelectPrimitiveComponents.Option {...restProps}>
            <div className="items center flex justify-between">
                <ResLink label={data.label} src={data.logo} id={data.value} size="sm" entity="apps" disableLink />
                {restProps.isSelected && <Check />}
            </div>
        </SelectPrimitiveComponents.Option>
    );
};
