import type { Editor, JSONContent } from '@tiptap/core';
import { omit } from 'lodash-es';
import { create } from 'zustand';
import type { TreeItem } from '../dnd-tree/tree';

type HistoryEntry =
    | {
          type: 'node-update';
          editorId: string;
          state: JSONContent;
          cursorPosition: number;
      }
    | {
          type: 'tree-update';
          state: TreeItem[];
          editorId: string;
          cursorPosition: number;
      };

interface Store {
    editors: Record<string, Editor | undefined>;
    register: (id: string, editor: Editor) => void;
    unregister: (id: string) => void;

    // Manages the history across multiple tiptap editors
    history: HistoryEntry[];
    activeHistoryIndex: number;
    pushHistory: (args: HistoryEntry) => void;
    undo: () => HistoryEntry | undefined;
    redo: () => HistoryEntry | undefined;

    dispose: () => void;
}

// Manages Tiptap editors, so that other parts of the app can access them
export const useTiptapEditors = create<Store>((set, get) => ({
    editors: {},
    register: (id: string, editor: Editor) =>
        set(store => {
            return {
                ...store,
                editors: {
                    ...store.editors,
                    [id]: editor,
                },
            };
        }),
    unregister: (id: string) =>
        set(store => {
            const editors = omit(store.editors, id);
            const history = store.history.filter(h => h.editorId !== id);

            return {
                ...store,
                editors,
                history,
            };
        }),

    activeHistoryIndex: 0,
    history: [],

    pushHistory: (args: HistoryEntry) =>
        set(store => {
            const history = [...store.history.slice(0, store.activeHistoryIndex + 1), args];

            return {
                ...store,
                history,
                activeHistoryIndex: history.length - 1,
            };
        }),
    undo: () => {
        const index = get().activeHistoryIndex - 1;
        if (index < 0) {
            return;
        }

        set(store => {
            return {
                ...store,
                activeHistoryIndex: index,
            };
        });

        return get().history[index];
    },
    redo: () => {
        const index = get().activeHistoryIndex + 1;
        if (index >= get().history.length) {
            return;
        }

        set(store => {
            return {
                ...store,
                activeHistoryIndex: index,
            };
        });

        return get().history[index];
    },
    dispose: () => set({ editors: {}, history: [], activeHistoryIndex: 0 }),
}));
