import { useMutation } from '@lib/hooks/useMutation';
import { type DefaultError, useQueryClient } from '@tanstack/react-query';

export const useAppUserDelete = ({ slug, userId }: { slug: string; userId: string }) => {
    const queryClient = useQueryClient();
    return useMutation<void, DefaultError, void>({
        url: `/api/v1/apps/${slug}/users/${userId}`,
        method: 'DELETE',
        onSuccess: async () => {
            queryClient.refetchQueries({ queryKey: [`/api/v1/apps/${slug}`] });
        },
        successToast: {
            message: 'User removed from app',
        },
    });
};
