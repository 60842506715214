import { AppStatus } from '@/components/AppStatus';
import { Stacked } from '@/components/Stacked';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { getCssColor } from 'lib/requests/utils';
import { components as SelectPrimitiveComponents } from 'react-select';
import type { GroupBase, OptionProps, ValueContainerProps } from 'react-select';
type StatusOption = { label: string; value: string; color: string };

export const StatusOption = <
    Option extends StatusOption,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
>(
    props: OptionProps<Option, IsMulti, Group>,
) => {
    const { children, ...restProps } = props;
    const data = restProps.data;

    return (
        <SelectPrimitiveComponents.Option {...restProps}>
            <div className="flex items-center">
                <AppStatus color={getCssColor(data.color, 'bg')} label={data.label} />
            </div>
        </SelectPrimitiveComponents.Option>
    );
};

export const StatusValue = <
    Option extends StatusOption,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
>(
    props: ValueContainerProps<Option, IsMulti, Group>,
) => {
    const { children, ...restProps } = props;
    const isSelected = props.getValue().length > 0;
    const isOne = props.getValue().length === 1;

    return (
        <SelectPrimitiveComponents.ValueContainer {...restProps}>
            <div className="flex items-center">
                {isSelected &&
                    (isOne ? (
                        <AppStatus
                            color={getCssColor(props.getValue()[0]!.color, 'bg')}
                            label={props.getValue()[0]!.label}
                        />
                    ) : (
                        <>
                            <Stacked
                                items={props.getValue() as Option[]}
                                renderItem={(item, index) => {
                                    if (!item) return null;
                                    return <AppStatus key={index} color={getCssColor(item.color)} />;
                                }}
                                getTooltipContent={item => item.label}
                            />
                            <Tooltip>
                                <TooltipTrigger>
                                    <div>{`${props.getValue().length} selected`}</div>
                                </TooltipTrigger>
                                <TooltipContent>
                                    {props
                                        .getValue()
                                        .map(item => item.label)
                                        .join(', ')}
                                </TooltipContent>
                            </Tooltip>
                        </>
                    ))}
                {children}
            </div>
        </SelectPrimitiveComponents.ValueContainer>
    );
};
