import { Brow } from '@/components/Brow';
import { IconButton } from '@/components/IconButton';
import { ResLink } from '@/components/ResLink';
import { TableEmptyState } from '@/components/TableEmptyState';
import { Overview } from '@/components/apps/Overview';
import { getDefaultColumns } from '@/components/apps/Policies';
import { CreatePolicyBtn } from '@/components/policies/CreatePolicyBtn';
import { PolicyDraftsBtn } from '@/components/policies/PolicyDraftsBtn';
import { ShowMore } from '@/components/policies/ShowMore';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useApp, useGetAppsWithPolicyCounts } from '@/hooks/queries/useApps';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { CnslPolicyIcon } from '@/lib/iconConstants';
import { LIMIT_DEFAULT, THRESHOLD_DEFAULT } from '@/lib/paginationConstants';
import { cn } from '@/lib/styling';
import type { Row } from '@tanstack/react-table';
import { Table } from '@ui/table/table';
import { FlagKey } from 'lib/flags';
import type { AppsWithPolicyCount } from 'lib/models/app';
import type { PolicyListItem } from 'lib/models/app_policy';
import { CloudUpload, PanelLeftClose, PanelRightClose, PanelsTopLeft, TableIcon } from 'lucide-react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import { CsvDnd } from './AppMatrix/CsvDnd';
import { MatrixModal } from './AppMatrix/MatrixModal';
import { AppList } from './components/AppList';
import { PolicyAppTabs } from './components/PolicyAppTabs';

const pagination = {
    queryKey: ['policies'],
    limit: LIMIT_DEFAULT,
    threshold: THRESHOLD_DEFAULT,
    pathname: '/api/v1/policies',
};

const EmptyState = () => {
    const [showCsvModal, setShowCsvModal] = useState(false);
    const [showMatrixModal, setShowMatrixModal] = useState(false);
    const isAppMatrixEnabled = useFeatureFlag(FlagKey.AppMatrix);

    return (
        <TableEmptyState
            title="Access Policies"
            description="Create your first access policy to get started"
            imageSrc="/emptyStates/empty-policies.svg"
        >
            <CreatePolicyBtn />
            {isAppMatrixEnabled && (
                <>
                    <CsvDnd
                        showModal={showCsvModal}
                        setShowModal={setShowCsvModal}
                        onFinish={() => setShowMatrixModal(true)}
                    />
                    <Button size="sm" onClick={() => setShowCsvModal(true)}>
                        <CloudUpload /> Upload App Matrix
                    </Button>
                    <MatrixModal isOpen={showMatrixModal} setIsOpen={setShowMatrixModal} />
                </>
            )}
        </TableEmptyState>
    );
};

const makeInjectedRow = (colCount: number) => {
    const injectBeforeRow = (row: Row<PolicyListItem>, prior?: Row<PolicyListItem>) => {
        const showAppRow = !prior || prior.original.appId !== row.original.appId || prior.index === row.index;
        return (
            showAppRow && (
                <tr key={row.original.appId + row.original.id} className="bg-bg-overlay">
                    <td colSpan={colCount}>
                        <div className="p-md flex items-center gap-lg">
                            <ResLink
                                className="text-xs font-medium"
                                entity="apps"
                                size="sm"
                                id={row.original.app.slug}
                                to={`/apps/${row.original.app.slug}/policies`}
                                label={row.original.app.name ?? ''}
                                rounded="square"
                                bold
                            />
                        </div>
                    </td>
                </tr>
            )
        );
    };

    return injectBeforeRow;
};

const ListViewToggle = ({
    viewStyle,
    onUpdate,
}: {
    viewStyle: 'appBar' | 'matrix';
    onUpdate: (viewStyle: 'appBar' | 'matrix') => void;
}) => {
    return (
        <div className="flex gap-sm bg-bg-grey-primary rounded-md border-grey border-[0.5px] px-[2px] justify-center items-center h-6">
            <button
                onClick={() => onUpdate('appBar')}
                className={cn(
                    viewStyle === 'appBar' ? 'bg-white' : 'bg-bg-grey-primary',
                    'hover:bg-bg-grey-primary-hover size-5 flex items-center justify-center rounded-[5px]',
                )}
            >
                <PanelsTopLeft className="size-[14px]" />
            </button>
            <button
                onClick={() => onUpdate('matrix')}
                className={cn(
                    viewStyle === 'matrix' ? 'bg-white' : 'bg-bg-grey-primary',
                    'hover:bg-bg-grey-primary-hover size-5 flex items-center justify-center rounded-[5px]',
                )}
            >
                <TableIcon className="size-[14px]" />
            </button>
        </div>
    );
};

const AppBarView = () => {
    const [showCsvModal, setShowCsvModal] = useState(false);
    const [showMatrixModal, setShowMatrixModal] = useState(false);
    const { slug = '' } = useParams();
    const { data: appData, isLoading: appLoading } = useApp({ slug });
    const { data } = useGetAppsWithPolicyCounts();
    const [isCollapsed, setIsCollapsed] = useLocalStorage('POLICIES_APP_BAR_COLLAPSED', false);
    const appsWithPolicies: AppsWithPolicyCount[] = [];
    const appsWithoutPolicies: AppsWithPolicyCount[] = [];

    const collapseIcon = isCollapsed ? (
        <PanelRightClose className="shrink-0 size-3.5" />
    ) : (
        <PanelLeftClose className="shrink-0 size-3.5" />
    );
    if (data?.apps) {
        data.apps.forEach(app => {
            if (app._count.policies > 0) {
                appsWithPolicies.push(app);
            } else {
                appsWithoutPolicies.push(app);
            }
        });
    }

    const collapsedWidth = isCollapsed ? 'w-[48px]' : 'w-[300px]';
    return (
        <div className="flex relative h-full">
            <div
                className={cn(
                    'bg-bg-surface border-r-grey border-r-[0.5px] flex flex-col transition-[width] duration-100',
                    collapsedWidth,
                )}
            >
                <h3
                    className={cn(
                        'text-sm font-medium border-b-grey border-b-[0.5px] leading-none flex items-center gap-sm',
                        isCollapsed ? 'py-lg justify-center' : 'p-lg',
                    )}
                >
                    {isCollapsed ? (
                        <IconButton onClick={() => setShowCsvModal(true)}>
                            <CloudUpload />
                        </IconButton>
                    ) : (
                        'Apps'
                    )}
                    {!isCollapsed && (
                        <IconButton onClick={() => setShowCsvModal(true)}>
                            <CloudUpload />
                        </IconButton>
                    )}
                </h3>
                <ScrollArea className={cn('flex-grow h-full', collapsedWidth)}>
                    <AppList apps={appsWithPolicies} hasPolicies collapsed={isCollapsed ?? false} />
                    <AppList apps={appsWithoutPolicies} hasPolicies={false} collapsed={isCollapsed ?? false} />
                </ScrollArea>
                <div
                    className={cn(
                        'sticky bottom-0 flex items-center bg-bg-surface p-1 border-t-[0.5px] border-grey',
                        isCollapsed ? 'justify-center' : 'justify-end',
                    )}
                >
                    <Button
                        mode="borderless"
                        size="sm"
                        className="text-body-subtle hover:text-body-subtle-hover hover:bg-bg-grey-primary-hover size-6 rounded-full"
                        onClick={() => setIsCollapsed(!isCollapsed)}
                    >
                        {collapseIcon}
                    </Button>
                </div>
            </div>
            {appData?.app ? (
                <div className="size-full flex flex-col">
                    <div className="p-xl">
                        <Overview app={appData.app} mode="policies" />
                    </div>
                    <PolicyAppTabs slug={slug} app={appData.app} />
                </div>
            ) : (
                <div className="size-full flex flex-col items-center justify-center">
                    <TableEmptyState
                        title={appLoading ? 'Loading app policies...' : 'No app selected'}
                        description="Select an app to view policies and access requests"
                        imageSrc="/emptyStates/empty-apps.svg"
                        isLoading={appLoading}
                    />
                </div>
            )}
            <CsvDnd showModal={showCsvModal} setShowModal={setShowCsvModal} onFinish={() => setShowMatrixModal(true)} />
            <MatrixModal isOpen={showMatrixModal} setIsOpen={setShowMatrixModal} />
        </div>
    );
};

export const Policies = () => {
    const [policyCount, setPolicyCount] = useState<number | null>(null);
    const [viewStyle, setViewStyle] = useLocalStorage<'appBar' | 'matrix'>('POLICIES_VIEW_STYLE', 'appBar');
    const columns = getDefaultColumns('page');

    return (
        <>
            <Brow
                title="Access Policies"
                icon={<CnslPolicyIcon />}
                {...(policyCount !== null ? { resourceCount: policyCount } : {})}
                actions={[
                    <PolicyDraftsBtn key="drafts" />,
                    <CreatePolicyBtn key="add-policy" />,
                    <ListViewToggle key="view-toggle" viewStyle={viewStyle ?? 'matrix'} onUpdate={setViewStyle} />,
                    <ShowMore key="show-more" />,
                ]}
            />
            {viewStyle === 'matrix' && (
                <Table
                    columns={columns}
                    pagination={{ ...pagination }}
                    onPaginationCountChange={c => setPolicyCount(c)}
                    emptyState={<EmptyState />}
                    beforeRow={makeInjectedRow(columns.length)}
                />
            )}
            {viewStyle === 'appBar' && <AppBarView />}
        </>
    );
};
