import { CircleIndicator } from './CircleIndicator';

export const AppStatus = ({ color, label }: { color: string; label?: string }) => {
    return (
        <div className="gap-sm flex items-center">
            <CircleIndicator color={color} />
            <span className="truncate">{label}</span>
        </div>
    );
};
