import { Button } from '@ui/button';
import type { ReactNode } from 'react';

export const FilterBar = ({ filters, onClearFilters }: { filters: ReactNode[]; onClearFilters?: () => void }) => {
    return (
        <div className="flex justify-between items-center border-b-[0.5px] p-md border-grey bg-bg z-30">
            <div className="gap-md px-md flex items-center ">
                {filters.map((filter, index) => (
                    <div className="h-6" key={index}>
                        {filter}
                    </div>
                ))}
            </div>
            {onClearFilters && (
                <Button size="sm" mode="borderless" onClick={onClearFilters}>
                    Clear all
                </Button>
            )}
        </div>
    );
};
