import { useMutation } from '@lib/hooks/useMutation';
import { type DefaultError, useQueryClient } from '@tanstack/react-query';
import { IntegrationId } from 'lib/integration';
import type {
    RequestProviderConfigRes,
    RequestProviderFieldRes,
    SchemaRequestNamespaceConfig,
    SchemaRequestProviderFieldReq,
} from 'lib/models/settings/requests';
import qs from 'qs';
import type * as v from 'valibot';

import { useQuery } from '@/lib/hooks/useQuery';
import type { APIResponse } from 'lib/models/error';
import type { ProvisioningTicketFieldMapping } from 'lib/prisma/types';
import { ONBOARDING_SUBSCRIBE_MUTATION_KEY } from '../useSubscribeOnboardingFlow';

export const useSettingRequest = (provider: IntegrationId, namespace?: string[], ticketType?: string) => {
    /* Need to always include default namespace for fs */
    if (provider === IntegrationId.Freshservice && namespace && !namespace.includes('1')) {
        namespace!.push('1');
    }
    return useQuery<APIResponse<RequestProviderFieldRes>>({
        url: `/api/v1/settings/requests/${provider}${namespace ? `?${qs.stringify({ namespace, ticketType })}` : ''}`,
    });
};

export const useSettingRequestUpdate = (
    provider: IntegrationId,
    { onSuccess = () => {} }: { onSuccess?: () => void },
) => {
    return useMutation<RequestProviderFieldRes, DefaultError, v.InferInput<typeof SchemaRequestProviderFieldReq>>({
        url: `/api/v1/settings/requests/${provider}`,
        method: 'PUT',
        mutationKey: [ONBOARDING_SUBSCRIBE_MUTATION_KEY],
        onSuccess: () => {
            // TODO refetch or use response to update the cache?
            onSuccess();
        },
    });
};

export const useSettingRequestConfig = () => {
    return useQuery<RequestProviderConfigRes | { config?: undefined }>({
        url: '/api/v1/settings/requests/config',
    });
};

export const useSettingRequestConfigUpdate = () => {
    const queryClient = useQueryClient();

    return useMutation<RequestProviderConfigRes, DefaultError, v.InferInput<typeof SchemaRequestNamespaceConfig>>({
        url: '/api/v1/settings/requests/config',
        method: 'PUT',
        mutationKey: [ONBOARDING_SUBSCRIBE_MUTATION_KEY],
        onSuccess: async ({ config: { provider } }) => {
            await Promise.allSettled([
                queryClient.refetchQueries({ queryKey: [`/api/v1/settings/requests/${provider}`] }),
                queryClient.refetchQueries({ queryKey: ['/api/v1/settings/requests/config'] }),
            ]);

            // TODO refetch or use response to update the cache?
        },
    });
};

export const useSettingProvisioningTicketFieldMapping = () => {
    return useQuery<{ fields: ProvisioningTicketFieldMapping[] }>({
        url: '/api/v1/settings/provisioning-tickets/field-mappings',
    });
};
