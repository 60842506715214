import { Brow } from '@/components/Brow';
import { DatePickerWithRange } from '@/components/RangeDatePicker';
import { TrendingIndicator } from '@/components/TrendingIndicator';
import {
    GenResArea,
    type ResStatus,
    ResolutionTooltip,
    makeLabel,
    statusConfig,
} from '@/components/charts/RequestChart';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useIntegrationSettingsStatus } from '@/hooks/queries/integrations/useIntegrationSettingsStatus';
import {
    useDepartmentStats,
    usePlaybookUtilization,
    useRequestByCategory,
    useResolutionStats,
} from '@/hooks/queries/useInsights';
import { CnslInsightsIcon, CnslRequestIcon } from '@/lib/iconConstants';
import { cn } from '@/lib/styling';
import { PLAYBOOKS } from '@/routes/paths';
import { differenceInCalendarMonths } from 'date-fns';
import { addDays, differenceInCalendarDays, endOfDay, startOfDay } from 'date-fns';
import { MergeIntegrationId } from 'lib/integration';
import { ChartInterval } from 'lib/models/insights';
import { RequestStatus } from 'lib/prisma/enums';
import { BookText, Minus, Tags, TrendingDown, TrendingUp } from 'lucide-react';
import { useMemo, useState } from 'react';
import type * as React from 'react';
import type { DateRange } from 'react-day-picker';
import { Link } from 'react-router-dom';
import {
    AreaChart,
    CartesianGrid,
    Cell,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    type TooltipProps,
    XAxis,
    YAxis,
} from 'recharts';

const getPriorPeriod = (since?: string, until?: string) => {
    if (!since || !until) {
        const now = new Date();
        const start = startOfDay(addDays(now, -60));
        const end = endOfDay(addDays(now, -30));
        return {
            since: start.toISOString(),
            until: end.toISOString(),
        };
    }
    return {
        since: startOfDay(
            addDays(new Date(since), differenceInCalendarDays(new Date(until), new Date(since))),
        ).toISOString(),
        until: since,
    };
};

interface ChartContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    title?: string;
    icon?: React.ReactNode;
    chart?: React.ReactNode;
    headerContent?: React.ReactNode;
}

const COLORS = ['#0091FF', '#3E63DD', '#6E56CF', '#8E4EC6', '#D6409F', '#2EBDE5', '#05A2C2'];
const TOOLTIP_SPEED = 120;

const ChartContainer = ({ title, className, chart, icon, headerContent, ...props }: ChartContainerProps) => {
    return (
        <div className={cn('rounded-lg bg-bg-base border border-grey h-[331px] flex flex-col', className)} {...props}>
            {title && (
                <div className="p-lg border-b border-grey flex items-center gap-sm">
                    {icon}
                    <h3 className="text-sm font-medium">{title}</h3>
                    {headerContent}
                </div>
            )}
            <div className="flex-1">{chart}</div>
        </div>
    );
};

const BarTrend = ({ value, priorValue }: { value: number; priorValue?: number }) => {
    if (priorValue === undefined || priorValue === value) {
        return <Minus className="text-bg-grey-primary" />;
    }
    const isUp = value > priorValue;
    return isUp ? (
        <TrendingUp className="text-bg-green-secondary" />
    ) : (
        <TrendingDown className="text-bg-red-secondary" />
    );
};

interface InsightProps extends React.HTMLAttributes<HTMLDivElement> {
    since?: string;
    until?: string;
}
const DepartmentStats = ({ className, since, until }: InsightProps) => {
    const { data } = useDepartmentStats(since, until);
    const rows = data?.rows ?? [];
    const total = data?.total ?? 0;
    const slices = [...rows].sort((a, b) => a.count - b.count);
    const priorPeriod = getPriorPeriod(since, until);
    const { data: priorData } = useDepartmentStats(priorPeriod.since, priorPeriod.until);
    const priorCounts = new Map<string, number>();
    priorData?.rows?.forEach(row => {
        priorCounts.set(row.name, row.count);
    });

    const percentFormatter = new Intl.NumberFormat(window.navigator.language, {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    const CustomTooltip = ({ active, payload }: TooltipProps<number, string>) => {
        if (active && payload && payload.length) {
            const { name, count, fill } = payload[0].payload;
            const fillColor = fill as string;
            return (
                <div className="bg-white border border-grey rounded-md p-md flex items-center justify-between gap-xl">
                    <div className="flex gap-sm items-center">
                        <div className="size-2 rounded-[2px]" style={{ backgroundColor: fillColor }} />
                        <p className="text-body-grey-primary">{name}</p>
                    </div>
                    <p className="text-body-grey-primary">{percentFormatter.format(count / total)}</p>
                </div>
            );
        }
        return null;
    };

    const integerFormatter = new Intl.NumberFormat(window.navigator.language, {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    const formattedTotal = integerFormatter.format(total);
    const totalTextSize = formattedTotal.length > 6 ? 'text-base' : 'text-[32px]';

    return (
        <ChartContainer
            className={cn(className)}
            chart={
                <div className="h-full">
                    <ResponsiveContainer width="100%" height={145}>
                        <PieChart>
                            <Pie
                                dataKey="count"
                                label={false}
                                startAngle={180}
                                endAngle={0}
                                data={slices}
                                cx="50%"
                                cy="100%"
                                outerRadius={120}
                                innerRadius={93}
                                isAnimationActive={false}
                            >
                                {slices.map((_, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip content={<CustomTooltip />} animationDuration={TOOLTIP_SPEED} />
                            <text
                                x="50%"
                                y="70%"
                                textAnchor="middle"
                                dominantBaseline="middle"
                                className={cn('font-bold', totalTextSize)}
                            >
                                {formattedTotal}
                            </text>
                            <text
                                x="50%"
                                y="88%"
                                textAnchor="middle"
                                dominantBaseline="middle"
                                className="font-semibold text-md"
                            >
                                Requests by department
                            </text>
                        </PieChart>
                    </ResponsiveContainer>
                    <ScrollArea className="p-lg h-[175px]">
                        {/* we have to grab the reverse item */}
                        {slices.map((_, index) => (
                            <div
                                key={index}
                                className="flex items-center justify-between border-b border-bg-grey-primary border-dashed p-sm"
                            >
                                <div className="flex items-center gap-x-sm">
                                    <div
                                        className="w-2 h-2 rounded-[2px]"
                                        style={{ backgroundColor: COLORS[(slices.length - 1 - index) % COLORS.length] }}
                                    />
                                    <span className="text-body-subtle-hover">
                                        {slices[slices.length - 1 - index].name}
                                    </span>
                                </div>
                                <div className="flex items-center gap-sm">
                                    <span className="text-body-grey-primary font-medium">
                                        {slices[slices.length - 1 - index].count}
                                    </span>
                                    <BarTrend
                                        value={slices[slices.length - 1 - index].count}
                                        priorValue={priorCounts.get(slices[slices.length - 1 - index].name)}
                                    />
                                </div>
                            </div>
                        ))}
                    </ScrollArea>
                </div>
            }
        />
    );
};

interface ResolutionGraphProps extends React.HTMLAttributes<HTMLDivElement> {
    interval: ChartInterval;
    since?: string;
    until?: string;
}
const ResolutionGraph = ({ className, interval, since, until, ...props }: ResolutionGraphProps) => {
    const priorPeriod = getPriorPeriod(since, until);

    const { data } = useResolutionStats(interval, since, until);
    const rows = data?.rows ?? [];

    const { data: priorData } = useResolutionStats(interval, priorPeriod.since, priorPeriod.until);
    const priorRows = priorData?.rows ?? [];

    const tickFormatter = (idx: number) => {
        return makeLabel(idx, rows, interval);
    };

    const statuses: ResStatus[] = ['in_progress', 'escalated', 'auto_resolved'];

    const total = useMemo(
        () => rows.reduce((acc, row) => acc + row.auto_resolved + row.escalated + row.in_progress, 0),
        [rows],
    );

    const delta = useMemo(() => {
        const priorTotal = priorRows.reduce((acc, row) => acc + row.auto_resolved + row.escalated + row.in_progress, 0);
        const percentChange = (total - priorTotal) / priorTotal;
        const direction = percentChange > 0 ? 'up' : 'down';
        const color = direction === 'up' ? 'green' : 'red';
        return {
            direction,
            color,
            percentChange,
            priorPeriod,
        };
    }, [total, priorRows, priorPeriod]);

    const intFormatter = new Intl.NumberFormat(window.navigator.language, {
        style: 'decimal',
        maximumFractionDigits: 0,
    });

    const formattedTotal = intFormatter.format(total);

    const rightPadding = interval === ChartInterval.WEEK ? 50 : 20;

    return (
        <ChartContainer
            title="Requests"
            icon={<CnslRequestIcon />}
            headerContent={
                <>
                    <div className="rounded-full size-1 bg-body-subtle mx-sm" />
                    <span className="font-medium">{formattedTotal}</span>
                    <TrendingIndicator
                        direction={delta.direction as 'up' | 'down'}
                        value={delta.percentChange}
                        placeholder=""
                        color={delta.color as 'red' | 'green'}
                    />
                </>
            }
            chart={
                <div className={className} {...props}>
                    <ResponsiveContainer height={282}>
                        <AreaChart data={rows} margin={{ top: 20, right: rightPadding, left: 0, bottom: 20 }}>
                            <CartesianGrid strokeDasharray="2 2" vertical={false} />
                            <defs>
                                {statuses.map(status => (
                                    <linearGradient key={status} id={`color_${status}`} x1="0" y1="0" x2="0" y2="1">
                                        <stop stopColor={statusConfig[status].hex} />
                                        <stop offset="1" stopColor={statusConfig[status].hex} stopOpacity="0.3" />
                                    </linearGradient>
                                ))}
                            </defs>
                            <XAxis
                                tickFormatter={tickFormatter}
                                interval={'preserveStartEnd'}
                                tickMargin={10}
                                axisLine={{ stroke: 'hsla(215, 19%, 89%, 1)' }}
                                tickLine={false}
                                padding={{ left: 0, right: 0 }}
                            />
                            <YAxis tickLine={false} tickMargin={5} axisLine={false} />
                            <Tooltip content={<ResolutionTooltip rows={rows} />} animationDuration={120} />

                            {statuses.map(status => GenResArea({ status }))}
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            }
            className="lg:col-span-2"
        />
    );
};

interface StatBarProps {
    title: string;
    value: number;
    priorValue?: number;
    linkTo?: string;
    max: number;
    color: string;
}

const StatBar = ({ title, value, max, color, priorValue, linkTo }: StatBarProps) => {
    const percent = max === 0 ? 0 : (value / max) * 100;
    return (
        <div className="grid grid-cols-12 items-center justify-between gap-sm py-md border-b border-bg-grey-primary border-dashed">
            <div className="text-body-subtle-hover col-span-4 line-clamp-1">
                {linkTo ? <Link to={linkTo}>{title}</Link> : title}
            </div>
            <div className="col-span-6 flex items-center gap-sm">
                <div className="h-3 rounded-full w-full bg-bg-grey-primary">
                    <div
                        className={cn('h-3', value === max ? 'rounded-full' : 'rounded-l-full')}
                        style={{ backgroundColor: color, width: `${percent}%` }}
                    />
                </div>
            </div>
            <div className="flex items-center gap-sm col-span-2 justify-end">
                <span className="text-body-grey-primary">{value}</span>
                <BarTrend value={value} priorValue={priorValue} />
            </div>
        </div>
    );
};

const PlaybookUtilization = ({ className, since, until }: InsightProps) => {
    const { data } = usePlaybookUtilization(since, until);
    const rows = data?.rows ?? [];
    const biggest = rows.reduce((acc, row) => (row.invocationCount > acc ? row.invocationCount : acc), 0);
    const priorPeriod = getPriorPeriod(since, until);
    const { data: priorData } = usePlaybookUtilization(priorPeriod.since, priorPeriod.until);
    const priorVals = new Map<string, number>();
    priorData?.rows?.forEach(row => {
        priorVals.set(row.slug, row.invocationCount);
    });
    return (
        <ChartContainer
            title="Most used playbooks"
            className={className}
            icon={<BookText />}
            chart={
                <ScrollArea className="p-lg h-[270px]">
                    {rows.map((row, idx) => (
                        <StatBar
                            key={row.slug}
                            title={row.name}
                            value={row.invocationCount}
                            priorValue={priorVals.get(row.slug)}
                            linkTo={`${PLAYBOOKS}/${row.slug}`}
                            max={biggest}
                            color={COLORS[idx % COLORS.length]}
                        />
                    ))}
                </ScrollArea>
            }
        />
    );
};

const RequestByCategory = ({ className, since, until }: InsightProps) => {
    const { data } = useRequestByCategory(since, until);
    const rows = data?.rows ?? [];
    const biggest = rows.reduce((acc, row) => (row.count > acc ? row.count : acc), 0);
    const priorPeriod = getPriorPeriod(since, until);
    const { data: priorData } = useRequestByCategory(priorPeriod.since, priorPeriod.until);
    const priorVals = new Map<string, number>();
    priorData?.rows?.forEach(row => {
        priorVals.set(row.id, row.count);
    });
    return (
        <ChartContainer
            title="Requests by category"
            className={className}
            icon={<Tags />}
            chart={
                <ScrollArea className="p-lg h-[270px]">
                    {rows.map(row => (
                        <StatBar
                            key={row.id}
                            title={row.name}
                            value={row.count}
                            priorValue={priorVals.get(row.id)}
                            linkTo="/settings/requests/categories"
                            max={biggest}
                            color={row.color}
                        />
                    ))}
                </ScrollArea>
            }
        />
    );
};

const LeastAutoResolved = ({ className, since, until }: InsightProps) => {
    const { data } = useRequestByCategory(since, until, RequestStatus.TICKET_CREATED);
    const rows = data?.rows ?? [];
    const biggest = rows.reduce((acc, row) => (row.count > acc ? row.count : acc), 0);
    const priorPeriod = getPriorPeriod(since, until);
    const { data: priorData } = useRequestByCategory(
        priorPeriod.since,
        priorPeriod.until,
        RequestStatus.TICKET_CREATED,
    );
    const priorVals = new Map<string, number>();
    priorData?.rows?.forEach(row => {
        priorVals.set(row.id, row.count);
    });
    return (
        <ChartContainer
            title="Categories w/ least auto-resolution"
            className={className}
            icon={<Tags />}
            chart={
                <ScrollArea className="p-lg h-[270px]">
                    {rows.map(row => (
                        <StatBar
                            key={row.id}
                            title={row.name}
                            value={row.count}
                            priorValue={priorVals.get(row.id)}
                            linkTo="/settings/requests/categories"
                            max={biggest}
                            color={row.color}
                        />
                    ))}
                </ScrollArea>
            }
        />
    );
};

export const Insights = () => {
    const { data, isLoading } = useIntegrationSettingsStatus();
    const [date, setDate] = useState<DateRange>({
        from: undefined,
        to: undefined,
    });

    const interval = useMemo(() => {
        if (!date.from || !date.to) {
            return ChartInterval.DAY;
        }
        const monthDiff = differenceInCalendarMonths(date.to, date.from);
        if (monthDiff > 3) {
            return ChartInterval.MONTH;
        }
        const dayDiff = differenceInCalendarDays(date.to, date.from);
        if (dayDiff > 30) {
            return ChartInterval.WEEK;
        }
        return ChartInterval.DAY;
    }, [date]);

    const since = date.from?.toISOString();
    const until = date.to?.toISOString();

    const hasHris = Object.keys(data?.status ?? {}).some(key =>
        Object.values(MergeIntegrationId).includes(key as MergeIntegrationId),
    );

    const handleDateChange = (range?: DateRange) => {
        if (range) {
            setDate(range);
        } else {
            setDate({
                from: undefined,
                to: undefined,
            });
        }
    };

    return (
        <>
            <Brow
                title="Insights"
                icon={<CnslInsightsIcon />}
                actions={[
                    <DatePickerWithRange
                        date={date}
                        onChange={handleDateChange}
                        key="date"
                        numberOfMonths={1}
                        presets
                    />,
                ]}
            />
            {!isLoading && (
                <section className="p-xl grid lg:grid-cols-3 gap-xl">
                    <ResolutionGraph interval={interval} since={since} until={until} className="lg:col-span-2" />
                    {hasHris && <DepartmentStats className="col-span-1" since={since} until={until} />}
                    <RequestByCategory className="col-span-1" since={since} until={until} />
                    <LeastAutoResolved className="col-span-1" since={since} until={until} />
                    <PlaybookUtilization className="col-span-1" since={since} until={until} />
                </section>
            )}
        </>
    );
};
