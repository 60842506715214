import { useMutation } from '@lib/hooks/useMutation';
import { type DefaultError, useQueryClient } from '@tanstack/react-query';
import type { AccessRequestOverridePayload } from 'lib/models/provisioning_requests';
import { ProvisioningReviewStatus, type ProvisioningReviewStatusType } from 'lib/prisma/enums';

export const useAccessRequestOverride = (status: ProvisioningReviewStatusType, appSlug: string) => {
    const queryClient = useQueryClient();
    const message = status === ProvisioningReviewStatus.APPROVED ? 'Access granted' : 'Access denied';
    return useMutation<AccessRequestOverridePayload, DefaultError, AccessRequestOverridePayload>({
        url: `/api/v1/provisioning-requests/override`,
        method: 'POST',
        onSuccess: async () => {
            await queryClient.refetchQueries({ queryKey: [`/api/v1/apps/${appSlug}/access-requests`] });
        },
        successToast: {
            message,
        },
    });
};
