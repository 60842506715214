import { usePlaybookActions, usePlaybooks } from '@/hooks/queries/usePlaybook';
import { useEffect } from 'react';
import { usePlaybookDetailsStore } from './store/usePlaybookDetailsStore';
import { getActionOptions, getPlaybooksOptions } from './utils';

export const useLoadOptions = () => {
    // store
    const setOptions = usePlaybookDetailsStore(state => state.setOptions);
    const loaded = usePlaybookDetailsStore(state => state.loaded);
    const options = usePlaybookDetailsStore(state => state.options);

    // queries
    const { data: playbookData } = usePlaybooks();
    const { data: actionsData } = usePlaybookActions();

    useEffect(() => {
        if (actionsData?.actions && playbookData?.playbooks) {
            const actionOptions = getActionOptions(actionsData.actions);
            const playbookOptions = getPlaybooksOptions(playbookData.playbooks);

            setOptions([...actionOptions, ...playbookOptions]);
        }
    }, [actionsData?.actions, playbookData?.playbooks, setOptions]);

    return { options, loaded };
};
