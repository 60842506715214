import { cn } from '@lib/styling';
import { Check, ChevronDown } from 'lucide-react';
import { useEffect, useState } from 'react';

import { Button } from '@/components/ui/button';
import { Command, CommandEmpty, CommandGroup, CommandItem } from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';

export interface Option<T extends string | number> {
    value: T;
    label: string | React.ReactNode;
}

export type Value<T extends string | number> = T;

interface BaseProps<T extends string | number> {
    disabled?: boolean;
    placeholder?: string;
    className?: string;
    options: Option<T>[];
    TriggerWrapper?: React.FC<{ children: React.ReactNode }>;
}

type SelectMultiProp<T extends string | number, TMultiple = boolean> = (TMultiple extends false
    ? {
          isMultiple?: TMultiple;
          defaultValue?: T;
          value?: T;
          onChange?: (v: T | undefined) => void;
          icon?: React.ReactNode;
          constrainWidth?: boolean;
      }
    : {
          isMultiple: TMultiple;
          defaultValue?: Value<T>[];
          value?: Value<T>[];
          onChange?: (v: Value<T>[]) => void;
          icon?: React.ReactNode;
          constrainWidth?: boolean;
      }) &
    BaseProps<T>;

const ComboboxPopover = <T extends string | number>({
    options = [],
    defaultValue,
    onChange,
    isMultiple,
    TriggerWrapper,
    disabled,
    placeholder = 'Select ...',
    value: controlledValue,
    className,
    icon,
    constrainWidth,
}: SelectMultiProp<T>) => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        if (controlledValue !== undefined) {
            setValue(controlledValue);
        }
    }, [controlledValue]);

    /**
     * @param v is always coming back as a string that is lowercase, but sometimes options that have uppercase and number values
     */
    const handleSelect = (v: string) => {
        let updatedValue: typeof value = isMultiple ? [] : undefined;

        const isMatch = (o: Option<T>) =>
            typeof o.value === 'number' ? `${o.value}` === v : o.value.toLowerCase() === v;

        if (isMultiple && Array.isArray(value)) {
            const lowerValues = value.map(val => (typeof val === 'number' ? `${val}` : val.toLowerCase()));

            if (lowerValues.includes(v)) {
                updatedValue = value.filter(val =>
                    typeof val === 'number' ? `${val}` !== v : val.toLowerCase() !== v,
                );
            } else {
                const o = options.find(isMatch);
                if (o) {
                    updatedValue = [...value, o.value];
                }
            }
        } else {
            const o = options.find(isMatch);
            if (o) {
                updatedValue = o.value;
            }
        }

        setValue(updatedValue);

        if (onChange) {
            // @ts-expect-error - fix later
            onChange(updatedValue);
        }

        if (!isMultiple) {
            setOpen(false);
        }
    };

    const isSelected = (v: T) => (Array.isArray(value) ? value.includes(v) : v === value);

    const buttonText =
        Array.isArray(value) && value.length > 0
            ? options.find(o => value.includes(o.value))?.label
            : value
              ? options.find(o => o.value === value)?.label
              : placeholder;

    const trigger = (
        <Button role="combobox" aria-expanded={open} className="w-full text-start" disabled={disabled}>
            {icon && <div className="mr-md size-lg shrink-0">{icon}</div>}
            <div className="w-full">{buttonText}</div>
            {!disabled && (
                <ChevronDown
                    className={cn('transform transition duration-100 ease-out', {
                        'rotate-180': open,
                    })}
                />
            )}
        </Button>
    );

    return (
        <div className={className}>
            <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                    {TriggerWrapper ? <TriggerWrapper>{trigger}</TriggerWrapper> : trigger}
                </PopoverTrigger>
                <PopoverContent className={cn('p-sm', constrainWidth && 'PopoverContent')}>
                    <Command>
                        <CommandEmpty>No found.</CommandEmpty>
                        <CommandGroup>
                            {options.map(o => (
                                <CommandItem
                                    key={o.value}
                                    // ComandItem only supports string values
                                    value={`${o.value}`}
                                    onSelect={handleSelect}
                                    className="flex w-full items-center justify-between"
                                >
                                    {o.label}
                                    <Check className={cn(isSelected(o.value) ? 'opacity-100' : 'opacity-0')} />
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </Command>
                </PopoverContent>
            </Popover>
        </div>
    );
};

export default ComboboxPopover;
