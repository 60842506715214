import { BookCopy, Box, CalendarDays, LayoutGrid, Mail, UsersRound } from 'lucide-react';
import { useEffect, useState } from 'react';

import { AppProvider } from '@/components/AppProvider';
import { Copy } from '@/components/Copy';
import { ResLink } from '@/components/ResLink';
import { AppOwnersSelect } from '@/components/apps/AppOwnersSelect';
import { AppStatusSelect } from '@/components/apps/AppStatusSelect';
import { FormField, Label } from '@/components/ui/form';
import { useApp } from '@/hooks/queries/useApps';
import { useGroup } from '@/hooks/queries/useGroup';
import { usePlaybooks } from '@/hooks/queries/usePlaybook';
import { useSlackChannel } from '@/hooks/queries/useSlackChannel';
import { useUser } from '@/hooks/queries/useUser';
import { useEventListener } from '@/hooks/useEventListener';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { prettyAccessType } from '@/lib/apps';
import { fmtDateFull } from '@/lib/dates';
import { cn } from '@/lib/styling';

import { Checkbox } from '@/components/ui/checkbox';
import { Separator } from '@/components/ui/separator';
import { getProviderAssets } from 'lib/3p';
import { FlagKey } from 'lib/flags/keys';
import { IntegrationId } from 'lib/integration';
import type { RichPlaybook } from 'lib/models/playbook';
import type { UseFormReturn } from 'react-hook-form';
import { PlaybookReferences } from '../PlaybookReferences';
import { usePlaybookDetailsStore } from '../store/usePlaybookDetailsStore';
import { Details } from './Details';
import { PlaybookActionApprovalsDropdown } from './PlaybookActionApprovalsDropdown';

export const DetailSidebar = ({ form }: { form: UseFormReturn<RichPlaybook> }) => {
    const setActiveOption = usePlaybookDetailsStore(state => state.setActiveOption);
    const setActiveReference = usePlaybookDetailsStore(state => state.setActiveReference);
    const activeOption = usePlaybookDetailsStore(state => state.activeOption);
    const activeReference = usePlaybookDetailsStore(state => state.activeReference);
    const { data } = usePlaybooks();
    const [isVisible, setIsVisible] = useState(false);
    const isConfirmWithUserEnabled = useFeatureFlag(FlagKey.PlaybookActionConfirmWithUser);
    const isChallengeUserEnabled = useFeatureFlag(FlagKey.PlaybookActionChallengeUser);
    const isApprovalsEnabled = useFeatureFlag(FlagKey.PlaybookActionApprovals);

    /**
     * A reference have more metadata with them that are not needed in dropdown list.
     * So, we are fetching the extra data needed to render details.
     */
    const { data: groupData } = useGroup({
        slug: PlaybookReferences.isGroup(activeReference) ? activeReference.slug : '',
        enabled: PlaybookReferences.isGroup(activeReference),
    });
    const { data: appData } = useApp({
        slug: PlaybookReferences.isApp(activeReference) ? activeReference.slug : '',
        enabled: PlaybookReferences.isApp(activeReference),
    });
    const { data: userData } = useUser({
        email: PlaybookReferences.isUser(activeReference) ? activeReference.email : '',
        enabled: PlaybookReferences.isUser(activeReference),
    });
    const { data: slackChannelData } = useSlackChannel({
        slackId: PlaybookReferences.isSlackChannel(activeReference) ? activeReference.id : '',
        enabled: PlaybookReferences.isSlackChannel(activeReference),
    });

    const selectedPlaybook =
        activeOption?.type === 'playbook'
            ? data?.playbooks.find(playbook => playbook.id === activeOption.id)
            : undefined;

    const activeParameters = activeOption?.parameters ?? [];

    useEffect(() => {
        if (activeOption || activeReference) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    }, [activeOption, activeReference]);

    useEventListener('keydown', event => {
        if (event.key === 'Escape') {
            setIsVisible(false);
            setTimeout(() => {
                setActiveOption(undefined);
                setActiveReference(undefined);
            }, 100);
        }
    });

    // where in the actionReference we are looking, so that we can pass to the FieldForm
    let actionIdx: number | undefined;
    if (activeOption?.type === 'action') {
        actionIdx = form.getValues().references.actionReference.findIndex(ref => ref.id === activeOption?.referenceId);
    }
    const validActionIdx = (idx: number | undefined): idx is number => {
        return idx !== undefined && idx > -1;
    };

    const renderContent = () => {
        if (activeOption?.type === 'action') {
            return (
                <Details
                    header={
                        <div className="gap-md flex items-center capitalize">
                            <div className="border-grey flex size-8 items-center justify-center rounded-full border-[0.5px]">
                                {activeOption.provider === 'custom' ? (
                                    <Box />
                                ) : (
                                    <img
                                        src={getProviderAssets(activeOption.provider).logo}
                                        alt={activeOption.provider}
                                        className="size-4"
                                    />
                                )}
                            </div>
                            <h3>{activeOption.name}</h3>
                        </div>
                    }
                    description={activeOption.description}
                    content={
                        <>
                            <div className="px-lg">
                                <Label className="font-medium">Parameters</Label>
                                {activeParameters.length > 0 ? (
                                    activeParameters.map(param => (
                                        <div key={param.key} className="py-md">
                                            <div className="flex justify-between items-center">
                                                <div className="flex items-center gap-sm">
                                                    <span className="font-medium">{param.key}</span>
                                                    {param.required && (
                                                        <span className="text-body-red-primary mr-sm">*</span>
                                                    )}
                                                </div>
                                                <div className="border-grey border-[0.5px] rounded-sm px-sm text-xs font-medium">
                                                    {param.type}
                                                </div>
                                            </div>
                                            <span className="text-body-subtle-hover">{param.description}</span>
                                        </div>
                                    ))
                                ) : (
                                    <span className="text-body-subtle py-md flex items-center">No parameters</span>
                                )}
                            </div>
                            {isConfirmWithUserEnabled && validActionIdx(actionIdx) && (
                                <>
                                    <Separator />
                                    <div className="p-lg flex gap-md">
                                        <FormField
                                            control={form.control}
                                            name={`references.actionReference.${actionIdx}.config.confirmWithUser.shouldConfirm`}
                                            render={({ field }) => {
                                                // field.value is not pulling the correct value when the actionIdx changes
                                                const value =
                                                    form.getValues().references.actionReference[actionIdx].config
                                                        .confirmWithUser.shouldConfirm;
                                                return (
                                                    <Checkbox
                                                        id={`confirm-with-user-${actionIdx}`}
                                                        checked={value}
                                                        onCheckedChange={v => {
                                                            field.onChange(v);
                                                        }}
                                                    />
                                                );
                                            }}
                                        />

                                        <div>
                                            <Label
                                                className="text-body-grey-primary leading-none"
                                                htmlFor={`confirm-with-user-${actionIdx}`}
                                            >
                                                Confirm with user
                                            </Label>
                                            <span className="text-body-subtle">
                                                Confirms with user before executing this action
                                            </span>
                                        </div>
                                    </div>
                                </>
                            )}
                            {isChallengeUserEnabled && validActionIdx(actionIdx) && (
                                <>
                                    <Separator />
                                    <div className="p-lg flex gap-md">
                                        <FormField
                                            control={form.control}
                                            name={`references.actionReference.${actionIdx}.config.challengeUser.shouldChallenge`}
                                            render={({ field }) => {
                                                // field.value is not pulling the correct value when the actionIdx changes
                                                const value =
                                                    form.getValues().references.actionReference[actionIdx].config
                                                        .challengeUser?.shouldChallenge ?? false;
                                                return (
                                                    <Checkbox
                                                        id={`challenge-user-${actionIdx}`}
                                                        checked={value}
                                                        onCheckedChange={v => {
                                                            field.onChange(v);
                                                        }}
                                                    />
                                                );
                                            }}
                                        />

                                        <div>
                                            <Label
                                                className="text-body-grey-primary leading-none"
                                                htmlFor={`challenge-user-${actionIdx}`}
                                            >
                                                Require Okta Verify Confirmation
                                            </Label>
                                            <span className="text-body-subtle">
                                                Requires an identity verification from the user before executing the
                                                action
                                            </span>
                                        </div>
                                    </div>
                                </>
                            )}
                            {isApprovalsEnabled && validActionIdx(actionIdx) && (
                                <>
                                    <Separator />
                                    <div className="p-lg">
                                        <PlaybookActionApprovalsDropdown form={form} actionSlug={activeOption.slug} />
                                    </div>
                                    <Separator />
                                </>
                            )}
                        </>
                    }
                />
            );
        } else if (activeOption?.type === 'playbook') {
            return (
                <Details
                    header={
                        <div className="gap-md flex items-center capitalize">
                            <div className="border-grey flex size-8 items-center justify-center rounded-full border-[0.5px]">
                                <BookCopy />
                            </div>
                            <h3>{activeOption.name}</h3>
                        </div>
                    }
                    description={activeOption.description}
                    content={
                        <div className="px-lg">
                            {selectedPlaybook?.createdBy && (
                                <div className="flex flex-col gap-sm">
                                    <Label className="text-body-subtle font-medium">Created by</Label>
                                    <ResLink
                                        entity="users"
                                        id={selectedPlaybook.createdBy.id}
                                        label={selectedPlaybook.createdBy.displayName}
                                    />
                                </div>
                            )}
                            <div className="flex flex-col gap-sm">
                                <Label className="text-body-subtle font-medium">Created on</Label>
                                <div>{fmtDateFull(selectedPlaybook?.createdAt)}</div>
                            </div>
                        </div>
                    }
                />
            );
        } else if (userData) {
            return (
                <Details
                    header={
                        <ResLink
                            size="lg"
                            entity="users"
                            id={userData.user.email}
                            label={userData.user.displayName}
                            src={userData.user.avatar}
                        />
                    }
                    description={userData.user.title ?? <span className="text-body-subtle">No title found</span>}
                    content={
                        <div className="flex flex-col gap-lg px-lg">
                            <div className="flex flex-col gap-sm">
                                <Label className="text-body-subtle font-medium">Email</Label>
                                <div className="flex gap-md items-center">
                                    <Mail />
                                    <Copy text={userData.user.email} className="w-full justify-between" />
                                </div>
                            </div>
                            <div className="flex flex-col gap-sm">
                                <Label className="text-body-subtle font-medium">Manger</Label>
                                <div>
                                    {userData.user.manager ? (
                                        <ResLink
                                            size="sm"
                                            entity="users"
                                            id={userData.user.manager.email}
                                            label={userData.user.manager.displayName}
                                            src={userData.user.manager.avatar}
                                        />
                                    ) : (
                                        <span className="text-body-subtle">No manager</span>
                                    )}
                                </div>
                            </div>
                            {/* <div className="flex flex-col gap-sm">
                                <Label className="text-body-subtle font-medium">Started</Label>
                                <div><CalendarDays />{fmtDateFull(userData.user.createdAt)}</div>
                            </div> */}
                        </div>
                    }
                />
            );
        } else if (PlaybookReferences.isGroup(activeReference) && groupData?.group) {
            return (
                <Details
                    header={
                        <div className="gap-md flex items-center capitalize">
                            <div className="border-grey flex size-8 items-center justify-center rounded-full border-[0.5px]">
                                {groupData.group.logo ? (
                                    <img
                                        className="size-4"
                                        src={groupData.group.logo || undefined}
                                        alt={groupData.group.name || ''}
                                    />
                                ) : (
                                    <UsersRound />
                                )}
                            </div>
                            <h3>{groupData.group.name}</h3>
                        </div>
                    }
                    description={groupData.group.description}
                    content={
                        <div className="flex flex-col gap-lg px-lg">
                            <div className="flex flex-col gap-lg">
                                <Label className="text-body-subtle font-medium">Members</Label>
                                <div className="flex flex-col gap-lg">
                                    {groupData.group.group_users.length > 0 ? (
                                        groupData.group.group_users.map(groupUser => (
                                            <ResLink
                                                key={groupUser.id}
                                                entity="users"
                                                id={groupUser.cnsl_user.email}
                                                label={groupUser.cnsl_user.displayName}
                                                src={groupUser.cnsl_user.avatar}
                                            />
                                        ))
                                    ) : (
                                        <span className="text-body-subtle">No members</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    }
                />
            );
        } else if (PlaybookReferences.isApp(activeReference) && appData?.app) {
            return (
                <Details
                    header={
                        <div className="gap-md flex items-center capitalize">
                            <div className="border-grey flex size-8 items-center justify-center rounded-full border-[0.5px]">
                                {appData.app.logo ? (
                                    <img
                                        className="size-4"
                                        src={appData.app.logo || undefined}
                                        alt={appData.app.name || ''}
                                    />
                                ) : (
                                    <LayoutGrid />
                                )}
                            </div>
                            <h3>{appData.app.name}</h3>
                        </div>
                    }
                    description={appData.app.description}
                    content={
                        <div className="flex flex-col gap-lg px-lg">
                            <div className="flex flex-col gap-sm">
                                <Label className="text-body-subtle font-medium">Staus</Label>
                                <AppStatusSelect trigger="kv" status={appData.app.status} slug={appData.app.slug} />
                            </div>
                            <div className="flex flex-col gap-sm">
                                <Label className="text-body-subtle font-medium">Owner</Label>
                                <AppOwnersSelect trigger="kv" slug={appData.app.slug} owners={appData.app.owners} />
                            </div>
                            <div className="flex flex-col gap-sm">
                                <Label className="text-body-subtle font-medium">Source</Label>
                                <AppProvider provider={appData.app.provider} />
                            </div>
                            <div className="flex flex-col gap-sm">
                                <Label className="text-body-subtle font-medium">Access type</Label>
                                {prettyAccessType(appData.app.authType)}
                            </div>
                            <div className="flex flex-col gap-sm">
                                <Label className="text-body-subtle font-medium">First detected</Label>
                                <div className="flex gap-sm items-center">
                                    <CalendarDays />
                                    {fmtDateFull(appData.app.createdAt)}
                                </div>
                            </div>
                        </div>
                    }
                />
            );
        } else if (PlaybookReferences.isSlackChannel(activeReference) && slackChannelData?.channel) {
            const { name, logo } = getProviderAssets(IntegrationId.Slack);
            const channel = slackChannelData?.channel.data;
            return (
                <Details
                    header={
                        <div className="gap-md flex items-center capitalize">
                            <div className="border-grey flex size-8 items-center justify-center rounded-full border-[0.5px]">
                                <img className="size-4" src={logo} alt={name} />
                            </div>
                            <h3>{channel?.name}</h3>
                        </div>
                    }
                    description={channel?.purpose.value}
                />
            );
        } else {
            return null;
        }
    };

    return (
        <div
            className={cn(
                'z-10 transition-all duration-100 ease-out absolute top-0 w-[250px] left-0 bottom-0 bg-bg border-r-[0.5px] border-grey shadow-lpane',
                isVisible ? 'w-[350px] grow shrink-0 overflow-y-scroll' : 'w-0 overflow-hidden',
            )}
        >
            {isVisible && <div className="shrink-0 w-[350px]">{renderContent()}</div>}
        </div>
    );
};
