import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';

import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';

import { ArrowRight, BookOpenCheck, Info } from 'lucide-react';

export const ConditionsInfoDialog = () => {
    return (
        <Button
            mode="borderless"
            size="sm"
            className="rounded-full text-body-subtle hover:text-body-subtle-hover"
            tabIndex={-1}
        >
            <Dialog>
                <DialogTrigger asChild>
                    <Info className="size-3.5" />
                </DialogTrigger>
                <DialogContent>
                    <div className="p-lg flex flex-col items-center gap-xl text-center text-sm">
                        <div className="flex flex-col items-center gap-sm pt-lg">
                            <BookOpenCheck className="size-2xl" />
                            <h3 className="text-xl">Conditions</h3>
                            <p className="text-body-subtle-hover text-base">When should Console run this Playbook?</p>
                        </div>
                        <p>
                            In a few sentences, describe the type of support request that will trigger this Playbook.
                            This is how Console determines which Playbook to use.
                        </p>
                        <Separator className="my-md" />
                        <div className="flex flex-col items-start text-left gap-sm pl-xl pt-xl bg-bg-overlay border-grey border-[0.5px] rounded-md overflow-hidden">
                            <h6 className="mb-md">Example conditions for an office wifi Playbook</h6>
                            <div className="pl-2xl pt-2xl pb-2xl bg-bg border-t-[0.5px] border-l-[0.5px] border-grey shadow-lg flex flex-col items-start gap-lg">
                                <div className="flex items-center gap-md font-mono uppercase w-full border-b-[0.5px] border-grey mt-2xl">
                                    <ArrowRight />
                                    Conditions
                                </div>
                                <p>
                                    When a user asks to get access to the wifi, needs the wifi password or is having
                                    trouble connecting to the wifi
                                </p>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </Button>
    );
};
