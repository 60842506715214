import { Toaster as Sonner } from 'sonner';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
    return (
        <Sonner
            className="toaster group"
            toastOptions={{
                classNames: {
                    toast: 'group toast group-[.toaster]:bg-bg group-[.toaster]:border-grey group-[.toaster]:border-[0.5px] group-[.toaster]:shadow-lg',
                    description: 'group-[.toast]:text-body-subtle',
                    actionButton: '',
                    cancelButton: 'group-[.toast]:bg-bg group-[.toast]:text-body-subtle',
                },
            }}
            {...props}
        />
    );
};

export { Toaster };
