import { useUnbecome } from '@/hooks/mutations/useUnbecome';
import { useUser as useUserStore } from '@/stores/useUser';
import { defaultLogger } from 'lib/log';
import type { MeType } from 'lib/models/me';
import { Crown, Undo2 } from 'lucide-react';

export const GodModeBanner = () => {
    const { user } = useUserStore.getState();
    const unbecomeMutation = useUnbecome();

    if (!user) return null;

    const isAssumedByPresent = (session?: MeType['session']) => {
        return session && 'assumedBy' in session;
    };

    const handleClick = async () => {
        await unbecomeMutation.mutate({});
    };

    if (user.session.assumedBy && user.user.id && user.session.id) {
        defaultLogger.info(`User: ${user.user.id} Assumed by: ${user.session.assumedBy} Session: ${user.session.id}`);
    }

    return isAssumedByPresent(user.session) ? (
        <div className="z-50 fixed w-full h-[2px] bg-[#F76808] hover:bg-[#FFA01C] flex items-start justify-center text-body-white uppercase text-[10px] font-bold cursor-pointer group">
            <div
                className="flex flex-col items-center rounded-b-sm bg-[#F76808] group-hover:bg-[#FFA01C] hover:bg-[#FFA01C] w-24 hover:w-[112px] h-5 transition-all duration-150 ease-out relative group/tag overflow-clip"
                onClick={handleClick}
            >
                <div className="flex justify-center items-center gap-sm group-hover/tag:-translate-y-5 py-[2px] transition duration-150 ease-out size-full">
                    <Crown className="size-3" />
                    You're a god
                </div>

                <div className="flex items-center gap-sm group-hover/tag:-translate-y-5 text-nowrap py-[2px] transition duration-150 ease-out size-full">
                    <Undo2 className="size-3" />
                    Un-become god
                </div>
            </div>
        </div>
    ) : null;
};
