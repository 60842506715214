import type { ColumnDef } from '@tanstack/react-table';
import { Table } from '@ui/table/table';
import type { CnslGroupList, ListGroupsQP } from 'lib/models/group';

import { Brow } from '@/components/Brow';
import { ResLink } from '@/components/ResLink';
import { TableEmptyState } from '@/components/TableEmptyState';
import { UsersFilter } from '@/components/filters/UsersFilter';
import { Button } from '@/components/ui/button';
import { Select } from '@/components/ui/select';
import { AppResLinkOption, AppResLinkValuesContainer } from '@/components/ui/select/AppResLinkOptions';
import { useAppProviderFilterOptions } from '@/hooks/queries/useApps';
import { useUsers } from '@/hooks/queries/useUsers';
import { CnslGroupIcon } from '@/lib/iconConstants';
import { LIMIT_DEFAULT, THRESHOLD_DEFAULT } from '@/lib/paginationConstants';
import { getProviderAssets } from 'lib/3p';
import type { CnslUser } from 'lib/prisma/types';
import { Radio } from 'lucide-react';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

const columns: ColumnDef<CnslGroupList>[] = [
    {
        accessorKey: 'name',
        header: () => 'Source',
        cell: props => {
            const { logo } = getProviderAssets(props.row.original.provider);
            return (
                <ResLink
                    className="pl-lg h-full"
                    entity="groups"
                    id={props.row.original.slug}
                    label={props.row.original.name ?? ''}
                    src={logo}
                    rounded="square"
                    bold
                />
            );
        },
        meta: {
            expand: true,
        },
    },
    {
        accessorKey: 'members',
        header: () => 'Members',
        cell: props => props.row.original._count.group_users,
    },
    {
        accessorKey: 'description',
        header: () => 'Description',
        cell: props => <div className="truncate">{props.row.original.description}</div>,
    },
];

const pagination = {
    queryKey: ['groups'],
    limit: LIMIT_DEFAULT,
    threshold: THRESHOLD_DEFAULT,
    pathname: '/api/v1/groups',
};

const EmptyState = () => {
    const navigate = useNavigate();

    return (
        <TableEmptyState
            title="Groups"
            description="Connect your user providers to sync groups"
            imageSrc="/emptyStates/empty-groups.svg"
        >
            <Button onClick={() => navigate('/settings/organization/integrations/okta')}>
                <img src="/3p/okta-logo.png" alt="Okta logo" className="size-4" />
                Connect Okta
            </Button>
            <Button onClick={() => navigate('/settings/organization/integrations/google')}>
                <img src="/3p/google-logo.png" alt="Google logo" className="size-4" />
                Connect Google
            </Button>
        </TableEmptyState>
    );
};

export const Groups = () => {
    const [appCount, setAppCount] = useState<number | null>(null);
    const [rawFilters, setFilters] = useLocalStorage<NonNullable<ListGroupsQP>>('GROUPS_FILTERS', {});
    const filters = rawFilters ?? {};
    const { data } = useAppProviderFilterOptions();
    const providerFilterOptions = (data?.providers ?? []).map(p => {
        const { logo, name } = getProviderAssets(p);
        return { label: name, value: p, logo };
    });

    const [onMountUsersFilter, _] = useState<string[]>(filters.member ?? []);
    const [users, setUsers] = useState<CnslUser[]>([]);
    const { data: usersData } = useUsers({
        userIds: onMountUsersFilter.length ? onMountUsersFilter : undefined,
        enabled: !!onMountUsersFilter.length,
    });
    const canRenderUsersFilter = onMountUsersFilter.length ? !!usersData?.items : true;

    useEffect(() => {
        if (usersData?.items) {
            setUsers(usersData.items);
        }
    }, [usersData]);

    return (
        <>
            <Brow title="Groups" icon={<CnslGroupIcon />} {...(appCount !== null ? { resourceCount: appCount } : {})} />
            <div className="flex justify-between items-center border-b-[0.5px] p-md border-grey">
                <div className="gap-md px-md flex items-center ">
                    {canRenderUsersFilter && (
                        <div className="h-6">
                            <UsersFilter
                                users={users}
                                onChange={selectedUsers => {
                                    const newUsers = selectedUsers.map(o => o.value);
                                    setUsers(newUsers);
                                    setFilters({ ...filters, member: newUsers.map(u => u.id) });
                                }}
                            />
                        </div>
                    )}
                    <div className="h-6">
                        <Select
                            placeholder={
                                <div className="flex items-center gap-sm">
                                    <Radio className="size-3.5" />
                                    Source
                                </div>
                            }
                            trigger="small"
                            isClearable
                            closeMenuOnSelect={false}
                            isMulti={true}
                            options={providerFilterOptions as typeof providerFilterOptions | undefined}
                            components={{
                                Option: AppResLinkOption,
                                ValueContainer: AppResLinkValuesContainer,
                                MultiValue: () => null,
                            }}
                            value={
                                filters.source
                                    ? filters.source!.map(s => providerFilterOptions.find(o => o.value === s)!)
                                    : null
                            }
                            onChange={o => {
                                setFilters({ ...filters, source: o.length ? o.map(u => u.value) : undefined });
                            }}
                        />
                    </div>
                </div>

                {!!Object.values(filters)
                    .filter((i: string[] | string | undefined): i is string[] => Array.isArray(i))
                    .flat().length && (
                    <Button
                        mode="borderless"
                        size="sm"
                        onClick={() => {
                            setFilters({});
                            setUsers([]);
                        }}
                    >
                        Clear all
                    </Button>
                )}
            </div>
            <Table
                columns={columns}
                pagination={{ ...pagination, filters: Object.keys(filters).length ? qs.stringify(filters) : undefined }}
                onPaginationCountChange={c => setAppCount(c)}
                emptyState={<EmptyState />}
            />
        </>
    );
};
