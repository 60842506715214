import { AppStatus } from 'lib/models/app';
import { getCssColor } from 'lib/requests/utils';

export const appStatusConfig = (status?: string) => {
    switch (status) {
        case AppStatus.ignored:
            return {
                label: 'Ignored',
                color: 'grey',
            };
        case AppStatus.managed:
            return {
                label: 'Managed',
                color: 'green',
            };
        default:
            return {
                label: 'Needs Review',
                color: 'yellow',
            };
    }
};

export const prettyAccessType = (type: string) => {
    switch (type.toLowerCase()) {
        case 'oauth':
            return 'OAuth';
        case 'sso':
            return 'SSO';
        case 'email':
            return 'Email';
        default:
            return 'Unknown';
    }
};

export const appStatusOptions = Object.values(AppStatus).map(status => ({
    label: appStatusConfig(status).label,
    value: status,
    color: getCssColor(appStatusConfig(status).color, 'bg'),
}));
