import type { ArticleListResponse, ArticleResponse, ArticleVersionListResponse } from 'lib/models/article';

import { useQuery } from '@/lib/hooks/useQuery';

export const useArticle = (key: string) => {
    return useQuery<ArticleResponse>({
        url: `/api/v1/articles/${key}`,
    });
};

export const useArticleVersions = (key: string) => {
    return useQuery<ArticleVersionListResponse>({
        url: `/api/v1/articles/${key}/versions`,
    });
};

export const useArticles = () => {
    return useQuery<ArticleListResponse>({
        url: `/api/v1/articles`,
        staleTime: 0,
        refetchOnMount: true,
    });
};
