import Placeholder from '@tiptap/extension-placeholder';
import type { JSONContent } from '@tiptap/react';
import type { InternalArticle } from 'lib/models/article';
import type { UseFormReturn } from 'react-hook-form';

import { Editor } from '@/components/tiptap/Editor';
import { Form } from '@/components/ui/form';
import { FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form/form';
import { Textarea } from '@/components/ui/textarea';
import { configureExtensions } from 'lib/tiptap';

export const ArticleDetailsForm = ({
    form,
    saveVersion,
}: { form: UseFormReturn<InternalArticle>; saveVersion: (refetchDirectories?: boolean) => void }) => {
    const extensions = configureExtensions(
        [
            Placeholder.configure({
                placeholder: 'Write your article...',
            }),
        ],
        [],
    );

    return (
        <Form {...form}>
            <div className="w-full flex flex-col gap-sm">
                <FormField
                    control={form.control}
                    name="name"
                    render={({ field: { onBlur, ...field } }) => (
                        <FormItem>
                            <FormControl>
                                <Textarea
                                    autoResize
                                    minHeight="30px"
                                    className="border-none text-xl font-medium !p-0"
                                    placeholder="Add a title"
                                    onBlur={() => {
                                        onBlur();
                                        saveVersion(true);
                                    }}
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="description"
                    render={({ field: { onBlur, ...field } }) => (
                        <FormItem>
                            <FormControl>
                                <Textarea
                                    autoResize
                                    minHeight="24px"
                                    className="border-none text-base !p-0"
                                    placeholder="Add a description"
                                    onBlur={() => {
                                        onBlur();
                                        saveVersion();
                                    }}
                                    {...field}
                                />
                            </FormControl>
                        </FormItem>
                    )}
                />
            </div>

            <FormField
                control={form.control}
                name="data"
                render={({ field: { onBlur, ...field } }) => (
                    <FormItem className="flex grow flex-col mt-md">
                        <FormControl className="h-full">
                            <Editor
                                className="text-sm leading-6"
                                disabled={field.disabled}
                                content={field.value as JSONContent}
                                extensions={extensions}
                                onChange={d => field.onChange({ target: { value: d } })}
                                onBlur={() => {
                                    onBlur();
                                    saveVersion();
                                }}
                            />
                        </FormControl>
                    </FormItem>
                )}
            />
        </Form>
    );
};
