import { ResLink } from '@/components/ResLink';
import { Badge } from '@/components/ui/badge';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { ScrollArea } from '@/components/ui/scroll-area';
import { usePRDetails } from '@/hooks/queries/useProvisioningRequests';
import { fmtDate } from '@/lib/dates';
import { cn } from '@/lib/styling';
import { formatDistance } from 'date-fns';
import type { SingleCnslApp } from 'lib/models/app';
import type { UserPolicyHistory } from 'lib/models/app_policy';
import { durMap, prettyPolicyDuration } from 'lib/models/app_policy';
import { AppPolicyDuration, ProvisioningReviewStatus, type ProvisioningReviewStatusType } from 'lib/prisma/enums';
import { ArrowRight, ChevronRight, CircleCheck, CircleX, Clock4, Scale, ShieldX, TriangleAlert } from 'lucide-react';
import { useState } from 'react';

type colorVariant = 'green' | 'red' | 'yellow' | 'grey';
const opts: Record<ProvisioningReviewStatusType, { variant: colorVariant; label: string; icon: React.ReactNode }> = {
    [ProvisioningReviewStatus.APPROVED]: {
        variant: 'green',
        label: 'Access granted',
        icon: <CircleCheck className="size-4 text-body-green-primary" stroke="white" fill="currentColor" />,
    },
    [ProvisioningReviewStatus.DENIED]: {
        variant: 'red',
        label: 'Access denied',
        icon: <CircleX className="size-4 text-body-red-primary" stroke="white" fill="currentColor" />,
    },
    [ProvisioningReviewStatus.REVOKED]: {
        variant: 'red',
        label: 'Access revoked',
        icon: <ShieldX className="size-4 text-body-red-primary" stroke="white" fill="currentColor" />,
    },
    [ProvisioningReviewStatus.PENDING]: {
        variant: 'yellow',
        label: 'Awaiting approval',
        icon: <Clock4 className="size-4 text-body-yellow-primary" stroke="white" fill="currentColor" />,
    },
    [ProvisioningReviewStatus.EXPIRED]: {
        variant: 'grey',
        label: 'Request expired',
        icon: <TriangleAlert className="size-4 text-body-orange-primary" stroke="white" fill="currentColor" />,
    },
};

const PolicyBadge = ({ status }: { status: ProvisioningReviewStatusType }) => {
    const { variant, label } = opts[status];
    return (
        <Badge variant={variant} mode="dark">
            {label}
        </Badge>
    );
};
const PolicyIcon = ({ status }: { status: ProvisioningReviewStatusType }) => {
    const { icon } = opts[status];
    return icon;
};
interface HTimelineStepProps {
    status: ProvisioningReviewStatusType;
    label: string;
    ts: Date;
    isPending?: boolean;
    hasPriorStep?: boolean;
}

const HTimelineStep = ({ status, label, ts, isPending, hasPriorStep }: HTimelineStepProps) => {
    const { icon } = opts[status];
    return (
        <>
            {hasPriorStep && (
                <ArrowRight className={cn(isPending ? 'text-body-subtle' : 'text-body-default', 'size-4')} />
            )}
            <div className="flex gap-md items-center bg-bg-elevated px-lg py-md">
                {icon}
                <div>
                    <div className={cn(isPending ? 'text-body-subtle' : 'text-body-default')}>{label}</div>
                    <div className="text-body-subtle">{formatDistance(ts, new Date(), { addSuffix: true })}</div>
                </div>
            </div>
        </>
    );
};

const HorizontalTimeline = ({ pr }: { pr: UserPolicyHistory['provisioningRequests'][0] }) => {
    let revokeDate: Date | null = null;
    if (pr.status === 'APPROVED' && pr.reviewedAt && pr.duration !== AppPolicyDuration.INDEFINITE) {
        const { compute } = durMap[pr.duration];
        revokeDate = compute(pr.reviewedAt);
    }

    return (
        <div className="flex items-center bg-bg-elevated px-lg py-md">
            <HTimelineStep status={ProvisioningReviewStatus.APPROVED} label="Requested" ts={new Date(pr.createdAt)} />
            {pr.reviewedAt && (
                <HTimelineStep
                    status={pr.status}
                    label={pr.status === 'APPROVED' ? 'Access granted' : 'Access denied'}
                    ts={new Date(pr.reviewedAt)}
                    hasPriorStep
                />
            )}
            {revokeDate && (
                <HTimelineStep
                    status={ProvisioningReviewStatus.PENDING}
                    label="Access expired"
                    ts={new Date(revokeDate)}
                    hasPriorStep
                    isPending
                />
            )}
        </div>
    );
};
interface EntryData {
    actor: string;
    avatar: React.ReactNode;
    ts: Date;
    message: string;
    subMessage?: string | null;
}

interface EntryGrouping {
    position: 'first' | 'middle' | 'last' | 'single';
    darken: boolean;
    first: boolean;
    last: boolean;
}

const PolicyTimelineEntry = ({ entry, grouping }: { entry: EntryData; grouping: EntryGrouping }) => {
    const rounding = () => {
        switch (grouping.position) {
            case 'first':
                return 'rounded-t-md rounded-t-md';
            case 'last':
                return 'rounded-bl-md rounded-br-md';
            default:
                return 'rounded-md';
        }
    };

    const border = () => {
        if (grouping.darken) {
            switch (grouping.position) {
                case 'first':
                    return 'border-t border-x';
                case 'last':
                    return 'border-b border-x';
                case 'middle':
                    return 'border-x';
                default:
                    return 'border';
            }
        }
        return 'border-x border-transparent';
    };

    return (
        <div className={cn('p-md relative', grouping.darken ? 'bg-bg-surface border-grey' : '', rounding(), border())}>
            <div
                className={cn(
                    'absolute left-5 bottom-0 w-px bg-bg-grey-primary',
                    grouping.first ? 'top-5' : 'top-0',
                    grouping.last ? 'h-5' : 'bottom-0',
                )}
            />

            <div className="flex items-center gap-sm justify-between">
                <div className="flex items-center gap-sm">
                    {entry.avatar}
                    <span className="text-body-subtle-hover">{entry.message}</span>
                </div>
                <span className="text-body-subtle-hover text-xs">{fmtDate(entry.ts)}</span>
            </div>
            {entry.subMessage && (
                <div className="ml-8 my-md w-3/4">
                    <span className="text-body p-md bg-bg-blue-primary rounded-md">{entry.subMessage}</span>
                </div>
            )}
        </div>
    );
};

const VerticalTimeline = ({
    pr,
    history,
    app,
}: { pr: UserPolicyHistory['provisioningRequests'][0]; history: UserPolicyHistory; app: SingleCnslApp }) => {
    const durationLabel =
        pr.duration === AppPolicyDuration.INDEFINITE
            ? 'indefinitely'
            : `for ${prettyPolicyDuration(pr.duration, window.navigator.language)}`;

    const { data } = usePRDetails(pr.id);
    const { reviewer, user } = data?.provisioningRequest ?? {};

    const entries: EntryData[] = [
        {
            actor: pr.userId,
            avatar: (
                <ResLink
                    entity="users"
                    id={user?.email}
                    label={history.displayName}
                    src={history.avatar}
                    bold
                    badge={<img src="/3p/slack-logo.png" alt="Slack Logo" className="size-[10px]" />}
                />
            ),
            ts: pr.createdAt,
            message: `Requested access to ${app.name} ${durationLabel}`,
            subMessage: pr.reason,
        },
        {
            actor: 'console',
            avatar: (
                <ResLink
                    bold
                    label="Console"
                    fallback={
                        <div className="bg-bg border-grey flex size-6 items-center justify-center rounded-full border">
                            <Scale className="size-3.5" />
                        </div>
                    }
                />
            ),
            ts: pr.createdAt,
            message: `used the ${app.name} "${pr.policy.name}" access policy`,
        },
    ];

    const reviews = data?.provisioningRequest?.reviews ?? [];

    if (reviews.length > 0) {
        reviews?.forEach(r => {
            if (r.reviewer) {
                entries.push({
                    actor: 'console',
                    avatar: (
                        <ResLink
                            bold
                            label="Console"
                            badge={<img src="/3p/slack-logo.png" alt="Slack Logo" className="size-[10px]" />}
                            fallback={
                                <div className="bg-bg border-grey flex size-6 items-center justify-center rounded-full border">
                                    <img src="/console-icon.svg" alt="Console Logo" className="size-[12px]" />
                                </div>
                            }
                        />
                    ),
                    ts: r.createdAt,
                    message: `Send an approval request to ${r.reviewer?.displayName}`,
                });
                const grantMessage = r.approved ? 'approved access' : 'denied access';
                entries.push({
                    actor: r.reviewer.id,
                    avatar: (
                        <ResLink
                            entity="users"
                            id={r.reviewer.id}
                            label={r.reviewer.displayName}
                            src={r.reviewer.avatar}
                            bold
                            badge={<img src="/3p/slack-logo.png" alt="Slack Logo" className="size-[10px]" />}
                        />
                    ),
                    ts: r.reviewedAt ?? pr.createdAt,
                    message: `${grantMessage} to ${app.name} ${durationLabel}`,
                    subMessage: r.reviewerComment,
                });
            }
        });
    } else if (reviewer && pr.reviewedAt) {
        const grantMessage = pr.status === 'APPROVED' ? 'granted access' : 'denied access';

        entries.push({
            actor: 'console',
            avatar: (
                <ResLink
                    bold
                    label="Console"
                    badge={<img src="/3p/slack-logo.png" alt="Slack Logo" className="size-[10px]" />}
                    fallback={
                        <div className="bg-bg border-grey flex size-6 items-center justify-center rounded-full border">
                            <img src="/console-icon.svg" alt="Console Logo" className="size-[12px]" />
                        </div>
                    }
                />
            ),
            ts: pr.createdAt,
            message: `Send an approval request to ${reviewer.displayName}`,
        });

        entries.push({
            actor: reviewer.id,
            avatar: (
                <ResLink
                    entity="users"
                    id={reviewer.id}
                    label={reviewer.displayName}
                    src={reviewer.avatar}
                    bold
                    badge={<img src="/3p/slack-logo.png" alt="Slack Logo" className="size-[10px]" />}
                />
            ),
            ts: pr.reviewedAt,

            message: `${grantMessage} to ${app.name} ${durationLabel}`,
            subMessage: pr.reviewerComment,
        });
    }

    if (pr.status === ProvisioningReviewStatus.APPROVED && pr.reviewedAt) {
        entries.push({
            actor: 'console',
            avatar: (
                <ResLink
                    className="text-sm"
                    bold
                    label="Console"
                    badge={
                        <ResLink
                            entity="apps"
                            id={app.id}
                            bold
                            label={app.name}
                            src={app.logo ?? ''}
                            avatarOnly
                            size="sm"
                        />
                    }
                    fallback={
                        <div className="bg-bg border-grey flex size-6 items-center justify-center rounded-full border">
                            <img src="/console-icon.svg" alt="Console Logo" className="size-[12px]" />
                        </div>
                    }
                />
            ),
            ts: pr.reviewedAt,
            message: `Ran grant flow for "${pr.policy.name}" access policy`,
        });
    }

    const colorizeEntries = (): EntryGrouping[] => {
        const darken = [true, false];
        let currentColor = 0;
        let currentActor: string | null = null;

        return entries.map((entry, index, array) => {
            if (entry.actor !== currentActor) {
                currentActor = entry.actor;
                currentColor = (currentColor + 1) % darken.length;
            }

            let position: 'first' | 'middle' | 'last' | 'single';
            if (index === 0 || entry.actor !== array[index - 1].actor) {
                position = index === array.length - 1 || entry.actor !== array[index + 1].actor ? 'single' : 'first';
            } else {
                position = index === array.length - 1 || entry.actor !== array[index + 1].actor ? 'last' : 'middle';
            }

            return {
                darken: darken[currentColor],
                position,
                first: index === 0,
                last: index === array.length - 1,
            };
        });
    };

    const colorizedEntries = colorizeEntries();

    return (
        <div className="px-md py-lg">
            {entries.map((entry, index) => (
                <PolicyTimelineEntry key={index} entry={entry} grouping={colorizedEntries[index]} />
            ))}
        </div>
    );
};

interface PolicyHistoryProps {
    history: UserPolicyHistory;
    app: SingleCnslApp;
}

export const PolicyHistory = ({ history, app }: PolicyHistoryProps) => {
    const [activePolicy, setActivePolicy] = useState<(typeof history.provisioningRequests)[number] | undefined>(
        history.provisioningRequests[0],
    );

    return (
        <Dialog>
            <DialogTrigger asChild>
                <button className="text-left line-clamp-1">
                    {history.provisioningRequests.map(req => req.policy.name).join(', ')}
                </button>
            </DialogTrigger>
            <DialogContent size="wider">
                <div className="grid grid-cols-3 border-b border-grey items-center">
                    <DialogHeader>
                        <DialogTitle className="flex items-center gap-md">
                            <ResLink
                                entity="users"
                                id={history.email}
                                label={history.displayName}
                                src={history.avatar}
                                avatarOnly
                            />
                            <span className="text-body-default">{history.displayName}'s Policies</span>
                        </DialogTitle>
                    </DialogHeader>
                    <div className="col-span-2 border-l border-grey h-full flex items-center p-lg">
                        {activePolicy && (
                            <div className="flex gap-md items-center">
                                <Scale className="size-4" />
                                <h3 className="text-body-default">{activePolicy?.policy?.name}</h3>
                                <PolicyBadge status={activePolicy?.status} />
                            </div>
                        )}
                    </div>
                </div>
                <div className="grid grid-cols-3">
                    <ScrollArea className="col-span-1 h-auto max-h-[70vh]">
                        <ul className="flex flex-col gap-sm p-md">
                            {history.provisioningRequests.map(req => (
                                <li
                                    key={req.id}
                                    className={cn(
                                        'flex gap-md items-center justify-between p-md rounded-md hover:bg-bg-elevated transition-colors cursor-pointer',
                                        req.id === activePolicy?.id ? 'bg-bg-elevated' : '',
                                    )}
                                    onClick={() => setActivePolicy(req)}
                                >
                                    <div className="flex gap-md items-center">
                                        <PolicyIcon status={req.status} />
                                        <div>
                                            <div>{req.policy.name}</div>
                                            <div className="text-body-subtle">{req.policy.description}</div>
                                        </div>
                                    </div>
                                    <ChevronRight className="size-4" />
                                </li>
                            ))}
                        </ul>
                    </ScrollArea>
                    <div className="col-span-2 border-l border-grey">
                        {activePolicy && (
                            <>
                                <HorizontalTimeline pr={activePolicy} />
                                <VerticalTimeline pr={activePolicy} history={history} app={app} />
                            </>
                        )}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};
