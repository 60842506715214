import { ResLink } from '@/components/ResLink';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/styling';
import type { AppsWithPolicyCount } from 'lib/models/app';
import { Check, X } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

const AppListItem = ({ app, collapsed }: { app: AppsWithPolicyCount; collapsed: boolean }) => {
    const { slug = '' } = useParams();
    const selected = slug === app.slug;
    const to = `/policies/_apps/${app.slug}/policies`;

    return (
        <Link
            to={to}
            className={cn(
                'py-sm items-center gap-md hover:bg-bg-grey-primary rounded-md h-8',
                selected && 'bg-bg-grey-primary-selected',
                collapsed ? 'w-8  flex justify-center' : 'grid grid-cols-8 px-md justify-between',
            )}
        >
            <ResLink
                entity="apps"
                size="sm"
                id={app.slug}
                label={app.name ?? ''}
                rounded="square"
                bold
                to={to}
                avatarOnly={collapsed}
                className="col-span-7"
                clamp
            />
            {app._count.policies > 0 && !collapsed && (
                <div className="col-span-1 flex justify-end">
                    <Badge>{app._count.policies}</Badge>
                </div>
            )}
        </Link>
    );
};

export const AppList = ({
    apps,
    hasPolicies,
    collapsed,
}: { apps: AppsWithPolicyCount[]; hasPolicies: boolean; collapsed: boolean }) => {
    const label = hasPolicies ? 'Has policies' : 'No policies';
    const icon = collapsed ? hasPolicies ? <Check /> : <X /> : null;

    return (
        <div className="p-md">
            <h4 className="text-xs font-medium text-body-subtle py-sm px-md flex items-center gap-sm sticky top-0 bg-bg-surface z-10">
                {collapsed ? <div title={label}>{icon}</div> : label}
            </h4>
            <div className="flex flex-col">
                {apps.map(app => (
                    <AppListItem key={app.id} app={app} collapsed={collapsed} />
                ))}
            </div>
        </div>
    );
};
