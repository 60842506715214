'use client';
import { Button } from '@ui/button';
import { Calendar } from '@ui/calendar';
import {
    addDays,
    addMonths,
    addQuarters,
    addWeeks,
    endOfDay,
    endOfMonth,
    endOfQuarter,
    endOfWeek,
    startOfMonth,
    startOfQuarter,
    startOfWeek,
    startOfYear,
} from 'date-fns';
import { CalendarDays, ChevronDown, X } from 'lucide-react';
import type * as React from 'react';
import { useRef, useState } from 'react';
import type { DateRange, SelectRangeEventHandler } from 'react-day-picker';

import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { ScrollArea } from '@/components/ui/scroll-area';
import { cn } from '@/lib/styling';

import { fmtDate, fmtDayMonthShort, fmtDayOfMonth } from '@/lib/dates';

interface DatePickerWithRangeProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
    date: DateRange;
    onChange: (range?: DateRange) => void;
    numberOfMonths?: number;
    presets?: boolean;
}

interface PresetOption {
    label: string;
    value: DateRange;
    rangeLabel: string;
    spacer?: boolean;
}

const presetOptions = (): PresetOption[] => {
    const now = new Date();
    const startOfLastWeek = addWeeks(startOfWeek(now), -1);

    const startOfLastMonth = addMonths(startOfMonth(now), -1);
    const endOfLastMonth = endOfMonth(startOfLastMonth);

    const startOfLastQuarter = addQuarters(startOfQuarter(now), -1);
    const endOfLastQuarter = endOfQuarter(startOfLastQuarter);

    const startOfLastYear = new Date(now.getFullYear() - 1, 0, 1);
    const endOfLastYear = new Date(now.getFullYear() - 1, 11, 31);

    const presets = [
        {
            label: 'Last 30 Days',
            value: {
                from: addDays(now, -30),
                to: now,
            },
            rangeLabel: `${fmtDayMonthShort(addDays(now, -30))} - ${fmtDayMonthShort(now)}`,
        },
        {
            label: 'Last 60 Days',
            value: {
                from: addDays(now, -60),
                to: now,
            },
            rangeLabel: `${fmtDayMonthShort(addDays(now, -60))} - ${fmtDayMonthShort(now)}`,
        },
        {
            label: 'Last 90 Days',
            value: {
                from: addDays(now, -90),
                to: now,
            },
            rangeLabel: `${fmtDayMonthShort(addDays(now, -90))} - ${fmtDayMonthShort(now)}`,
            spacer: true,
        },
        {
            label: 'Last Week',
            value: {
                from: startOfLastWeek,
                to: endOfWeek(startOfLastWeek),
            },
            rangeLabel: `${fmtDayMonthShort(startOfLastWeek)} - ${fmtDayOfMonth(endOfWeek(startOfLastWeek))}`,
        },
        {
            label: 'Last Month',
            value: {
                from: startOfLastMonth,
                to: endOfLastMonth,
            },
            rangeLabel: `${fmtDayMonthShort(startOfLastMonth)} - ${fmtDayMonthShort(new Date(now.getFullYear(), now.getMonth(), 0))}`,
        },
        {
            label: 'Last Quarter',
            value: {
                from: startOfLastQuarter,
                to: endOfLastQuarter,
            },
            rangeLabel: `${fmtDayMonthShort(new Date(now.getFullYear(), Math.floor((now.getMonth() - 3) / 3) * 3, 1))} - ${fmtDayMonthShort(new Date(now.getFullYear(), Math.floor(now.getMonth() / 3) * 3, 0))}`,
        },
        {
            label: 'Last Year',
            value: {
                from: startOfLastYear,
                to: endOfLastYear,
            },
            rangeLabel: `${fmtDayMonthShort(startOfLastYear)} - ${fmtDayMonthShort(endOfLastYear)}`,
            spacer: true,
        },

        {
            label: 'Week to date',
            value: {
                from: startOfWeek(now),
                to: now,
            },
            rangeLabel: `${fmtDayMonthShort(startOfWeek(now))} - ${fmtDayMonthShort(now)}`,
        },
        {
            label: 'Month to date',
            value: {
                from: startOfMonth(now),
                to: now,
            },
            rangeLabel: `${fmtDayMonthShort(startOfMonth(now))} - ${fmtDayMonthShort(now)}`,
        },
        {
            label: 'Quarter to date',
            value: {
                from: startOfQuarter(now),
                to: now,
            },
            rangeLabel: `${fmtDayMonthShort(startOfQuarter(now))} - ${fmtDayMonthShort(now)}`,
        },
        {
            label: 'Year to date',
            value: {
                from: startOfYear(now),
                to: endOfDay(now),
            },
            rangeLabel: `${fmtDayMonthShort(startOfYear(now))} - ${fmtDayMonthShort(endOfDay(now))}`,
        },
    ];

    return presets;
};

const Presets = ({
    presetSelected,
    selected,
    className,
    style,
}: {
    presetSelected: (opt: PresetOption) => void;
    selected: string | undefined;
    className: string;
} & React.HTMLAttributes<HTMLDivElement>) => {
    const presets = presetOptions();
    return (
        <ScrollArea className={className} style={style}>
            {presets.map(preset => (
                <button
                    onClick={() => presetSelected(preset)}
                    key={preset.label}
                    className={cn(
                        'py-sm px-md block text-start w-full rounded-sm border border-transparent first:mt-[2px]',
                        preset.spacer ? 'mb-lg' : 'mb-sm',
                        selected === preset.label ? 'shadow border-grey bg-white' : 'hover:bg-bg-elevated',
                    )}
                >
                    <div
                        className={cn(
                            'text-body font-medium text-[13px]',
                            selected === preset.label && 'text-blue-900',
                        )}
                    >
                        {preset.label}
                    </div>
                    <div className="text-body-subtle text-xs">{preset.rangeLabel}</div>
                </button>
            ))}
        </ScrollArea>
    );
};

export const DatePickerWithRange = ({
    className,
    date,
    onChange,
    numberOfMonths = 2,
    presets,
}: DatePickerWithRangeProps) => {
    const firstPreset = presetOptions()[0];
    const [presetOption, setPresetOption] = useState<PresetOption | undefined>(presets ? firstPreset : undefined);

    const handleSelect: SelectRangeEventHandler = range => {
        setPresetOption(undefined);
        onChange(range);
    };

    const handleClear = () => {
        onChange(undefined);
        if (presets) {
            setPresetOption(firstPreset);
        }
    };

    const handlePreset = (opt: PresetOption) => {
        setPresetOption(opt);
        onChange(opt.value);
    };

    const calRef = useRef<HTMLDivElement>(null);
    return (
        <div className={cn('grid gap-2', className)}>
            <div className="bg-steel-600 border-grey border-[0.5px] shadow flex rounded-[6px] gap-[0.5px]">
                <Popover>
                    <PopoverTrigger asChild>
                        <Button
                            size="sm"
                            className={cn(
                                'justify-start text-left font-normal rounded-l-[6px] rounded-r-none w-full border-none shadow-none cursor-default',
                                !date && 'text-muted-foreground',
                            )}
                        >
                            <span className="w-full">
                                {presetOption ? (
                                    <>{presetOption.label}</>
                                ) : date?.from ? (
                                    date.to ? (
                                        <>
                                            {fmtDate(date.from)} - {fmtDate(date.to)}
                                        </>
                                    ) : (
                                        fmtDate(date.from)
                                    )
                                ) : (
                                    <div className="text-body-subtle flex items-center gap-sm">
                                        <CalendarDays className="size-3.5" />
                                        Date
                                    </div>
                                )}
                            </span>
                            <ChevronDown className="text-body-subtle shrink-0" />
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-sm" align="start">
                        <div className="flex">
                            {presets && (
                                <Presets
                                    presetSelected={handlePreset}
                                    selected={presetOption?.label}
                                    className="bg-bg-surface rounded-l-md border-grey border-r-[0.5px] p-md"
                                    style={{ height: calRef?.current?.offsetHeight ?? '310px' }}
                                />
                            )}
                            <div ref={calRef}>
                                <Calendar
                                    initialFocus
                                    mode="range"
                                    defaultMonth={date?.from}
                                    selected={date}
                                    onSelect={handleSelect}
                                    numberOfMonths={numberOfMonths}
                                />
                            </div>
                        </div>
                    </PopoverContent>
                </Popover>
                {(date.from || presetOption) && (
                    <Button
                        size="sm"
                        onClick={handleClear}
                        className="cursor-default rounded-r-[6px] rounded-l-none border-none shadow-none text-body-subtle min-w-[30px] w-[30px]"
                    >
                        <X className="size-3.5" />
                    </Button>
                )}
            </div>
        </div>
    );
};
