import { buttonVariants } from '@ui/button';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import type * as React from 'react';
import { DayPicker } from 'react-day-picker';

import { cn } from '@/lib/styling';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({ className, classNames, showOutsideDays = true, numberOfMonths, ...props }: CalendarProps) {
    return (
        <DayPicker
            showOutsideDays={showOutsideDays}
            className={cn('p-lg', className)}
            classNames={{
                months: 'flex flex-col sm:flex-row space-y-lg sm:space-x-lg sm:space-y-0',
                month: 'space-y-lg',
                caption: 'flex justify-center pt-1 relative items-center',
                caption_label: 'text-sm font-medium',
                nav: 'space-x-1 flex items-center',
                nav_button: cn(buttonVariants({ variant: 'default' }), ''),
                nav_button_previous: cn(buttonVariants({ variant: 'default' }), 'absolute left-1'),
                nav_button_next: cn(buttonVariants({ variant: 'default' }), 'absolute right-1'),
                table: 'w-full border-collapse',
                head_row: 'flex',
                head_cell: 'text-body-subtle size-10 font-normal',
                row: 'flex w-full',
                cell: 'size-10 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
                day: 'size-10 bg-bg hover:bg-bg-blue-primary hover:text-body-blue-primary',
                day_today: 'border-[0.5px] border-grey rounded-full',
                day_selected: '',
                day_range_start:
                    '!rounded-l-sm rounded-r-none bg-bg-blue-secondary !text-body-blue-secondary hover:bg-bg-blue-secondary',
                day_range_middle: 'bg-bg-blue-primary !text-body-blue-primary !rounded-none !border-blue',
                day_range_end:
                    '!rounded-r-sm rounded-l-none bg-bg-blue-secondary !text-body-blue-secondary hover:bg-bg-blue-secondary hover:text-body-blue-primary',
                day_outside: 'text-body-subtle',
                day_disabled: 'text-body-subtle',
                day_hidden: 'invisible',
                ...classNames,
            }}
            components={{
                IconLeft: () => <ChevronLeft className="size-4" />,
                IconRight: () => <ChevronRight className="size-4" />,
            }}
            {...props}
        />
    );
}
Calendar.displayName = 'Calendar';

export { Calendar };
