import { useMutation } from '@lib/hooks/useMutation';
import { type DefaultError, useQueryClient } from '@tanstack/react-query';
import type { AppPolicyPostWrapper } from 'lib/models/app_policy';
import type { AppPolicy } from 'lib/prisma/types';
import { refetch } from './cache';
export const usePolicyPost = (slug?: string, onSuccess?: (id: string) => void) => {
    const queryClient = useQueryClient();
    return useMutation<{ policy: AppPolicy }, DefaultError, AppPolicyPostWrapper>({
        url: `/api/v1/policies`,
        method: 'POST',
        successToast: {
            message: 'Policy created',
        },
        errorToast: {
            message: 'Problem creating policy',
        },
        onSuccess: data => {
            refetch(queryClient, slug);
            if (onSuccess) {
                onSuccess(data.policy.id);
            }
        },
    });
};
