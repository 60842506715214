import { Brow } from '@/components/Brow';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { usePlaybookCreate } from '@/hooks/mutations/usePlaybook';
import { useActions } from '@/hooks/queries/useActions';
import { usePlaybookTemplates, usePlaybooks } from '@/hooks/queries/usePlaybook';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import type { PLAYBOOK_DETAILS_TYPE } from '@/routes/paths';
import { ACTIONS, PLAYBOOKS, PLAYBOOK_TEMPLATES } from '@/routes/paths';
import { ACTIONS_CREATE } from '@/routes/paths';
import { SkeletonPlaybook } from '@ui/skeleton';
import { getProviderAssets } from 'lib/3p';
import { FlagKey } from 'lib/flags/keys';
import { capitalize } from 'lodash-es';
import { BookCopy, BookDashed, BookOpenText } from 'lucide-react';
import { Box, Boxes, Plus, Search } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { NewPlaybookCard, PlaybookCard } from './PlaybookCard';
import { PlaybookTemplates } from './PlaybookTemplates';
import { ActionCard } from './actions/ActionCard';
export const Playbooks = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [debouncedSearch, setDebouncedSearch] = useState('');

    const { data, isLoading } = usePlaybooks();
    const { data: templates } = usePlaybookTemplates();
    const { data: actionData } = useActions(debouncedSearch);

    const isPlaybookTemplatesEnabled = useFeatureFlag(FlagKey.PlaybookTemplates);

    const createPlaybook = usePlaybookCreate();
    const resourceCount = data?.playbooks ? data.playbooks.length : null;

    const [search, setSearch] = useState<string>('');

    const [, cancel] = useDebounce(
        () => {
            setDebouncedSearch(search);
        },
        250,
        [search],
    );

    useEffect(() => {
        () => cancel();
    }, [cancel]);

    /* Only sort rule is custom is on top */
    const sortedActions = Object.entries(actionData?.actions ?? {}).sort(([providerA], [providerB]) =>
        providerA === 'custom' ? -1 : providerB === 'custom' ? 1 : 0,
    );
    const resourceCountActions = actionData?.actions ? Object.values(actionData?.actions ?? {}).flat().length : null;

    const tabsValue = location.pathname.includes(ACTIONS)
        ? 'actions'
        : location.pathname.includes(PLAYBOOK_TEMPLATES)
          ? 'templates'
          : 'playbooks';
    return (
        <>
            <Brow title="Playbooks" icon={<BookCopy />} {...(resourceCount !== null ? { resourceCount } : {})} />
            <div className="flex justify-center overflow-y-auto">
                <div className="w-[640px]">
                    <div className="flex flex-col py-12 gap-md">
                        <div className="gap-md flex items-center">
                            <BookCopy className="size-xl" />
                            <h1 className="text-xl">Playbooks</h1>
                        </div>
                        <p className="text-body-subtle-hover text-base">
                            Create or upload instructions that tell Console how to handle user requests
                        </p>
                        <Tabs value={tabsValue}>
                            <TabsList>
                                <Link to={PLAYBOOKS}>
                                    <TabsTrigger value="playbooks" className="gap-sm">
                                        <BookOpenText />
                                        Playbooks
                                        <Badge>{resourceCount}</Badge>
                                    </TabsTrigger>
                                </Link>
                                {isPlaybookTemplatesEnabled && (
                                    <Link to={PLAYBOOK_TEMPLATES}>
                                        <TabsTrigger value="templates" className="gap-sm">
                                            <BookDashed />
                                            Templates
                                            <Badge>{templates?.templates?.length ?? 0}</Badge>
                                        </TabsTrigger>
                                    </Link>
                                )}
                                <Link to={ACTIONS}>
                                    <TabsTrigger value="actions" className="gap-sm">
                                        <Boxes />
                                        Playbook Actions
                                        <Badge>{resourceCountActions}</Badge>
                                    </TabsTrigger>
                                </Link>
                            </TabsList>
                            <TabsContent value="playbooks" className="pt-8">
                                <div className="gap-lg flex flex-wrap">
                                    <NewPlaybookCard
                                        onClick={() => {
                                            createPlaybook.mutate({});
                                        }}
                                    />
                                    {isLoading ? (
                                        <>
                                            <SkeletonPlaybook />
                                        </>
                                    ) : (
                                        data?.playbooks.map(playbook => (
                                            <PlaybookCard
                                                key={playbook.id}
                                                id={playbook.id}
                                                onClick={() => {
                                                    navigate(`${PLAYBOOKS}/${playbook.slug}` as PLAYBOOK_DETAILS_TYPE);
                                                }}
                                                name={playbook.name}
                                                state={playbook.state}
                                                creator={playbook.createdBy}
                                                recentVersion={playbook.versions[0]}
                                            />
                                        ))
                                    )}
                                </div>
                            </TabsContent>
                            {isPlaybookTemplatesEnabled && (
                                <TabsContent value="templates" className="pt-8">
                                    <PlaybookTemplates />
                                </TabsContent>
                            )}
                            <TabsContent value="actions" className="pt-8">
                                <div className="flex flex-col gap-md">
                                    <div className="flex items-center gap-lg pb-8">
                                        <Input
                                            icon={<Search />}
                                            placeholder="Search actions"
                                            onChange={e => setSearch(e.target.value)}
                                        />
                                        <Button className="shrink-0" onClick={() => navigate(ACTIONS_CREATE)}>
                                            <Plus />
                                            New Action
                                        </Button>
                                    </div>
                                    <div className="gap-xl flex flex-wrap">
                                        {actionData?.actions &&
                                            sortedActions.map(([provider, actions]) => (
                                                <div key={provider} className="flex flex-col w-full gap-md">
                                                    <div className="flex items-center gap-md p-md">
                                                        {provider === 'custom' ? (
                                                            <Box />
                                                        ) : (
                                                            <img
                                                                src={getProviderAssets(provider).logo}
                                                                alt={provider}
                                                                className="size-4"
                                                            />
                                                        )}
                                                        <span className="text-body-subtle text-xs font-medium">
                                                            {capitalize(provider)} Actions
                                                        </span>
                                                    </div>
                                                    <div className="grid grid-cols-2 gap-lg">
                                                        {actions.map(action => (
                                                            <ActionCard key={action.id} action={action} />
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </TabsContent>
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    );
};
