import { cn } from '@/lib/styling';
import type { CnslCategory } from 'lib/prisma/types';

export const RequestCategoryChip = ({
    category,
    className,
}: {
    category?: CnslCategory | null;
    className?: string;
}) => {
    return (
        <div className={cn('flex items-center gap-md', className)}>
            {category ? (
                <>
                    <div style={{ backgroundColor: category.color }} className="size-md rounded-[2px] shrink-0" />
                    <span>{category.name}</span>
                </>
            ) : (
                <span>-</span>
            )}
        </div>
    );
};
