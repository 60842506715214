import { valibotResolver } from '@hookform/resolvers/valibot';
import { Tooltip, TooltipContent, TooltipTrigger } from '@ui/tooltip';
import { IntegrationId } from 'lib/integration';
import type {
    RequestProviderConfigRes,
    RequestProviderFieldRes,
    SchemaRequestProviderField,
} from 'lib/models/settings/requests';
import { SchemaRequestCanBeAutoCreated, SchemaRequestProviderFieldReq } from 'lib/models/settings/requests';
import {
    RequestFieldPopulationType,
    RequestFieldType,
    type RequestFieldTypeType,
    type TicketProviderType,
} from 'lib/prisma/enums';
import { Eye, EyeOff, PenLine, Settings, Space, Sparkles } from 'lucide-react';
import { Fragment, type ReactNode, useCallback, useEffect, useState } from 'react';
import { type ControllerRenderProps, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import type * as v from 'valibot';

import ComboboxPopover from '@/components/ComboboxPopover';
import FlaggedFeature from '@/components/FlaggedFeature';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
    Label,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useIntegrationSettingsSync } from '@/hooks/queries/integrations/useIntegrationSettingsStatus';
import {
    useSettingRequest,
    useSettingRequestConfig,
    useSettingRequestConfigUpdate,
    useSettingRequestUpdate,
} from '@/hooks/queries/useSettingRequest';
import { cn } from '@/lib/styling';
import { Switch } from '@ui/switch';
import { ProviderAssets } from 'lib/3p';
import { FlagKey } from 'lib/flags';
import { isApiError } from 'lib/models/index';
import { toast } from 'sonner';
import { safeParse } from 'valibot';

/* TODO should be able to replace with <T extends FieldValues>(f: T) => ... after eslint/ts fix */
// biome-ignore lint/suspicious/noExplicitAny: see todo
type FieldProps = ControllerRenderProps<any, any>;

const CheckboxComponent = (f: FieldProps) => (
    <div className="gap-sm flex items-center">
        Checked:
        <Checkbox checked={f.value} onCheckedChange={f.onChange} />
    </div>
);
const TextComponent = (f: FieldProps) => <Input className="w-[200px]" {...f} />;
const NumberComponent = (f: FieldProps) => <Input type="number" className="w-[200px]" {...f} />;
const DateComponent = (f: FieldProps) => <Input type="date" className="w-[200px]" {...f} />;
const SelectComponent = (
    f: FieldProps,
    options: { label: string; value: unknown }[],
    fieldType: RequestFieldTypeType,
) => (
    <>
        {/* TODO remove options cast after ComboboxPopover pass */}
        <ComboboxPopover
            isMultiple={fieldType === RequestFieldType.MULTISELECT}
            disabled={f.value === RequestFieldType.UNSUPPORTED}
            TriggerWrapper={FormControl}
            defaultValue={f.value}
            options={options as { label: string; value: string }[]}
            onChange={f.onChange}
        />
        <FormMessage />
    </>
);

const fieldTypes = {
    [RequestFieldType.TEXTAREA]: TextComponent,
    [RequestFieldType.INPUT]: TextComponent,
    [RequestFieldType.DATEPICKER]: DateComponent,
    [RequestFieldType.NUMBER_INPUT]: NumberComponent,
    [RequestFieldType.SELECT]: SelectComponent,
    [RequestFieldType.MULTISELECT]: SelectComponent,
    [RequestFieldType.BOOLEAN_INPUT]: CheckboxComponent,
    // this should never render a component
    [RequestFieldType.UNSUPPORTED]: () => <div />,
};

const OptionWrapper = ({ label, icon }: { label: string; icon: ReactNode }) => {
    return (
        <div>
            <div className="gap-sm flex items-center">
                {icon && icon}
                <span>{label}</span>
            </div>
        </div>
    );
};

const options: {
    value: string;
    label: JSX.Element | string;
}[] = [
    { value: 'SKIP', label: <OptionWrapper label="Skip Field" icon={<Space />} /> },
    { value: 'AI_GENERATED', label: <OptionWrapper label="Ask User" icon={<Sparkles />} /> },
    { value: 'HARDCODED', label: <OptionWrapper label="Set Value" icon={<PenLine />} /> },
];

const fieldToFormValues = (
    fields: RequestProviderFieldRes['fields'],
): v.InferInput<typeof SchemaRequestProviderField>[] => {
    return fields.map(f => ({
        ...f,
        populationType: f.populationType || RequestFieldPopulationType.SKIP,
    }));
};

const PROVIDER_NAMESPACE_ID = ['workspace_id'];

const isProviderNamespace = (f: string) => PROVIDER_NAMESPACE_ID.includes(f);

const RequestManagementForm = ({
    provider,
    namespace,
    config,
    ticketType,
}: {
    provider: IntegrationId;
    namespace: string;
    config: RequestProviderConfigRes['config'];
    ticketType: string | null | undefined;
}) => {
    const [canBeAutoCreated, setCanBeAutoCreated] = useState<boolean | null>(null);
    const [disabledAutoCreateReason, _setDisabledAutoCreateReason] = useState<string | null>(null);
    const [triggerState, _setTriggerState] = useState<boolean>(config.autoCreate);
    const [formLoaded, setFormLoaded] = useState<boolean>(false);
    const { data, isLoading, refetch } = useSettingRequest(
        provider,
        namespace ? [namespace] : undefined,
        ticketType ?? undefined,
    );

    const updateMutation = useSettingRequestUpdate(provider, {
        onSuccess: refetch,
    });
    const updateConfigMutation = useSettingRequestConfigUpdate();

    const form = useForm<v.InferInput<typeof SchemaRequestProviderFieldReq>>({
        resolver: valibotResolver(SchemaRequestProviderFieldReq),
    });

    const onSubmit = (values: v.InferInput<typeof SchemaRequestProviderFieldReq>) => {
        updateMutation.mutate(values, {
            onSuccess: () => {
                toast.success('Request form updated');
            },
        });
    };

    useEffect(() => {
        if (isApiError(data)) {
            return;
        }
        if (data?.fields) {
            form.reset({ fields: fieldToFormValues(data?.fields ?? []) });
            setFormLoaded(true);
        }
    }, [data, form]);

    const watchAllFields = form.watch();

    const setTriggerState = useCallback(
        (v: boolean) => {
            _setTriggerState(old => {
                if (old !== v) {
                    updateConfigMutation.mutate({
                        provider: config.provider,
                        namespace: config.namespace,
                        autoCreate: v,
                    });
                }
                return v;
            });
        },
        [config, updateConfigMutation],
    );

    const setDisabledAutoCreateReason = useCallback(
        (v: string | null) => {
            _setDisabledAutoCreateReason(old => {
                if (!old && v) {
                    setTriggerState(false);
                }
                return v;
            });
        },
        [setTriggerState],
    );

    useEffect(() => {
        if (watchAllFields && formLoaded) {
            const result = safeParse(SchemaRequestCanBeAutoCreated, watchAllFields);
            if (result.success !== canBeAutoCreated) {
                setCanBeAutoCreated(result.success);
            }
            if (!result.success) {
                setDisabledAutoCreateReason(result.issues.map(i => i.message).join(', '));
            } else {
                setDisabledAutoCreateReason(null);
            }
        }
    }, [watchAllFields, canBeAutoCreated, setDisabledAutoCreateReason, formLoaded]);

    if (!data || isLoading || isApiError(data)) {
        return;
    }

    const handleNamespaceUpdate = (v: string | undefined) => {
        if (v) {
            updateConfigMutation.mutate({
                provider: provider as TicketProviderType,
                namespace: v,
                ticketType: undefined,
                autoCreate: false,
            });
        }
    };

    const handleTicketTypeUpdate = (v: string | undefined) => {
        updateConfigMutation.mutate({
            provider: provider as TicketProviderType,
            namespace,
            ticketType: v,
            autoCreate: false,
        });
    };

    return (
        <div>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className="gap-lg pb-xl flex flex-col">
                        <div
                            className={`gap-lg bg-bg-overlay p-lg border-grey flex flex-col ${
                                !data.ticketTypes ? 'border-b-[0.5px]' : ''
                            } pt-0`}
                        >
                            {namespace !== null && data.namespaceField && (
                                <div className="flex items-center justify-between">
                                    <div className="mr-[57px]  overflow-hidden">
                                        <FormLabel required={data.namespaceField.required}>
                                            {data.namespaceField.fieldDisplay}
                                        </FormLabel>
                                        <FormDescription>{data.namespaceField.fieldDescription}</FormDescription>
                                    </div>

                                    <ComboboxPopover
                                        className="min-w-[150px]"
                                        constrainWidth
                                        TriggerWrapper={FormControl}
                                        defaultValue={`${namespace}`}
                                        options={data.namespaceField.options.map(o => ({
                                            value: `${o.value}`,
                                            label: o.label,
                                        }))}
                                        onChange={handleNamespaceUpdate}
                                    />
                                </div>
                            )}
                            {provider === IntegrationId.Jira && data.ticketTypes?.length ? (
                                <div className="flex items-center justify-between">
                                    <div className="mr-[57px] w-full overflow-hidden">
                                        <FormLabel required={true}>Jira Issue Type</FormLabel>
                                        <FormDescription>
                                            Select the Jira Issue Type to use for this request
                                        </FormDescription>
                                    </div>
                                    <ComboboxPopover
                                        className="w-full"
                                        constrainWidth
                                        TriggerWrapper={FormControl}
                                        defaultValue={ticketType ?? undefined}
                                        options={data.ticketTypes.map(o => ({
                                            value: `${o.id}`,
                                            label: o.name,
                                        }))}
                                        onChange={handleTicketTypeUpdate}
                                    />
                                </div>
                            ) : null}
                            {namespace && (
                                <FlaggedFeature flag={FlagKey.SeamlessHandoff}>
                                    <div className="w-full flex items-top gap-md">
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <div className="w-fit">
                                                    <Switch
                                                        checked={triggerState}
                                                        onCheckedChange={setTriggerState}
                                                        disabled={!canBeAutoCreated}
                                                    />
                                                </div>
                                            </TooltipTrigger>
                                            {disabledAutoCreateReason && (
                                                <TooltipContent>{disabledAutoCreateReason}</TooltipContent>
                                            )}
                                        </Tooltip>
                                        <div className="gap-sm flex flex-col">
                                            <Label className="font-medium">Seamless Handoff</Label>
                                            <p className="text-body-subtle">
                                                Allow Console to auto-create tickets from conversation data, skipping
                                                user forms. Only works with ticket forms that have subject and
                                                description fields only.
                                            </p>
                                        </div>
                                    </div>
                                </FlaggedFeature>
                            )}
                        </div>
                        {data.fields.map((t, i) => (
                            <div key={t.fieldKey} className="gap-lg px-lg flex items-center">
                                <Fragment key={t.fieldKey}>
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <Button
                                                type="button"
                                                mode="borderless"
                                                onClick={() =>
                                                    form.setValue(
                                                        `fields.${i}.visible`,
                                                        !watchAllFields?.fields?.[i]?.visible,
                                                        { shouldDirty: true },
                                                    )
                                                }
                                                aria-label={watchAllFields?.fields?.[i]?.visible ? 'Hide' : 'Show'}
                                                disabled={['HANDLEDBYCONSOLE', 'HANDLEDBYPROVIDER'].includes(
                                                    watchAllFields?.fields?.[i]?.populationType,
                                                )}
                                            >
                                                {watchAllFields?.fields?.[i]?.visible ? (
                                                    <Eye />
                                                ) : (
                                                    <EyeOff className="text-body-subtle" />
                                                )}
                                            </Button>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            {watchAllFields?.fields?.[i]?.visible ? 'Hide field' : 'Show field'}
                                        </TooltipContent>
                                    </Tooltip>
                                    <FormField
                                        control={form.control}
                                        name={
                                            isProviderNamespace(t.fieldKey)
                                                ? `fields.${i}.default`
                                                : `fields.${i}.populationType`
                                        }
                                        render={({ field }) => {
                                            let os =
                                                t.fieldType === 'UNSUPPORTED'
                                                    ? options.filter(o => o.value !== 'HARDCODED')
                                                    : options;
                                            os =
                                                field.value === 'HANDLEDBYCONSOLE'
                                                    ? [
                                                          {
                                                              value: 'HANDLEDBYCONSOLE',
                                                              label: 'Handled by Console',
                                                          },
                                                      ]
                                                    : field.value === 'HANDLEDBYPROVIDER'
                                                      ? [
                                                            {
                                                                value: 'HANDLEDBYPROVIDER',
                                                                label: 'Handled by Provider',
                                                            },
                                                        ]
                                                      : options;
                                            return (
                                                <>
                                                    <FormItem className="gap-lg flex min-h-12 w-2/3 shrink-0 items-center">
                                                        <div className="w-full overflow-hidden">
                                                            <FormLabel
                                                                className={cn(
                                                                    watchAllFields?.fields?.[i]?.visible
                                                                        ? ''
                                                                        : 'text-body-subtle',
                                                                )}
                                                                required={t.required}
                                                                info={
                                                                    field.value === 'HANDLEDBYCONSOLE'
                                                                        ? 'The mapping for this field is handled by Console'
                                                                        : field.value === 'HANDLEDBYPROVIDER'
                                                                          ? 'The mapping for this field is handled by the provider'
                                                                          : t.fieldType === 'UNSUPPORTED'
                                                                            ? 'This field is unsupported'
                                                                            : ''
                                                                }
                                                            >
                                                                {t.fieldDisplay}
                                                            </FormLabel>
                                                            <FormDescription>{t.fieldDescription}</FormDescription>
                                                        </div>
                                                        <div className="gap-md !mt-0 flex h-8 w-full flex-col">
                                                            {!!field.value && (
                                                                <ComboboxPopover
                                                                    constrainWidth
                                                                    disabled={
                                                                        field.value === 'HANDLEDBYCONSOLE' ||
                                                                        field.value === 'HANDLEDBYPROVIDER' ||
                                                                        t.fieldType === 'UNSUPPORTED'
                                                                    }
                                                                    TriggerWrapper={FormControl}
                                                                    defaultValue={field.value}
                                                                    value={field.value}
                                                                    options={os}
                                                                    onChange={field.onChange}
                                                                />
                                                            )}
                                                            <FormMessage />
                                                        </div>
                                                    </FormItem>
                                                </>
                                            );
                                        }}
                                    />
                                    {watchAllFields?.fields?.[i]?.populationType === 'HARDCODED' ? (
                                        <FormField
                                            control={form.control}
                                            name={`fields.${i}.default`}
                                            render={({ field }) => (
                                                <FormItem className="h-8 w-1/3">
                                                    <FormControl>
                                                        {fieldTypes[t.fieldType] &&
                                                            fieldTypes[t.fieldType](
                                                                field,
                                                                t.options || [],
                                                                t.fieldType,
                                                            )}
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    ) : (
                                        <div className="h-8 w-1/3 shrink-0" />
                                    )}
                                </Fragment>
                            </div>
                        ))}
                    </div>
                    <div className="p-lg border-grey col-span-3 col-start-1 flex items-center justify-between border-t-[0.5px]">
                        <Button
                            type="button"
                            mode="borderless"
                            disabled={!form.formState.isDirty || form.formState.isSubmitting}
                            onClick={e => {
                                form.reset({ fields: fieldToFormValues(data.fields) });
                                e.stopPropagation();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="blue"
                            mode="dark"
                            type="submit"
                            disabled={!form.formState.isDirty || form.formState.isSubmitting}
                        >
                            Save
                        </Button>
                    </div>
                </form>
            </Form>
        </div>
    );
};

const RequestManagement = () => {
    const { data } = useSettingRequestConfig();
    const { data: syncData } = useIntegrationSettingsSync();
    const updateConfig = useSettingRequestConfigUpdate();
    const navigate = useNavigate();

    if (!data || !syncData) {
        return null;
    }

    const fsFieldsSynced =
        IntegrationId.Freshservice in syncData && syncData[IntegrationId.Freshservice].status !== null;
    const zdFieldsSynced = IntegrationId.Zendesk in syncData && syncData[IntegrationId.Zendesk].status !== null;
    const jiraFieldsSynced = IntegrationId.Jira in syncData && syncData[IntegrationId.Jira].status !== null;

    const options = [
        ...(fsFieldsSynced ? [{ value: IntegrationId.Freshservice as string, label: 'Freshservice' }] : []),
        ...(zdFieldsSynced ? [{ value: IntegrationId.Zendesk as string, label: 'Zendesk' }] : []),
        ...(jiraFieldsSynced ? [{ value: IntegrationId.Jira as string, label: 'Jira' }] : []),
    ];

    const handleChange = (v: string | undefined) => {
        if (v) {
            updateConfig.mutate({ provider: v as TicketProviderType, namespace: '', autoCreate: false });
        }
    };

    const { provider, namespace, ticketType } = data.config ?? {
        provider: undefined,
        namespace: undefined,
        ticketType: undefined,
    };
    const providerLogo = ProviderAssets[provider as keyof typeof ProviderAssets]?.logo;

    return (
        <div className="gap-lg flex flex-col">
            <div className="gap-sm flex flex-col">
                <div className="w-full">
                    <Label className="font-medium">Ticket provider</Label>
                    <p className="text-body-subtle">Select from your linked ticket providers</p>
                </div>
                <div className="gap-md flex">
                    <ComboboxPopover
                        constrainWidth
                        className="w-1/3"
                        defaultValue={provider}
                        options={options}
                        onChange={handleChange}
                        icon={providerLogo ? <img src={providerLogo} alt={`${provider} logo`} /> : null}
                    />
                    <Button onClick={() => navigate('organization/integrations')}>
                        <Settings />
                    </Button>
                </div>
            </div>
            <div className="bg-bg border-grey overflow-hidden rounded-md border-[0.5px]">
                <div className="p-lg bg-bg-overlay">
                    <div>
                        <h3 className="text-base">Configure your request form</h3>
                        <p className="text-body-subtle-hover">
                            Toggle on the fields you want the user to fill out in the Slack form. For hidden fields, you
                            can determine whether or not to let Console handle it, set a pre-determined value or leave
                            it blank.
                        </p>
                    </div>
                </div>
                {provider && data.config && namespace !== null && (
                    <RequestManagementForm
                        provider={provider as IntegrationId}
                        namespace={namespace}
                        ticketType={ticketType}
                        config={data.config}
                    />
                )}
            </div>
        </div>
    );
};

export default RequestManagement;
