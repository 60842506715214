import { cn } from '@/lib/styling';
import React, { useCallback, useEffect, useRef } from 'react';

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    autoResize?: boolean;
    minHeight?: string;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
    ({ className, autoResize = false, minHeight = '30px', style, onChange, ...props }, ref) => {
        const textareaRef = useRef<HTMLTextAreaElement | null>(null);
        const combinedRef = useCallback(
            (element: HTMLTextAreaElement | null) => {
                textareaRef.current = element;
                if (typeof ref === 'function') {
                    ref(element);
                } else if (ref && 'current' in ref) {
                    (ref as React.MutableRefObject<HTMLTextAreaElement | null>).current = element;
                }
            },
            [ref],
        );

        const autoResizeTextarea = useCallback(() => {
            if (textareaRef.current && autoResize) {
                textareaRef.current.style.height = minHeight;
                textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
            }
        }, [autoResize, minHeight]);

        useEffect(() => {
            if (autoResize) {
                autoResizeTextarea();
            }
        }, [autoResize, autoResizeTextarea]);

        const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            if (onChange) {
                onChange(e);
            }
            if (autoResize) {
                autoResizeTextarea();
            }
        };

        return (
            <textarea
                className={cn(
                    'flex w-full rounded-sm border-[0.5px] border-grey bg-bg px-md py-sm placeholder:text-body-subtle disabled:cursor-not-allowed disabled:opacity-50 hover:border-grey-hover focus-visible:outline-none',
                    autoResize && 'resize-none',
                    className,
                )}
                ref={combinedRef}
                onChange={handleChange}
                style={{
                    ...style,
                    minHeight: autoResize ? minHeight : style?.minHeight || '30px',
                }}
                {...props}
            />
        );
    },
);

Textarea.displayName = 'Textarea';

export { Textarea };
