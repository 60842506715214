import { useMutation } from '@lib/hooks/useMutation';
import { type DefaultError, useQueryClient } from '@tanstack/react-query';

export const useGroupsUsersDel = ({ slug, userId }: { slug: string; userId: string }) => {
    const queryClient = useQueryClient();
    return useMutation<void, DefaultError, void>({
        url: `/api/v1/groups/${slug}/user/${userId}`,
        method: 'DELETE',
        onSuccess: async () => {
            queryClient.refetchQueries({ queryKey: [`/api/v1/groups/${slug}`] });
        },
        successToast: {
            message: 'User removed from group',
        },
    });
};
