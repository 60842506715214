import { renderEventMessage } from '@/lib/cnsl_events';
import { fmtDateFull } from '@lib/dates';
import type { ColumnDef, ColumnResizeMode } from '@tanstack/react-table';
import { Table } from '@ui/table/table';
import type { GetEventsResponse } from 'lib/models/event';
import type { CnslApp, CnslUser } from 'lib/prisma/types';
import { MousePointerClick, Radio, Settings } from 'lucide-react';

import { Brow } from '@/components/Brow';
import { ResLink } from '@/components/ResLink';
import { TableEmptyState } from '@/components/TableEmptyState';
import { Button } from '@/components/ui/button';
import { LIMIT_DEFAULT, THRESHOLD_DEFAULT } from '@/lib/paginationConstants';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { RequestEvents } from '../requests/details/Main';

const columns: ColumnDef<GetEventsResponse['items'][number]>[] = [
    {
        accessorKey: 'provider',
        header: () => (
            <div className="px-md py-sm flex items-center justify-center">
                <Radio className="!ml-[-8px] shrink-0" />
            </div>
        ),
        cell: info => {
            const app = info.getValue() as CnslApp | undefined;
            return (
                <div className="px-md py-sm flex items-center justify-center">
                    <img
                        src={app?.logo || '/console-icon.svg'}
                        alt={app?.name || 'console'}
                        className="size-4 shrink-0"
                    />
                </div>
            );
        },
        size: 0,
        meta: {
            expand: true,
        },
    },
    {
        accessorKey: 'actor',
        header: () => 'Actor',
        cell: info => {
            const user = info.getValue() as CnslUser;
            return (
                <ResLink
                    entity="users"
                    className="h-full px-md"
                    id={user.email}
                    label={user.displayName}
                    src={user?.avatar ?? ''}
                    bold
                />
            );
        },
        meta: {
            expand: true,
        },
    },
    {
        accessorKey: 'details',
        header: () => 'Action',
        cell: info => renderEventMessage(info.row.original as RequestEvents).simple,
    },
    {
        accessorKey: 'createdAt',
        header: () => 'Time',
        cell: info => fmtDateFull(info.getValue() as string),
    },
];

const EmptyState = () => {
    const navigate = useNavigate();

    return (
        <TableEmptyState
            title="Events"
            description="Connect your integrations to start tracking events"
            imageSrc="/emptyStates/empty-events.svg"
        >
            <Button onClick={() => navigate('/settings/organization/integrations')}>
                <Settings />
                Connect your integrations
            </Button>
        </TableEmptyState>
    );
};

const Events = () => {
    const [appCount, setAppCount] = useState<number | null>(null);
    const reactTableOpts = {
        columnResizeMode: 'onChange',
    } as { columnResizeMode: ColumnResizeMode };

    return (
        <>
            <Brow
                title="Events"
                icon={<MousePointerClick />}
                {...(appCount !== null ? { resourceCount: appCount } : {})}
            />
            <Table
                columns={columns}
                reactTableOpts={reactTableOpts}
                pagination={{
                    queryKey: ['events'],
                    limit: LIMIT_DEFAULT,
                    threshold: THRESHOLD_DEFAULT,
                    pathname: '/api/v1/events',
                }}
                onPaginationCountChange={c => setAppCount(c)}
                emptyState={<EmptyState />}
            />
        </>
    );
};

export default Events;
