import { Stacked } from '@/components/Stacked';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/lib/styling';
import type { CnslCategory } from 'lib/prisma/types';
import { components as SelectPrimitiveComponents } from 'react-select';
import type { GroupBase, OptionProps, ValueContainerProps } from 'react-select';

export type CategoryOption = {
    label: string;
    value: CnslCategory | null;
    color: string;
};

const Square = ({ color }: { color: string }) => (
    <div className={cn('size-md rounded-[2px] shrink-0 m-sm', color ? `bg-[${color}]` : 'bg-black')} />
);

export const CategoryOption = <
    Option extends CategoryOption,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
>(
    props: OptionProps<Option, IsMulti, Group>,
) => {
    const { children, ...restProps } = props;

    return (
        <SelectPrimitiveComponents.Option {...restProps}>
            <div className="flex items-center gap-sm">
                <Square color={props.data.color} />
                {children}
            </div>
        </SelectPrimitiveComponents.Option>
    );
};

export const CategoryValuesContainer = <
    Option extends CategoryOption,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
>({
    children,
    ...props
}: ValueContainerProps<Option, IsMulti, Group>) => {
    const size = props.getValue().length;
    const isMulti = props.isMulti;

    return (
        <SelectPrimitiveComponents.ValueContainer {...props}>
            <div className="flex items-center gap-sm">
                {size === 1 && isMulti && (
                    <>
                        <Square color={props.getValue()[0].color} />
                        {props.getValue()[0].label}
                    </>
                )}
                {size > 1 && isMulti && (
                    <>
                        <Stacked
                            items={props.getValue() as Option[]}
                            renderItem={item => <Square color={item.color} />}
                            getTooltipContent={item => item.label}
                        />{' '}
                        <Tooltip>
                            <TooltipTrigger>
                                <div>{`${props.getValue().length} selected`}</div>
                            </TooltipTrigger>
                            <TooltipContent>
                                {props
                                    .getValue()
                                    .map(item => item.label)
                                    .join(', ')}
                            </TooltipContent>
                        </Tooltip>
                    </>
                )}
                {!isMulti && !!props.getValue().length && <Square color={props.getValue()[0].color} />}
                {children}
            </div>
        </SelectPrimitiveComponents.ValueContainer>
    );
};
