import { useMutation } from '@lib/hooks/useMutation';
import { type DefaultError, useQueryClient } from '@tanstack/react-query';
import { refetch } from './cache';
interface PolicyDeleteProps {
    policyId: string;
    appSlug?: string;
    cb?: () => void;
}
export const usePolicyDelete = ({ policyId, appSlug }: PolicyDeleteProps) => {
    const queryClient = useQueryClient();
    return useMutation<undefined, DefaultError, PolicyDeleteProps>({
        url: `/api/v1/policies/${policyId}`,
        method: 'DELETE',
        successToast: {
            message: 'Policy deleted',
        },
        errorToast: {
            message: 'Problem deleting policy',
        },
        onError(error, variables, context) {
            console.error('Error deleting policy', error, variables, context);
        },
        onSuccess: () => {
            refetch(queryClient, appSlug);
        },
    });
};
