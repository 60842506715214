import FocusRing from '@atlaskit/focus-ring';
import type { Instruction, ItemMode } from '@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
    draggable,
    dropTargetForElements,
    monitorForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import type { DragLocationHistory } from '@atlaskit/pragmatic-drag-and-drop/types';
import { type ReactNode, memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import invariant from 'tiny-invariant';

import { cn } from '@/lib/styling';
import { createRoot } from 'react-dom/client';
import { DependencyContext, TreeContext } from './TreeContext';
import { type TreeItem as TreeItemType, tree } from './tree';

const outerHoverStyles = 'rounded cursor-pointer';
const innerDraggingStyles = 'opacity-40';
const parentOfInstructionStyles = 'bg-[rgba(9,30,66,0.06)]';

function getParentLevelOfInstruction(instruction: Instruction): number {
    if (instruction.type === 'instruction-blocked') {
        return getParentLevelOfInstruction(instruction.desired);
    }
    if (instruction.type === 'reparent') {
        return instruction.desiredLevel - 1;
    }
    return instruction.currentLevel - 1;
}

function delay({ waitMs: timeMs, fn }: { waitMs: number; fn: () => void }): () => void {
    let timeoutId: number | null = window.setTimeout(() => {
        timeoutId = null;
        fn();
    }, timeMs);
    return function cancel() {
        if (timeoutId) {
            window.clearTimeout(timeoutId);
            timeoutId = null;
        }
    };
}

interface TreeItemProps {
    item: TreeItemType;
    mode: ItemMode;
    level: number;
    index: number;
    renderItem?: (
        item: TreeItemType,
        level: number,
        index: number,
        itemRef: React.RefObject<HTMLDivElement>,
    ) => ReactNode;
    indentPerLevel: number;
    expanded?: boolean;
    passRef?: boolean;
}

export const TreeItem = memo(function TreeItem({
    item,
    passRef,
    mode,
    level,
    index,
    renderItem,
    indentPerLevel,
    expanded,
}: TreeItemProps) {
    const itemRef = useRef<HTMLDivElement>(null);

    const [state, setState] = useState<'idle' | 'dragging' | 'preview' | 'parent-of-instruction'>('idle');
    const [instruction, setInstruction] = useState<Instruction | null>(null);
    const cancelExpandRef = useRef<(() => void) | null>(null);

    const { dispatch, uniqueContextId, getPathToItem, registerTreeItem, components } = useContext(TreeContext);
    const { DropIndicator, attachInstruction, extractInstruction } = useContext(DependencyContext);
    const toggleOpen = useCallback(() => {
        dispatch({ type: 'toggle', itemId: item.id });
    }, [dispatch, item]);

    useEffect(() => {
        invariant(itemRef.current);

        return registerTreeItem({
            itemId: item.id,
            element: itemRef.current,
        });
    }, [item.id, registerTreeItem]);

    const cancelExpand = useCallback(() => {
        cancelExpandRef.current?.();
        cancelExpandRef.current = null;
    }, []);

    const clearParentOfInstructionState = useCallback(() => {
        setState(current => (current === 'parent-of-instruction' ? 'idle' : current));
    }, []);

    // When an item has an instruction applied
    // we are highlighting it's parent item for improved clarity
    const shouldHighlightParent = useCallback(
        (location: DragLocationHistory): boolean => {
            const target = location.current.dropTargets[0];

            if (!target) {
                return false;
            }

            const instruction = extractInstruction(target.data);

            if (!instruction) {
                return false;
            }

            const targetId = target.data.id;
            invariant(typeof targetId === 'string' || typeof targetId === 'number');

            const path = getPathToItem(targetId);
            const parentLevel: number = getParentLevelOfInstruction(instruction);
            const parentId = path[parentLevel];
            return parentId === item.id;
        },
        [getPathToItem, extractInstruction, item],
    );

    useEffect(() => {
        invariant(itemRef.current);

        function updateIsParentOfInstruction({ location }: { location: DragLocationHistory }) {
            if (shouldHighlightParent(location)) {
                setState('parent-of-instruction');
                return;
            }
            clearParentOfInstructionState();
        }

        return combine(
            draggable({
                element: itemRef.current,
                getInitialData: () => ({
                    id: item.id,
                    type: 'tree-item',
                    isOpenOnDragStart: item.isOpen,
                    uniqueContextId,
                }),
                onGenerateDragPreview: ({ nativeSetDragImage }) => {
                    setCustomNativeDragPreview({
                        getOffset: pointerOutsideOfPreview({ x: '16px', y: '8px' }),
                        render: ({ container }) => {
                            const root = createRoot(container);

                            root.render(<components.Preview item={item} />);
                            return () => root.unmount();
                        },
                        nativeSetDragImage,
                    });
                },
                onDragStart: ({ source }) => {
                    setState('dragging');
                    // collapse open items during a drag
                    if (source.data.isOpenOnDragStart) {
                        dispatch({ type: 'collapse', itemId: item.id });
                    }
                },
                onDrop: ({ source }) => {
                    setState('idle');
                    if (source.data.isOpenOnDragStart) {
                        dispatch({ type: 'expand', itemId: item.id });
                    }
                },
            }),
            dropTargetForElements({
                element: itemRef.current,
                getData: ({ input, element }) => {
                    const data = { id: item.id };

                    return attachInstruction(data, {
                        input,
                        element,
                        indentPerLevel,
                        currentLevel: level,
                        mode,
                        block: item.isDraft ? ['make-child'] : [],
                    });
                },
                canDrop: a => {
                    const { source } = a;

                    return source.data.type === 'tree-item' && source.data.uniqueContextId === uniqueContextId;
                },
                getIsSticky: () => true,
                onDrag: ({ self, source }) => {
                    const instruction = extractInstruction(self.data);

                    if (source.data.id !== item.id) {
                        // expand after 500ms if still merging
                        if (
                            instruction?.type === 'make-child' &&
                            tree.hasChildren(item) &&
                            !item.isOpen &&
                            !cancelExpandRef.current
                        ) {
                            cancelExpandRef.current = delay({
                                waitMs: 500,
                                fn: () => dispatch({ type: 'expand', itemId: item.id }),
                            });
                        }
                        if (instruction?.type !== 'make-child' && cancelExpandRef.current) {
                            cancelExpand();
                        }

                        setInstruction(instruction);
                        return;
                    }
                    if (instruction?.type === 'reparent') {
                        setInstruction(instruction);
                        return;
                    }
                    setInstruction(null);
                },
                onDragLeave: () => {
                    cancelExpand();
                    setInstruction(null);
                },
                onDrop: () => {
                    cancelExpand();
                    setInstruction(null);
                },
            }),
            monitorForElements({
                canMonitor: ({ source }) => source.data.uniqueContextId === uniqueContextId,
                onDragStart: updateIsParentOfInstruction,
                onDrag: updateIsParentOfInstruction,
                onDrop() {
                    clearParentOfInstructionState();
                },
            }),
        );
    }, [
        dispatch,
        item,
        mode,
        level,
        cancelExpand,
        uniqueContextId,
        extractInstruction,
        attachInstruction,
        clearParentOfInstructionState,
        shouldHighlightParent,
        indentPerLevel,
        components,
    ]);

    useEffect(
        function mount() {
            return function unmount() {
                cancelExpand();
            };
        },
        [cancelExpand],
    );

    const aria = (() => {
        if (!tree.hasChildren(item)) {
            return undefined;
        }
        return {
            'aria-expanded': item.isOpen,
            'aria-controls': `tree-item-${item.id}--subtree`,
        };
    })();

    return (
        <>
            <div className={cn([state === 'idle' ? outerHoverStyles : undefined])} style={{ position: 'relative' }}>
                <FocusRing isInset>
                    <div
                        {...aria}
                        className={
                            'w-full relative bg-transparent m-0 p-0 rounded cursor-pointer border-0 text-current'
                        }
                        id={`tree-item-${item.id}`}
                        onClick={toggleOpen}
                        ref={!passRef ? itemRef : null}
                        style={{ paddingLeft: level * indentPerLevel }}
                        data-index={index}
                        data-level={level}
                        data-testid={`tree-item-${item.id}`}
                    >
                        <span
                            className={cn([
                                'w-full',
                                state === 'dragging'
                                    ? innerDraggingStyles
                                    : state === 'parent-of-instruction'
                                      ? parentOfInstructionStyles
                                      : undefined,
                            ])}
                        >
                            {renderItem ? renderItem(item, level, index, itemRef) : item.name}
                        </span>
                        {instruction ? <DropIndicator instruction={instruction} /> : null}
                    </div>
                </FocusRing>
            </div>
            {tree.hasChildren(item) && (expanded || item.isOpen) ? (
                <div id={aria?.['aria-controls']}>
                    {item.children.map((child, index, array) => {
                        const childType: ItemMode = (() => {
                            if (tree.hasChildren(child) && child.isOpen) {
                                return 'expanded';
                            }

                            if (index === array.length - 1) {
                                return 'last-in-group';
                            }

                            return 'standard';
                        })();

                        return (
                            <TreeItem
                                item={child}
                                key={child.id}
                                level={level + 1}
                                mode={childType}
                                index={index}
                                indentPerLevel={indentPerLevel}
                                renderItem={renderItem}
                                expanded={expanded}
                            />
                        );
                    })}
                </div>
            ) : null}
        </>
    );
});
