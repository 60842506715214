import type { ReactNode } from 'react';

import { cn } from '@/lib/styling';

export const DetailsCrumbIconWrapper = ({ icon, label, bold }: { icon: ReactNode; label: string; bold?: boolean }) => {
    return (
        <div className={cn('gap-sm flex items-center', bold ? 'font-medium text-body-grey-primary' : '')}>
            {icon}
            <span className="line-clamp-1">{label}</span>
        </div>
    );
};
