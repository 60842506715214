import { useQuery } from '@/lib/hooks/useQuery';
import { addDays, addMonths, addWeeks, endOfDay, startOfDay } from 'date-fns';
import {
    ChartInterval,
    type DepartmentStatsResponse,
    type PlaybookUtilizationResponse,
    type RequestByCategoryResponse,
} from 'lib/models/insights';
import type { ResolutionStatsRow } from 'lib/models/request';
import type { RequestStatusType } from 'lib/prisma/enums';
import qs from 'qs';

const defaultDates = (interval: ChartInterval) => {
    const until = endOfDay(new Date());
    let since = startOfDay(addMonths(until, -6));
    if (interval === ChartInterval.WEEK) {
        since = startOfDay(addWeeks(until, -6));
    }
    if (interval === ChartInterval.DAY) {
        since = startOfDay(addDays(until, -30));
    }

    return { since: since.toISOString(), until: until.toISOString() };
};

const getSinceUntil = (interval: ChartInterval, since?: string, until?: string) => {
    const dates = defaultDates(interval);
    if (!since) {
        since = dates.since;
    }
    if (!until) {
        until = dates.until;
    }

    return { since, until };
};

export const useResolutionStats = (interval: ChartInterval, since?: string, until?: string) => {
    const tzOffset = new Date().getTimezoneOffset();
    const dates = getSinceUntil(interval, since, until);
    const qryStr = qs.stringify({ interval, tzOffset, ...dates });

    return useQuery<{ rows: ResolutionStatsRow[] }>({
        url: `/api/v1/insights/resolution?${qryStr}`,
    });
};

export const useDepartmentStats = (since?: string, until?: string) => {
    const tzOffset = new Date().getTimezoneOffset();
    const dates = getSinceUntil(ChartInterval.DAY, since, until);
    const qryStr = qs.stringify({ tzOffset, interval: ChartInterval.DAY, ...dates });

    return useQuery<DepartmentStatsResponse>({
        url: `/api/v1/insights/by_department?${qryStr}`,
    });
};

export const usePlaybookUtilization = (since?: string, until?: string) => {
    const tzOffset = new Date().getTimezoneOffset();
    const dates = getSinceUntil(ChartInterval.DAY, since, until);
    const qryStr = qs.stringify({ tzOffset, interval: ChartInterval.DAY, ...dates });

    return useQuery<PlaybookUtilizationResponse>({
        url: `/api/v1/insights/playbook_utilization?${qryStr}`,
    });
};

export const useRequestByCategory = (since?: string, until?: string, status?: RequestStatusType) => {
    const tzOffset = new Date().getTimezoneOffset();
    const dates = getSinceUntil(ChartInterval.DAY, since, until);
    const qryStr = qs.stringify({ tzOffset, interval: ChartInterval.DAY, ...dates, status });

    return useQuery<RequestByCategoryResponse>({
        url: `/api/v1/insights/by_category?${qryStr}`,
    });
};
