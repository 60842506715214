import { Tag } from 'lucide-react';
import { Select } from '../ui/select';
import {
    type CategoryOption,
    CategoryOption as CategorySelectOption,
    CategoryValuesContainer,
} from '../ui/select/CategoryOption';

export const CategoriesFilter = ({
    categoryOptions,
    value,
    onChange,
    placeholderName = 'Categories',
}: {
    categoryOptions: CategoryOption[];
    value: CategoryOption[] | undefined;
    onChange: (_: CategoryOption[]) => void;
    placeholderName?: string;
}) => {
    return (
        <Select
            isMulti={true}
            placeholder={
                <div className="flex items-center gap-sm">
                    <Tag className="size-3.5 text-body-subtle" />
                    {placeholderName}
                </div>
            }
            components={{
                Option: CategorySelectOption,
                ValueContainer: CategoryValuesContainer,
                MultiValue: () => null,
            }}
            options={categoryOptions}
            closeMenuOnSelect={false}
            value={value}
            // @ts-expect-error - fix in tandem with users filter
            onChange={onChange}
            trigger="small"
        />
    );
};
