import { Badge } from '@/components/ui/badge';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { AllTicketsSubpage } from '@pages/roi/AllTicketsSubpage.tsx';
import { BookCopy } from 'lucide-react';

export const PlaybooksDialog = ({
    onCancel,
    open,
    data,
    tickets,
    count,
}: {
    onCancel: () => void;
    open: boolean;
    data: { name: string; value: number; icon: React.ReactNode }[];
    tickets: { subject: string | null; providerCreatedAt: Date | null; provider: string; externalURL?: string }[];
    count: number;
}) => {
    return (
        <Dialog open={open} onOpenChange={onCancel}>
            <DialogContent>
                <DialogHeader className="border-grey border-b-[0.5px]">
                    <DialogTitle>
                        <div className="flex gap-md items-center">
                            <div className="size-2xl shrink-0 flex items-center justify-center bg-bg-brand rounded-full">
                                <BookCopy className="text-body-white" />
                            </div>
                            <div>
                                <h3 className="text-base">Employees submitted {count} requests</h3>
                                <p className="text-body-subtle-hover text-[13px] mt-[-2px]">
                                    Here is a breakdown of your tickets by automation
                                </p>
                            </div>
                        </div>
                    </DialogTitle>
                </DialogHeader>
                <div className="max-h-full overflow-y-auto">
                    <Tabs defaultValue="playbooks">
                        <TabsList className="px-lg sticky top-0 bg-white z-10">
                            <TabsTrigger value="playbooks">Requests by Playbook</TabsTrigger>
                            <TabsTrigger value="all">All Playbook Requests</TabsTrigger>
                        </TabsList>
                        <TabsContent value="playbooks">
                            <div className="flex flex-col px-lg py-md">
                                {data.map(item => (
                                    <div
                                        key={item.name}
                                        className="flex items-center h-2xl justify-between border-b border-grey border-dashed last:border-none"
                                    >
                                        <div className="flex gap-md items-center">
                                            {item.icon}
                                            <p className="font-medium">{item.name}</p>
                                        </div>

                                        <Badge className="text-body-subtle-hover rounded-sm h-[18px] px-sm">
                                            {item.value}%
                                        </Badge>
                                    </div>
                                ))}
                            </div>
                        </TabsContent>
                        <TabsContent value="all">
                            <AllTicketsSubpage tickets={tickets} />
                        </TabsContent>
                    </Tabs>
                </div>
            </DialogContent>
        </Dialog>
    );
};
