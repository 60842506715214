import { ApprovalSelect } from '@/components/ApproverSelect';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Label } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Select } from '@/components/ui/select';
import { useDefaultPolicySettingsPut } from '@/hooks/mutations/policies/userDefaultPolicySettingsPut';
import { useProvisioningTicketFieldMappingPost } from '@/hooks/mutations/useProvisioningTickets';
import { useDefaultPolicyConfig } from '@/hooks/queries/usePolicies';
import {
    useSettingProvisioningTicketFieldMapping,
    useSettingRequest,
    useSettingRequestConfig,
} from '@/hooks/queries/useSettingRequest';
import { useTicketProvisioning } from '@/hooks/queries/useTicketProvisioning';
import { cn } from '@/lib/styling';
import { useApprovalSettings } from '@/stores/useApprovalSettings';
import type { IntegrationId } from 'lib/integration';
import { makeDurationOptions } from 'lib/models/app_policy';
import { nudgeCounts, nudgeIntervals } from 'lib/models/app_policy';
import { isApiError } from 'lib/models/error';
import { MappingPostSchema } from 'lib/models/provisioning_ticket_field_maps';
import { RequestFieldType } from 'lib/prisma/enums';
import { AppPolicyDuration, type AppPolicyDurationType, AppPolicyReviewStrategy } from 'lib/prisma/enums';
import type { RequestProviderField } from 'lib/prisma/types';
import { ArrowRightLeft, BadgeCheck, Clock, Plus, SquareStack } from 'lucide-react';
import type React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { safeParse } from 'valibot';
import { FieldMappingListHeader, FieldMappingListItem } from './FieldMappingListItem';

export const SectionHeader = ({
    heading,
    description,
    icon,
    iconBg,
}: { heading: string; description: string; icon: React.ReactNode; iconBg: string }) => {
    return (
        <div className="flex items-center gap-lg border-b border-grey p-lg">
            <div className={cn('p-2 rounded-full', iconBg)}>{icon}</div>
            <div>
                <h2>{heading}</h2>
                <p>{description}</p>
            </div>
        </div>
    );
};

export const LabelDescription = ({
    label,
    description,
    forId,
    children,
}: { label: string; description: string; forId: string; children?: React.ReactNode }) => {
    return (
        <div className="flex flex-col">
            <Label htmlFor={forId} className="text-grey font-semibold">
                {label}
            </Label>
            <p className="mb-sm">{description}</p>
            {children}
        </div>
    );
};

const PendingApprovalSettings = () => {
    const store = useApprovalSettings();

    const { mutate: updateSettings } = useDefaultPolicySettingsPut();

    useEffect(() => {
        if (store.isDirty() && store.settings) {
            updateSettings(store.settings);
            store.init(store.settings);
        }
    }, [store.settings, updateSettings, store.isDirty, store.init]);

    return (
        <>
            <SectionHeader
                heading="Pending approvals"
                description="How Console will handle reviewers who are OOO or fail to respond in a timely manner"
                icon={<Clock />}
                iconBg="bg-bg-yellow-secondary"
            />
            <form className="p-lg flex flex-col gap-lg">
                <LabelDescription
                    label="Nudge reviewer every..."
                    description="How often should we nudge someone about pending approvals that require their review?"
                    forId="nudge-interval"
                >
                    <Select
                        className="lg:w-2/5 max-w-sm"
                        id="nudge-interval"
                        options={nudgeIntervals}
                        value={nudgeIntervals.find(
                            i => i.value === store.currentSettings?.pendingApprovalNudgeInterval,
                        )}
                        onChange={v => store.setSettings({ pendingApprovalNudgeInterval: v?.value })}
                    />
                </LabelDescription>
                <LabelDescription
                    label="Escalate after..."
                    description="How many times should we nudge someone before we escalate the request?"
                    forId="escalate-count"
                >
                    <Select
                        className="lg:w-2/5 max-w-sm"
                        id="escalate-count"
                        options={nudgeCounts}
                        value={nudgeCounts.find(v => v.value === store.currentSettings?.pendingApprovalNudgeCount)}
                        onChange={v => store.setSettings({ pendingApprovalNudgeCount: v?.value })}
                    />
                </LabelDescription>
                <LabelDescription
                    label="Escalate to..."
                    description="Who should we escalate requests to by default when the request has not been approved in time?"
                    forId="escalate-to"
                >
                    <ApprovalSelect
                        className="lg:w-2/5 max-w-sm"
                        id="escalate-to"
                        strategy={
                            store.currentSettings?.pendingApprovalEscalationStrategy ??
                            AppPolicyReviewStrategy.APP_OWNERS
                        }
                        userIds={store.currentSettings?.pendingApprovalEscalationUserIds ?? []}
                        groupIds={store.currentSettings?.pendingApprovalEscalationGroupIds ?? []}
                        onStrategy={strategy => store.setSettings({ pendingApprovalEscalationStrategy: strategy })}
                        onUsers={userIds => store.setSettings({ pendingApprovalEscalationUserIds: userIds })}
                        onGroups={groupIds => store.setSettings({ pendingApprovalEscalationGroupIds: groupIds })}
                    />
                </LabelDescription>
            </form>
        </>
    );
};

const FieldMapper = ({ fields, provider }: { fields: RequestProviderField[]; provider: IntegrationId }) => {
    const mutation = useProvisioningTicketFieldMappingPost();

    const strategies = [
        { value: 'setValue', label: 'Set value' },
        { value: 'access_request', label: 'Access request property' },
    ];

    const accessRequestFields = [
        { value: 'appName', label: 'App name' },
        { value: 'policyName', label: 'Policy name' },
        { value: 'reason', label: 'Reason' },
        { value: 'requesterEmail', label: 'Requester email' },
        { value: 'requesterName', label: 'Requester name' },
    ];

    const [selectedField, setSelectedField] = useState<RequestProviderField | null>(null);
    const [selectedStrategy, setSelectedStrategy] = useState<string | null>(null);
    const [selectedValue, setSelectedValue] = useState<string | null>(null);

    const [open, setOpen] = useState(false);

    const handleFieldChange = (fieldKey?: string) => {
        setSelectedField(fields.find(f => f.fieldKey === fieldKey) ?? null);
        setSelectedStrategy(null);
        setSelectedValue(null);
    };

    const payload = useMemo(() => {
        return safeParse(MappingPostSchema, {
            fieldKey: selectedField?.fieldKey,
            fieldType: selectedField?.fieldType,
            staticValue: selectedStrategy !== 'access_request' ? selectedValue : undefined,
            accessRequestProperty: selectedStrategy === 'access_request' ? selectedValue : undefined,
            provider,
        });
    }, [selectedField?.fieldKey, selectedField?.fieldType, selectedValue, provider, selectedStrategy]);

    const onSave = () => {
        if (payload.success) {
            mutation.mutate(payload.output);
            setOpen(false);
            setSelectedField(null);
            setSelectedStrategy(null);
            setSelectedValue(null);
        }
    };

    const StrategySelect = () => {
        if (selectedField && selectedField.fieldType === RequestFieldType.INPUT) {
            return (
                <LabelDescription
                    label="Map via"
                    description="How do you want to map the field?"
                    forId="mapping-strategy"
                >
                    <Select
                        id="mapping-strategy"
                        options={strategies}
                        onChange={v => setSelectedStrategy(v?.value ?? null)}
                        value={strategies.find(s => s.value === selectedStrategy)}
                    />
                </LabelDescription>
            );
        }
        return null;
    };

    const ValueInput = () => {
        if (selectedStrategy === 'access_request') {
            return (
                <LabelDescription
                    label="Select a property"
                    description="Which property from the access request should map to your ticket field?"
                    forId="value-entry"
                >
                    <Select
                        id="access-request-field"
                        options={accessRequestFields}
                        onChange={v => setSelectedValue(v?.value ?? null)}
                        value={accessRequestFields.find(f => f.value === selectedValue)}
                    />
                </LabelDescription>
            );
        } else if (selectedStrategy === 'setValue') {
            switch (selectedField?.fieldType) {
                case RequestFieldType.INPUT:
                    return (
                        <LabelDescription
                            label="Text value"
                            description="Enter a static text value to map to the ticket field"
                            forId="value-entry"
                        >
                            <Input
                                id="value-input"
                                value={selectedValue ?? undefined}
                                onChange={e => setSelectedValue(e.target.value)}
                            />
                        </LabelDescription>
                    );
                case RequestFieldType.NUMBER_INPUT:
                    return (
                        <LabelDescription
                            label="Number value"
                            description="Enter a static number value to map to the ticket field"
                            forId="value-entry"
                        >
                            <Input id="value-input" type="number" />
                        </LabelDescription>
                    );

                case RequestFieldType.BOOLEAN_INPUT:
                    return (
                        <LabelDescription
                            label="Boolean value"
                            description="Enter a static boolean value to map to the ticket field"
                            forId="value-entry"
                        >
                            <div className="flex items-center gap-sm">
                                <Checkbox
                                    id="value-input"
                                    checked={selectedValue === 'true'}
                                    onCheckedChange={checked => setSelectedValue(checked.toString())}
                                />
                                Always set to {selectedValue === 'true' ? 'true' : 'false'}
                            </div>
                        </LabelDescription>
                    );
                case RequestFieldType.SELECT:
                case RequestFieldType.MULTISELECT:
                    return (
                        <LabelDescription
                            label="Select option"
                            description="Choose from one of the field options"
                            forId="value-entry"
                        >
                            <Select
                                id="value-input"
                                options={selectedField.options}
                                value={selectedField.options.find(o => o.value === selectedValue)}
                                onChange={v => setSelectedValue((v?.value as string) ?? null)}
                            />
                        </LabelDescription>
                    );
            }
            return null;
        } else {
            return null;
        }
    };

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger>
                <Button size="sm">
                    <Plus /> Add mapping
                </Button>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        <ArrowRightLeft /> Add field mapping
                    </DialogTitle>
                </DialogHeader>
                <div className="p-lg flex flex-col gap-lg">
                    <LabelDescription
                        label="Ticket field"
                        description="Which ticket field do you want to map?"
                        forId="ticket-fields"
                    >
                        <Select
                            id="ticket-fields"
                            options={fields.map(f => ({ value: f.fieldKey, label: f.fieldDisplay }))}
                            onChange={v => handleFieldChange(v?.value)}
                        />
                    </LabelDescription>

                    <StrategySelect />

                    {selectedField && <ValueInput />}
                </div>
                <DialogFooter className="flex justify-center">
                    <Button disabled={!payload.success || !selectedValue} className="w-full" onClick={onSave}>
                        Save
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

const TicketProvisioningFieldMappings = ({
    provider,
    namespace,
    ticketType,
}: { provider: IntegrationId; namespace: string; ticketType?: string }) => {
    const { data } = useSettingRequest(provider, namespace ? [namespace] : undefined, ticketType);
    const { data: mappings } = useSettingProvisioningTicketFieldMapping();

    if (!data || isApiError(data)) {
        return null;
    }
    const handledKeys = new Set(['subject', 'description', 'status', 'requester']);
    const fields = data.fields.filter(f => {
        if (handledKeys.has(f.fieldKey)) {
            return false;
        }
        if (f.fieldType === RequestFieldType.SELECT || f.fieldType === RequestFieldType.MULTISELECT) {
            return f.options.length > 0;
        }
        return true;
    });

    const labelMap: Map<string, string> = new Map();
    data.fields.forEach(f => {
        labelMap.set(f.fieldKey, f.fieldDisplay);
    });

    return (
        <div className="flex flex-col gap-lg">
            <div className="flex justify-between items-center gap-lg">
                <LabelDescription
                    label="Field mappings"
                    description="Map ticket fields to access request fields"
                    forId="mapping-btn"
                />
                <FieldMapper fields={fields} provider={provider} />
            </div>
            <div>
                <FieldMappingListHeader />
                {mappings?.fields?.map(field => (
                    <FieldMappingListItem key={field.fieldKey} field={field} label={labelMap.get(field.fieldKey)} />
                ))}
            </div>
        </div>
    );
};

const castOption = (val: unknown) => {
    if (val) {
        return `${val}`;
    }
    return null;
};

const TicketProvisioningSettings = () => {
    const store = useApprovalSettings();
    const { data } = useTicketProvisioning();
    const { data: requestConfig } = useSettingRequestConfig();

    const { mutate: updateSettings } = useDefaultPolicySettingsPut();

    useEffect(() => {
        if (store.isDirty() && store.settings) {
            updateSettings(store.settings);
            store.init(store.settings);
        }
    }, [store.settings, updateSettings, store.isDirty, store.init]);

    const { provider, namespace, ticketType } = requestConfig?.config ?? {};
    if (!data?.options) {
        return null;
    }

    return (
        <>
            <SectionHeader
                heading="Ticket provisioning"
                description="Track users' access levels once access provisioning tickets reach a specified status"
                icon={<SquareStack />}
                iconBg="bg-bg-blue-secondary text-white"
            />
            <form className="p-lg flex flex-col gap-lg">
                <LabelDescription
                    label="Select tracking status"
                    description="Which status should Console watch for before tracking a user’s access level?"
                    forId="tracked-status"
                >
                    <Select
                        className="lg:w-2/5 max-w-sm"
                        id="tracked-status"
                        options={data.options}
                        value={data.options.find(
                            o => castOption(o.value) === store?.currentSettings?.ticketProvisioningWatchedStatus,
                        )}
                        onChange={v => store.setSettings({ ticketProvisioningWatchedStatus: castOption(v?.value) })}
                    />
                </LabelDescription>
            </form>
            <div className="p-lg">
                {provider && namespace && (
                    <TicketProvisioningFieldMappings
                        provider={provider as IntegrationId}
                        namespace={namespace}
                        ticketType={ticketType}
                    />
                )}
            </div>
        </>
    );
};

export const Approvals = () => {
    const { data: config } = useDefaultPolicyConfig();
    const store = useApprovalSettings();

    const userOpts = new Set<AppPolicyDurationType>();
    config?.settings?.defaultPolicyDurationOptions?.forEach(d => userOpts.add(d));

    const durations = makeDurationOptions(window.navigator.language);

    const duration = durations.find(d => d.value === store?.settings?.defaultPolicyDuration) ?? durations[0];
    const [userDefinedDurations, setUserDefinedDurations] = useState(new Set(userOpts));
    const userDefinedDurationOptions = durations.filter(d => d.value !== AppPolicyDuration.USER_DEFINED);

    useEffect(() => {
        if (config?.settings) {
            setUserDefinedDurations(new Set(config.settings.defaultPolicyDurationOptions));

            store.init(config.settings);
        }
    }, [config, store.init]);

    const { mutate: updateSettings } = useDefaultPolicySettingsPut();

    useEffect(() => {
        if (store.isDirty() && store.settings) {
            updateSettings(store.settings);
            store.init(store.settings);
        }
    }, [store.settings, updateSettings, store.isDirty, store.init]);

    const handleCheck = (checked: boolean, duration: AppPolicyDurationType) => {
        if (checked) {
            userDefinedDurations.add(duration);
        } else {
            userDefinedDurations.delete(duration);
        }
        setUserDefinedDurations(new Set(userDefinedDurations));
        store.setSettings({ defaultPolicyDurationOptions: Array.from(userDefinedDurations) });
    };

    return (
        <div className="flex flex-col gap-xl">
            <section className="border-grey border rounded-md">
                <TicketProvisioningSettings />
            </section>
            <section className="border-grey border rounded-md">
                <SectionHeader
                    heading="Default approval settings"
                    description="Define which settings you would like to appear by default when creating an access policy"
                    icon={<BadgeCheck className="text-white" />}
                    iconBg="bg-bg-green-secondary"
                />
                <form className="p-lg flex flex-col gap-lg">
                    <div className="flex flex-col gap-sm">
                        <Label htmlFor="default-approver" className="text-grey font-semibold">
                            Default approver
                        </Label>
                        <ApprovalSelect
                            className="lg:w-2/5 max-w-sm"
                            id="default-approver"
                            strategy={
                                store.currentSettings?.defaultPolicyReviewStrategy ?? AppPolicyReviewStrategy.APP_OWNERS
                            }
                            userIds={store.currentSettings?.defaultPolicyUserReviewerIds ?? []}
                            groupIds={store.currentSettings?.defaultPolicyGroupReviewerIds ?? []}
                            onStrategy={strategy => store.setSettings({ defaultPolicyReviewStrategy: strategy })}
                            onUsers={userIds => store.setSettings({ defaultPolicyUserReviewerIds: userIds })}
                            onGroups={groupIds => store.setSettings({ defaultPolicyGroupReviewerIds: groupIds })}
                        />
                    </div>
                    <div className="flex flex-col gap-sm">
                        <Label htmlFor="duration-select" className="text-grey font-semibold">
                            Default access length
                        </Label>
                        <Select
                            className="lg:w-2/5 max-w-sm"
                            id="duration-select"
                            options={durations}
                            value={duration}
                            onChange={v => store.setSettings({ defaultPolicyDuration: v?.value })}
                        />
                    </div>
                    {duration.value === AppPolicyDuration.USER_DEFINED && (
                        <div className="flex flex-col gap-sm">
                            <p>Select the default options you want to allow the user to choose from</p>
                            <div className="flex gap-lg flex-wrap">
                                {userDefinedDurationOptions.map(d => (
                                    <div key={d.value} className="flex items-center gap-sm">
                                        <Checkbox
                                            id={d.value}
                                            checked={userDefinedDurations.has(d.value)}
                                            className="data-[state=checked]:bg-bg-blue-secondary"
                                            onCheckedChange={checked => handleCheck(!!checked, d.value)}
                                        />
                                        <Label htmlFor={d.value}>{d.label}</Label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </form>
            </section>
            <section className="border-grey border rounded-md">
                <PendingApprovalSettings />
            </section>
        </div>
    );
};
