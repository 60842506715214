import { Loader } from '@/components/loader';
import { Separator } from '@/components/ui/separator';
import type React from 'react';

interface TableEmptyStateProps {
    title: string;
    description: string;
    imageSrc: string;
    isLoading?: boolean;
    isConnected?: boolean;
    connectedDescription?: string;
    children?: React.ReactNode;
}

export const TableEmptyState: React.FC<TableEmptyStateProps> = ({
    title,
    description,
    imageSrc,
    isLoading,
    isConnected,
    connectedDescription,
    children,
}) => {
    return (
        <div className="size-full flex items-center justify-center bg-bg-overlay">
            <div className="bg-bg border-grey border-[0.5px] rounded-lg overflow-hidden max-w-[600px] w-full flex flex-col gap-2xl items-center p-4xl shadow-xl text-center mx-2xl relative">
                <img src={imageSrc} alt={`Empty ${title}`} className="size-24" />
                <img
                    src="/emptyStates/empty-circles.svg"
                    alt="Concentric circles"
                    className="size-[320px] absolute -top-[63.5px] z-0"
                />
                <div className="flex flex-col gap-md items-center z-10 w-full">
                    <h3 className="text-xl">{title}</h3>
                    {isLoading ? (
                        <Loader />
                    ) : isConnected ? (
                        <p className="text-base text-body-subtle">{connectedDescription}</p>
                    ) : (
                        <>
                            <p className="text-base text-body-subtle">{description}</p>
                            {children && (
                                <>
                                    <Separator className="my-lg" />
                                    <div className="flex gap-md items-center">{children}</div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
