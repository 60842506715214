import { AppSelect, type AppSelectChangePayload } from '@/components/AppSelect';
import { Brow } from '@/components/Brow';
import { PolicyStepActionsList } from '@/components/policies/PolicySteps/PolicyStepActionsList';
import { PolicyStepApprovalList } from '@/components/policies/PolicySteps/PolicyStepApprovalList';
import { PolicyStepDetails } from '@/components/policies/PolicySteps/PolicyStepDetails';
import type { StepConfig } from '@/components/policies/PolicySteps/types';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Form, FormDescription, FormLabel } from '@/components/ui/form';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { usePolicyPost } from '@/hooks/mutations/policies/usePolicyPost';
import { usePolicyPut } from '@/hooks/mutations/policies/usePolicyPut';
import { useDefaultPolicyConfig, usePolicyActionTemplates, usePolicyDetails } from '@/hooks/queries/usePolicies';
import { CnslPolicyIcon } from '@/lib/iconConstants';
import { cn } from '@/lib/styling';
import { POLICIES, POLICY_DETAILS_V2 } from '@/routes/paths';
import { type PolicyStepperKey, usePolicyCreationStore } from '@/stores/usePolicy';
import type { DefaultPolicySettings } from 'lib/models/app_policy';
import { AppPolicyStepType } from 'lib/prisma/enums';
import type { CnslApp } from 'lib/prisma/types';
import { BadgeCheck, PenTool, Scroll, SearchCheck, ShieldX } from 'lucide-react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

const SavePolicyButton = ({
    policyId,
    app,
    mode = 'page',
}: { policyId?: string; app: CnslApp | null; mode: 'page' | 'drawer' }) => {
    const store = usePolicyCreationStore();
    const navigate = useNavigate();
    const post = usePolicyPost(app?.slug, (id: string) => {
        navigate(`/policies/${id}`);
    });
    const put = usePolicyPut(policyId ?? '', app?.slug);

    const handleSave = () => {
        const body = store.requestBody(app?.id ?? '');
        const onSuccess = () => {
            const to =
                mode === 'page'
                    ? POLICIES
                    : POLICY_DETAILS_V2.replace(':slug', app?.slug ?? '').replace('/:policyId', '');
            navigate(to);
            store.markClean();
        };

        if (policyId) {
            put.mutate(body, { onSuccess });
        } else {
            post.mutate(body, { onSuccess });
        }
    };
    return (
        <div className={cn('w-full', mode === 'drawer' ? 'mb-xl' : undefined)}>
            <Button
                variant="blue"
                mode="dark"
                size={mode === 'page' ? 'sm' : 'default'}
                disabled={!store.dirty || !store.valid()}
                onClick={handleSave}
                className="w-full"
            >
                Save policy
            </Button>
        </div>
    );
};

const PolicyDetailsBrow = ({ policyId, app }: { policyId?: string; app: CnslApp | null }) => {
    const store = usePolicyCreationStore();
    const title = store.policy?.name || 'New policy';

    return (
        <>
            <Brow
                data={[
                    {
                        label: (
                            <div className="flex items-center gap-sm">
                                <CnslPolicyIcon />
                                Access Policies
                            </div>
                        ),
                        href: POLICIES,
                    },
                    {
                        label: (
                            <div className="flex items-center gap-sm">
                                <Scroll />
                                {title}
                            </div>
                        ),
                        bold: true,
                    },
                ]}
                actions={[
                    <Button key="cancel-btn" mode="borderless" size="sm">
                        Cancel
                    </Button>,
                    <SavePolicyButton policyId={policyId} app={app} key="save-btn" mode="page" />,
                ]}
            />
        </>
    );
};

export const PolicyTitleDescription = () => {
    const store = usePolicyCreationStore();

    return (
        <div className="flex flex-col gap-sm">
            <input
                type="text"
                id="policy-title"
                className="text-xl font-medium outline-none"
                placeholder="Add policy title"
                value={store.name}
                onChange={e => store.setName(e.target.value)}
            />
            <input
                type="text"
                id="policy-description"
                className="text-lg outline-none"
                placeholder="Write a short description"
                value={store.description}
                onChange={e => store.setDescription(e.target.value)}
            />
        </div>
    );
};

export const PolicyDetailsApp = ({
    onChange,
    app,
}: { onChange: (v?: AppSelectChangePayload) => void; app: CnslApp | null }) => {
    const form = useForm();
    return (
        <Form {...form}>
            <div className="max-w-64">
                <FormLabel>App</FormLabel>
                <FormDescription className="mb-sm">Which app is this policy for?</FormDescription>
                <AppSelect
                    onChange={onChange}
                    selected={app ? { value: app.id, label: app.name ?? '', logo: app.logo ?? '', app } : undefined}
                />
            </div>
        </Form>
    );
};

export const PolicyTabs = ({ appSlug, settings }: { appSlug?: string; settings?: DefaultPolicySettings }) => {
    const store = usePolicyCreationStore();
    const [searchParams] = useSearchParams();
    if (!settings) {
        return null;
    }

    const steps: Array<StepConfig> = [
        { label: 'Details', Icon: PenTool, Component: PolicyStepDetails, key: 'details' },
        {
            label: 'Approvals',
            Icon: SearchCheck,
            Component: PolicyStepApprovalList,
            key: 'approvals',
            count: store.approvals.length,
        },
        {
            label: 'Grant access',
            Icon: BadgeCheck,
            Component: PolicyStepActionsList,
            type: AppPolicyStepType.PROVISION,
            key: 'grants',
            count: store.provisioningPSteps.length,
        },
        {
            label: 'Revoke access',
            Icon: ShieldX,
            Component: PolicyStepActionsList,
            type: AppPolicyStepType.REVOKE,
            key: 'revokes',
            count: store.revocationPSteps.length,
        },
    ];

    const validKeys = steps.map(s => s.key);
    const stepParam = 'step';
    const defaultTabVal = () => {
        const param = searchParams.get(stepParam);
        if (param && validKeys.includes(param as PolicyStepperKey)) {
            return param;
        }
        return validKeys[0];
    };

    return (
        <Tabs defaultValue={defaultTabVal()}>
            <TabsList className="w-full">
                {steps.map(s => (
                    <TabsTrigger
                        value={s.key}
                        className="flex gap-md"
                        key={s.key}
                        onClick={() => {
                            const p = new URLSearchParams(window.location.search);
                            p.set(stepParam, s.key);
                            window.history.replaceState(null, '', `?${p.toString()}`);
                        }}
                    >
                        <s.Icon />
                        {s.label}
                        {s.count !== undefined && <Badge className="text-bg-grey-secondary">{s.count}</Badge>}
                    </TabsTrigger>
                ))}
            </TabsList>
            {settings &&
                steps.map(s => (
                    <TabsContent value={s.key} key={s.key} asChild>
                        <div className="pt-2xl">
                            <s.Component
                                defaultSettings={settings}
                                appSlug={appSlug ?? ''}
                                type={s.type ?? AppPolicyStepType.PROVISION}
                            />
                        </div>
                    </TabsContent>
                ))}
        </Tabs>
    );
};

export const PolicyDetails = () => {
    const config = useDefaultPolicyConfig();
    const store = usePolicyCreationStore();
    const { data: templateData } = usePolicyActionTemplates();
    const { policyId } = useParams<{ policyId: string }>();
    const { data: policyData, isLoading } = usePolicyDetails(policyId);
    const settings = config.data?.settings;

    const { initFromDefaults, setInitialSteps, initWithPolicy, setActionTemplates } = store;

    useEffect(() => {
        if (policyData && templateData?.actionTemplates) {
            initWithPolicy(policyData, templateData.actionTemplates);
        }
    }, [policyData, templateData, initWithPolicy]);

    useEffect(() => {
        if (templateData?.actionTemplates) {
            setActionTemplates(templateData.actionTemplates);
        }
    }, [templateData?.actionTemplates, setActionTemplates]);

    useEffect(() => {
        if (!policyId && templateData?.actionTemplates && settings) {
            initFromDefaults(settings);
            setInitialSteps(templateData.actionTemplates);
        }
    }, [policyId, templateData?.actionTemplates, initFromDefaults, settings, setInitialSteps]);

    const onAppSelect = (v?: AppSelectChangePayload) => {
        if (v && 'app' in v) {
            store.setApp(v.app as CnslApp);
        }
    };

    if ((policyId && isLoading) || (policyId && !policyData)) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <PolicyDetailsBrow app={store.app} policyId={policyId} />
            <div className="max-w-[640px] w-full py-2xl mx-auto flex flex-col gap-2xl">
                <PolicyTitleDescription />
                <PolicyDetailsApp onChange={onAppSelect} app={store.app} />
                <PolicyTabs appSlug={store.app?.slug} settings={settings} />
            </div>
        </>
    );
};

export const PolicyDetailsDrawerContent = ({ policyId }: { policyId: string }) => {
    const config = useDefaultPolicyConfig();
    const store = usePolicyCreationStore();
    const { data: templateData } = usePolicyActionTemplates();
    const { data: policyData, isLoading } = usePolicyDetails(policyId);
    const settings = config.data?.settings;

    const { initFromDefaults, setInitialSteps, initWithPolicy, setActionTemplates } = store;

    useEffect(() => {
        if (policyData && templateData?.actionTemplates) {
            initWithPolicy(policyData, templateData.actionTemplates);
        }
    }, [policyData, templateData, initWithPolicy]);

    useEffect(() => {
        if (templateData?.actionTemplates) {
            setActionTemplates(templateData.actionTemplates);
        }
    }, [templateData?.actionTemplates, setActionTemplates]);

    useEffect(() => {
        if (!policyId && templateData?.actionTemplates && settings) {
            initFromDefaults(settings);
            setInitialSteps(templateData.actionTemplates);
        }
    }, [policyId, templateData?.actionTemplates, initFromDefaults, settings, setInitialSteps]);

    const onAppSelect = (v?: AppSelectChangePayload) => {
        if (v && 'app' in v) {
            store.setApp(v.app as CnslApp);
        }
    };

    if ((policyId && isLoading) || (policyId && !policyData)) {
        return null;
    }

    return (
        <>
            <div className="w-full mx-auto flex flex-col gap-2xl p-2xl overflow-y-auto grow">
                <PolicyTitleDescription />
                <PolicyDetailsApp onChange={onAppSelect} app={store.app} />
                <PolicyTabs appSlug={store.app?.slug} settings={settings} />
                <SavePolicyButton policyId={policyId} app={store.app} mode="drawer" />
            </div>
        </>
    );
};
