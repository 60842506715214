import ComboboxPopover from '@/components/ComboboxPopover';
import { ResLink } from '@/components/ResLink';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Form, FormDescription, FormLabel } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useGroups } from '@/hooks/queries/useGroups';
import { CnslGroupIcon } from '@/lib/iconConstants';
import { usePolicyCreationStore } from '@/stores/usePolicy';
import { makeDurationOptions, prettyVisibility, visibilityOptions } from 'lib/models/app_policy';
import { AppPolicyDuration } from 'lib/prisma/enums';
import { ChevronDown, CircleX, Search } from 'lucide-react';
import { useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

export const PolicyStepDetails = () => {
    const form = useForm();
    const store = usePolicyCreationStore();

    const [searchTerm, setSearchTerm] = useState('');
    const { data: groupsData } = useGroups();
    const groups = groupsData?.items ?? [];

    const selectedGroups = useMemo(
        () => groups.filter(g => store.visibleToGroupIds.has(g.id)),
        [store.visibleToGroupIds, groups],
    );

    const filteredGroups = useMemo(() => {
        const unselected = groups.filter(g => !store.visibleToGroupIds.has(g.id));
        if (searchTerm.length > 0) {
            return unselected.filter(g => g.name?.toLowerCase().includes(searchTerm.toLowerCase())).slice(0, 15);
        }
        return unselected.slice(0, 15);
    }, [searchTerm, groups, store.visibleToGroupIds]);

    const durationOpts = makeDurationOptions(window.navigator.language);

    const selectableDurationOpts = durationOpts.filter(d => d.value !== AppPolicyDuration.USER_DEFINED);

    const searchInputRef = useRef<HTMLInputElement>(null);
    const clearSearch = () => {
        setSearchTerm('');
        if (searchInputRef.current) {
            searchInputRef.current.value = '';
        }
    };
    const onGroupSelect = (groupId: string, add: boolean) => {
        store.updateVisibileGroupIds(groupId, add);
    };

    return (
        <Form {...form}>
            <form className="gap-lg flex flex-col">
                <div>
                    <FormLabel required htmlFor="visibility">
                        Policy Visibility
                    </FormLabel>
                    <FormDescription>Who do you want to see this policy?</FormDescription>
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button className="flex w-full items-center justify-between">
                                <div className="gap-x-sm flex items-center">
                                    {prettyVisibility(store.visibility)}
                                    {store.visibleToGroupIds.size > 0 && (
                                        <Badge className="ml-sm">{store.visibleToGroupIds.size} selected</Badge>
                                    )}
                                </div>
                                <ChevronDown />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent side="bottom" align="start" className="w-[275px]">
                            {visibilityOptions.map(item => (
                                <DropdownMenuItem
                                    key={item.value}
                                    onSelect={() => store.setVisibility(item.value)}
                                    className="gap-x-sm flex items-center"
                                >
                                    {item.label}
                                </DropdownMenuItem>
                            ))}
                            <DropdownMenuSeparator />

                            <DropdownMenuSub>
                                <DropdownMenuSubTrigger className="flex items-center">
                                    <div className="flex w-full items-center justify-between">
                                        <div className="flex items-center gap-x-sm">
                                            <CnslGroupIcon />
                                            <div className="gap-x-sm flex items-center">Groups</div>
                                        </div>
                                        {store.visibleToGroupIds.size > 0 && (
                                            <Badge className="mr-sm">{store.visibleToGroupIds.size} selected</Badge>
                                        )}
                                    </div>
                                </DropdownMenuSubTrigger>
                                <DropdownMenuSubContent className="w-[275px]">
                                    <div className="bg-bg-overlay pl-sm relative flex items-center rounded-sm border-grey border-[0.5px]">
                                        <Search className="opacity-50" />
                                        <Input
                                            id="group-search"
                                            ref={searchInputRef}
                                            placeholder="Groups"
                                            onInput={e => setSearchTerm(e.currentTarget.value)}
                                            className="bg-bg-overlay border-none"
                                        />
                                        {searchTerm.length > 0 && (
                                            <button className="right-sm absolute">
                                                <CircleX onClick={clearSearch} />
                                            </button>
                                        )}
                                    </div>
                                    {selectedGroups.length > 0 && <DropdownMenuSeparator />}
                                    {selectedGroups.map(group => (
                                        <DropdownMenuCheckboxItem
                                            textValue="**" // hack so that typing in search doesn't grab the item
                                            key={group.id}
                                            className="gap-x-sm flex items-center"
                                            checked={store.visibleToGroupIds.has(group.id)}
                                            side="right"
                                            onSelect={e => e.preventDefault()}
                                            onCheckedChange={checked => onGroupSelect(group.id, checked)}
                                        >
                                            <div className="gap-md ml-md flex items-center">
                                                <ResLink label={group.name ?? ''} entity="groups" src={group.logo} />
                                            </div>
                                        </DropdownMenuCheckboxItem>
                                    ))}
                                    <DropdownMenuSeparator />

                                    {filteredGroups.map(group => (
                                        <DropdownMenuCheckboxItem
                                            textValue="**" // hack so that typing in search doesn't grab the item
                                            key={group.id}
                                            className="gap-x-sm flex items-center"
                                            checked={store.visibleToGroupIds.has(group.id)}
                                            side="right"
                                            onSelect={e => e.preventDefault()}
                                            onCheckedChange={checked => onGroupSelect(group.id, checked)}
                                        >
                                            <div className="gap-md ml-md flex items-center">
                                                <ResLink label={group.name ?? ''} entity="groups" src={group.logo} />
                                            </div>
                                        </DropdownMenuCheckboxItem>
                                    ))}
                                    {filteredGroups.length === 0 && searchTerm.length > 0 && (
                                        <DropdownMenuItem disabled>No groups found for "{searchTerm}"</DropdownMenuItem>
                                    )}
                                </DropdownMenuSubContent>
                            </DropdownMenuSub>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>

                <div>
                    <FormLabel required htmlFor="duration">
                        Access length
                    </FormLabel>
                    <FormDescription>How long should users have access?</FormDescription>
                    <ComboboxPopover
                        constrainWidth
                        defaultValue={store.duration}
                        key={store.duration}
                        options={durationOpts}
                        onChange={d => {
                            if (d) {
                                store.setDuration(d);
                            }
                        }}
                    />
                    {store.duration === AppPolicyDuration.USER_DEFINED && (
                        <div className="pt-md">
                            <FormDescription className="py-md">
                                Which options do you want to allow the user to choose from?
                            </FormDescription>
                            <div className="gap-sm grid grid-cols-4">
                                {selectableDurationOpts.map(option => (
                                    <div key={option.value} className="gap-md flex items-center">
                                        <Checkbox
                                            id={`${option.value}-checkbox`}
                                            checked={store.durationOptions.has(option.value)}
                                            onCheckedChange={checked => {
                                                store.updateDurationOptions(option.value, !!checked);
                                            }}
                                        />
                                        <label htmlFor={`${option.value}-checkbox`}>{option.label}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </form>
        </Form>
    );
};
