import { Button } from '@/components/ui/button';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/styling';
import { SETTINGS_ORGANIZATION_INTEGRATION } from '@/routes/paths';
import type { IntegrationSettingsSchema } from 'lib/models/integrations';
import type { UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import type * as v from 'valibot';
import type { IntegrationStep } from './ProviderForm';

export const StepsForm = ({
    form,
    steps,
    onSubmit,
    isPending,
    borderless,
    buttonSeparator,
    onCancel,
}: {
    form: UseFormReturn<v.InferInput<typeof IntegrationSettingsSchema>>;
    steps: IntegrationStep[];
    onSubmit: (values: v.InferInput<typeof IntegrationSettingsSchema>) => void;
    isPending: boolean;
    borderless?: boolean;
    buttonSeparator?: boolean;
    onCancel?: () => void;
}) => {
    const navigate = useNavigate();
    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="">
                {steps.map((s, i) => (
                    <FormField
                        control={form.control}
                        key={s.field}
                        name={`data.${s.field}`}
                        render={({ field }) => (
                            <FormItem
                                className={cn(
                                    'p-lg',
                                    i === 0 ? 'rounded-t-md' : '',
                                    borderless ? '' : 'border-t-[0.5px] border-r-[0.5px] border-l-[0.5px] border-grey',
                                )}
                            >
                                <FormLabel>{`${i + 1}. ${s.title}`}</FormLabel>
                                <FormControl>
                                    <Input placeholder={s.placeholder} {...field} />
                                </FormControl>
                                <FormMessage />
                                {s.description && <FormDescription>{s.description}</FormDescription>}
                            </FormItem>
                        )}
                    />
                ))}
                <div
                    className={cn(
                        'flex items-center justify-between w-full p-lg rounded-b-md',
                        borderless ? '' : 'border-[0.5px] border-grey',
                        buttonSeparator ? 'border-t-[0.5px] border-grey' : '',
                    )}
                >
                    <Button
                        mode="borderless"
                        onClick={onCancel || (() => navigate(SETTINGS_ORGANIZATION_INTEGRATION))}
                        disabled={isPending}
                    >
                        Cancel
                    </Button>
                    <Button variant="blue" mode="dark" type="submit" disabled={isPending}>
                        {isPending ? 'Verifying Credentials...' : 'Submit'}
                    </Button>
                </div>
            </form>
        </Form>
    );
};
