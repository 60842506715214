import { Separator } from '@ui/separator';

import { cn } from '@/lib/styling';

function Skeleton({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
    return <div className={cn('animate-pulse rounded-md overflow-hidden bg-bg-elevated', className)} {...props} />;
}

export { Skeleton };

export function SkeletonTable({ columns = 5, row = 5 }: { columns?: number; row?: number }) {
    return (
        <div className="flex w-full flex-col">
            {Array.from({ length: row }).map((_, i) => (
                <div key={i} className="border-subtle-hover flex h-10 border-b-[0.5px]">
                    {Array.from({ length: columns }).map((_, j) => (
                        <div key={j} className="px-md flex size-full items-center">
                            <Skeleton className="h-lg w-full" />
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export function SkeletonPlaybook() {
    return (
        <>
            <Skeleton className="h-48 w-36 rounded-l-sm rounded-r-lg" />
            <Skeleton className="h-48 w-36 rounded-l-sm rounded-r-lg" />
            <Skeleton className="h-48 w-36 rounded-l-sm rounded-r-lg" />
        </>
    );
}

export function SkeletonDetails() {
    return (
        <div className="flex size-full flex-col">
            <div className="p-lg bg-bg border-grey flex h-12 items-center border-b-[0.5px]">
                <Skeleton className="size-lg w-[150px] rounded-full" />
            </div>
            <div className="flex size-full items-start">
                <div className="bg-bg-overlay gap-xl flex h-full w-[300px] shrink-0 flex-col items-center p-6">
                    <div className="gap-lg flex flex-col items-center">
                        <Skeleton className="mt-12 size-24 rounded-full" />
                        <Skeleton className="h-7 w-[150px] rounded-full" />
                    </div>
                    <Separator />
                    <div className="gap-sm flex w-full flex-col">
                        <Skeleton className="h-7 w-full rounded-full" />
                        <Skeleton className="h-7 w-full rounded-full" />
                        <Skeleton className="h-7 w-full rounded-full" />
                    </div>
                </div>
                <div className="mx-2xl gap-4xl py-4xl flex h-full max-w-screen-sm flex-col min-[1260px]:m-auto">
                    <div className="gap-lg flex flex-col">
                        <Skeleton className="h-6 w-[250px]" />
                        <SkeletonKV />
                    </div>
                </div>
            </div>
        </div>
    );
}

export function SkeletonKV() {
    return (
        <div className="flex w-full flex-col">
            <div className="gap-md flex h-8">
                <Skeleton className="h-lg w-[150px]" />
                <Skeleton className="h-lg w-[350px]" />
            </div>
            <div className="gap-md flex h-8">
                <Skeleton className="h-lg w-[150px]" />
                <Skeleton className="h-lg w-[350px]" />
            </div>
            <div className="gap-md flex h-8">
                <Skeleton className="h-lg w-[150px]" />
                <Skeleton className="h-lg w-[350px]" />
            </div>
        </div>
    );
}

export function SkeletonRows() {
    return (
        <div className="border-grey flex w-full flex-col overflow-hidden rounded-md border-[0.5px]">
            <div className="bg-bg-overlay border-grey h-8 border-b-[0.5px]" />
            <div className="gap-md p-md border-subtle flex h-[54px] w-full items-center border-b">
                <Skeleton className="size-8 shrink-0 rounded-full" />
                <div className="gap-sm flex w-full flex-col">
                    <Skeleton className="h-[14px] w-full" />
                    <Skeleton className="h-[12px] w-[150px]" />
                </div>
            </div>
            <div className="gap-md p-md border-subtle flex h-[54px] w-full items-center border-b">
                <Skeleton className="size-8 shrink-0 rounded-full" />
                <div className="gap-sm flex w-full flex-col">
                    <Skeleton className="h-[14px] w-full" />
                    <Skeleton className="h-[12px] w-[150px]" />
                </div>
            </div>
            <div className="gap-md p-md border-subtle flex h-[54px] w-full items-center border-b">
                <Skeleton className="size-8 shrink-0 rounded-full" />
                <div className="gap-sm flex w-full flex-col">
                    <Skeleton className="h-[14px] w-full" />
                    <Skeleton className="h-[12px] w-[150px]" />
                </div>
            </div>
        </div>
    );
}
