import type { GroupBase, ValueContainerProps } from 'react-select';
import { components as SelectPrimitiveComponents } from 'react-select';

export const GenericValuesContainer = <
    Option extends { label: string; value: string } | null,
    IsMulti extends boolean = true,
    Group extends GroupBase<Option> = GroupBase<Option>,
>({
    children,
    ...props
}: ValueContainerProps<Option, IsMulti, Group>) => {
    const isSelected = props.getValue().length > 0;
    const isOne = props.getValue().length === 1;
    return (
        <SelectPrimitiveComponents.ValueContainer {...props}>
            {isSelected && (
                <div className="gap-md flex items-center">
                    {isOne ? props.getValue()[0]!.label : <div>{`${props.getValue().length} selected`}</div>}
                </div>
            )}
            {children}
        </SelectPrimitiveComponents.ValueContainer>
    );
};
