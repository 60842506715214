import { Button } from '@/components/ui/button';
import { usePlaybookCreate } from '@/hooks/mutations/usePlaybook';
import { useOrgOnboarding } from '@/hooks/queries/useOrgOnboarding';
import { SETTINGS_ORGANIZATION_INTEGRATION, SETTINGS_REQUESTS } from '@/routes/paths';
import { BookText, Check, Settings } from 'lucide-react';
import { Link } from 'react-router-dom';

export interface OnboardingStep {
    step: number;
    title: string;
    description: string;
    content: React.ReactNode;
    completed: boolean;
}

export const useOnboardingSteps = () => {
    const { data } = useOrgOnboarding();
    const createPlaybook = usePlaybookCreate();

    if (!data?.onboarding) {
        return [];
    }

    const steps: OnboardingStep[] = [
        {
            step: 1,
            title: 'Connect your identity provider',
            description: 'Import users and apps from Okta`',
            content: data.onboarding.integratedOkta ? (
                <div className="flex gap-1 items-center">
                    <Check />
                    <h3>Connected Okta</h3>
                </div>
            ) : (
                <Link to={`${SETTINGS_ORGANIZATION_INTEGRATION}/okta`}>
                    <Button>Integration Okta</Button>
                </Link>
            ),
            completed: data.onboarding.integratedOkta,
        },
        {
            step: 2,
            title: 'Connect your help desk',
            description: 'Console will let users file issues if our AI cannot handle their request',
            content: data.onboarding.integratedHelpDesk ? (
                <div className="flex gap-2 items-center">
                    <Check />
                    <h3>Connected Help Desk</h3>
                </div>
            ) : (
                <div className="flex gap-2 items-center">
                    Select from:
                    <Link to={`${SETTINGS_ORGANIZATION_INTEGRATION}/freshservice`}>
                        <Button>Freshservice</Button>
                    </Link>
                    <Link to={`${SETTINGS_ORGANIZATION_INTEGRATION}/zendesk`}>
                        <Button>Zendesk</Button>
                    </Link>
                </div>
            ),
            completed: data.onboarding.integratedHelpDesk,
        },
        {
            step: 3,
            title: 'Configure how tickets are created',
            description: "Determine which fields are visible to users and how they're set",
            content: data.onboarding.requestsConfigured ? (
                <div className="flex gap-2 items-center">
                    <Check />
                    <h3>Configured Tickets</h3>
                </div>
            ) : (
                <Link to={SETTINGS_REQUESTS}>
                    <Button>
                        <Settings className="size-4" />
                        Configure Tickets
                    </Button>
                </Link>
            ),
            completed: data.onboarding.requestsConfigured,
        },
        {
            step: 4,
            title: 'Create a Playbook',
            description: 'Create your first Playbook automation to handle common requests',
            content: data.onboarding.createdPlaybook ? (
                <div className="flex gap-2 items-center">
                    <Check />
                    <h3>Created Playbook</h3>
                </div>
            ) : (
                <Button onClick={() => createPlaybook.mutate({})}>
                    <BookText className="size-4" />
                    Create Playbook
                </Button>
            ),
            completed: data.onboarding.createdPlaybook,
        },
        {
            step: 5,
            title: 'Install the Slackbot',
            description: 'Enable the /help command in your workspace to make it easy for users to file requests',
            content: data.onboarding.integratedSlackBot ? (
                <div className="flex gap-2 items-center">
                    <Check />
                    <h3>Installed Slackbot</h3>
                </div>
            ) : (
                <Link to={`${SETTINGS_ORGANIZATION_INTEGRATION}/slack`}>
                    <img src="/3p/slack-logo.png" alt="Slack Logo" className="size-4" />
                    <Button>Install Slackbot</Button>
                </Link>
            ),
            completed: data.onboarding.integratedSlackBot,
        },
        {
            step: 6,
            title: 'Send your first request',
            description: 'Just type /help followed by your request anywhere in Slack',
            content: data.onboarding.createdRequest ? (
                <div className="flex gap-2 items-center">
                    <Check />
                    <h3>Sent Request</h3>
                </div>
            ) : (
                <Link to="slack://open">
                    <Button>
                        <img src="/3p/slack-logo.png" alt="Slack Logo" className="size-4" />
                        Open Slack
                    </Button>
                </Link>
            ),
            completed: data.onboarding.createdRequest,
        },
    ];

    return steps;
};
