import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';

import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';

import { ArrowRight, BookOpenText, Info } from 'lucide-react';

export const InstructionsInfoDialog = () => {
    return (
        <Button
            mode="borderless"
            size="sm"
            className="rounded-full text-body-subtle hover:text-body-subtle-hover"
            tabIndex={-1}
        >
            <Dialog>
                <DialogTrigger asChild>
                    <Info className="size-3.5" />
                </DialogTrigger>
                <DialogContent>
                    <div className="p-lg flex flex-col items-center gap-xl text-center text-sm">
                        <div className="flex flex-col items-center gap-sm pt-lg">
                            <BookOpenText className="size-2xl" />
                            <h3 className="text-xl">Instructions</h3>
                            <p className="text-body-subtle-hover text-base">
                                What should Console do when it runs this Playbook?
                            </p>
                        </div>
                        <Separator className="my-md" />
                        <p>
                            You can type # to reference an{' '}
                            <span className="bg-bg-blue-primary text-body-blue-primary rounded-sm px-sm py-px font-medium">
                                #Action
                            </span>{' '}
                            or another{' '}
                            <span className="bg-bg-orange-primary text-body-orange-primary rounded-sm px-sm py-px font-medium">
                                #Playbook
                            </span>
                            , or you can type @ to reference a{' '}
                            <span className="bg-bg-yellow-primary text-body-yellow-primary rounded-sm px-sm py-px font-medium">
                                @User
                            </span>
                            ,{' '}
                            <span className="bg-bg-green-primary text-body-green-primary rounded-sm px-sm py-px font-medium">
                                @Group
                            </span>{' '}
                            or{' '}
                            <span className="bg-bg-purple-primary text-body-purple-primary rounded-sm px-sm py-px font-medium">
                                @App
                            </span>
                        </p>
                        <div className="flex flex-col items-start text-left gap-sm pl-xl pt-xl bg-bg-overlay border-grey border-[0.5px] rounded-md overflow-hidden">
                            <h6 className="mb-md">Example instructions for an office wifi Playbook</h6>
                            <div className="pl-2xl pt-2xl pb-2xl bg-bg border-t-[0.5px] border-l-[0.5px] border-grey shadow-lg flex flex-col items-start gap-lg">
                                <div className="flex items-center gap-md font-mono uppercase w-full border-b-[0.5px] border-grey mt-2xl mb-md">
                                    <ArrowRight />
                                    Instructions
                                </div>
                                <p>
                                    <span className="font-medium">Password Request:</span> If the user just needs the
                                    password, tell them it's Password1234.
                                </p>
                                <p>
                                    <span className="font-medium">New Device:</span> If they've never connected this
                                    device before, tell them the network is companyWifi and the password is
                                    Password1234. Once the modal appears, they'll need to log in with their company
                                    email and password before they can connect.
                                </p>
                                <p>
                                    <span className="font-medium">Troubleshooting:</span> If the user is having trouble
                                    connecting, run them through traditional wifi troubleshooting steps.
                                </p>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </Button>
    );
};
