import { Brow } from '@/components/Brow';
import { ResLink } from '@/components/ResLink';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { useROI } from '@/hooks/queries/useROI';
import { useSignupStatus } from '@/hooks/queries/useSignup';
import { SETTINGS_ORGANIZATION_INTEGRATION } from '@/routes/paths';
import { useUser } from '@/stores/useUser';
import { Tooltip, TooltipContent, TooltipTrigger } from '@ui/tooltip';
import { BookCopy, BookOpenText } from 'lucide-react';
import { ArrowRight, Asterisk, Brain, FolderSync, Gem, Scale, ScrollText, Sparkles } from 'lucide-react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Cell, Pie, PieChart } from 'recharts';
import { ProcessTickets } from '../signup';
import { AppAccessDialog } from './AppAccessDialog';
import { KnowledgeBaseDialog } from './KnowledgeBaseDialog';
import { PlaybooksDialog } from './PlaybooksDialog';

const ROICard = ({
    title,
    description,
    viewAll,
    data,
}: {
    title: string;
    description: string;
    viewAll: React.ReactNode;
    data: { name: string; n: number; percentage: number; icon: JSX.Element }[];
}) => {
    return (
        <div className="p-2xl rounded-lg bg-bg border-[0.5px] border-grey w-full max-w-[500px] flex flex-col gap-2xl shadow-md">
            <div className="flex flex-col gap-sm">
                <h3 className="text-base text-center">{title}</h3>
                <p className="text-body-subtle-hover text-center">{description}</p>
                <div className="flex justify-center">{viewAll}</div>
            </div>
            <div className="flex flex-col">
                {data.map(item => (
                    <div
                        key={item.name}
                        className="flex items-center h-2xl justify-between border-b border-grey border-dashed last:border-none"
                    >
                        {item.icon}
                        <div className="flex gap-md items-center">
                            <p className="font-medium">{item.n}</p>
                            <Badge className="text-body-subtle-hover rounded-sm h-[18px] px-sm">
                                {item.percentage}%
                            </Badge>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const COLORS = [
    'chart-pink',
    'chart-plum',
    'chart-eggplant',
    'chart-violet',
    'chart-grape',
    'chart-royal',
    'chart-sky',
    'chart-cyan',
    'chart-teal',
    'chart-seafoam',
    'chart-emerald',
    'chart-grass',
    'chart-lime',
    'chart-sunbeam',
    'chart-gold',
    'chart-carrot',
    'chart-tomato',
    'chart-brick',
    'chart-bronze',
];

// temp hack
function capitalizeWords(str: string) {
    return str
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

export const ROIPage = () => {
    const { orgId = '' } = useParams();
    const { data: roi } = useROI(orgId);
    const data = roi?.rollup;

    const [appAccessOpen, setAppAccessOpen] = useState(false);
    const [kbOpen, setKbOpen] = useState(false);
    const [playbooksOpen, setPlaybooksOpen] = useState(false);

    const nerfed = useUser(state => state?.user?.orgMemberships?.some(m => m.nerfed));

    const { data: signupStatus } = useSignupStatus(nerfed);

    if (nerfed && signupStatus?.status === 'processing') {
        return (
            <div className="flex justify-center items-center w-full h-full">
                <Card className="px-0 max-w-[375px]">
                    <ProcessTickets />
                </Card>
            </div>
        );
    }

    if (!roi?.rollup || !data) {
        return null;
    }
    const resolutionHours = Math.round((roi.rollup.data.resolutionTimes?.excludedAverageResolutionTime ?? 0) / 60);

    const totalClassifiedPercentage = Math.round((data.solvable / data.totalCount) * 100);
    const appAccessPercentage = Math.round((data.primaryAppAccessCount / data.totalCount) * 100);
    const knowledgeBasePercentage = Math.round((data.primaryKbArticleCount / data.totalCount) * 100);
    const playbooksPercentage = Math.round((data.primaryPlaybookCount / data.totalCount) * 100);

    const appAccessData = data.data.topAppAccess.map(item => ({
        name: capitalizeWords(item.appName),
        n: item.count,
        percentage: Math.round((item.count / data.primaryAppAccessCount) * 100),
        icon: (
            <ResLink
                className="text-xs"
                bold
                size="sm"
                disableLink
                id={item.appName.toLowerCase()}
                label={capitalizeWords(item.appName)}
                src={`/api/v1/brands/${item.appName.toLowerCase()}/logo`}
            />
        ),
    }));

    const knowledgeBaseData = data.data.suggestedArticles.map((item, index) => ({
        name: item.name,
        n: item.count,
        percentage: Math.round((item.count / data.primaryKbArticleCount) * 100),
        icon: (
            <div className="flex gap-md items-center">
                <div
                    className={`size-md rounded-[2px] shrink-0`}
                    style={{ backgroundColor: `hsl(var(--color-${COLORS[index % COLORS.length]}))` }}
                />
                <h6 className="line-clamp-1 text-xs">{item.name}</h6>
            </div>
        ),
    }));

    const playbookData = data.data.topSuggestedPlaybooks.map((item, index) => ({
        value: Math.round((item.count / data.primaryPlaybookCount) * 100),
        name: item.playbookName,
        icon: <BookOpenText style={{ color: `hsl(var(--color-${COLORS[index % COLORS.length]}))` }} />,
    }));

    return (
        <>
            <Brow title="Your ROI report" icon={<Gem />} />
            <div className="overflow-y-auto size-full">
                <div className="p-4xl w-full relative">
                    <img
                        src="/emptyStates/empty-circles.svg"
                        alt="Concentric circles"
                        className="size-[640px] absolute -top-[182px] left-[50%] translate-x-[-50%] z-0"
                    />
                    <div className="flex flex-col items-center text-center gap-lg max-w-[640px] mx-auto z-10 relative">
                        <Badge>
                            <Sparkles className="size-3" />
                            Powered by AI
                        </Badge>
                        <div className="text-2xl font-medium flex items-center gap-md justify-center">
                            Automate up to{' '}
                            <span className="text-body-brand font-bold text-6xl">{totalClassifiedPercentage}%</span> of
                            your tickets
                        </div>
                        <p className="text-xl text-body-subtle-hover">
                            After analyzing {data.totalCount} tickets, we think that Console can
                            <span className="text-body-brand font-medium relative">
                                {' '}
                                reduce your average TTR by {resolutionHours} hours
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <div className="rounded-full size-3.5 bg-bg-brand text-body-white inline-flex items-center justify-center ml-1 absolute hover:bg-bg-brand-hover transition-all duration-150 ease-out cursor-help">
                                            <Asterisk className="size-3.5" />
                                        </div>
                                    </TooltipTrigger>
                                    <TooltipContent
                                        className="bg-bg text-body-grey-primary max-w-[350px] text-left border-grey border-[0.5px] !py-md rounded-[12px]"
                                        side="bottom"
                                        align="start"
                                    >
                                        <img src="/math-numbers.gif" alt="ROI tooltip" className="rounded-sm mb-md" />
                                        <h3 className="text-sm mb-sm">How did you get this number?</h3>
                                        <p className="text-xs text-body-subtle-hover">
                                            By looking at the TTR (Total Time to Resolution) on every ticket over the
                                            past year that Console could have automated for you, we were able to
                                            calculate total time savings for your team and employees
                                        </p>
                                    </TooltipContent>
                                </Tooltip>
                            </span>
                        </p>
                    </div>
                </div>
                <div className="flex flex-col items-center text-center md:text-left md:flex-row gap-4xl w-full p-4xl relative md:justify-center">
                    <div className="flex flex-col justify-center items-center md:items-start gap-2xl max-w-[640px]">
                        <div className="flex flex-col gap-sm">
                            <h2 className="flex items-center justify-center md:justify-start text-body-subtle-hover gap-sm">
                                <Brain />
                                Knowledge Base
                            </h2>
                            <p className="text-2xl font-medium">
                                {knowledgeBasePercentage}% of your tickets are basic questions that Console can answer
                                for you
                            </p>
                            <p className="text-base text-body-subtle-hover">
                                With Knowledge Base, Console can automate knowledge retrieval and eliminate copy/pasting
                                canned responses or linking to articles.
                            </p>
                        </div>
                        <div className="flex flex-col gap-[1px] p-md rounded-md border-grey border border-dashed bg-bg-surface text-xs">
                            <div className="flex items-center gap-sm">
                                <FolderSync className="size-3.5" />
                                <span className="font-medium">Sync from</span>
                                <Link to={SETTINGS_ORGANIZATION_INTEGRATION}>
                                    <Button size="sm">
                                        <>
                                            <img src="/3p/zendesk-logo.png" alt="Zendesk logo" className="size-3.5" />
                                            Zendesk
                                        </>
                                    </Button>
                                </Link>
                                <span className="font-medium">or</span>
                                <Link to={SETTINGS_ORGANIZATION_INTEGRATION}>
                                    <Button size="sm">
                                        <>
                                            <img src="/3p/notion-logo.png" alt="Notion logo" className="size-3.5" />
                                            Notion
                                        </>
                                    </Button>
                                </Link>
                            </div>
                            <p className="ml-[18px] text-body-subtle-hover">
                                <Link to="/articles">
                                    <span className="text-body-brand hover:text-body-brand-hover font-medium">
                                        or write your own
                                    </span>
                                </Link>{' '}
                                to begin automating up to {knowledgeBasePercentage}% of requests
                            </p>
                        </div>
                    </div>
                    <ROICard
                        title={`Employees asked ${data.primaryKbArticleCount} questions`}
                        description="Here are the most common questions that were asked"
                        viewAll={
                            <Button
                                mode="borderless"
                                className="text-body-brand hover:bg-transparent hover:text-body-brand-hover"
                                onClick={() => setKbOpen(true)}
                            >
                                View all questions
                            </Button>
                        }
                        data={knowledgeBaseData.slice(0, 5)}
                    />
                    <KnowledgeBaseDialog
                        open={kbOpen}
                        data={knowledgeBaseData}
                        tickets={roi.kbArticle}
                        onCancel={() => setKbOpen(false)}
                        count={data.primaryKbArticleCount}
                    />
                </div>
                <div className="flex flex-col md:flex-row-reverse items-center text-center md:text-left gap-4xl w-full p-4xl relative bg-bg-overlay md:justify-center">
                    <div className="flex flex-col justify-center items-center md:items-start gap-2xl max-w-[640px]">
                        <div className="flex flex-col gap-sm">
                            <h2 className="flex items-center justify-center md:justify-start text-body-subtle-hover gap-sm">
                                <Scale />
                                Access Policy
                            </h2>
                            <p className="text-2xl font-medium">
                                {appAccessPercentage}% of your tickets are app access requests that Console can fulfill
                                automatically
                            </p>
                            <p className="text-base text-body-subtle-hover">
                                With Access Policies, you can use Console to automate approval chains, timed access and
                                complex provisioning flows.
                            </p>
                        </div>
                        <Link
                            className="group flex flex-col gap-[1px] p-md rounded-md border-grey border border-dashed bg-bg-surface hover:bg-bg-elevated text-xs transition-all duration-150 ease-out"
                            to="/policies"
                        >
                            <div className="flex items-center gap-sm">
                                <ScrollText className="size-3.5" />
                                <span className="font-medium">Define your first access policy</span>
                                <ArrowRight className="size-3.5 transition-all duration-150 ease-out group-hover:ml-sm" />
                            </div>
                            <p className="ml-[18px] text-body-subtle-hover">
                                Set just 5 policies to automate up to{' '}
                                {appAccessData.slice(0, 5).reduce((acc, v) => acc + v.percentage, 0)}% of your access
                                requests
                            </p>
                        </Link>
                    </div>
                    <ROICard
                        title={`Employees made ${data.primaryAppAccessCount} app access requests`}
                        description="Here are the most commonly requested apps"
                        viewAll={
                            <Button
                                mode="borderless"
                                className="text-body-brand hover:bg-transparent hover:text-body-brand-hover"
                                onClick={() => setAppAccessOpen(true)}
                            >
                                View all apps
                            </Button>
                        }
                        data={appAccessData.slice(0, 5)}
                    />
                    <AppAccessDialog
                        open={appAccessOpen}
                        data={appAccessData}
                        tickets={roi.appAccess}
                        onCancel={() => setAppAccessOpen(false)}
                        count={data.primaryAppAccessCount}
                    />
                </div>
                <div className="flex flex-col items-center text-center md:text-left md:flex-row gap-4xl w-full p-4xl relative md:justify-center">
                    <div className="flex flex-col justify-center items-center md:items-start gap-2xl max-w-[640px]">
                        <div className="flex flex-col gap-sm">
                            <h2 className="flex items-center justify-center md:justify-start text-body-subtle-hover gap-sm">
                                <BookCopy />
                                Playbooks
                            </h2>
                            <p className="text-2xl font-medium">
                                {playbooksPercentage}% of your tickets are common support requests that you can teach
                                Console to solve
                            </p>
                            <p className="text-base text-body-subtle-hover">
                                With Playbooks, you can train Console to resolve common support requests just like a
                                human agent - in plain English.
                            </p>
                        </div>
                    </div>
                    <div className="p-2xl rounded-lg bg-bg border-[0.5px] border-grey w-full max-w-[500px] flex flex-col gap-md shadow-md items-center">
                        <PieChart width={320} height={160}>
                            <Pie
                                dataKey="value"
                                startAngle={180}
                                endAngle={0}
                                data={playbookData}
                                cx="50%"
                                cy="100%"
                                outerRadius="200%"
                                innerRadius="140%"
                                fill="#8884d8"
                            >
                                {playbookData.map((_entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        style={{ color: `hsl(var(--color-${COLORS[index % COLORS.length]}))` }}
                                        fill="currentColor"
                                    />
                                ))}
                            </Pie>
                        </PieChart>
                        <div className="absolute flex flex-col justify-center items-center" style={{ top: '175px' }}>
                            <div className="text-2xl font-medium">{data.primaryPlaybookCount}</div>
                            <div className="text-body-subtle-hover">automatable requests</div>
                        </div>
                        <div className="text-lg font-medium">
                            Employees submitted {data.primaryPlaybookCount} requests
                        </div>
                        <div>Here is a breakdown of your tickets by automation</div>
                        <Button
                            mode="borderless"
                            className="text-body-brand hover:bg-transparent hover:text-body-brand-hover"
                            onClick={() => setPlaybooksOpen(true)}
                        >
                            View full breakdown
                        </Button>
                    </div>
                    <PlaybooksDialog
                        open={playbooksOpen}
                        data={playbookData}
                        onCancel={() => setPlaybooksOpen(false)}
                        tickets={roi.playbook}
                        count={data.primaryPlaybookCount}
                    />
                </div>
            </div>
        </>
    );
};
