import { EntryScreen } from '@/components/EntryScreen';
import { CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@ui/button';
import { Input } from '@ui/input';
import strings from 'lib/strings';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
const pathname = '/auth/login';
import FlaggedFeature from '@/components/FlaggedFeature';
import { useGoogleSignupUrl } from '@/hooks/queries/useOAuth';
import { getProviderAssets } from 'lib/3p';
import { FlagKey } from 'lib/flags/keys';
import { IntegrationId } from 'lib/integration';

const isToast = (error: string) => ['auth_error', 'unconfigured'].includes(error);

const Login = () => {
    const { data } = useGoogleSignupUrl();
    const [searchParams] = useSearchParams();
    const error = searchParams.get('error');
    const errorConfig = useMemo(
        () => (error ? { type: isToast(error) ? 'toast' : 'inline', msg: strings.login[error] } : null),
        [error],
    );
    const googleIcon = getProviderAssets(IntegrationId.Google).logo;

    const onEmailInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchParams.set('email', event.target.value);
    };

    useEffect(() => {
        if (errorConfig && errorConfig.type === 'toast') {
            toast.error(errorConfig.msg);
        }
    }, [errorConfig]);

    const handleMailto = () => {
        window.location.href = 'mailto:waitlist@console.co';
    };

    const handleLogin = () => {
        searchParams.delete('error');
        window.location.href = `${pathname}?${searchParams.toString()}`;
    };

    return (
        <EntryScreen>
            <CardHeader>
                <CardTitle className="text-center">Log in to Console</CardTitle>
            </CardHeader>
            <CardContent>
                <form className="gap-sm flex flex-col" onSubmit={e => e.preventDefault()}>
                    <Input
                        id="email"
                        name="email"
                        defaultValue={searchParams.get('email') ?? ''}
                        onChange={onEmailInput}
                        required
                        placeholder="Enter your email address"
                        className="placeholder:text-center"
                        autoComplete="off"
                        data-testid="email-input"
                    />
                    <div>
                        {errorConfig?.type === 'inline' && (
                            <div className="text-center text-red-900">{errorConfig.msg}</div>
                        )}
                    </div>
                    <div className="flex flex-col gap-lg">
                        <Button
                            mode="dark"
                            variant="grey"
                            className="w-full"
                            onClick={handleLogin}
                            data-testid="continue-button"
                        >
                            Continue &rarr;
                        </Button>

                        <FlaggedFeature
                            flag={FlagKey.SelfService}
                            fallback={
                                <Button
                                    mode="borderless"
                                    variant="grey"
                                    className="text-body-subtle hover:text-body-grey-primary w-full"
                                    onClick={handleMailto}
                                    rel="noreferrer noopener"
                                >
                                    Request early access
                                </Button>
                            }
                        >
                            <div className="flex w-full items-center justify-center gap-lg">
                                <div className="flex-1 h-[0.5px] bg-bg-grey-primary" />
                                <div className="text-body-subtle">or</div>
                                <div className="flex-1 h-[0.5px] bg-bg-grey-primary" />
                            </div>
                            <Button className="w-full">
                                <a
                                    href={data?.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="size-full flex gap-sm items-center justify-center"
                                >
                                    <img src={googleIcon} alt="Google" className="size-[14px]" />
                                    Sign up with Google
                                </a>
                            </Button>
                        </FlaggedFeature>
                    </div>
                </form>
            </CardContent>
        </EntryScreen>
    );
};

export default Login;
