import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';

export function PolicyStepListHeading({
    heading,
    description,
    onAdd,
}: {
    heading: string;
    description: string;
    onAdd: () => void;
}) {
    return (
        <div className="flex justify-between">
            <div className="flex flex-col mb-md text-[13px]">
                <h4>{heading}</h4>
                <p className="text-body-subtle-hover">{description}</p>
            </div>
            <Button className="gap-sm flex items-center" onClick={onAdd}>
                <Plus />
            </Button>
        </div>
    );
}
