import { TrendingIndicator } from '@/components/TrendingIndicator';
import {
    GenResArea,
    type ResStatus,
    ResolutionTooltip,
    computeTrending,
    makeLabel,
    statusConfig,
} from '@/components/charts/RequestChart';
import { Badge } from '@/components/ui/badge';
import { useResolutionStats } from '@/hooks/queries/useInsights';
import { cn } from '@/lib/styling';
import type { RequestFilters } from 'lib/models/cnsl_request';
import { ChartInterval } from 'lib/models/insights';
import type { ResolutionStatsRow } from 'lib/models/request';
import { useEffect, useState } from 'react';
import { AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

interface ResolutionGraphProps extends React.HTMLAttributes<HTMLDivElement> {
    filters: RequestFilters;
}

const TotalWithTrending = ({ rows, interval }: { rows: ResolutionStatsRow[]; interval: ChartInterval }) => {
    const { trendingPercentage, direction, showTrending, currentVolume } = computeTrending(rows);

    return (
        <div className="flex text-body text-lg font-medium p-lg gap-md">
            <span>
                {currentVolume} {currentVolume === 1 ? 'request' : 'requests'}{' '}
                {interval === ChartInterval.WEEK ? 'this week' : 'today'}
            </span>
            {showTrending && (
                <Badge className="flex items-center gap-x-sm">
                    <TrendingIndicator direction={direction} value={trendingPercentage} />
                </Badge>
            )}
        </div>
    );
};

export const ResolutionGraph = ({ filters, className, ...props }: ResolutionGraphProps) => {
    const [currentInterval, setCurrentInterval] = useState(ChartInterval.WEEK);

    const { data } = useResolutionStats(currentInterval);
    const rows = data?.rows ?? [];

    useEffect(() => {
        if (currentInterval === ChartInterval.WEEK && rows.length > 0 && rows.length < 2) {
            setCurrentInterval(ChartInterval.DAY);
        }
    }, [rows.length, currentInterval]);

    const tickFormatter = (idx: number) => {
        const label = makeLabel(idx, rows, currentInterval);
        const offset = currentInterval === ChartInterval.DAY ? 4 : 6;
        if (idx === 0) {
            return label.padStart(label.length + offset, ' ');
        }
        if (idx === rows.length - 1) {
            return label.padEnd(label.length + offset, ' ');
        }
        return label;
    };

    const statuses: ResStatus[] = ['in_progress', 'escalated', 'auto_resolved'];

    return rows.length > 0 ? (
        <div className={className}>
            <div className="flex flex-col gap-2 rounded-lg border border-bg-grey-primary" {...props}>
                <ResponsiveContainer height={282}>
                    <AreaChart data={rows} margin={{ top: 0, right: 0, left: 0, bottom: 10 }}>
                        <Legend
                            verticalAlign={'top'}
                            align="right"
                            content={
                                <div className="flex gap-lg justify-between">
                                    <TotalWithTrending rows={rows} interval={currentInterval} />
                                    <div className="text-grey-primary flex justify-end gap-x-lg p-lg">
                                        {statuses.map(status => (
                                            <div className="flex gap-sm items-center" key={status}>
                                                <div className={cn('size-md rounded-[2px]', statusConfig[status].bg)} />
                                                <div>{statusConfig[status].name}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                        />
                        <CartesianGrid strokeDasharray="2 2" vertical={false} />
                        <defs>
                            {statuses.map(status => (
                                <linearGradient key={status} id={`color_${status}`} x1="0" y1="0" x2="0" y2="1">
                                    <stop stopColor={statusConfig[status].hex} />
                                    <stop offset="1" stopColor={statusConfig[status].hex} stopOpacity="0.3" />
                                </linearGradient>
                            ))}
                        </defs>
                        <XAxis
                            tickFormatter={tickFormatter}
                            interval={'preserveStartEnd'}
                            tickMargin={10}
                            axisLine={{ stroke: 'hsla(215, 19%, 89%, 1)' }}
                            tickLine={false}
                            padding={{ left: 0, right: 0 }}
                        />
                        <YAxis hide />
                        <Tooltip content={<ResolutionTooltip rows={rows} />} animationDuration={120} />

                        {statuses.map(status => GenResArea({ status }))}
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </div>
    ) : null;
};
