import { useMutation } from '@lib/hooks/useMutation';
import { type DefaultError, useQueryClient } from '@tanstack/react-query';
import type { ArticleResponse, ArticleUpdateSchemaType } from 'lib/models/article';
import { useNavigate } from 'react-router-dom';

import { ARTICLES, type ARTICLE_DETAILS_TYPE } from '@/routes/paths';

export const useArticleCreate = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    return useMutation<ArticleResponse, DefaultError>({
        url: `/api/v1/articles`,
        method: 'POST',
        onSuccess: async ({ article }) => {
            await queryClient.refetchQueries({ queryKey: [`/api/v1/directory`] });
            await queryClient.refetchQueries({ queryKey: [`/api/v1/articles`] });
            navigate(`${ARTICLES}/${article.slug}` as ARTICLE_DETAILS_TYPE);
        },
        successToast: {
            message: 'Article draft created',
        },
    });
};

export const useArticleDelete = (slug?: string) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    return useMutation<ArticleResponse, DefaultError, void>({
        url: `/api/v1/articles/${slug}`,
        method: 'DELETE',
        onSuccess: async () => {
            await queryClient.refetchQueries({ queryKey: [`/api/v1/directory`] });
            await queryClient.refetchQueries({ queryKey: [`/api/v1/articles`], exact: true });
            navigate(ARTICLES);
        },
        successToast: {
            message: 'Article deleted',
        },
    });
};

export const useArticleUpdate = (slug?: string) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    return useMutation<ArticleResponse, DefaultError, ArticleUpdateSchemaType>({
        url: `/api/v1/articles/${slug}`,
        method: 'PUT',
        onSuccess: async ({ article }) => {
            if (article.slug !== slug) {
                navigate(`${ARTICLES}/${article.slug}` as ARTICLE_DETAILS_TYPE);
            } else {
                await queryClient.refetchQueries({ queryKey: [`/api/v1/articles/${article.slug}`], exact: true });
                await queryClient.refetchQueries({ queryKey: [`/api/v1/articles`], exact: true });
            }
        },
    });
};

export const useArticleUnpublish = (slug?: string) => {
    const queryClient = useQueryClient();
    return useMutation<ArticleResponse, DefaultError, void>({
        url: `/api/v1/articles/${slug}/unpublish`,
        method: 'PUT',
        onSuccess: async ({ article }) => {
            await queryClient.refetchQueries({ queryKey: [`/api/v1/articles/${article.slug}`], exact: true });
        },
        successToast: {
            message: 'Article moved to draft',
        },
    });
};

export const useArticlePublish = (slug?: string) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    return useMutation<ArticleResponse, DefaultError, ArticleUpdateSchemaType>({
        url: `/api/v1/articles/${slug}/publish`,
        method: 'PUT',
        onSuccess: async ({ article }) => {
            if (article.slug !== slug) {
                navigate(`${ARTICLES}/${article.slug}` as ARTICLE_DETAILS_TYPE, { replace: true });
            } else {
                await Promise.all([
                    queryClient.refetchQueries({ queryKey: [`/api/v1/articles/${article.slug}`], exact: true }),
                    queryClient.refetchQueries({ queryKey: [`/api/v1/articles/`], exact: true }),
                ]);
            }
        },
        successToast: {
            message: 'Article published',
        },
    });
};
