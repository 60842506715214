import { createContext } from 'react';

import { attachInstruction, extractInstruction } from '@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item';
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/tree-item';

import { PreviewDefault, type TreeComponents } from '.';
import type { TreeAction, TreeItem } from './tree';

export type TreeContextValue = {
    dispatch: (action: TreeAction) => void;
    uniqueContextId: symbol;
    getPathToItem: (itemId: string | number) => (string | number)[];
    getMoveTargets: ({ itemId }: { itemId: string | number }) => TreeItem[];
    getChildrenOfItem: (itemId: string | number) => TreeItem[];
    registerTreeItem: (args: {
        itemId: string | number;
        element: HTMLElement;
    }) => void;
    components: TreeComponents;
};

export const TreeContext = createContext<TreeContextValue>({
    dispatch: () => {},
    uniqueContextId: Symbol('uniqueId'),
    getPathToItem: () => [],
    getMoveTargets: () => [],
    getChildrenOfItem: () => [],
    registerTreeItem: () => {},
    components: { Preview: PreviewDefault },
});

export type DependencyContext = {
    DropIndicator: typeof DropIndicator;
    attachInstruction: typeof attachInstruction;
    extractInstruction: typeof extractInstruction;
};

export const DependencyContext = createContext<DependencyContext>({
    DropIndicator: DropIndicator,
    attachInstruction: attachInstruction,
    extractInstruction: extractInstruction,
});
