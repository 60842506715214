import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useAppMatrixUpload } from '@/hooks/mutations/useAppMatrix';
import { useAppMatrixJobStatus } from '@/hooks/queries/useAppMatrix';
import { useQueryClient } from '@tanstack/react-query';

import { CloudUpload } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

export const CsvDnd = ({
    onFinish,
    showModal,
    setShowModal,
}: { onFinish: () => void; showModal: boolean; setShowModal: (show: boolean) => void }) => {
    const queryClient = useQueryClient();
    const [jobId, setJobId] = useState<string | null>(null);

    const { mutate: uploadAppMatrix } = useAppMatrixUpload();

    const { data } = useAppMatrixJobStatus(jobId!, {
        enabled: !!jobId,
        refetchInterval: 1000,
    });

    useEffect(() => {
        if (data?.status === 100) {
            queryClient.refetchQueries({ queryKey: [`/api/v1/app-matrix`], exact: true }).then(() => {
                setShowModal(false);
                onFinish();
                setJobId(null);
            });
        }
    }, [data?.status, onFinish, queryClient, setShowModal]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: async (files: File[]) => {
            if (files.length > 0) {
                const formData = new FormData();
                formData.append('appMatrix', files[0]);
                uploadAppMatrix(formData, {
                    onSuccess: async data => {
                        const body = await data.json();
                        setJobId(body.jobId);
                    },
                });
            }
        },
        accept: {
            'text/csv': ['.csv'],
        },
        maxFiles: 1,
    });

    return (
        <Dialog open={showModal} onOpenChange={setShowModal}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        <CloudUpload className="size-5" /> Import app access policies
                    </DialogTitle>
                </DialogHeader>

                <section>
                    <div className="flex flex-col gap-md p-lg">
                        <h3>
                            {jobId
                                ? 'Uploading your app matrix - this may take a moment'
                                : 'Upload your existing app matrix'}
                        </h3>
                        <div
                            className="p-lg border-grey border border-dashed rounded-md bg-bg-surface cursor-pointer flex items-center justify-start gap-lg"
                            {...(!jobId && getRootProps())}
                        >
                            {!jobId && <input {...getInputProps()} />}
                            <div
                                className={`size-10 rounded-full flex items-center justify-center p-sm border-[0.5px] transition-all duration-500 ease-out ${
                                    jobId || isDragActive
                                        ? 'bg-gradient-to-b from-blue-200 to-blue-500 border-blue shadow-upload'
                                        : 'bg-gradient-to-b from-steel-200 to-steel-500 border-grey'
                                }`}
                            >
                                <div
                                    className={`size-8 bg-bg rounded-full flex items-center justify-center p-md border-[0.5px] shadow-sm transition-all duration-500 ease-out ${
                                        jobId || isDragActive
                                            ? 'text-body-blue-primary border-blue'
                                            : 'text-body-subtle border-grey'
                                    }`}
                                >
                                    <CloudUpload />
                                </div>
                            </div>
                            {jobId ? (
                                <div className="w-full h-md bg-bg-elevated rounded-full">
                                    <div
                                        className="transition-all duration-500 ease-out bg-bg-blue-secondary h-full rounded-full"
                                        style={{ width: `${data?.status || 0}%` }}
                                    />
                                </div>
                            ) : (
                                <p>
                                    Drag and drop your CSV file here, or{' '}
                                    <span className="text-body-blue-primary font-medium">click to select a file</span>
                                </p>
                            )}
                        </div>
                    </div>
                </section>
            </DialogContent>
        </Dialog>
    );
};
