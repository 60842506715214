import { DnDTree } from '@/components/dnd-tree';
import type { TreeItem } from '@/components/dnd-tree/tree';

const treeData: TreeItem[] = [
    {
        id: 1,
        isOpen: true,
        type: 'directory',
        name: 'Directory 1',
        children: [
            {
                id: 2,
                type: 'file',
                name: 'Cool file',
                children: [],
            },
            {
                id: 3,
                type: 'file',
                name: 'Not as cool file',
                children: [],
            },
            {
                id: 4,
                type: 'file',
                name: 'Very cool file',
                children: [],
            },
        ],
    },
    {
        id: 5,
        isOpen: true,
        type: 'directory',
        name: 'Directory 2',
        children: [
            {
                id: 6,
                type: 'file',
                name: 'Bad file',
                children: [],
            },
            {
                id: 7,
                type: 'file',
                name: 'Good file',
                children: [],
            },
        ],
    },
];

export const DragAndDropTree = () => {
    return (
        <div className="w-[300px]">
            <DnDTree treeData={treeData} maxDepth={1} />
        </div>
    );
};
