import { BookOpenText, Boxes } from 'lucide-react';
import { useLocalStorage } from 'react-use';

import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog';
import { FIRST_TIME_PLAYBOOK_DETAIL, type FIRST_TIME_PLAYBOOK_DETAIL_TYPE } from '@/lib/localStorageConstants';

export const IntroDialog = () => {
    const [firstTime, setFirstTime] = useLocalStorage<FIRST_TIME_PLAYBOOK_DETAIL_TYPE>(
        FIRST_TIME_PLAYBOOK_DETAIL,
        true,
    );
    const closeIntroDialog = () => {
        setFirstTime(false);
    };

    return (
        <Dialog open={firstTime} onOpenChange={closeIntroDialog}>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader className="flex flex-col items-center">
                    <BookOpenText className="size-2xl" />
                    <DialogTitle>Introduction to Playbooks</DialogTitle>
                    <DialogDescription>Build workflows for handling employee requests</DialogDescription>
                </DialogHeader>
                <div className="p-xl flex flex-col gap-lg">
                    <div className="flex flex-col gap-md">
                        <p>
                            Playbooks allow you to build workflows in natural language, providing Console AI with the
                            instructions it needs to help you solve, triage or escalate requests.
                        </p>
                    </div>
                    <div className="flex flex-col gap-md">
                        <h3 className="font-semibold">Resources</h3>
                        <p>
                            In the left sidebar, you'll find resources which consist of actions, other playbooks, users,
                            groups and apps.
                        </p>
                        <div />
                        <p>
                            Within the instructions, you can type # to reference an action or another playbook or @ to
                            reference a user, group or app.
                        </p>
                    </div>
                    <div className="flex flex-col gap-md p-md border-[1px] border-blue bg-bg-blue-primary rounded-sm">
                        <div className="flex flex-row gap-md">
                            <Boxes />
                            <h3 className="font-semibold">Actions</h3>
                        </div>
                        <p>You can think of actions as jobs that you want another app to do. </p>
                        <div />
                        <p>
                            Under the hood, they’re essentially an API call which is why you’re able to construct custom
                            actions if Console doesn’t support one natively.
                        </p>
                    </div>
                </div>
                <DialogClose className="p-lg gap-md" data-testid="got-it">
                    Got it!
                </DialogClose>
            </DialogContent>
        </Dialog>
    );
};
