import { Separator } from '@ui/separator';
import { ArticleState } from 'lib/prisma/enums';
import { Brain, Circle, FileText, Plus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { Brow } from '@/components/Brow';
import { Button } from '@/components/ui/button';
import { useArticleCreate } from '@/hooks/mutations/useArticle';
import { useArticles } from '@/hooks/queries/useArticle';
import { cn } from '@/lib/styling';
import { ARTICLES, type ARTICLE_DETAILS_TYPE } from '@/routes/paths';
import type { Article } from 'lib/models/article';
import { ArticlesSidebar } from './details/ArticlesSidebar';
import { getArticleName, getDaysAgo, getStatusDot } from './details/utils';

const ArticleRow = ({ article }: { article?: Article }) => {
    const navigate = useNavigate();
    return (
        <div
            className={cn(
                'p-md border-subtle flex gap-lg items-center justify-between border-b last:border-none',
                article
                    ? article.internal_article?.state === ArticleState.DRAFT
                        ? 'text-body-subtle'
                        : 'text-body-grey'
                    : 'text-center',
            )}
        >
            {article ? (
                <>
                    <div
                        className="flex items-center gap-md cursor-pointer"
                        onClick={() => {
                            if (article.internal_article) {
                                navigate(`${ARTICLES}/${article.internal_article?.slug}` as ARTICLE_DETAILS_TYPE);
                            } else if (article.external_article) {
                                window.open(article.external_article.link, '_blank');
                            }
                        }}
                    >
                        <div className="border-grey flex size-8 items-center justify-center rounded-full border-[0.5px] shrink-0">
                            <FileText />
                        </div>
                        <div className="flex flex-col">
                            <h3 className="text-sm line-clamp-1">{getArticleName(article)}</h3>
                            <div className="text-xs text-body-subtle">
                                {article.internal_article?.createdAt
                                    ? `Created ${getDaysAgo(article.internal_article?.createdAt)}`
                                    : article.external_article?.createdAt
                                      ? `Synced ${getDaysAgo(article.external_article?.createdAt)}`
                                      : ''}
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center gap-md">
                        {article.internal_article ? (
                            getStatusDot(article.internal_article.state)
                        ) : article.external_article ? (
                            <Circle fill="currentColor" className={cn('size-[6px]', 'text-body-blue-primary')} />
                        ) : null}
                        {article.internal_article?.state === ArticleState.DRAFT
                            ? 'Draft'
                            : article.internal_article?.state === ArticleState.PUBLISHED
                              ? 'Published'
                              : 'Synced'}
                    </div>
                </>
            ) : (
                <div className="w-full text-center text-body-subtle">No articles found</div>
            )}
        </div>
    );
};

const sortArticles = (a: Article, b: Article) => {
    if (a.internal_article?.createdAt && b.internal_article?.createdAt) {
        return new Date(b.internal_article.createdAt).getTime() - new Date(a.internal_article.createdAt).getTime();
    } else if (a.external_article?.createdAt && b.external_article?.createdAt) {
        return new Date(b.external_article.createdAt).getTime() - new Date(a.external_article.createdAt).getTime();
    }
    return 0;
};
export const Articles = () => {
    const { data } = useArticles();
    const createArticle = useArticleCreate();
    const resourceCount = data?.articles ? data.articles.length : null;

    const articles = data?.articles ?? [];
    const filteredArticles = articles.sort(sortArticles);

    const draftArticles = filteredArticles.filter(article => article.internal_article?.state === ArticleState.DRAFT);
    const publishedArticles = filteredArticles.filter(
        article => article.internal_article?.state === ArticleState.PUBLISHED || article.external_article,
    );

    return (
        <>
            <Brow
                title="Knowledge Base"
                icon={<Brain />}
                {...(resourceCount !== null ? { resourceCount } : {})}
                actions={[
                    <Button
                        key="new-article"
                        size="sm"
                        onClick={() => {
                            createArticle.mutate({});
                        }}
                    >
                        <Plus />
                        New article
                    </Button>,
                ]}
            />
            <div className="flex grow overflow-hidden">
                <ArticlesSidebar />
                <div className="grow px-2xl">
                    <div className="py-4xl flex m-auto max-w-screen-sm">
                        <div className="flex flex-col gap-8 w-full">
                            <div className="gap-sm flex flex-col">
                                <div className="gap-md flex items-center">
                                    <Brain className="size-6" />
                                    <h1 className="text-xl">Knowledge Base</h1>
                                </div>
                                <p className="text-body-subtle-hover text-base">
                                    Sync or write articles to let Console reference organizational knowledge
                                </p>
                            </div>
                            <Separator />

                            <div className="w-full border-grey border-[0.5px] rounded-md overflow-hidden">
                                <div className="bg-bg-overlay text-xs font-medium w-full flex items-center p-md">
                                    Draft articles
                                </div>
                                {draftArticles.length === 0 ? (
                                    <ArticleRow />
                                ) : (
                                    draftArticles.map(article => <ArticleRow key={article.id} article={article} />)
                                )}
                            </div>

                            <div className="w-full border-grey border-[0.5px] rounded-md overflow-hidden">
                                <div className="bg-bg-overlay text-xs font-medium w-full flex items-center p-md">
                                    Recent articles
                                </div>
                                {publishedArticles.slice(0, 5).length === 0 ? (
                                    <ArticleRow />
                                ) : (
                                    publishedArticles
                                        .slice(0, 5)
                                        .map(article => <ArticleRow key={article.id} article={article} />)
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
