import { Badge } from '@/components/ui/badge';
import { useAppAuthTypeCounts } from '@/hooks/queries/useApps';
import { AppAuthType, type AppAuthTypeType } from 'lib/prisma/enums';
import { KeyRound } from 'lucide-react';
import { Cell, Pie, PieChart } from 'recharts';

const colorMap = {
    [AppAuthType.SSO]: '#60CB3D',
    [AppAuthType.OAUTH]: '#0085FF',
    [AppAuthType.EMAIL]: '#FFB224',
};

const authConfig: Record<
    AppAuthTypeType,
    { color: string; name: string; variant: 'blue' | 'green' | 'yellow'; badgeText?: string }
> = {
    [AppAuthType.SSO]: {
        color: '#60CB3D',
        name: 'SSO',
        variant: 'green',
    },
    [AppAuthType.OAUTH]: {
        color: '#0085FF',
        name: 'OAuth',
        variant: 'blue',
    },
    [AppAuthType.EMAIL]: {
        color: '#FFB224',
        name: 'Email',
        variant: 'yellow',
        badgeText: 'text-subtle',
    },
};

export const AuthChart = ({ search }: { search?: string }) => {
    const { data } = useAppAuthTypeCounts({ search });

    const chartData = Object.entries(data?.authTypes ?? {}).map(([key, value]) => ({
        name: key,
        value: value,
    }));

    return (
        <div className="flex justify-between items-center border-b-[0.5px] px-md py-lg border-grey">
            <div className="flex rounded-md border-grey border-[0.5px] py-md px-lg ml-md gap-md">
                <PieChart width={40} height={40}>
                    <Pie
                        data={chartData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={20}
                        innerRadius={9}
                        fill="#8884d8"
                        dataKey="value"
                        paddingAngle={0}
                    >
                        {chartData.map(entry => (
                            <Cell key={`cell-${entry.name}`} fill={colorMap[entry.name as AppAuthTypeType]} />
                        ))}
                    </Pie>
                </PieChart>
                <div className="flex flex-col items-start">
                    <div className="flex items-center justify-center text-body-subtle gap-sm">
                        <KeyRound className="size-3" />
                        Auth protocols
                    </div>
                    <div className="flex gap-lg">
                        {Object.keys(AppAuthType).map(type => (
                            <div key={type} className="flex items-center justify-center gap-sm">
                                <div className="text-body">{authConfig[type as AppAuthTypeType].name}</div>
                                <Badge
                                    variant={authConfig[type as AppAuthTypeType].variant}
                                    mode="dark"
                                    className={authConfig[type as AppAuthTypeType].badgeText}
                                >
                                    {data?.authTypes[type as AppAuthTypeType] ?? 0}
                                </Badge>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
