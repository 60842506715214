import Loader from '@/components/loader/Loader';
import { Avatar, AvatarFallback, AvatarImage } from '@ui/avatar/avatar';
import { Badge } from '@ui/badge';
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from '@ui/breadcrumb';
import { Button } from '@ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@ui/card';
import { Checkbox } from '@ui/checkbox';
import {
    CommandDialog,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
    CommandShortcut,
} from '@ui/command';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@ui/dialog';
import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerFooter,
    DrawerHeader,
    DrawerTitle,
    DrawerTrigger,
} from '@ui/drawer';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuPortal,
    DropdownMenuSeparator,
    DropdownMenuShortcut,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from '@ui/dropdown-menu';
import { Label } from '@ui/form/label';
import { Input } from '@ui/input';
import { RadioGroup, RadioGroupItem } from '@ui/radio-group';
import { Separator } from '@ui/separator';
import { SkeletonKV, SkeletonPlaybook, SkeletonRows, SkeletonTable } from '@ui/skeleton';
import { Toaster } from '@ui/sonner';
import { Switch } from '@ui/switch';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@ui/tabs';
import { Tooltip, TooltipContent, TooltipTrigger } from '@ui/tooltip';
import {
    Calculator,
    Calendar,
    Cloud,
    CreditCard,
    Github,
    Keyboard,
    LifeBuoy,
    LogOut,
    Mail,
    MessageSquare,
    Plus,
    PlusCircle,
    Settings,
    Smile,
    User,
    UserPlus,
    Users,
} from 'lucide-react';
import * as React from 'react';
import { toast } from 'sonner';
import { DragAndDropTree } from './examples/DragAndDropTree';
import MultiSelect from './examples/MultiSelect';
import TableResizable from './examples/TableResizable';

const StickerSheet = () => {
    const [open, setOpen] = React.useState(false);
    React.useEffect(() => {
        const down = (e: KeyboardEvent) => {
            if (e.key === 'j' && (e.metaKey || e.ctrlKey)) {
                e.preventDefault();
                setOpen(open => !open);
            }
        };

        document.addEventListener('keydown', down);
        return () => document.removeEventListener('keydown', down);
    }, []);

    return (
        <div className="bg-bg flex flex-col gap-8 overflow-auto p-12">
            <h1 className="text-base">Sticker Sheet</h1>

            <div className="flex flex-col items-start gap-2">
                <h3>Avatars</h3>
                <div className="flex gap-md">
                    <Avatar size="xl">
                        <AvatarImage src="https://avatars.githubusercontent.com/u/62900807?v=4" />
                        <AvatarFallback>JJ</AvatarFallback>
                    </Avatar>
                    <Avatar size="xl">
                        <AvatarImage />
                        <AvatarFallback>JJ</AvatarFallback>
                    </Avatar>
                </div>
            </div>

            <div className="flex flex-col items-start gap-2">
                <h3>Badge</h3>
                <Badge variant="grey" mode="dark">
                    Badge
                </Badge>
            </div>

            <div className="flex flex-col items-start gap-2">
                <h3>Breadcrumb</h3>
                <Breadcrumb>
                    <BreadcrumbList>
                        <BreadcrumbItem>
                            <BreadcrumbLink href="/">Menu</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <BreadcrumbLink href="/components">Submenu</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <BreadcrumbPage>Current Page</BreadcrumbPage>
                        </BreadcrumbItem>
                    </BreadcrumbList>
                </Breadcrumb>
            </div>

            <div className="flex flex-col items-start gap-2">
                <h3>Button</h3>
                <Button variant="grey" mode="dark">
                    Button
                </Button>
            </div>

            <div className="flex flex-col items-start gap-2">
                <h3>Card</h3>
                <Card className="w-[350px]">
                    <CardHeader>
                        <CardTitle>Profile</CardTitle>
                        <CardDescription>Let's set up your profile!</CardDescription>
                    </CardHeader>
                    <CardContent>
                        <form>
                            <div className="grid w-full items-center gap-4">
                                <div className="flex flex-col space-y-1.5">
                                    <Label htmlFor="firstname">First name</Label>
                                    <Input id="firstname" placeholder="First name" />
                                </div>
                                <div className="flex flex-col space-y-1.5">
                                    <Label htmlFor="lastname">Last name</Label>
                                    <Input id="lastname" placeholder="Last name" />
                                </div>
                            </div>
                        </form>
                    </CardContent>
                    <CardFooter className="flex justify-between">
                        <Button mode="borderless">Cancel</Button>
                        <Button variant="grey" mode="dark">
                            Save
                        </Button>
                    </CardFooter>
                </Card>
            </div>

            <div className="flex flex-col gap-2">
                <h3>Checkbox</h3>
                <div className="flex items-center space-x-2">
                    <Checkbox id="checkbox" />
                    <label
                        htmlFor="checkbox"
                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                        Checkbox
                    </label>
                </div>
            </div>

            <div className="flex flex-col gap-2">
                <h3>Command Menu</h3>
                <p className="text-body-subtle">
                    Press{' '}
                    <kbd className="bg-bg text-body-grey-primary border-grey pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded-sm border px-1.5 font-mono text-[10px] font-medium opacity-100 shadow-sm">
                        <span className="text-xs">⌘</span>J
                    </kbd>
                </p>
                <CommandDialog open={open} onOpenChange={setOpen}>
                    <CommandInput placeholder="Type a command or search..." />
                    <CommandList>
                        <CommandEmpty>No results found.</CommandEmpty>
                        <CommandGroup heading="Suggestions">
                            <CommandItem>
                                <Calendar className="mr-2 size-4" />
                                <span>Calendar</span>
                            </CommandItem>
                            <CommandItem>
                                <Smile className="mr-2 size-4" />
                                <span>Search Emoji</span>
                            </CommandItem>
                            <CommandItem>
                                <Calculator className="mr-2 size-4" />
                                <span>Calculator</span>
                            </CommandItem>
                        </CommandGroup>
                        <CommandSeparator />
                        <CommandGroup heading="Settings">
                            <CommandItem>
                                <User className="mr-2 size-4" />
                                <span>Profile</span>
                                <CommandShortcut>⌘P</CommandShortcut>
                            </CommandItem>
                            <CommandItem>
                                <CreditCard className="mr-2 size-4" />
                                <span>Billing</span>
                                <CommandShortcut>⌘B</CommandShortcut>
                            </CommandItem>
                            <CommandItem>
                                <Settings className="mr-2 size-4" />
                                <span>Settings</span>
                                <CommandShortcut>⌘S</CommandShortcut>
                            </CommandItem>
                        </CommandGroup>
                    </CommandList>
                </CommandDialog>
            </div>

            <div className="flex flex-col items-start gap-2">
                <h3>Dialog</h3>
                <Dialog>
                    <DialogTrigger asChild>
                        <Button>Open Dialog</Button>
                    </DialogTrigger>
                    <DialogContent className="sm:max-w-[425px]">
                        <DialogHeader>
                            <DialogTitle>Profile</DialogTitle>
                            <DialogDescription>Let's set up your profile!</DialogDescription>
                        </DialogHeader>
                        <form className="p-xl pt-0 overflow-y-scroll">
                            <div className="grid w-full items-center gap-4">
                                <div className="flex flex-col space-y-1.5">
                                    <Label htmlFor="firstname">First name</Label>
                                    <Input id="firstname" placeholder="First name" />
                                </div>
                                <div className="flex flex-col space-y-1.5">
                                    <Label htmlFor="lastname">Last name</Label>
                                    <Input id="lastname" placeholder="Last name" />
                                </div>
                            </div>
                        </form>
                        <DialogFooter>
                            <Button mode="borderless">Cancel</Button>
                            <Button variant="grey" mode="dark">
                                Save
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            </div>

            <div className="flex flex-col items-start gap-2">
                <h3>Drawer</h3>
                <Drawer>
                    <DrawerTrigger>
                        <Button>Open Drawer</Button>
                    </DrawerTrigger>
                    <DrawerContent>
                        <DrawerHeader>
                            <DrawerTitle>Are you absolutely sure?</DrawerTitle>
                            <DrawerDescription>This action cannot be undone.</DrawerDescription>
                        </DrawerHeader>
                        <DrawerFooter>
                            <DrawerClose className="w-full flex flex-col gap-md">
                                <Button className="w-full" variant="grey" mode="dark">
                                    Save
                                </Button>
                                <Button className="w-full" mode="borderless">
                                    Cancel
                                </Button>
                            </DrawerClose>
                        </DrawerFooter>
                    </DrawerContent>
                </Drawer>
            </div>

            <div className="flex flex-col items-start gap-2">
                <h3>Dropdown</h3>
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button>Open Dropdown</Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-56">
                        <DropdownMenuLabel>My Account</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <DropdownMenuGroup>
                            <DropdownMenuItem>
                                <User className="mr-2 size-4" />
                                <span>Profile</span>
                                <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>
                            </DropdownMenuItem>
                            <DropdownMenuItem>
                                <CreditCard className="mr-2 size-4" />
                                <span>Billing</span>
                                <DropdownMenuShortcut>⌘B</DropdownMenuShortcut>
                            </DropdownMenuItem>
                            <DropdownMenuItem>
                                <Settings className="mr-2 size-4" />
                                <span>Settings</span>
                                <DropdownMenuShortcut>⌘S</DropdownMenuShortcut>
                            </DropdownMenuItem>
                            <DropdownMenuItem>
                                <Keyboard className="mr-2 size-4" />
                                <span>Keyboard shortcuts</span>
                                <DropdownMenuShortcut>⌘K</DropdownMenuShortcut>
                            </DropdownMenuItem>
                        </DropdownMenuGroup>
                        <DropdownMenuSeparator />
                        <DropdownMenuGroup>
                            <DropdownMenuItem>
                                <Users className="mr-2 size-4" />
                                <span>Team</span>
                            </DropdownMenuItem>
                            <DropdownMenuSub>
                                <DropdownMenuSubTrigger>
                                    <UserPlus className="mr-2 size-4" />
                                    <span>Invite users</span>
                                </DropdownMenuSubTrigger>
                                <DropdownMenuPortal>
                                    <DropdownMenuSubContent>
                                        <DropdownMenuItem>
                                            <Mail className="mr-2 size-4" />
                                            <span>Email</span>
                                        </DropdownMenuItem>
                                        <DropdownMenuItem>
                                            <MessageSquare className="mr-2 size-4" />
                                            <span>Message</span>
                                        </DropdownMenuItem>
                                        <DropdownMenuSeparator />
                                        <DropdownMenuItem>
                                            <PlusCircle className="mr-2 size-4" />
                                            <span>More...</span>
                                        </DropdownMenuItem>
                                    </DropdownMenuSubContent>
                                </DropdownMenuPortal>
                            </DropdownMenuSub>
                            <DropdownMenuItem>
                                <Plus className="mr-2 size-4" />
                                <span>New Team</span>
                                <DropdownMenuShortcut>⌘+T</DropdownMenuShortcut>
                            </DropdownMenuItem>
                        </DropdownMenuGroup>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem>
                            <Github className="mr-2 size-4" />
                            <span>GitHub</span>
                        </DropdownMenuItem>
                        <DropdownMenuItem>
                            <LifeBuoy className="mr-2 size-4" />
                            <span>Support</span>
                        </DropdownMenuItem>
                        <DropdownMenuItem disabled>
                            <Cloud className="mr-2 size-4" />
                            <span>API</span>
                        </DropdownMenuItem>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem>
                            <LogOut className="mr-2 size-4" />
                            <span>Log out</span>
                            <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>

            <div className="flex flex-col items-start gap-2">
                <h3>Input</h3>
                <Input id="input" placeholder="Input" />
            </div>

            <div className="flex flex-col items-start gap-2">
                <h3>Loader</h3>
                <div className="gap-lg flex flex-col">
                    <Loader size="sm" />
                    <Loader size="default" />
                    <Loader size="lg" />
                    <Loader size="xl" />
                </div>
            </div>

            <div className="flex flex-col items-start gap-2">
                <h3>Multiselect</h3>
                <MultiSelect />
            </div>

            <div className="flex flex-col items-start gap-2">
                <h3>Radio Group</h3>
                <RadioGroup defaultValue="comfortable">
                    <div className="flex items-center space-x-2">
                        <RadioGroupItem value="default" id="r1" />
                        <Label htmlFor="r1">Default</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                        <RadioGroupItem value="comfortable" id="r2" />
                        <Label htmlFor="r2">Comfortable</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                        <RadioGroupItem value="compact" id="r3" />
                        <Label htmlFor="r3">Compact</Label>
                    </div>
                </RadioGroup>
            </div>

            <div className="flex flex-col items-start gap-2">
                <h3>Separator</h3>
                <Separator />
            </div>

            <div className="flex flex-col items-start gap-2">
                <h3>Shadows</h3>
                <div className="item-center gap-lg p-lg border-grey bg-bg-elevated flex rounded-lg border-[0.5px]">
                    <div className="bg-bg py-lg px-2xl rounded-md font-medium shadow-xl">XL</div>
                    <div className="bg-bg py-lg px-2xl rounded-md font-medium shadow-lg">LG</div>
                    <div className="bg-bg py-lg px-2xl rounded-md font-medium shadow-md">MD</div>
                    <div className="bg-bg py-lg px-2xl rounded-md font-medium shadow-sm">SM</div>
                    <div className="bg-bg py-lg px-2xl rounded-md font-medium shadow">Default</div>
                </div>
            </div>

            <div className="gap-lg flex flex-col items-start">
                <h3>Table Skeleton</h3>
                <SkeletonTable />
                <h3>Playbook Skeleton</h3>
                <SkeletonPlaybook />
                <h3>KV Skeleton</h3>
                <SkeletonKV />
                <h3>Row Skeleton</h3>
                <SkeletonRows />
            </div>

            <div className="flex flex-col items-start gap-2">
                <h3>Switch</h3>
                <div className="flex items-center space-x-2">
                    <Switch id="switch" />
                    <label htmlFor="switch">Toggle</label>
                </div>
            </div>

            <div className="flex flex-col items-start gap-2">
                <h3>Tabs</h3>
                <Tabs defaultValue="account" className="w-[400px]">
                    <TabsList>
                        <TabsTrigger value="firstTab">First tab</TabsTrigger>
                        <TabsTrigger value="secondTab">Second tab</TabsTrigger>
                    </TabsList>
                    <TabsContent value="firstTab">This is the first tab.</TabsContent>
                    <TabsContent value="secondTab">This is the second tab.</TabsContent>
                </Tabs>
            </div>

            <div className="flex flex-col items-start gap-2">
                <Toaster />
                <h3>Toast</h3>
                <Button
                    onClick={() =>
                        toast('Event has been created', {
                            description: 'Sunday, December 03, 2023 at 9:00 AM',
                            action: {
                                label: 'Undo',
                                onClick: () => {},
                            },
                        })
                    }
                >
                    Open Toast
                </Button>
            </div>

            <div className="flex flex-col items-start gap-2">
                <h3>Drag and Drop Tree</h3>
                <DragAndDropTree />
            </div>
            <div className="flex flex-col items-start gap-2">
                <h3>Tooltip</h3>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button>Hover me</Button>
                    </TooltipTrigger>
                    <TooltipContent>
                        <p>Data nugs go nuts</p>
                    </TooltipContent>
                </Tooltip>
            </div>

            <div className="flex flex-col items-start gap-2">
                <h3>Table (Resizable)</h3>
                <TableResizable />
            </div>
        </div>
    );
};

export default StickerSheet;
