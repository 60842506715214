import type { PStep } from '@/stores/usePolicy';
import type { Approval } from 'lib/models/app_policy';

const stepDataKey = Symbol('action');

export interface DragActionData {
    [stepDataKey]: true;
    actionId: PStep['id'];
    [key: string]: unknown;
    [key: symbol]: unknown;
}

export function getStepData(step: PStep): DragActionData {
    return { [stepDataKey]: true, actionId: step.id };
}

export function isStepData(data: Record<string | symbol, unknown>): data is DragActionData {
    return data[stepDataKey] === true;
}

const approvalDataKey = Symbol('approval');

export interface DragApprovalData {
    [approvalDataKey]: true;
    id: Approval['id'];
    [key: string]: unknown;
    [key: symbol]: unknown;
}

export function getApprovalData(approval: Approval): DragApprovalData {
    return { [approvalDataKey]: true, id: approval.id };
}

export function isApprovalData(data: Record<string | symbol, unknown>): data is DragApprovalData {
    return data[approvalDataKey] === true;
}
