import { prettyPolicyDuration } from 'lib/models/app_policy';
import type { RequestAppAccess } from '../details/Main';
export const AppAcessEntryDetails = ({ pr }: { pr: RequestAppAccess }) => {
    const values = [
        {
            label: 'App',
            value: pr.policy.app.name,
        },
        {
            label: 'Policy',
            value: pr.policy.name,
        },
        {
            label: 'Duration',
            value: prettyPolicyDuration(pr.policy.duration, window.navigator.language),
        },
    ];

    return (
        <div className="text-body-subtle-hover border-l-2 border-bg-blue-secondary p-md flex flex-col gap-sm">
            <div className="flex gap-lg">
                {values.map(item => (
                    <div key={item.label} className="flex gap-sm text-xs">
                        <span className="font-medium text-body-grey-primary shrink-0">{item.label}:</span>
                        <span>{item.value}</span>
                    </div>
                ))}
            </div>
            <div className="flex gap-sm text-xs">
                <span className="font-medium text-body-grey-primary shrink-0">Reason:</span>
                <span>{pr.reason}</span>
            </div>
        </div>
    );
};
