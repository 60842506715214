import { capitalize } from 'lodash-es';

import { ProviderAssets } from 'lib/3p';
import { Avatar, AvatarImage, type AvatarVariantSize } from './ui/avatar';

interface AppProviderProps {
    provider: string;
    size?: AvatarVariantSize;
}

export const AppProvider = ({ provider, size = 'sm' }: AppProviderProps) => {
    const config = ProviderAssets[provider as keyof typeof ProviderAssets];
    if (config) {
        return (
            <span className="flex items-center gap-md">
                <Avatar size={size}>
                    <AvatarImage src={config.logo} alt={config.name} rounded="square" />
                </Avatar>
                <span>{config.name}</span>
            </span>
        );
    }
    return capitalize(provider);
};
