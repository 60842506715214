import { fmtDate } from '@lib/dates.ts';
import { ProviderAssets } from 'lib/3p';
import type { IntegrationId } from 'lib/integration';

export function AllTicketsSubpage({
    tickets,
}: {
    tickets: { subject: string | null; providerCreatedAt: Date | null; provider: string; externalURL?: string }[];
}) {
    return (
        <div className="flex flex-col px-lg py-md">
            <div className="flex justify-between font-bold">
                <div>Requests</div>
                <div>Created At</div>
            </div>
            {tickets.map(item => (
                <div
                    key={item.subject}
                    className="flex items-center h-2xl justify-between border-b border-grey border-dashed last:border-none"
                >
                    <a
                        href={item.externalURL}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-md"
                    >
                        <img
                            src={ProviderAssets[item.provider as IntegrationId].logo}
                            alt={`${item.provider} Logo`}
                            className="size-4"
                        />
                        {item.subject}
                    </a>
                    <div className="flex gap-md items-center">
                        <p className="font-medium">{fmtDate(item.providerCreatedAt)}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}
