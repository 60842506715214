import { RelativeDate } from '@/components/RelativeDate';
import { ResLink } from '@/components/ResLink';
import { Button } from '@/components/ui/button';
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Select } from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import { useAccessRequestOverride } from '@/hooks/mutations/access-requests/useAccessRequestOverride';
import { useApp, useAppAccessRequests } from '@/hooks/queries/useApps';
import { fmtDate } from '@/lib/dates';
import { CnslPolicyIcon } from '@/lib/iconConstants';
import { cn } from '@/lib/styling';
import { Tooltip, TooltipContent, TooltipTrigger } from '@ui/tooltip';
import type { AccessRequestListItem } from 'lib/models/provisioning_requests';
import { ProvisioningReviewStatus, type ProvisioningReviewStatusType } from 'lib/prisma/enums';
import { BadgeCheck, CircleX, Ellipsis, ShieldX } from 'lucide-react';
import type { HTMLAttributes } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const statusConfigMap: Record<
    ProvisioningReviewStatusType,
    {
        label: string;
    }
> = {
    [ProvisioningReviewStatus.PENDING]: {
        label: 'Pending',
    },
    [ProvisioningReviewStatus.APPROVED]: {
        label: 'Access granted',
    },
    [ProvisioningReviewStatus.DENIED]: {
        label: 'Access denied',
    },
    [ProvisioningReviewStatus.REVOKED]: {
        label: 'Access revoked',
    },
    [ProvisioningReviewStatus.EXPIRED]: {
        label: 'Request expired',
    },
};

const StatusIcon = ({
    status,
    className,
}: { status: ProvisioningReviewStatusType } & React.SVGProps<SVGSVGElement>) => {
    switch (status) {
        case ProvisioningReviewStatus.APPROVED:
            return (
                <BadgeCheck className={cn(className, 'text-body-green-primary')} stroke="white" fill="currentColor" />
            );
        case ProvisioningReviewStatus.DENIED:
            return <CircleX className={cn(className, 'text-body-red-primary')} stroke="white" fill="currentColor" />;
        case ProvisioningReviewStatus.REVOKED:
            return <ShieldX className={cn(className, 'text-body-red-primary')} stroke="white" fill="currentColor" />;
        case ProvisioningReviewStatus.EXPIRED:
            return <CircleX className={cn(className, 'text-body-subtle')} stroke="white" fill="currentColor" />;
        case ProvisioningReviewStatus.PENDING:
            return (
                <svg width="14" height="14" viewBox="0 0 14 14" className={cn(className)}>
                    <title>Access request pending icon</title>
                    <circle cx="7" cy="7" r="6" fill="none" stroke="hsl(var(--color-steel-600))" strokeWidth="2" />
                    <circle
                        cx="7"
                        cy="7"
                        r="6"
                        fill="none"
                        stroke="hsl(var(--bg-blue-secondary))"
                        strokeWidth="2"
                        strokeDasharray={`var(--pending-percent)`}
                        transform="rotate(-90 7 7)"
                    />
                </svg>
            );
        default:
            return null;
    }
};

const DenyRequestDialog = ({ pr, appSlug }: { pr: AccessRequestListItem; appSlug: string }) => {
    const [denyReason, setDenyReason] = useState('');

    const { mutate } = useAccessRequestOverride(ProvisioningReviewStatus.DENIED, appSlug);
    const handleDeny = () => {
        mutate({
            id: pr.id,
            status: ProvisioningReviewStatus.DENIED,
            reason: denyReason,
        });
    };

    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button mode="borderless" className="justify-start">
                    <StatusIcon status={ProvisioningReviewStatus.DENIED} className="size-4" />
                    Deny request
                </Button>
            </DialogTrigger>
            <DialogContent>
                <DialogTitle className="p-lg">
                    <StatusIcon status={ProvisioningReviewStatus.DENIED} className="size-6" />
                    Deny request
                </DialogTitle>
                <div className="p-lg border-y-[0.5px] border-grey flex flex-col gap-sm">
                    <DialogDescription className="text-body-grey font-medium">
                        Why are you denying this request?
                    </DialogDescription>
                    <Textarea
                        placeholder="Reason for denial"
                        value={denyReason}
                        onChange={e => setDenyReason(e.target.value)}
                    />
                </div>
                <DialogClose asChild className="p-lg">
                    <div className="flex justify-between gap-sm">
                        <Button mode="borderless">Cancel</Button>
                        <Button variant="red" mode="dark" onClick={handleDeny}>
                            Deny request
                        </Button>
                    </div>
                </DialogClose>
            </DialogContent>
        </Dialog>
    );
};

const ApproveRequestDialog = ({ pr, appSlug }: { pr: AccessRequestListItem; appSlug: string }) => {
    const { mutate } = useAccessRequestOverride(ProvisioningReviewStatus.APPROVED, appSlug);
    const handleApprove = () => {
        mutate({
            id: pr.id,
            status: ProvisioningReviewStatus.APPROVED,
        });
    };

    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button mode="borderless" className="justify-start">
                    <StatusIcon status={ProvisioningReviewStatus.APPROVED} className="size-4" />
                    Grant access
                </Button>
            </DialogTrigger>
            <DialogContent>
                <DialogTitle className="p-lg">
                    <StatusIcon status={ProvisioningReviewStatus.APPROVED} className="size-6" />
                    Grant access
                </DialogTitle>
                <div className="p-lg border-y-[0.5px] border-grey flex flex-col gap-sm">
                    <DialogDescription className="text-body-grey font-medium">
                        Are you sure you want to grant access to this user?
                    </DialogDescription>
                </div>
                <DialogClose asChild className="p-lg">
                    <div className="flex justify-between gap-sm">
                        <Button mode="borderless">Cancel</Button>
                        <Button variant="green" mode="dark" onClick={handleApprove}>
                            Grant access
                        </Button>
                    </div>
                </DialogClose>
            </DialogContent>
        </Dialog>
    );
};

const SwitchPolicyDialog = ({ pr, appSlug }: { pr: AccessRequestListItem; appSlug: string }) => {
    const { mutate } = useAccessRequestOverride(ProvisioningReviewStatus.APPROVED, appSlug);
    const { data } = useApp({ slug: appSlug });
    const policies = data?.app?.policies ?? [];
    const handleApprove = () => {
        mutate({
            id: pr.id,
            status: ProvisioningReviewStatus.APPROVED,
            policyId: selectedPolicy?.id,
        });
    };

    if (policies.length < 2) {
        return null;
    }

    const [selectedPolicy, setSelectedPolicy] = useState(policies.find(p => p.id === pr.policyId));
    const enableGrant = selectedPolicy?.id !== pr.policyId;
    const changedPolicyName = enableGrant ? selectedPolicy?.name : undefined;

    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button mode="borderless" className="justify-start">
                    <CnslPolicyIcon />
                    Grant with different policy
                </Button>
            </DialogTrigger>
            <DialogContent>
                <DialogTitle className="p-lg">
                    <CnslPolicyIcon className="size-6" />
                    Grant with different policy
                </DialogTitle>
                <div className="p-lg border-y-[0.5px] border-grey flex flex-col gap-sm">
                    <DialogDescription className="text-body-grey font-medium">
                        Which policy would you like to use?
                    </DialogDescription>
                    <div className="flex items-center gap-sm max-w-xs">
                        <Select
                            value={{ label: selectedPolicy?.name, value: selectedPolicy?.id }}
                            options={policies.map(p => ({ label: p.name, value: p.id }))}
                            onChange={o => setSelectedPolicy(policies.find(p => p.id === o?.value))}
                        />
                    </div>
                </div>
                <DialogClose asChild className="p-lg">
                    <div className="flex justify-between gap-sm">
                        <Button mode="borderless">Cancel</Button>
                        <Button variant="green" mode="dark" onClick={handleApprove} disabled={!enableGrant}>
                            {enableGrant ? `Grant access with "${changedPolicyName}" policy` : 'Grant access'}
                        </Button>
                    </div>
                </DialogClose>
            </DialogContent>
        </Dialog>
    );
};

export const AccessRequestActionMenu = ({ pr, appSlug }: { pr: AccessRequestListItem; appSlug: string }) => {
    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button mode="borderless">
                    <Ellipsis />
                </Button>
            </PopoverTrigger>
            <PopoverContent side="bottom" align="end">
                <div className="flex flex-col gap-sm p-md">
                    <ApproveRequestDialog pr={pr} appSlug={appSlug} />
                    <DenyRequestDialog pr={pr} appSlug={appSlug} />
                    <SwitchPolicyDialog pr={pr} appSlug={appSlug} />
                </div>
            </PopoverContent>
        </Popover>
    );
};

export const AccessRequestStatusBadge = ({ pr }: { pr: AccessRequestListItem }) => {
    const status = pr.status;
    const percent = pr.reviews.length / pr.policy.reviewSteps.length || 0.25;
    const pendingPercent = `${percent * 37.7} 37.7`; // stroke-dasharray css variable

    const nextStepReviewers = () => {
        const reviews = pr.reviews;

        const nextStep = pr.policy.reviewSteps.find(step => !reviews.some(r => r.stepId === step.id));
        const nextReviewers = nextStep?.reviewers ?? [];
        const reviewerNames = nextReviewers.map(r => {
            if (r.cnsl_user) {
                return r.cnsl_user.displayName;
            }
            return r?.cnsl_group?.name ?? 'Unknown Reviewer';
        });

        return reviewerNames.join(', ');
    };

    const progressText = () => {
        const reviews = pr.reviews;
        const displayPercent = Intl.NumberFormat(window.navigator.language, { style: 'percent' }).format(
            reviews.length / pr.policy.reviewSteps.length,
        );
        return `${displayPercent} - Step ${reviews.length} of ${pr.policy.reviewSteps.length}`;
    };

    const approvers = pr.reviews.map(r => r.reviewer?.displayName).join(', ');

    return (
        <Tooltip>
            <TooltipTrigger className="cursor-default">
                <div
                    className="flex items-center gap-sm hover:bg-bg-elevated py-sm px-md rounded-sm cursor-default font-medium"
                    style={{ '--pending-percent': pendingPercent } as React.CSSProperties}
                >
                    <StatusIcon status={status} className="size-[14px]" />

                    <div>{statusConfigMap[status].label}</div>
                </div>
            </TooltipTrigger>
            <TooltipContent side="top">
                <div className="flex flex-col gap-sm text-xs">
                    {status === ProvisioningReviewStatus.PENDING && (
                        <>
                            <div>Waiting for approval from {nextStepReviewers()}</div>
                            <div className="text-white-60">{progressText()}</div>
                        </>
                    )}
                    {(status === ProvisioningReviewStatus.APPROVED || status === ProvisioningReviewStatus.DENIED) && (
                        <div>
                            {statusConfigMap[status].label} by {approvers}
                        </div>
                    )}
                    {status === ProvisioningReviewStatus.REVOKED && (
                        <div>
                            <div>Granted on {fmtDate(pr.reviewedAt)}</div>
                            <div>Revoked on {fmtDate(pr.revokedAt)}</div>
                        </div>
                    )}
                    {status === ProvisioningReviewStatus.EXPIRED && (
                        <div>
                            <div>Requested on {fmtDate(pr.createdAt)}</div>
                            <div>Expired on {fmtDate(pr.reviewedAt)}</div>
                        </div>
                    )}
                </div>
            </TooltipContent>
        </Tooltip>
    );
};

interface RequestListProps extends HTMLAttributes<HTMLDivElement> {
    requests: AccessRequestListItem[];
    title: string;
    appSlug: string;
}
export const RequestList = ({ requests, title, appSlug, ...props }: RequestListProps) => {
    return (
        <>
            <h3 className={cn('font-medium w-full p-md bg-bg-overlay text-xs', props.className)}>{title}</h3>
            <ul>
                {requests.map(pr => (
                    <li key={pr.id} className="border-t flex gap-md items-center justify-between border-grey p-md">
                        <div className="flex gap-md items-center">
                            <Link to={`/users/${pr.user.email}`}>
                                <ResLink entity="users" id={pr.userId} label={pr.user.displayName} avatarOnly />
                            </Link>
                            <div>
                                <Link to={`/users/${pr.user.email}`} className="block font-medium">
                                    {pr.user.displayName}
                                </Link>
                                <RelativeDate
                                    className="text-body-subtle"
                                    prefix={
                                        pr.status === ProvisioningReviewStatus.PENDING
                                            ? 'Requested'
                                            : statusConfigMap[pr.status].label
                                    }
                                    date={pr.indexTime}
                                />
                            </div>
                        </div>
                        <div>
                            <AccessRequestStatusBadge pr={pr} />

                            {pr.status === ProvisioningReviewStatus.PENDING && (
                                <AccessRequestActionMenu pr={pr} appSlug={appSlug} />
                            )}
                        </div>
                    </li>
                ))}
                {requests.length === 0 && <div className="p-md border-t border-grey">No access requests found</div>}
            </ul>
        </>
    );
};

export const AccessRequestHistory = ({
    slug,
    mode = 'overview',
    className,
    ...props
}: { slug: string; mode?: 'overview' | 'tab' } & HTMLAttributes<HTMLDivElement>) => {
    const { data } = useAppAccessRequests({ slug });

    const items = data?.items || [];
    const pendingRequests = items.filter(pr => pr.status === ProvisioningReviewStatus.PENDING);
    const reviewedRequests = items.filter(pr => pr.status !== ProvisioningReviewStatus.PENDING);

    const containerRef = useRef<HTMLDivElement>(null);
    const [scrollHeight, setScrollHeight] = useState(720);

    useEffect(() => {
        const updateHeight = () => {
            if (containerRef.current) {
                const containerTop = containerRef.current.getBoundingClientRect().top;
                const viewportHeight = window.innerHeight;
                const padding = 32; // Leave some space at bottom
                setScrollHeight(viewportHeight - containerTop - padding);
            }
        };

        updateHeight();
        window.addEventListener('resize', updateHeight);
        return () => window.removeEventListener('resize', updateHeight);
    }, []);

    const content = (
        <div className="border border-grey rounded-md mb-xl">
            <RequestList
                requests={pendingRequests}
                title="Pending Access Requests"
                appSlug={slug}
                className="rounded-t-md"
            />
            <RequestList
                requests={reviewedRequests}
                title="Access History"
                appSlug={slug}
                className="border-t border-grey"
            />
        </div>
    );

    return (
        <div className={cn('mt-2xl ', className)} {...props} ref={containerRef}>
            {mode === 'overview' && <h2 className="text-base font-medium mb-lg">Access Requests</h2>}

            {items.length === 0 ? (
                <div className="w-full flex flex-col items-center justify-center text-center relative h-[320px]">
                    <img
                        src="/emptyStates/empty-circles.svg"
                        alt="Concentric circles"
                        className="size-[320px] absolute z-0"
                    />
                    <img
                        src="/emptyStates/empty-question.svg"
                        alt="No access requests found"
                        className="size-24 z-10"
                    />
                    <div className="absolute bottom-0 h-24">
                        <h4 className="text-body-grey font-semibold text-sm">No access requests found</h4>
                        <div className="text-body-subtle">
                            As users request access, their request history will appear here
                        </div>
                    </div>
                </div>
            ) : mode === 'tab' ? (
                <ScrollArea style={{ height: scrollHeight }}>{content}</ScrollArea>
            ) : (
                content
            )}
        </div>
    );
};
