import { Form } from '@/components/ui/form';
import { FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form/form';
import { Textarea } from '@/components/ui/textarea';
import { usePlaybookUpdate } from '@/hooks/mutations/usePlaybook';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { FlagKey } from 'lib/flags/keys';
import type { RichPlaybook } from 'lib/models/playbook';
import { ArrowRight } from 'lucide-react';
import type { UseFormReturn } from 'react-hook-form';
import { PlaybookInstructionsData } from './PlaybookInstructionsData';
import { ProposalRewrite } from './ProposalRewrite';
import { ConditionsInfoDialog } from './dialogs/ConditionsInfoDialog';
import { InstructionsInfoDialog } from './dialogs/InstructionsInfoDialog';

export const PlaybookDetailsForm = ({
    form,
    historical,
    slug,
    setDisableForm,
}: {
    form: UseFormReturn<RichPlaybook>;
    slug: string;
    historical?: boolean;
    setDisableForm: (disabled: boolean) => void;
}) => {
    const playbookUpdate = usePlaybookUpdate(slug);

    const handleOnBlur = (onBlur: () => void) => {
        onBlur();

        const values = form.getValues();
        playbookUpdate.mutate(values);
    };

    const isPlaybookRewriteEnabled = useFeatureFlag(FlagKey.PlaybookRewrite);

    return (
        <Form {...form}>
            {isPlaybookRewriteEnabled && (
                <div className="flex gap-md justify-end absolute top-lg right-lg">
                    <ProposalRewrite form={form} slug={slug} setDisableForm={setDisableForm} />
                </div>
            )}
            <FormField
                control={form.control}
                name="name"
                render={({ field: { onBlur, ...field } }) => (
                    <FormItem>
                        <FormControl>
                            <Textarea
                                autoResize
                                minHeight="30px"
                                className="border-none text-2xl font-medium !p-0"
                                placeholder="Add a title"
                                data-testid="playbook-title"
                                onBlur={() => handleOnBlur(onBlur)}
                                {...field}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
            <div className="flex justify-between border-b-[0.5px] border-grey mt-2xl mb-md">
                <div className="flex items-center gap-md font-mono uppercase">
                    <ArrowRight />
                    Conditions
                </div>
                <ConditionsInfoDialog />
            </div>
            <FormField
                control={form.control}
                name="description"
                render={({ field: { onBlur, ...field } }) => (
                    <FormItem>
                        <FormControl>
                            <Textarea
                                autoResize
                                minHeight="24px"
                                className="border-none text-sm !p-0"
                                placeholder="Which conditions should be met in order to run this Playbook?"
                                data-testid="playbook-conditions"
                                onBlur={() => handleOnBlur(onBlur)}
                                {...field}
                            />
                        </FormControl>
                    </FormItem>
                )}
            />
            <div className="flex justify-between border-b-[0.5px] border-grey mt-2xl mb-md">
                <div className="flex items-center gap-md font-mono uppercase">
                    <ArrowRight />
                    Instructions
                </div>
                <InstructionsInfoDialog />
            </div>
            <PlaybookInstructionsData form={form} historical={historical} slug={slug} />
        </Form>
    );
};
