import { cn } from '@/lib/styling';
import { ACTIONS, type ACTIONS_DETAILS_TYPE } from '@/routes/paths';
import { useUser } from '@/stores/useUser';
import { getProviderAssets } from 'lib/3p';
import type { Action } from 'lib/prisma/types';
import { getBaseMentionConfig } from 'lib/tiptap';
import { Box } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { getActionName } from '../actions/utils';

export const ActionCard = ({ action }: { action: Action }) => {
    const navigate = useNavigate();
    const data = useUser();
    const formattedAction = getActionName(action, data.user?.orgMemberships[0].orgName || '');
    return (
        <div
            className={cn(
                'flex flex-col gap-md p-lg justify-between w-full border-[0.5px] border-grey rounded-md shadow duration-100 ease-out transition-all cursor-pointer hover:shadow-lg',
            )}
            onClick={() => {
                navigate(`${ACTIONS}/${action.provider}/${action.slug}` as ACTIONS_DETAILS_TYPE);
            }}
        >
            <div className="flex flex-col gap-sm">
                <div className="flex justify-between">
                    <span className="font-medium text-sm">{action.name}</span>
                    {action.provider === 'custom' ? (
                        <Box />
                    ) : (
                        <img src={getProviderAssets(action.provider).logo} alt={action.provider} className="w-4 h-4" />
                    )}
                </div>
                <div className="text-body-subtle-hover line-clamp-1">{action.description}</div>
            </div>
            <div className="text-xs text-body-subtle">{`${getBaseMentionConfig({ key: 'mention-action' })?.suggestion?.char}${formattedAction}`}</div>
        </div>
    );
};
