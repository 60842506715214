import { AccessRequestHistory } from '@/components/apps/AccessRequestHistory';
import { Policies } from '@/components/apps/Policies';
import { Tabs, TabsList, TabsTrigger } from '@ui/tabs';
import type { CnslApp } from 'lib/prisma/types';
import { Link, useParams } from 'react-router-dom';

export const PolicyAppTabs = ({ slug, app }: { slug: string; app: CnslApp }) => {
    const { tab = 'policies' } = useParams();
    const makeLink = (tab: string) => `/policies/_apps/${slug}/${tab}`;

    const tabs = [
        { label: 'Policies', value: 'policies', component: <Policies slug={slug} app={app} mode="drawer" /> },
        {
            label: 'Access Requests',
            value: 'access_requests',
            component: <AccessRequestHistory slug={slug} mode="tab" className="mt-0 mb-xl p-xl" />,
        },
    ];
    const tabComponent = tabs.find(t => t.value === tab)?.component;
    return (
        <>
            <Tabs value={tab}>
                <TabsList className="px-lg">
                    {tabs.map(t => (
                        <TabsTrigger key={t.value} value={t.value}>
                            <Link to={makeLink(t.value)} className="size-full">
                                {t.label}
                            </Link>
                        </TabsTrigger>
                    ))}
                </TabsList>
            </Tabs>
            {tabComponent}
        </>
    );
};
