import { useUser as useUserStore } from '@/stores/useUser';
import type { MeType } from 'lib/models/me';
import type { ReactNode } from 'react';

export const CnslOnly = ({ children }: { children: ReactNode }) => {
    const { user } = useUserStore.getState();

    const isAssumedByPresent = (session?: MeType['session']) => {
        return session && 'assumedBy' in session;
    };

    const isCnslUser =
        user?.cnslUser?.email?.endsWith('@dev.console.co') || user?.cnslUser?.email?.endsWith('@console.co');
    if (!isAssumedByPresent(user?.session) && !isCnslUser) {
        return null;
    }
    return <>{children}</>;
};
