import { useMutation } from '@/lib/hooks/useMutation';
import { type DefaultError, useQueryClient } from '@tanstack/react-query';
import type { KBDirectory } from 'lib/models/directory';

export const useArticleDirectoryUpdate = ({ id }: { id?: string }) => {
    const queryClient = useQueryClient();
    return useMutation<{ success: boolean }, DefaultError, KBDirectory>({
        url: `/api/v1/directory/${id}`,
        method: 'PUT',
        onSuccess: async () => await queryClient.refetchQueries({ queryKey: ['/api/v1/directory'] }),
    });
};
