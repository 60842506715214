import { getProviderAssets } from 'lib/3p';
import { groupBy } from 'lodash-es';
import { BookText, Box } from 'lucide-react';

import { useIntegrationSettings } from '@/hooks/queries/useIntegrationSettings';
import type { PlaybookReferences } from './PlaybookReferences';
import { ResourceOption } from './ResourceOption';
import { SectionLabel } from './SectionLabel';
import { ReferencesSections } from './reference/ReferencesSections';
import { usePlaybookDetailsStore } from './store/usePlaybookDetailsStore';

export const AllResourcesTab = ({ references }: { references: PlaybookReferences }) => {
    const options = usePlaybookDetailsStore(state => state.options);
    const setActiveOption = usePlaybookDetailsStore(state => state.setActiveOption);
    const activeOption = usePlaybookDetailsStore(state => state.activeOption);
    const { data } = useIntegrationSettings();
    const statusMap = data?.status ?? {};

    const groupByProvider = groupBy(options, 'provider');

    return (
        <div className="flex flex-col p-md gap-md mb-32">
            {Object.keys(groupByProvider).map(provider => {
                if (!groupByProvider[provider].length) return null;
                const p = getProviderAssets(provider);

                const connected = statusMap[provider];

                return (
                    <div key={provider} className="mb-lg">
                        <SectionLabel label={p.name} connected={connected} />
                        {groupByProvider[provider].map(option => {
                            return (
                                <ResourceOption
                                    key={option.id}
                                    title={option.name}
                                    provider={p.name}
                                    src={p.logo}
                                    description={option.description}
                                    connected={connected}
                                    icon={
                                        option.type === 'playbook' ? (
                                            <BookText className="shrink-0" />
                                        ) : provider === 'custom' ? (
                                            <Box className="shrink-0" />
                                        ) : undefined
                                    }
                                    isActive={activeOption?.id === option.id}
                                    onClick={
                                        connected === false
                                            ? undefined
                                            : () => {
                                                  setActiveOption(option);
                                              }
                                    }
                                />
                            );
                        })}
                    </div>
                );
            })}
            <ReferencesSections references={references} />
        </div>
    );
};
