import { capitalize } from 'lodash-es';
import { ChevronDown } from 'lucide-react';
import { useState } from 'react';

import { Button } from '@/components/ui/button';
import { cn } from '@/lib/styling';
import type { CnslApp, CnslGroup, CnslUser } from 'lib/prisma/types';

import type { PlaybookReferenceKeys, PlaybookReferences } from '../PlaybookReferences';
import { SectionLabel } from '../SectionLabel';
import { usePlaybookDetailsStore } from '../store/usePlaybookDetailsStore';
import { AppReference } from './AppReference';
import { GroupReference } from './GroupReference';
import { SlackChannelReference } from './SlackChannelReference';
import { UserReference } from './UserReference';

const LIMIT = 10;
const sections: PlaybookReferenceKeys[] = ['users', 'groups', 'apps', 'slackChannels'];

const camelCaseToSentence = (str: string) => capitalize(str.replace(/([A-Z])/g, ' $1').trim());

export const ReferencesSections = ({ references }: { references: PlaybookReferences }) => {
    const setActiveReference = usePlaybookDetailsStore(state => state.setActiveReference);
    const [expand, setExpand] = useState(
        sections.reduce((acc, section) => ({ ...acc, [section]: false }), {} as Record<PlaybookReferenceKeys, boolean>),
    );

    return (
        <>
            {sections.map(section => {
                const data = references[section];
                const sliceData = data.slice(0, LIMIT);
                const showButton = data.length > LIMIT;
                return (
                    <div key={section}>
                        {sliceData.length ? (
                            <>
                                <SectionLabel label={camelCaseToSentence(section)} />
                                {(expand[section] ? data : sliceData).map(entity =>
                                    section === 'users' ? (
                                        <UserReference
                                            key={entity.id}
                                            user={entity as CnslUser}
                                            onClick={() => {
                                                setActiveReference(entity);
                                            }}
                                        />
                                    ) : section === 'groups' ? (
                                        <GroupReference
                                            key={entity.id}
                                            group={entity as CnslGroup}
                                            onClick={() => {
                                                setActiveReference(entity);
                                            }}
                                        />
                                    ) : section === 'slackChannels' ? (
                                        <SlackChannelReference
                                            key={entity.id}
                                            channel={entity as PrismaJson.SlackChannel}
                                            onClick={() => {
                                                setActiveReference(entity);
                                            }}
                                        />
                                    ) : (
                                        <AppReference
                                            key={entity.id}
                                            app={entity as CnslApp}
                                            onClick={() => {
                                                setActiveReference(entity);
                                            }}
                                        />
                                    ),
                                )}
                                {showButton ? (
                                    <div className="flex p-md">
                                        <Button
                                            size="sm"
                                            onClick={() => setExpand({ ...expand, [section]: !expand[section] })}
                                        >
                                            <ChevronDown
                                                className={cn(
                                                    'size-4 transition-all duration-100 ease-out',
                                                    !expand[section] ? '' : 'rotate-180',
                                                )}
                                            />
                                            {!expand[section] ? 'Show more' : 'Show less'}
                                        </Button>
                                    </div>
                                ) : null}
                            </>
                        ) : null}
                    </div>
                );
            })}
        </>
    );
};
