import { fmtDayMonthNumeric, fmtMonthShort } from '@/lib/dates';
import { ChartInterval } from 'lib/models/insights';
import type { ResolutionStatsRow } from 'lib/models/request';
import { Area, type TooltipProps } from 'recharts';
import { TrendingIndicator } from '../TrendingIndicator';

export type ResStatus = 'auto_resolved' | 'escalated' | 'in_progress';

export interface ResStatusConfig {
    hex: string;
    bg: string;
    name: string;
}

export const statusConfig: Record<ResStatus, ResStatusConfig> = {
    auto_resolved: {
        hex: '#8E4EC6',
        bg: 'bg-[#8E4EC6]',
        name: 'auto-resolved',
    },
    escalated: {
        hex: '#3E63DD',
        bg: 'bg-[#3E63DD]',
        name: 'escalated to IT',
    },
    in_progress: {
        hex: '#0091FF',
        bg: 'bg-[#0091FF]',
        name: 'in progress',
    },
};

export const GenResArea = ({ status }: { status: ResStatus }) => {
    const config = statusConfig[status];
    return (
        <Area
            key={status}
            animateNewValues={false}
            isAnimationActive={false}
            dataKey={status}
            name={config.name}
            stroke={config.hex}
            strokeWidth={0}
            fillOpacity={1}
            fill={`url(#color_${status})`}
            stackId="1"
            connectNulls
        />
    );
};

export const makeLabel = (idx: number, rows: ResolutionStatsRow[], currentInterval: ChartInterval) => {
    const { date_bucket_start, date_bucket_end } = rows[idx];
    switch (currentInterval) {
        case ChartInterval.DAY:
            return fmtDayMonthNumeric(date_bucket_start);
        case ChartInterval.WEEK:
            return `${fmtDayMonthNumeric(date_bucket_start)} - ${fmtDayMonthNumeric(date_bucket_end)}`;
        case ChartInterval.MONTH:
            return fmtMonthShort(date_bucket_start);
    }
};

interface Trending {
    trendingPercentage: number | undefined;
    direction: 'up' | 'down';
    showTrending: boolean;
    currentVolume: number | undefined;
}

export const computeTrending = (rows: ResolutionStatsRow[]): Trending => {
    let trendingPercentage: number | undefined;
    let currentVolume: number | undefined;
    if (rows.length > 1) {
        const currentSegment = rows[rows.length - 1];
        const previousSegment = rows[rows.length - 2];

        // Calculate the total volume for each segment
        currentVolume = currentSegment.auto_resolved + currentSegment.escalated + currentSegment.in_progress;
        const previousVolume = previousSegment.auto_resolved + previousSegment.escalated + previousSegment.in_progress;
        // Calculate the percentage of the segment that has elapsed
        const startOfSegment = new Date(currentSegment.date_bucket_start);
        const endOfSegment = new Date(currentSegment.date_bucket_end);
        const progress = (Date.now() - startOfSegment.getTime()) / (endOfSegment.getTime() - startOfSegment.getTime());

        // Extrapolate the current segment's volume
        const projectedCurrentVolume = currentVolume / progress;

        // Calculate the percentage change
        trendingPercentage = (projectedCurrentVolume - previousVolume) / previousVolume;
    } else {
        currentVolume = rows.reduce((acc, row) => acc + row.auto_resolved + row.escalated + row.in_progress, 0);
    }

    const percentage = trendingPercentage ?? 0;
    const direction = percentage > 0 ? 'up' : 'down';
    const showTrending = Number.isFinite(trendingPercentage) && trendingPercentage !== undefined;
    return { trendingPercentage, direction, showTrending, currentVolume };
};

export const ResolutionTooltip = ({
    active,
    payload,
    label,
    rows,
}: TooltipProps<number, string> & { rows: ResolutionStatsRow[] }) => {
    if (active && payload && payload.length && typeof label === 'number') {
        const priorRow = rows[label - 1];
        const currentRow = rows[label];

        let autoResolvedChange: number | undefined;
        let escalatedChange: number | undefined;
        if (priorRow && currentRow) {
            const currentAutoResolved = currentRow.auto_resolved;
            const priorAutoResolved = priorRow.auto_resolved;
            autoResolvedChange = (currentAutoResolved - priorAutoResolved) / priorAutoResolved;

            const currentEscalated = currentRow.escalated;
            const priorEscalated = priorRow.escalated;
            escalatedChange = (currentEscalated - priorEscalated) / priorEscalated;
        }
        const changeConfig = new Map<
            string,
            { change: number | undefined; upColor: 'red' | 'green'; downColor: 'red' | 'green' }
        >();
        changeConfig.set('auto-resolved', { change: autoResolvedChange, upColor: 'green', downColor: 'red' });
        changeConfig.set('escalated to IT', { change: escalatedChange, upColor: 'red', downColor: 'green' });

        const getChangeValue = (name: string | undefined) => {
            if (!name) {
                return undefined;
            }
            return changeConfig.get(name)?.change;
        };

        const getColor = (name: string | undefined) => {
            if (name) {
                const config = changeConfig.get(name);
                if (config && config.change) {
                    return config.change > 0 ? config.upColor : config.downColor;
                }
            }
            return 'neutral';
        };

        const getDirection = (name: string | undefined) => {
            const change = getChangeValue(name) ?? 0;
            return change > 0 ? 'up' : 'down';
        };

        // sort the payload
        const order = ['auto-resolved', 'escalated to IT', 'in progress'];
        const sortedPayload = [...payload].sort((a, b) => order.indexOf(a.name ?? '') - order.indexOf(b.name ?? ''));

        return (
            <div className="border border-grey rounded-md bg-white">
                <div className="p-md border-b border-bg-grey-primary flex justify-between text-body font-medium gap-x-lg">
                    <div>Total requests</div>
                    <div>
                        {payload.reduce((acc, p) => {
                            if (p && p.value) {
                                return acc + p.value;
                            }
                            return acc;
                        }, 0)}
                    </div>
                </div>
                <div className="p-md">
                    {sortedPayload.map(p => (
                        <div key={p.name} className="flex items-center gap-x-sm">
                            <div style={{ backgroundColor: p.color }} className="size-md rounded-[2px]" />
                            <div>{p.value}</div>
                            <div>{p.name}</div>
                            {getChangeValue(p.name) !== undefined && (
                                <TrendingIndicator
                                    direction={getDirection(p.name)}
                                    value={getChangeValue(p.name)}
                                    color={getColor(p.name)}
                                    placeholder=""
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
    return null;
};
