import { Tooltip, TooltipContent, TooltipTrigger } from '@ui/tooltip';
import { Radio, Unplug } from 'lucide-react';
import { Link } from 'react-router-dom';

export const SectionLabel = ({ label, connected }: { label: string; connected?: boolean }) => {
    const icon = () => {
        if (connected !== undefined) {
            return connected ? <Radio /> : <Unplug />;
        }
        return null;
    };

    return (
        <div className="px-md text-body-subtle py-[7px] flex gap-md items-center text-xs font-medium">
            <div className="flex items-center gap-sm justify-between w-full">
                <div className="flex items-center gap-sm">
                    {icon()}

                    {label}
                </div>
                {connected === false && (
                    <Tooltip>
                        <TooltipContent>{`Connect ${label} to use these actions`}</TooltipContent>
                        <TooltipTrigger>
                            <Link
                                to="/settings/organization/integrations"
                                className="bg-bg-grey-primary px-md py-[1px] text-xs rounded-full text-body-grey-primary"
                            >
                                Connect
                            </Link>
                        </TooltipTrigger>
                    </Tooltip>
                )}
            </div>
        </div>
    );
};
