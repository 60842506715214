import { cn } from '@/lib/styling';
import { differenceInDays } from 'date-fns';
import type { Article } from 'lib/models/article';
import { ArticleState, type ArticleStateType } from 'lib/prisma/enums';
import { Circle } from 'lucide-react';

export const getDaysAgo = (date: Date) => {
    const diffDays = differenceInDays(new Date(), date);
    if (diffDays === 0) {
        return 'today';
    } else if (diffDays === 1) {
        return 'yesterday';
    } else {
        return `${diffDays} days ago`;
    }
};

export const getStatusDot = (state?: ArticleStateType) => {
    const color = state === ArticleState.DRAFT ? 'text-body-subtle-hover' : 'text-body-blue-primary';

    return <Circle fill="currentColor" className={cn('size-[6px]', color)} />;
};

export const getArticleName = (article: Article) => {
    return article?.internal_article
        ? article?.internal_article?.name || article?.internal_article?.versions[0]?.name || 'No title'
        : article?.external_article?.name || 'No title';
};
