import { FormField, FormItem, FormLabel } from '@/components/ui/form';
import { baseInputStyle } from '@/components/ui/input';
import { cn } from '@/lib/styling';
import Placeholder from '@tiptap/extension-placeholder';
import type { JSONContent } from '@tiptap/react';
import { type ActionSchemaType, getParametersFromErrorMessage } from 'lib/models/actions';
import type { FieldPath, UseFormReturn } from 'react-hook-form';

import { actionDetailMentionConfig } from '../editorFields';

import { Editor } from '@/components/tiptap/Editor';
import { NoNewLine } from '@/lib/tiptap/noNewLine';
import { configureExtensions } from 'lib/tiptap';
import { get } from 'lodash-es';

interface EditorFieldProps {
    form: UseFormReturn<ActionSchemaType>;
    content: JSONContent | null;
    field: FieldPath<ActionSchemaType>;
    placeholder: string;
    label: string;
    error?: string;
    newLineEnabled?: boolean;
}

export const EditorField = ({ form, content, field, placeholder, label, newLineEnabled }: EditorFieldProps) => {
    const baseExtensions = [
        Placeholder.configure({
            placeholder,
        }),
    ];

    if (!newLineEnabled) {
        baseExtensions.push(NoNewLine);
    }

    const parameterError = get(form.formState.errors, field);
    let missingParams: string[] = [];

    if (parameterError) {
        missingParams = getParametersFromErrorMessage(parameterError.message as string);
    }

    const config = actionDetailMentionConfig;

    // define renderHTML method in order the handle the case where a parameter is missing from configuration
    config.renderHTML = ({ options, node }) => {
        const hasError = missingParams.includes(node.attrs.id);
        const type = config?.HTMLAttributes?.class ?? 'mention-action-parameter';
        return [
            'span',
            { 'data-type': type, class: `${type} ${hasError ? 'error' : ''}`, 'data-id': node.attrs.id },
            `${options.suggestion.char}{${node.attrs.id}}`,
        ];
    };

    const handleChange = (v: JSONContent) => {
        form.setValue(field, v, { shouldDirty: true });
    };

    return (
        <FormField
            control={form.control}
            name={field}
            render={({ field }) => (
                <FormItem className="flex-grow">
                    <FormLabel>{label}</FormLabel>
                    <Editor
                        controlled={missingParams?.length > 0}
                        content={field.value ?? content}
                        onChange={handleChange}
                        extensions={configureExtensions(baseExtensions, [config])}
                        className={cn(baseInputStyle, '')}
                    />
                </FormItem>
            )}
        />
    );
};
