import { Button } from '@/components/ui/button';
import { useProvisioningTicketFieldMappingDelete } from '@/hooks/mutations/useProvisioningTickets';
import type { ProvisioningTicketFieldMapping } from 'lib/prisma/types';
import { Trash2 } from 'lucide-react';

export const FieldMappingListHeader = () => {
    return (
        <div className="grid grid-cols-3 gap-sm justify-between items-center mb-2 text-xs font-medium">
            <p>Ticket field</p>
            <p>Value</p>
            <p />
        </div>
    );
};

export const FieldMappingListItem = ({ field, label }: { field: ProvisioningTicketFieldMapping; label?: string }) => {
    const mutation = useProvisioningTicketFieldMappingDelete();

    const handleDelete = () => {
        mutation.mutate({ provider: field.provider, fieldKey: field.fieldKey });
    };

    return (
        <div className="grid grid-cols-3 gap-sm justify-between items-center mb-2">
            <p>{label}</p>
            <p>{field.accessRequestProperty ?? field.staticValue}</p>
            <div className="flex justify-end">
                <Button mode="borderless" onClick={handleDelete}>
                    <Trash2 />
                </Button>
            </div>
        </div>
    );
};
