import type { JSONContent } from '@tiptap/core';
import isEqual from 'lodash-es/isEqual';

import type { PlaybookInstructionsTree } from 'lib/models/tiptap';
import { PlaybookInstructionsTreeBase } from 'lib/prisma/validation/playbook/utils/PlaybookInstructionsTreeBase';

/**
 * Takes a schema and keeps track of the instance count of each node given a key
 */
export class TiptapSchemaInstanceCounter extends PlaybookInstructionsTreeBase {
    //  keeps track of the instance count of each node given
    private _instances: Record<string, number> | null = null;
    // used to loop up the key in the attributes that you want to count on
    private _countFn: (node: JSONContent) => string;

    constructor(countFn: (node: JSONContent) => string, tree?: PlaybookInstructionsTree) {
        super(tree);
        this._countFn = countFn;

        this.count();
    }

    count(tree?: PlaybookInstructionsTree): boolean {
        if (tree) {
            this.tree = structuredClone(tree);
        }

        const newInstances: Record<string, number> = {};

        this.update(node => {
            if (!node?.attrs) return node;

            const key = this._countFn(node);
            if (!key) return node;

            newInstances[key] = (newInstances[key] ?? -1) + 1;
            node.attrs.instanceCount = newInstances[key];
            return node;
        });

        const dirty = !isEqual(this._instances, newInstances);
        this._instances = structuredClone(newInstances);

        return dirty;
    }

    updateCountFunction(countFn: (node: JSONContent) => string) {
        this._countFn = countFn;
        this.count();
    }

    destroy() {
        super.destroy();
        this._instances = null;
    }
}
