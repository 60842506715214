import type { PlaybookReferencesResponse } from 'lib/actions/actions';
import { parseInstructionDataReferences } from 'lib/actions/utils';
import type { PlaybookInstructionsTree } from 'lib/models/tiptap';
import { PlaybookResourceType } from 'lib/prisma/enums';
import { uniqBy } from 'lodash-es';

import type { Reference } from './store/usePlaybookDetailsStore';

export type PlaybookReferenceKeys = ['users', 'groups', 'apps', 'slackChannels'][number];

/**
 * Helper class for dealing with playbook references
 */
export class PlaybookReferences {
    private _config: PlaybookReferencesResponse['references'];
    public users: PlaybookReferencesResponse['references']['USER'];
    public groups: PlaybookReferencesResponse['references']['GROUP'];
    public apps: PlaybookReferencesResponse['references']['APP'];
    public slackChannels: PlaybookReferencesResponse['references']['SLACK_CHANNEL'];

    constructor(config: PlaybookReferencesResponse['references']) {
        this._config = config;
        this.users = this._config.USER;
        this.groups = this._config.GROUP;
        this.apps = this._config.APP;
        this.slackChannels = this._config.SLACK_CHANNEL;
    }

    static isUser(reference?: Reference): reference is PlaybookReferencesResponse['references']['USER'][number] {
        return reference !== undefined && 'displayName' in reference;
    }

    static isGroup(reference?: Reference): reference is PlaybookReferencesResponse['references']['GROUP'][number] {
        return reference !== undefined && !('displayName' in reference) && !('status' in reference);
    }

    static isApp(reference?: Reference): reference is PlaybookReferencesResponse['references']['APP'][number] {
        return reference !== undefined && 'status' in reference;
    }

    static isSlackChannel(
        reference?: Reference,
    ): reference is PlaybookReferencesResponse['references']['SLACK_CHANNEL'][number] {
        return reference !== undefined && 'purpose' in reference;
    }

    getUserIndex(index: number) {
        return index;
    }

    getGroupIndex(index: number) {
        return this.users.length + index;
    }

    getAppIndex(index: number) {
        return this.users.length + this.groups.length + index;
    }

    getChannelsIndex(index: number) {
        return this.users.length + this.groups.length + this.apps.length + index;
    }

    getItem(index: number) {
        return this.flatten()[index];
    }

    getCommand(index: number) {
        return this.mapToCommand(this.getItem(index));
    }

    mapToCommand(item: Reference) {
        // XXX - we use the id to determine the color in CSS. See Editor.css
        if (PlaybookReferences.isUser(item)) {
            return { label: `User:${item.displayName}`, id: `${PlaybookResourceType.USER}:${item.id}` };
        }

        if (PlaybookReferences.isApp(item)) {
            return { label: `App:${item.name}`, id: `${PlaybookResourceType.APP}:${item.id}` };
        }

        if (PlaybookReferences.isSlackChannel(item)) {
            return { label: `App:Slack:Channel:${item.name}`, id: `${PlaybookResourceType.SLACK_CHANNEL}:${item.id}` };
        }

        return { label: `Group:${item.name}`, id: `${PlaybookResourceType.GROUP}:${item.id}` };
    }

    search(query: string, limit: number = Number.MAX_SAFE_INTEGER) {
        // We will have fancier search in the future, but for now just remove all whitespace, colons, and support passing plural PlaybookResourceType
        const searchStr = query
            .replace(/[\s:]/g, '')
            .replace(/users/g, PlaybookResourceType.USER)
            .replace(/apps/g, PlaybookResourceType.APP)
            .replace(/groups/g, PlaybookResourceType.GROUP)
            .replace(/slackChannels/g, PlaybookResourceType.SLACK_CHANNEL)
            .toLowerCase();

        return new PlaybookReferences({
            USER: this.users
                .filter(user => `${PlaybookResourceType.USER}${user.displayName}`.toLowerCase().includes(searchStr))
                .slice(0, limit),
            APP: this.apps
                .filter(app => `${PlaybookResourceType.APP}${app.name}`.toLowerCase().includes(searchStr))
                .slice(0, limit),
            GROUP: this.groups
                .filter(group => `${PlaybookResourceType.GROUP}${group.name}`.toLowerCase().includes(searchStr))
                .slice(0, limit),
            SLACK_CHANNEL: this.slackChannels
                .filter(channel =>
                    `${PlaybookResourceType.SLACK_CHANNEL}${channel.name}`.toLowerCase().includes(searchStr),
                )
                .slice(0, limit),
        });
    }

    isEmpty() {
        return this.flatten().length === 0;
    }

    flatten() {
        return [...this.users, ...this.groups, ...this.apps, ...this.slackChannels];
    }

    loadInstructionData(instructionsData: PlaybookInstructionsTree) {
        const playbookReferences = uniqBy(
            parseInstructionDataReferences(instructionsData),
            d => `${d.resourceType}:${d.resourceId}`,
        );

        return new PlaybookReferences({
            USER: playbookReferences
                .filter(r => r.resourceType === PlaybookResourceType.USER)
                .map(r => this.users.find(u => u.id === r.resourceId))
                .filter(r => r !== undefined),
            APP: playbookReferences
                .filter(r => r.resourceType === PlaybookResourceType.APP)
                .map(r => this.apps.find(u => u.id === r.resourceId))
                .filter(r => r !== undefined),
            GROUP: playbookReferences
                .filter(r => r.resourceType === PlaybookResourceType.GROUP)
                .map(r => this.groups.find(u => u.id === r.resourceId))
                .filter(r => r !== undefined),
            SLACK_CHANNEL: playbookReferences
                .filter(r => r.resourceType === PlaybookResourceType.SLACK_CHANNEL)
                .map(r => this.slackChannels.find(u => u.id === r.resourceId))
                .filter(r => r !== undefined),
        });
    }
}
