import { Button } from '@/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog';
import {
    useArticleExternalDirectory,
    useArticleExternalDirectorySelected,
} from '@/hooks/queries/articles/useArticleDirectory';
import { HelpCircle, Info } from 'lucide-react';

import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { useArticleExternalDirectorySelectedPost } from '@/hooks/mutations/articles/useArticleExternalDirectorySelectedPost';
import {
    useIntegrationReSync,
    useIntegrationSettingsSync,
} from '@/hooks/queries/integrations/useIntegrationSettingsStatus';
import { fmtDateFull } from '@/lib/dates';
import { getProviderAssets } from 'lib/3p';
import type { IntegrationId } from 'lib/integration';
import type { NestableDirectory } from 'lib/models/directory';
import { useState } from 'react';
import { type NestableDirectoryWithChecked, NestedCheckboxTree } from './NestedCheckboxTree';

export const SyncArticleDialog = ({ onClose, provider }: { onClose: () => void; provider: IntegrationId }) => {
    const [selectedIds, setSelectedIds] = useState<(string | number)[] | undefined>();
    const [hovered, setHovered] = useState(false);

    const { data: syncData } = useIntegrationSettingsSync();
    const { data, isLoading } = useArticleExternalDirectory({ provider });
    const { data: selectedData } = useArticleExternalDirectorySelected({
        provider,
    });
    const updateSelected = useArticleExternalDirectorySelectedPost({ provider });
    const syncMutation = useIntegrationReSync({ provider });

    if (!data?.directory || isLoading) {
        return null;
    }

    const handleChange = (selectedIds: (string | number)[]) => {
        setSelectedIds(selectedIds);
    };

    const addCheckedField = (
        directory: NestableDirectory[number] | NestableDirectoryWithChecked,
    ): NestableDirectoryWithChecked => {
        const children = 'children' in directory ? (directory.children?.map(c => addCheckedField(c)) ?? []) : [];

        const checked =
            children.length > 0
                ? children.every(c => c?.checked)
                : Array.isArray(selectedIds)
                  ? selectedIds.some(s => s === directory.id)
                  : selectedData?.selected.some(s => `${s.article.externalId}` === `${directory.id}`);

        if (directory.type === 'directory') {
            return {
                ...directory,
                checked,
                children: children ?? [],
            } as NestableDirectoryWithChecked;
        }

        return {
            ...directory,
            checked: checked ?? false,
        };
    };

    const dataWithChecked = data.directory.map(addCheckedField);

    const handleSync = () => {
        if (!selectedIds) {
            return;
        }

        updateSelected.mutate(
            { selectedIds },
            {
                onSuccess: () => {
                    onClose();
                },
            },
        );
    };

    const handleIngest = () => {
        syncMutation.mutate({});
    };

    const lastSynced = syncData?.[provider]?.lastSync;

    const { logo, name } = getProviderAssets(provider);

    return (
        <Dialog
            open={true}
            onOpenChange={() => {
                onClose();
            }}
        >
            <DialogContent size="max" className="max-w-[800px]">
                <DialogHeader>
                    <DialogTitle>
                        <img src={logo} alt={name} className="size-6" />
                        Sync articles from {name}
                    </DialogTitle>
                </DialogHeader>
                <DialogDescription className="bg-bg-overlay px-lg py-md flex flex-col gap-sm border-grey border-y-[0.5px]">
                    <div className="text-body-grey-primary flex items-center gap-md">
                        <Info />
                        <h3>Syncing</h3>
                    </div>
                    <div>
                        You can manage which articles you would like to sync into Console at any time. Articles will
                        automatically re-sync every hour or you can manually sync changes at any time to ensure your
                        Knowledge Base stays up to date.
                    </div>
                </DialogDescription>
                <div className="overflow-y-auto px-md py-lg">
                    <NestedCheckboxTree data={dataWithChecked} onChange={handleChange} />
                </div>
                <DialogFooter className="flex justify-between">
                    <Button mode="borderless" onClick={onClose}>
                        Cancel
                    </Button>
                    <div className="flex items-center gap-md">
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Button
                                    mode="borderless"
                                    onClick={handleIngest}
                                    className="text-body-subtle hover:text-body-grey-primary w-36"
                                    onMouseEnter={() => setHovered(true)}
                                    onMouseLeave={() => setHovered(false)}
                                >
                                    {hovered ? (
                                        <>
                                            <img src="/3p/zendesk-logo.png" alt="Zendesk logo" className="size-4" />
                                            Refresh articles
                                        </>
                                    ) : (
                                        <>
                                            <HelpCircle />
                                            Missing articles?
                                        </>
                                    )}
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                                Last synced {lastSynced ? fmtDateFull(lastSynced) : 'never'}
                            </TooltipContent>
                        </Tooltip>

                        <Button variant="blue" mode="dark" onClick={handleSync}>
                            Save articles
                        </Button>
                    </div>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
