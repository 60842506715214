import { ResLink } from '@/components/ResLink';
import { ProviderAssets } from 'lib/3p';
import { IntegrationId } from 'lib/integration';
import { ResourceOption } from '../ResourceOption';

export const SlackChannelReference = ({
    channel,
    isActive,
    onClick,
}: { channel: PrismaJson.SlackChannel; isActive?: boolean; onClick?: () => void }) => {
    return (
        <ResourceOption
            title={<ResLink size="sm" src={ProviderAssets[IntegrationId.Slack].logo} label={channel.name ?? ''} />}
            isActive={isActive}
            onClick={onClick}
        />
    );
};
