import { Overview } from '@/components/apps/Overview';
import { Policies } from '@/components/apps/Policies';
import { Users } from '@pages/apps/details/Users';
import { SkeletonDetails } from '@ui/skeleton';
import { NotebookTabs, Scale } from 'lucide-react';
import { useLocation, useParams } from 'react-router-dom';

import { ResLink } from '@/components/ResLink';
import { useApp } from '@/hooks/queries/useApps';
import { Details } from '@/pages/layouts/Details';
import { DetailsCrumbIconWrapper } from '@/pages/layouts/components/DetailsCrumbIconWrapper';
import type { BrowConfig } from '@/pages/layouts/types/DetailsConfig';
import { POLICIES, USERS } from '@/routes/paths';

import { CreatePolicyBtn } from '@/components/policies/CreatePolicyBtn';
import { CnslAppIcon, CnslUserIcon } from '@/lib/iconConstants';

const entity = 'apps';

export const AppDetails = () => {
    const currentPath = useLocation().pathname;
    const { slug = '' } = useParams();
    const { data, isLoading } = useApp({ slug });
    const app = data?.app;

    if (isLoading) {
        return <SkeletonDetails />;
    }

    if (!app) {
        return null;
    }

    const config = [
        {
            name: 'Overview',
            pathname: '',
            Icon: NotebookTabs,
            component: <Overview app={app} />,
            matchPathExactly: true,
        },
        {
            name: 'Users',
            count: app.app_users.length,
            pathname: USERS,
            Icon: CnslUserIcon,
            component: <Users app={app} />,
        },
        {
            name: 'Access Policies',
            count: app.policies.length,
            pathname: POLICIES,
            Icon: Scale,
            component: <Policies slug={slug} app={app} mode="drawer" />,
        },
    ];

    const logo = `/api/v1/apps/${slug}/logo`;

    const basePath = `/${entity}/${slug}`;

    const crumbs = [
        { label: <DetailsCrumbIconWrapper label="Apps" icon={<CnslAppIcon />} />, href: `/${entity}` },
        {
            label: (
                <ResLink
                    id={slug}
                    label={app?.name ?? ''}
                    src={logo}
                    rounded="square"
                    size="sm"
                    bold={basePath === currentPath}
                />
            ),
            href: basePath,
        },
    ];

    config.forEach(c => {
        c.pathname = `${basePath}${c.pathname}`;
        if (c.pathname === currentPath && c.pathname !== basePath) {
            crumbs.push({
                label: <DetailsCrumbIconWrapper label={c.name} icon={<c.Icon />} bold={true} />,
                href: c.pathname,
            });
        }
    });

    const browConfig: BrowConfig = {
        breadcrumbs: crumbs,
        actions: [],
    };
    if (currentPath.endsWith(POLICIES)) {
        browConfig.actions = [<CreatePolicyBtn key="new-policy" app={app} />];
    }

    return (
        <Details
            config={config}
            headerConfig={{
                src: logo,
                title: app?.name ?? 'App Name',
                subtitle: app?.category,
                fallback: app?.name?.[0],
                rounded: 'square',
            }}
            browConfig={browConfig}
        />
    );
};
