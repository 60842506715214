const slackScopes = [
    'chat:write',
    'chat:write.customize',
    'commands',
    'im:history',
    'im:read',
    'im:write',
    'metadata.message:read',
    'users:read',
    'users:read.email',
    'channels:history',
    'channels:join',
    'groups:history',
    'groups:read',
    'groups:write',
    'groups:write.invites',
    'mpim:history',
    'mpim:read',
    'mpim:write',
    'channels:read',
    'channels:manage',
    'channels:write.invites',
];

export const getSlackOAuthUrl = () => {
    const clientId = import.meta.env.VITE_SLACK_CLIENT_ID;
    const redirectUri = import.meta.env.VITE_SLACK_REDIRECT_URI;
    const url = new URL('https://slack.com/oauth/v2/authorize');
    url.searchParams.set('client_id', clientId);
    url.searchParams.set('scope', slackScopes.join(','));
    url.searchParams.set('redirect_uri', redirectUri);

    return url.toString();
};
