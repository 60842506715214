import { Brow } from '@/components/Brow';
import { Separator } from '@/components/ui/separator';
import { useOrgOnboarding } from '@/hooks/queries/useOrgOnboarding';
import { OrgOnboardingStatus } from 'lib/prisma/enums';
import { Goal } from 'lucide-react';
import { OnboardingCompletedDialog } from './OnboardingCompletedDialog';
import { OnboardingStep } from './OnboardingStep';
import { ProgressCircle } from './ProgressCircle';
import { useOnboardingSteps } from './useOnboardinSteps';
import { getTotalSteps, numberOfCompletedSteps } from './utils';

export const Onboarding = () => {
    const { data } = useOrgOnboarding();
    const steps = useOnboardingSteps();

    if (!data?.onboarding) {
        return <div>Loading...</div>;
    }

    const stepsCompleted = numberOfCompletedSteps(data.onboarding);
    const totalSteps = getTotalSteps(data.onboarding);

    return (
        <>
            <Brow title="Onboarding" icon={<Goal />} />
            <div className="flex justify-center overflow-y-auto">
                <div className="w-[640px]">
                    <div className="flex flex-col py-12 gap-md">
                        <div className="gap-md flex items-center">
                            <Goal className="size-xl" />
                            <h1 className="text-xl">Welcome to Console</h1>
                        </div>
                        <p className="text-body-subtle-hover text-base">
                            Let's get you set up to maximize your experience with Console
                        </p>
                        <Separator className="my-8" />
                        <div className="border-grey border-[0.5px] flex flex-col gap-[0.5px] bg-bg-grey-primary-hover rounded-lg overflow-hidden">
                            <div className="flex items-center justify-between p-lg text-base bg-bg-overlay">
                                <h3>Let's get started!</h3>
                                <div className="flex items-center gap-lg">
                                    <h3>{`${stepsCompleted}/${totalSteps}`}</h3>
                                    <ProgressCircle progress={stepsCompleted / totalSteps} />
                                </div>
                            </div>
                            {steps.map(step => (
                                <OnboardingStep key={step.step} {...step} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {data.onboarding.status === OrgOnboardingStatus.COMPLETED && <OnboardingCompletedDialog />}
        </>
    );
};
