import { cn } from '@/lib/styling';
import { Tooltip, TooltipContent, TooltipTrigger } from '@ui/tooltip';
import { LockKeyhole } from 'lucide-react';
import type { ReactNode } from 'react';

export const ResourceOption = ({
    title,
    description,
    onClick,
    isActive,
    isDefaultAction,
    src,
    provider,
    icon,
    connected,
}: {
    title: ReactNode;
    description?: string;
    onClick?: () => void;
    isActive?: boolean;
    isDefaultAction?: boolean;
    src?: string;
    provider?: string;
    icon?: ReactNode;
    connected?: boolean;
}) => {
    return (
        <div
            className={cn(
                'rounded-[6px] flex items-center gap-md px-md py-[6px]',
                connected === false ? 'text-body-subtle cursor-not-allowed' : '',
                onClick ? 'cursor-pointer hover:bg-bg-overlay' : '',
                isActive ? 'bg-bg-overlay' : '',
            )}
            onClick={onClick}
        >
            {src && <img src={src} alt={`${provider} logo`} className="size-lg" />}
            {icon}
            <div className="flex grow flex-col w-full overflow-hidden">
                <div className="self-stretch truncate font-medium">{title}</div>
                {description && <div className="text-body-subtle self-stretch truncate text-xs">{description}</div>}
            </div>
            {isDefaultAction && (
                <Tooltip>
                    <TooltipTrigger asChild>
                        <LockKeyhole className="text-body-subtle hover:text-body-grey-primary duration-100 transition-colors ease-out" />
                    </TooltipTrigger>
                    <TooltipContent>
                        <p>Default action</p>
                    </TooltipContent>
                </Tooltip>
            )}
        </div>
    );
};
