import { useMutation } from '@lib/hooks/useMutation';
import { type DefaultError, useQueryClient } from '@tanstack/react-query';
import type { AppPolicyPostWrapper } from 'lib/models/app_policy';
import type { AppPolicy } from 'lib/prisma/types';
import { refetch } from './cache';
export const usePolicyPut = (policyId: string, slug?: string) => {
    const queryClient = useQueryClient();
    return useMutation<AppPolicy, DefaultError, AppPolicyPostWrapper>({
        url: `/api/v1/policies/${policyId}`,
        method: 'PUT',
        successToast: {
            message: 'Policy updated',
        },
        errorToast: {
            message: 'Problem updating policy',
        },
        onSuccess: () => refetch(queryClient, slug),
    });
};
