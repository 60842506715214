import { useMutation } from '@/lib/hooks/useMutation';
import { ACTIONS, type ACTIONS_DETAILS_TYPE } from '@/routes/paths';
import { type DefaultError, useQueryClient } from '@tanstack/react-query';
import type { ActionSchemaType, ActionsResponse } from 'lib/models/actions';
import { useNavigate } from 'react-router-dom';

export const useActionCreate = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    return useMutation<ActionsResponse, DefaultError, ActionSchemaType>({
        url: `/api/v1/actions`,
        method: 'POST',
        onSuccess: async ({ action }) => {
            await queryClient.refetchQueries({ queryKey: [`/api/v1/playbooks/actions`], exact: true });
            await queryClient.refetchQueries({ queryKey: [`/api/v1/actions`], exact: true });
            navigate(`${ACTIONS}/${action.provider}/${action.slug}` as ACTIONS_DETAILS_TYPE);
        },
        successToast: {
            message: 'Action created',
        },
    });
};

export const useActionUpdate = (provider: string, slug: string) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    return useMutation<ActionsResponse, DefaultError, ActionSchemaType>({
        url: `/api/v1/actions/${provider}/${slug}`,
        method: 'PUT',
        onSuccess: async ({ action }) => {
            await queryClient.refetchQueries({ queryKey: [`/api/v1/playbooks/actions`], exact: true });
            await queryClient.refetchQueries({ queryKey: [`/api/v1/actions`], exact: true });
            if (action.slug !== slug) {
                navigate(`${ACTIONS}/${action.provider}/${action.slug}` as ACTIONS_DETAILS_TYPE);
            }
        },
        successToast: {
            message: 'Action updated',
        },
    });
};

export const useActionDelete = (provider: string, slug: string) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    return useMutation<void, DefaultError, void>({
        url: `/api/v1/actions/${provider}/${slug}`,
        method: 'DELETE',
        onSuccess: async () => {
            await queryClient.refetchQueries({ queryKey: [`/api/v1/playbooks/actions`], exact: true });
            await queryClient.refetchQueries({ queryKey: [`/api/v1/actions`], exact: true });
            await navigate(ACTIONS);
        },
        successToast: {
            message: 'Action deleted',
        },
    });
};
