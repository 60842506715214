import { AppWindowMac, Building, MessageSquareText } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { Brow } from '@/components/Brow';
import { SETTINGS } from '@/routes/paths';

import { CnslUserIcon } from '@/lib/iconConstants';
import { Approvals } from './components/Approvals/Approvals';
import Categories from './components/Categories';
import General from './components/General';
import { Members } from './components/Members';
import RequestManagement from './components/RequestManagement';
import SettingsContent from './components/SettingsContent';
import SettingsNav from './components/SettingsNav';
import OrgIntegrations from './components/integrations/Organization';
import type { Setting } from './types/Settings';
import { findGroup, findSettingItem, formatPathname } from './utils';

const settings = [
    {
        label: 'Account',
        icon: CnslUserIcon,
        hide: true,
        children: [
            {
                label: 'Profile',
                index: true,
            },
            {
                label: 'Account Integrations',
                pathname: 'integrations',
            },
        ],
    },
    {
        label: 'Organization',
        icon: Building,
        children: [
            {
                label: 'General',
                index: true,
                Component: General,
                description: 'Configure Console for your organization',
            },
            {
                label: 'Approvals',
                description: 'Configure default approval settings',
                Component: Approvals,
            },
            {
                label: 'Members',
                description: 'View and manage the users of your organization',
                Component: Members,
            },
            {
                label: 'Integrations',
                description: 'Connect your service accounts to see your data in Console',
                Component: OrgIntegrations,
            },
        ],
    },
    {
        label: 'Users',
        icon: CnslUserIcon,
        hide: true,
        children: [
            {
                label: 'General',
                description: 'View and manage the users of your organization',
                index: true,
                Component: () => <div>User Management - general goes here</div>,
            },
        ],
    },
    {
        label: 'Requests',
        icon: MessageSquareText,
        children: [
            {
                label: 'Help Desk Configuration',
                description: 'Configure fields for tickets created by Console',
                index: true,
                Component: RequestManagement,
            },
            {
                label: 'Categories',
                description: 'Configure categories for your requests',
                Component: Categories,
            },
            {
                label: 'Types',
                hide: true,
            },
        ],
    },
    {
        label: 'Apps',
        icon: AppWindowMac,
        hide: true,
        children: [
            {
                label: 'General',
                index: true,
            },
        ],
    },
] satisfies Setting[];

const Settings = () => {
    const { group, setting, subsetting } = useParams();

    const selectedGroup = findGroup(settings, group);
    const selectedSetting = findSettingItem(selectedGroup?.children, setting);

    const browData = selectedGroup
        ? [
              { label: selectedGroup.label },
              {
                  label: selectedSetting?.label,
                  href: formatPathname(
                      SETTINGS,
                      selectedGroup.label,
                      selectedSetting?.index ? undefined : (selectedSetting?.pathname ?? selectedSetting?.label),
                  ),
              },
          ]
        : [];

    if (subsetting) {
        browData.push({
            label: subsetting,
        });
    }

    const activeRoute = `/${group}${setting ? `/${setting}` : ''}`;

    return (
        <div className="flex size-full flex-col">
            <Brow data={browData} />
            <div className="flex grow overflow-auto">
                <div className="bg-bg-overlay border-subtle-hover sticky top-0 w-[300px] shrink-0 overflow-auto border-r-[0.5px]">
                    <div className="p-lg bg-bg-overlay sticky top-0 z-10 flex items-center">
                        <h2 className="text-base">Settings</h2>
                    </div>
                    <SettingsNav basePath={SETTINGS} settings={settings} activeRoute={activeRoute} />
                </div>
                <div className="grow px-2xl">
                    <SettingsContent settings={settings} />
                </div>
            </div>
        </div>
    );
};

export default Settings;
