import {
    type Edge,
    attachClosestEdge,
    extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import type { PolicyActionTemplate } from 'lib/models/app_policy';
import type { AppPolicyStepTypeType } from 'lib/prisma/enums';
import { ChevronDown, GripVertical, X } from 'lucide-react';
import { type HTMLAttributes, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import invariant from 'tiny-invariant';

import { Button } from '@/components/ui/button';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Select, SelectAsync } from '@/components/ui/select';
import { GroupResLinkOption } from '@/components/ui/select/UserResLinkComponents';
import { baseFetch } from '@/lib/baseFetch';
import { getHeaders } from '@/lib/getHeaders';
import { cn } from '@/lib/styling';
import type { PStep } from '@/stores/usePolicy';
import { usePolicyCreationStore } from '@/stores/usePolicy';
import { getProviderAssets } from 'lib/3p';
import { CnslActionSlug } from 'lib/actions/slugs';
import type { CnslGroupList } from 'lib/models/group';
import type { PaginatedListResponse } from 'lib/models/pagination';
import qs from 'qs';
import { ConfigureCustomAction } from './ConfigureCustomAction';
import { DropIndicator } from './DropIndicator';
import { getStepData, isStepData } from './StepData';

type DragState =
    | {
          type: 'idle';
      }
    | {
          type: 'preview';
          container: HTMLElement;
      }
    | {
          type: 'is-dragging';
      }
    | {
          type: 'is-dragging-over';
          closestEdge: Edge | null;
      };

const stateStyles: { [Key in DragState['type']]?: HTMLAttributes<HTMLDivElement>['className'] } = {
    'is-dragging': 'opacity-40',
};

const idle: DragState = { type: 'idle' };

export function ActionLabel({ action }: { action: { name: string; provider: string } | null }) {
    return (
        <div className="gap-md flex items-center">
            {action && (
                <>
                    <img
                        src={getProviderAssets(action.provider).logo}
                        alt={getProviderAssets(action.provider).name}
                        className="size-4"
                    />
                    {action.name}
                </>
            )}
            {!action && 'Select an action'}
        </div>
    );
}

interface StepActionProps {
    step: PStep;
    onDelete: (id: string) => void;
    index: number;
    count: number;
    type: AppPolicyStepTypeType;
    templates: PolicyActionTemplate[];
}

export function PolicyStepAction({ step, index, onDelete, type, count, templates }: StepActionProps) {
    const store = usePolicyCreationStore();
    const ref = useRef<HTMLDivElement | null>(null);

    const [state, setState] = useState<DragState>(idle);

    const updateAction = (template: PolicyActionTemplate) => {
        step.template = template;
        store.updatePStep(step, type);
    };

    const updateValue = (opt: { value: string; label: string; logo?: string }) => {
        step.selectedOption = opt;
        store.updatePStep(step, type);
    };

    useEffect(() => {
        const element = ref.current;
        invariant(element);
        return combine(
            draggable({
                element,
                getInitialData() {
                    return getStepData(step);
                },
                onGenerateDragPreview({ nativeSetDragImage }) {
                    setCustomNativeDragPreview({
                        nativeSetDragImage,
                        getOffset: pointerOutsideOfPreview({
                            x: '16px',
                            y: '8px',
                        }),
                        render({ container }) {
                            setState({ type: 'preview', container });
                        },
                    });
                },
                onDragStart() {
                    setState({ type: 'is-dragging' });
                },
                onDrop() {
                    setState(idle);
                },
            }),
            dropTargetForElements({
                element,
                canDrop({ source }) {
                    // not allowing dropping on yourself
                    if (source.element === element) {
                        return false;
                    }
                    // only allowing steps to be dropped on me
                    return isStepData(source.data);
                },
                getData({ input }) {
                    const data = getStepData(step);
                    return attachClosestEdge(data, {
                        element,
                        input,
                        allowedEdges: ['top', 'bottom'],
                    });
                },
                getIsSticky() {
                    return true;
                },
                onDragEnter({ self }) {
                    const closestEdge = extractClosestEdge(self.data);
                    setState({ type: 'is-dragging-over', closestEdge });
                },
                onDrag({ self }) {
                    const closestEdge = extractClosestEdge(self.data);

                    // Only need to update react state if nothing has changed.
                    // Prevents re-rendering.
                    setState(current => {
                        if (current.type === 'is-dragging-over' && current.closestEdge === closestEdge) {
                            return current;
                        }
                        return { type: 'is-dragging-over', closestEdge };
                    });
                },
                onDragLeave() {
                    setState(idle);
                },
                onDrop() {
                    setState(idle);
                },
            }),
        );
    }, [step]);

    const makeQuery = (search: string) => {
        const qry: Record<string, string | Array<string>> = {
            source: [step.template.action.provider],
        };
        if (search) {
            qry.search = search;
        }
        return qs.stringify(qry);
    };

    const groupSlugs = new Set<string>([CnslActionSlug.AddToGroup, CnslActionSlug.RemoveFromGroup]);

    const handleCustomActionConfigUpdate = (config: Record<string, string>) => {
        if (step.selectedOption) {
            step.selectedOption.config = config;
            updateValue(step.selectedOption);
        }
    };

    return (
        <>
            <div className="relative">
                <div
                    // Adding data-attribute as a way to query for this for our post drop flash
                    data-step-id={step.id}
                    ref={ref}
                    className={cn(
                        stateStyles[state.type],
                        'p-md bg-bg-elevated border-grey gap-md flex w-full flex-col rounded-[14px] border-[0.5px] text-sm hover:cursor-grab',
                    )}
                >
                    <div className="flex items-center justify-between">
                        <div className="flex items-center h-6">
                            {count > 1 && <GripVertical className="text-body-subtle" />}
                            <span className="ml-sm font-medium">Step {index + 1}</span>
                        </div>

                        {count > 1 && (
                            <Button
                                size="sm"
                                mode="borderless"
                                className="text-body-subtle hover:text-body-grey-primary"
                                onClick={() => onDelete(step.id)}
                            >
                                <X />
                            </Button>
                        )}
                    </div>
                    <div className="w-full">
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button className="flex w-full items-center justify-between hover:bg-bg-grey-primary-hover">
                                    <ActionLabel action={step.template.action} />
                                    <ChevronDown />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent side="bottom" align="start" className="min-w-72">
                                {templates.map(t => (
                                    <DropdownMenuItem key={t.action.id} onSelect={() => updateAction(t)}>
                                        <ActionLabel action={t.action} />
                                    </DropdownMenuItem>
                                ))}
                                {templates.length === 0 && (
                                    <DropdownMenuItem disabled>No actions available</DropdownMenuItem>
                                )}
                            </DropdownMenuContent>
                        </DropdownMenu>
                        {step.template.inputType === 'text' && (
                            <textarea
                                className="mt-md p-xs border-grey p-sm w-full rounded-md border-[0.5px] border-solid"
                                placeholder="Enter message"
                            />
                        )}
                        {step.template.inputType === 'select' && groupSlugs.has(step.template.action.slug) && (
                            <div>
                                <SelectAsync
                                    className="mt-md bg-white hover:bg-bg-grey-primary-hover"
                                    isMulti={false}
                                    value={step.selectedOption}
                                    placeholder="Select an option"
                                    components={{ Option: GroupResLinkOption }}
                                    menuPlacement="auto"
                                    loadOptions={async (search: string) => {
                                        const res = await baseFetch<PaginatedListResponse<CnslGroupList>>(
                                            `/api/v1/groups?${makeQuery(search)}`,
                                            {
                                                headers: getHeaders(),
                                            },
                                        );

                                        return {
                                            options: res.items.map(value => ({
                                                label: value.name ?? '',
                                                value: value.id ?? '',
                                                logo: value.logo ?? undefined,
                                            })),
                                            hasMore: res.pagination.remaining > 0,
                                        };
                                    }}
                                    onChange={i => {
                                        if (i) {
                                            updateValue(i);
                                        }
                                    }}
                                />
                            </div>
                        )}
                        {step.template.inputType === 'select' &&
                            step.template.action.slug === CnslActionSlug.RunPolicyCustomAction && (
                                <div className="mt-md relative">
                                    <Select
                                        isMulti={false}
                                        value={step.selectedOption}
                                        placeholder="Select an option"
                                        options={step.template.options}
                                        menuPlacement="auto"
                                        onChange={i => {
                                            if (i) {
                                                updateValue(i);
                                            }
                                        }}
                                        className="bg-white hover:bg-bg-grey-primary-hover"
                                    />
                                    {step.selectedOption?.value && (
                                        <ConfigureCustomAction
                                            selectedOption={step.selectedOption}
                                            onUpdate={handleCustomActionConfigUpdate}
                                        />
                                    )}
                                </div>
                            )}
                    </div>
                </div>
                {state.type === 'is-dragging-over' && state.closestEdge ? (
                    <DropIndicator edge={state.closestEdge} gap="16px" />
                ) : null}
            </div>
            {state.type === 'preview' ? createPortal(<DragPreview template={step.template} />, state.container) : null}
        </>
    );
}

// A simplified version of our step for the user to drag around
function DragPreview({ template }: { template: PolicyActionTemplate | null }) {
    return (
        <div className="p-md rounded-md border-solid bg-white">
            <ActionLabel action={template?.action ?? null} />
        </div>
    );
}
