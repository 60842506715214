import { ResLink } from '@/components/ResLink';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';

import { ApprovalSelect } from '@/components/ApproverSelect';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { useAppOwners } from '@/hooks/queries/useApps';
import { usePolicyCreationStore } from '@/stores/usePolicy';
import {
    type Edge,
    attachClosestEdge,
    extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { cn } from '@lib/styling';
import type { AppOwnersListItem } from 'lib/models/app';
import type { Approval } from 'lib/models/app_policy';
import { AppPolicyReviewStrategy, type AppPolicyReviewStrategyType } from 'lib/prisma/enums';
import { GripVertical, Trash2 } from 'lucide-react';
import type { HTMLAttributes } from 'react';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import invariant from 'tiny-invariant';
import { DropIndicator } from './DropIndicator';
import { getApprovalData, isApprovalData } from './StepData';
import { useApprovalLabels } from './labels';

type DragState =
    | {
          type: 'idle';
      }
    | {
          type: 'preview';
          container: HTMLElement;
      }
    | {
          type: 'is-dragging';
      }
    | {
          type: 'is-dragging-over';
          closestEdge: Edge | null;
      };

const stateStyles: { [Key in DragState['type']]?: HTMLAttributes<HTMLDivElement>['className'] } = {
    'is-dragging': 'opacity-40',
};

const idle: DragState = { type: 'idle' };

interface ApprovalProps {
    approval: Approval;
    onDelete: (id: string) => void;
    appSlug: string;
    index: number;
    count: number;
}
export function PolicyStepApproval({ index, onDelete, count, approval, appSlug }: ApprovalProps) {
    const ref = useRef<HTMLDivElement | null>(null);

    const [state, setState] = useState<DragState>(idle);

    const appOwners = useAppOwners({ slug: appSlug });
    const owners = appOwners.data?.items ?? [];

    const store = usePolicyCreationStore();

    const [reviewStrategy, setReviewStrategy] = useState(approval.strategy);
    const [selectedUserIds, setSelectedUserIds] = useState(approval.userIds);
    const [selectedGroupIds, setSelectedGroupIds] = useState(approval.groupIds);
    const [escalateEnabled, setEscalateEnabled] = useState(!!approval.escalation);
    const [escalationStrategy, setEscalationStrategy] = useState(approval.escalation?.strategy ?? approval.strategy);
    const [escalationUserIds, setEscalationUserIds] = useState(approval.escalation?.userIds ?? []);
    const [escalationGroupIds, setEscalationGroupIds] = useState(approval.escalation?.groupIds ?? []);

    const labels = useApprovalLabels(selectedUserIds, selectedGroupIds);
    const fallbackLabels = useApprovalLabels(escalationUserIds, escalationGroupIds);

    const onUserSelect = (ids: string[]) => {
        setSelectedUserIds(ids);
        if (ids.length) {
            setReviewStrategy(AppPolicyReviewStrategy.EXPLICIT);
        }
    };

    const onGroupSelect = (ids: string[]) => {
        setSelectedGroupIds(ids);
        if (ids.length) {
            setReviewStrategy(AppPolicyReviewStrategy.EXPLICIT);
        }
    };

    const onEscalateToggle = (checked: boolean) => {
        setEscalateEnabled(checked);
    };

    // clear users and groups when switching to another strategy
    useEffect(() => {
        if (reviewStrategy !== AppPolicyReviewStrategy.EXPLICIT) {
            setSelectedUserIds([]);
            setSelectedGroupIds([]);
        }
    }, [reviewStrategy]);

    // clear escalation users and groups when switching to another strategy or toggling off escalation
    useEffect(() => {
        if (escalationStrategy !== AppPolicyReviewStrategy.EXPLICIT || !escalateEnabled) {
            setEscalationUserIds([]);
            setEscalationGroupIds([]);
        }
    }, [escalationStrategy, escalateEnabled]);

    const updateFunc = store.updateApproval;

    useEffect(() => {
        const userReviewers = reviewStrategy === AppPolicyReviewStrategy.EXPLICIT ? selectedUserIds : [];
        const groupReviewers = reviewStrategy === AppPolicyReviewStrategy.EXPLICIT ? selectedGroupIds : [];
        const updatedApproval = {
            id: approval.id,
            strategy: reviewStrategy,
            userIds: userReviewers,
            groupIds: groupReviewers,
            escalation: escalateEnabled
                ? {
                      strategy: escalationStrategy,
                      userIds: escalationUserIds,
                      groupIds: escalationGroupIds,
                  }
                : undefined,
        };
        updateFunc(updatedApproval);
    }, [
        selectedUserIds,
        selectedGroupIds,
        reviewStrategy,
        updateFunc,
        approval.id,
        escalateEnabled,
        escalationStrategy,
        escalationUserIds,
        escalationGroupIds,
    ]);

    useEffect(() => {
        const element = ref.current;
        invariant(element);
        return combine(
            draggable({
                element,
                getInitialData() {
                    return getApprovalData(approval);
                },
                onGenerateDragPreview({ nativeSetDragImage }) {
                    setCustomNativeDragPreview({
                        nativeSetDragImage,
                        getOffset: pointerOutsideOfPreview({
                            x: '16px',
                            y: '8px',
                        }),
                        render({ container }) {
                            setState({ type: 'preview', container });
                        },
                    });
                },
                onDragStart() {
                    setState({ type: 'is-dragging' });
                },
                onDrop() {
                    setState(idle);
                },
            }),
            dropTargetForElements({
                element,
                canDrop({ source }) {
                    // not allowing dropping on yourself
                    if (source.element === element) {
                        return false;
                    }
                    // only allowing approvals to be dropped on me
                    return isApprovalData(source.data);
                },
                getData({ input }) {
                    const data = getApprovalData(approval);
                    return attachClosestEdge(data, {
                        element,
                        input,
                        allowedEdges: ['top', 'bottom'],
                    });
                },
                getIsSticky() {
                    return true;
                },
                onDragEnter({ self }) {
                    const closestEdge = extractClosestEdge(self.data);
                    setState({ type: 'is-dragging-over', closestEdge });
                },
                onDrag({ self }) {
                    const closestEdge = extractClosestEdge(self.data);

                    // Only need to update react state if nothing has changed.
                    // Prevents re-rendering.
                    setState(current => {
                        if (current.type === 'is-dragging-over' && current.closestEdge === closestEdge) {
                            return current;
                        }
                        return { type: 'is-dragging-over', closestEdge };
                    });
                },
                onDragLeave() {
                    setState(idle);
                },
                onDrop() {
                    setState(idle);
                },
            }),
        );
    }, [approval]);

    return (
        <>
            <div className="relative">
                <div
                    // Adding data-attribute as a way to query for this for our post drop flash
                    data-approval-id={approval.id}
                    ref={ref}
                    className={cn(
                        stateStyles[state.type],
                        'p-md bg-bg-surface border-grey gap-md flex w-full flex-col rounded-[14px] border-[0.5px] text-sm hover:cursor-grab hover:bg-steel-100',
                    )}
                >
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <GripVertical className="text-body-subtle mr-sm" />
                            <span className="font-medium">Approval Step #{index + 1}</span>
                        </div>

                        {count > 1 && (
                            <Button
                                size="sm"
                                mode="borderless"
                                className="text-body-subtle hover:text-body-grey-primary"
                                onClick={() => onDelete(approval.id)}
                            >
                                <Trash2 />
                            </Button>
                        )}
                    </div>
                    <div className="w-full flex flex-col">
                        <ApprovalSelect
                            className="w-full"
                            strategy={reviewStrategy}
                            userIds={selectedUserIds}
                            groupIds={selectedGroupIds}
                            onStrategy={setReviewStrategy}
                            onUsers={onUserSelect}
                            onGroups={onGroupSelect}
                        />
                        {labels.length > 0 && <Preview strategy={reviewStrategy} labels={labels} owners={owners} />}
                        <div className="flex flex-col gap-sm py-md">
                            <label
                                htmlFor={`escalate-${index}`}
                                className="font-medium flex gap-md items-start leading-none"
                            >
                                <Checkbox
                                    checked={escalateEnabled}
                                    onCheckedChange={onEscalateToggle}
                                    id={`escalate-${index}`}
                                />
                                <div>
                                    Escalate
                                    <p className="text-body-subtle leading-normal">
                                        Escalate if the request has not been approved in time
                                    </p>
                                </div>
                            </label>
                            {escalateEnabled && (
                                <div>
                                    <ApprovalSelect
                                        className="w-full"
                                        strategy={escalationStrategy}
                                        userIds={escalationUserIds}
                                        groupIds={escalationGroupIds}
                                        onStrategy={setEscalationStrategy}
                                        onUsers={setEscalationUserIds}
                                        onGroups={setEscalationGroupIds}
                                    />
                                    {fallbackLabels.length > 0 && (
                                        <Preview
                                            strategy={escalationStrategy}
                                            labels={fallbackLabels}
                                            owners={owners}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {state.type === 'is-dragging-over' && state.closestEdge ? (
                    <DropIndicator edge={state.closestEdge} gap="16px" />
                ) : null}
            </div>
            {state.type === 'preview'
                ? createPortal(
                      <DragPreview idx={index} strategy={reviewStrategy} labels={labels} owners={owners} />,
                      state.container,
                  )
                : null}
        </>
    );
}

const Preview = ({
    strategy,
    labels,
    owners,
}: { strategy: AppPolicyReviewStrategyType; labels: string; owners: AppOwnersListItem[] }) => {
    if (strategy === AppPolicyReviewStrategy.EXPLICIT) {
        return (
            <Tooltip>
                <TooltipTrigger asChild>
                    <p className="text-body-subtle h-6 truncate pt-sm">{labels}</p>
                </TooltipTrigger>
                <TooltipContent>{labels}</TooltipContent>
            </Tooltip>
        );
    }
    if (strategy === AppPolicyReviewStrategy.APP_OWNERS) {
        return (
            <div className="flex justify-center">
                {owners.length === 1 && (
                    <div className="pt-sm">
                        Current app owner is{' '}
                        <ResLink entity="users" label={owners[0].cnsl_user?.displayName ?? ''} size="sm" />
                    </div>
                )}
                {owners.length > 1 && (
                    <div className="flex flex-wrap items-center pt-sm">
                        <span className="shrink-0 mr-1">Current app owners are:</span>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                {owners.map((owner, idx) => (
                                    <div key={owner.cnsl_user?.id ?? ''} className="ml-1 flex items-center">
                                        <ResLink entity="users" label={owner.cnsl_user?.displayName ?? ''} size="sm" />
                                        {idx < owners.length - 1 && ', '}
                                    </div>
                                ))}
                            </TooltipTrigger>
                            <TooltipContent>
                                {owners.map(o => o.cnsl_user?.displayName ?? '').join(', ')}
                            </TooltipContent>
                        </Tooltip>
                    </div>
                )}
            </div>
        );
    }

    return null;
};

// A simplified version of our step for the user to drag around
function DragPreview({
    idx,
    strategy,
    labels,
    owners,
}: { idx: number; strategy: AppPolicyReviewStrategyType; labels: string; owners: AppOwnersListItem[] }) {
    return (
        <div className="p-md rounded-md border-solid bg-white">
            <div>Approval Step #{idx + 1}</div>
            <div className="mt-sm text-body-subtle">
                <Preview strategy={strategy} labels={labels} owners={owners} />
            </div>
        </div>
    );
}
