import { useQuery } from '@/lib/hooks/useQuery';
import type { PaginatedListResponse } from 'lib/models/pagination';
import type { CnslRequestList, CnslRequestResponse } from 'lib/models/request';

export const useRequest = ({ slug }: { slug: string }) => {
    return useQuery<CnslRequestResponse>({
        url: `/api/v1/requests/${slug}`,
    });
};

export const useLatestRequest = (refetchInterval?: number) => {
    return useQuery<PaginatedListResponse<CnslRequestList>>({
        url: `/api/v1/requests?limit=1`,
        refetchInterval,
    });
};
