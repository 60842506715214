import { ArrowRight, BookOpenText, Copy } from 'lucide-react';
import { useState } from 'react';

import { DnDTree } from '@/components/dnd-tree';
import { type TreeItem, tree } from '@/components/dnd-tree/tree';
import { Editor } from '@/components/tiptap/Editor';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { Textarea } from '@/components/ui/textarea';
import { usePlaybookTemplateClone } from '@/hooks/mutations/usePlaybook';
import { usePlaybookTemplates } from '@/hooks/queries/usePlaybook';
import { cn } from '@/lib/styling';
import type { JSONContent } from '@tiptap/core';
import type { PlaybookTemplate } from 'lib/models/playbook';
import { microhash, pastelSchemes } from './PlaybookCard';
import { numberToLetter, playbookExtensions } from './details/PlaybookInstructionsData';
import { ConditionsInfoDialog } from './details/dialogs/ConditionsInfoDialog';
import { useLoadOptions } from './details/useLoadOptions';

const indentPerLevel = 22;

export const TemplateCard = ({
    template,
    onClick,
}: {
    template: PlaybookTemplate;
    onClick: () => void;
}) => {
    const [border, spine, bg, title] = pastelSchemes[microhash(template.name) % pastelSchemes.length];

    return (
        <div className="flex gap-lg border-grey border-[0.5px] pt-lg px-lg rounded-[6px] justify-between items-center">
            <div
                className={cn(
                    'flex h-32 w-36 shrink-0 rounded-tl-sm rounded-tr-lg border-t-[0.5px] border-x-[0.5px] overflow-hidden',
                    border,
                )}
            >
                <div className={cn('h-full w-6 shrink-0 border-r-[0.5px]', border, spine)} />
                <div className={cn('flex w-[120px] shrink-0 flex-col justify-between p-lg', bg)}>
                    <div className="gap-sm flex flex-col">
                        <p className={cn('text-xs', title)}>Template</p>
                        <p className={cn('line-clamp-3 font-medium')}>{template.name}</p>
                    </div>
                </div>
            </div>
            <div className="flex flex-col w-full">
                <h2 className={cn('text-base font-medium')}>{template.name}</h2>
                <p className={cn('text-body-subtle-hover')}>{template.description}</p>
            </div>
            <div className="flex flex-col gap-sm">
                <Button onClick={onClick}>
                    <BookOpenText />
                    Preview
                </Button>
            </div>
        </div>
    );
};
export const PlaybookTemplates = () => {
    // used to look up the labels for templates with action/playbook references
    useLoadOptions();

    const [selectedTemplate, setSelectedTemplate] = useState<PlaybookTemplate | null>(null);

    const { data } = usePlaybookTemplates();
    const templates = data?.templates;

    const { mutate: cloneTemplate } = usePlaybookTemplateClone();

    const handleTemplateSelection = (template: PlaybookTemplate) => {
        setSelectedTemplate(template);
    };

    const handleUseTemplate = () => {
        if (!selectedTemplate) return;
        cloneTemplate(selectedTemplate);
        setSelectedTemplate(null);
    };

    const renderItem = (item: TreeItem) => {
        const path = tree.getIndexPathToItem({
            current: selectedTemplate?.instructionsData?.data ?? [],
            targetId: item.id,
        });

        if (!path) return null;

        const rowNumber = Number(path[0]) + 1;
        const letter = numberToLetter(Number(path[1]));

        return (
            <div className="flex items-start gap-sm group mb-sm">
                <div className="flex gap-sm items-start h-fit">
                    <div className="text-sm font-medium">{letter ? `${letter}.` : `${rowNumber}.`}</div>
                </div>
                <div className="h-full">
                    <Editor
                        className="text-sm"
                        disabled
                        content={item.schema as JSONContent}
                        extensions={playbookExtensions}
                    />
                </div>
            </div>
        );
    };

    return (
        <div className="flex flex-col gap-md overflow-y-auto">
            {templates?.map(t => {
                return <TemplateCard key={t.name} template={t} onClick={() => handleTemplateSelection(t)} />;
            })}
            <Dialog open={!!selectedTemplate} onOpenChange={() => setSelectedTemplate(null)}>
                <DialogContent className="p-6 md:p-12 rounded-none aspect-[85/110] w-[min(80vh,calc(80vh*8.5/11))] max-w-none">
                    <Textarea
                        autoResize
                        minHeight="30px"
                        className="border-none text-2xl font-medium !p-0"
                        value={selectedTemplate?.name}
                        readOnly
                    />
                    <div className="flex justify-between border-b-[0.5px] border-grey mt-2xl mb-md">
                        <div className="flex items-center gap-md font-mono uppercase">
                            <ArrowRight />
                            Conditions
                        </div>
                        <ConditionsInfoDialog />
                    </div>

                    <Textarea
                        autoResize
                        minHeight="24px"
                        className="border-none text-sm !p-0"
                        value={selectedTemplate?.description}
                        readOnly
                    />

                    <div className="flex justify-between border-b-[0.5px] border-grey mt-2xl mb-md">
                        <div className="flex items-center gap-md font-mono uppercase">
                            <ArrowRight />
                            Instructions
                        </div>
                    </div>
                    <div style={{ cursor: 'default !important' }}>
                        <DnDTree
                            expanded
                            treeData={selectedTemplate?.instructionsData?.data ?? []}
                            indentPerLevel={indentPerLevel}
                            renderItem={renderItem}
                        />
                    </div>

                    <Button
                        variant="blue"
                        mode="dark"
                        onClick={handleUseTemplate}
                        className="absolute bottom-6 md:bottom-12 left-1/2 -translate-x-1/2"
                    >
                        <Copy />
                        Use this template
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    );
};
