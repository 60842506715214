import type { SingleCnslUserResp } from 'lib/models/cnsl_user';

import { useQuery } from '@/lib/hooks/useQuery';

const useUser = ({ email, enabled }: { email: string; enabled?: boolean }) => {
    return useQuery<SingleCnslUserResp>({
        url: `/api/v1/users/${email}`,
        enabled,
    });
};

export { useUser };
