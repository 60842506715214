import { groupBy, uniqBy } from 'lodash-es';
import { create } from 'zustand';

import type { ActionsResponse, PlaybookReferencesResponse } from 'lib/actions/actions';

export interface Option {
    type: 'playbook' | 'action' | 'slack-channel';
    id: string;
    name: string;
    description: string;
    provider: string;
    label: string;
    parameters?: ActionsResponse['actions'][number]['parameters'];
    referenceId?: string;
    slug?: string;
}

export type Reference =
    | PlaybookReferencesResponse['references']['USER'][number]
    | PlaybookReferencesResponse['references']['APP'][number]
    | PlaybookReferencesResponse['references']['GROUP'][number]
    | PlaybookReferencesResponse['references']['SLACK_CHANNEL'][number];

interface Store {
    // current search string
    queryString: string;
    setQueryString: (queryString: string) => void;

    options: Option[];
    setOptions: (options: Option[]) => void;
    loaded?: boolean;

    references?: PlaybookReferencesResponse;
    setReferences: (references: PlaybookReferencesResponse) => void;

    activeOption?: Option;
    setActiveOption: (option?: Option) => void;

    activeReference?: Reference;
    setActiveReference: (option?: Reference) => void;

    saveForm?: () => void;
    setSaveForm: (saveForm: () => void) => void;
}

export const usePlaybookDetailsStore = create<Store>(set => ({
    queryString: '',
    setQueryString: (queryString: string) => set({ queryString }),

    references: undefined,
    setReferences: (references: PlaybookReferencesResponse) => set({ references }),

    options: [],
    setOptions: (options: Option[]) =>
        set(store => {
            const existingOptions = uniqBy([...store.options, ...options], 'id');
            const grouped = groupBy(existingOptions, 'type');
            for (const key in grouped) {
                grouped[key].sort((a, b) => a.label.localeCompare(b.label));
            }

            const flattened = Object.values(grouped)
                .flat()
                .sort(a => (a.type === 'playbook' ? 1 : -1));

            return { options: flattened, loaded: true };
        }),

    activeOption: undefined,
    setActiveOption: (activeOption?: Option) => set({ activeOption, activeReference: undefined }),

    activeReference: undefined,
    setActiveReference: (activeReference?: Reference) => set({ activeReference, activeOption: undefined }),

    saveForm: undefined,
    setSaveForm: (saveForm: () => void) => set({ saveForm }),
}));
