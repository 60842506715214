import { ResLink } from '@/components/ResLink';
import type { CnslUser } from 'lib/prisma/types';
import { ResourceOption } from '../ResourceOption';

export const UserReference = ({
    user,
    isActive,
    onClick,
}: { user: CnslUser; isActive?: boolean; onClick?: () => void }) => {
    return (
        <ResourceOption
            title={<ResLink size="sm" src={user.avatar} label={user.displayName} />}
            isActive={isActive}
            onClick={onClick}
        />
    );
};
