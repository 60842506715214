// Note - we're using the built in Intl.DateTimeFormat to format dates
// when possible. It will format the date based on the user's locale.
// date-fns is cool too, but generally we should use that for handy
// date functions like `addDays`, or `startOfToday`, etc., and let
// Intl.DateTimeFormat handle the formatting.

import type { MaybeDatable } from 'lib/dates/types';
import { locale } from './locale';
const fallback = '--';

/**
 * Formats a given date into a full date and time (hours and minutes) string based on the locale.
 *
 * @param {MaybeDatable} d - The date to format. Can be a string, number, Date object, undefined, or null.
 * @returns {string} The formatted date string or a fallback string if the input is invalid.
 *
 * @example
 * // Assuming the locale is 'en-US'
 * fmtDateFull('2024-07-11T10:20:30Z'); // "Jul 11, 2024, 10:20 AM"
 * fmtDateFull(1657531230000); // "Jul 11, 2024, 10:20 AM"
 * fmtDateFull(new Date('2024-07-11T10:20:30Z')); // "Jul 11, 2024, 10:20 AM"
 * fmtDateFull(null); // "--"
 */
export const fmtDateFull = (d: MaybeDatable) => {
    const fullDateFormatter = new Intl.DateTimeFormat(locale, {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    });
    if (d) {
        try {
            return fullDateFormatter.format(new Date(d));
        } catch (_error) {
            return fallback;
        }
    }
    return fallback;
};

/**
 * Formats a given date into a date string (year, month, and day) based on the locale.
 *
 * @param {MaybeDatable} d - The date to format. Can be a string, number, Date object, undefined, or null.
 * @returns {string} The formatted date string or a fallback string if the input is invalid.
 *
 * @example
 * // Assuming the locale is 'en-US'
 * fmtDate('2024-07-11T10:20:30Z'); // "Jul 11, 2024"
 * fmtDate(1657531230000); // "Jul 11, 2024"
 * fmtDate(new Date('2024-07-11T10:20:30Z')); // "Jul 11, 2024"
 * fmtDate(null); // "--"
 */
export const fmtDate = (d: MaybeDatable) => {
    const fullDateFormatter = new Intl.DateTimeFormat(locale, {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
    });
    if (d) {
        try {
            return fullDateFormatter.format(new Date(d));
        } catch (_error) {
            return fallback;
        }
    }
    return fallback;
};

/**
 * Formats a given date into a short date string (month and day) based on the locale.
 *
 * @param {MaybeDatable} d - The date to format. Can be a string, number, Date object, undefined, or null.
 * @returns {string} The formatted short date string or a fallback string if the input is invalid.
 *
 * @example
 * // Assuming the locale is 'en-US'
 * fmtDayMonthNumeric('2024-07-11T10:20:30Z'); // "7/11"
 * fmtDayMonthNumeric(1657531230000); // "7/11"
 * fmtDayMonthNumeric(new Date('2024-07-11T10:20:30Z')); // "7/11"
 * fmtDayMonthNumeric(null); // "--"
 */
export const fmtDayMonthNumeric = (d: MaybeDatable) => {
    const shortDateFormatter = new Intl.DateTimeFormat(locale, {
        month: 'numeric',
        day: 'numeric',
    });
    if (d) {
        try {
            return shortDateFormatter.format(new Date(d));
        } catch (_error) {
            return fallback;
        }
    }
    return fallback;
};

export const fmtDayMonthShort = (d: MaybeDatable) => {
    const shortDateFormatter = new Intl.DateTimeFormat(locale, {
        month: 'short',
        day: 'numeric',
    });
    if (d) {
        try {
            return shortDateFormatter.format(new Date(d));
        } catch (_error) {
            return fallback;
        }
    }
    return fallback;
};

export const fmtDayOfMonth = (d: MaybeDatable) => {
    const shortDateFormatter = new Intl.DateTimeFormat(locale, {
        day: 'numeric',
    });
    if (d) {
        try {
            return shortDateFormatter.format(new Date(d));
        } catch (_error) {
            return fallback;
        }
    }
    return fallback;
};

export const fmtMonthShort = (d: MaybeDatable) => {
    const monthShortFormatter = new Intl.DateTimeFormat(locale, {
        month: 'short',
    });
    if (d) {
        try {
            return monthShortFormatter.format(new Date(d));
        } catch (_error) {
            return fallback;
        }
    }
    return fallback;
};
