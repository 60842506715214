import type { GetMeResponse } from 'lib/models/me';
import { RouterProvider, createBrowserRouter, redirect } from 'react-router-dom';

import { useSubscribeOnboardingFlow } from '@/hooks/useSubscribeOnboardingFlow';
import { baseFetch } from '@/lib/baseFetch';
import StickerSheet from '@/pages/_stickersheet';
import { Apps } from '@/pages/apps';
import { AppDetails } from '@/pages/apps/details';
import { Articles } from '@/pages/articles';
import { ArticleDetails } from '@/pages/articles/details';
import { NotFoundPage } from '@/pages/errors/404';
import Events from '@/pages/events';
import { Groups } from '@/pages/groups';
import { GroupDetails } from '@/pages/groups/details';
import Home from '@/pages/home';
import InitializeOrg from '@/pages/initialize';
import { Insights } from '@/pages/insights';
import { PageIndex } from '@/pages/layouts/PageIndex';
import Login from '@/pages/login';
import { Onboarding } from '@/pages/onboarding';
import { Playbooks } from '@/pages/playbooks';
import { ActionDetails } from '@/pages/playbooks/actions/details';
import { ActionCreate } from '@/pages/playbooks/actions/details';
import { PlaybookDetails } from '@/pages/playbooks/details';
import { Policies } from '@/pages/policies';
import { Requests } from '@/pages/requests';
import { RequestDetails } from '@/pages/requests/details';
import { ROIPage } from '@/pages/roi';
import Settings from '@/pages/settings';
import SignUp from '@/pages/signup';
import { Users } from '@/pages/users';
import { UserDetails } from '@/pages/users/details';
import { getOrgMembership, useUser } from '@/stores/useUser';
import { PolicyDetails } from '@pages/policies/details';
import { OrgOnboardingStatus } from 'lib/prisma/enums';
import {
    ACTIONS,
    ACTIONS_CREATE,
    ACTIONS_DETAILS,
    APPS,
    APP_DETAILS,
    ARTICLES,
    ARTICLE_DETAILS,
    EVENTS,
    GOOGLE_OAUTH_CALLBACK,
    GROUPS,
    GROUP_DETAILS,
    INITIALIZE_ORG,
    INSIGHTS,
    JIRA_OAUTH_CALLBACK,
    LOGIN,
    NOTION_OAUTH_CALLBACK,
    OKTA_OAUTH_CALLBACK,
    ONBOARDING_SELF_SERVICE,
    PLAYBOOKS,
    PLAYBOOK_DETAILS,
    PLAYBOOK_TEMPLATES,
    POLICIES,
    POLICY_CREATE,
    POLICY_DETAILS,
    POLICY_DETAILS_APP,
    POLICY_DETAILS_V2,
    REQUESTS,
    REQUESTS_DETAILS,
    ROI,
    ROI_ROOT,
    ROOT,
    SETTINGS,
    SETTINGS_ORGANIZATION_INTEGRATION,
    SETUP,
    SETUP_STEP,
    SETUP_STEP_ORG,
    SIGNUP,
    SLACK_CALLBACK,
    USERS,
    USER_DETAILS,
} from './paths';

export const ME_ROUTE = '/api/v1/me';

const router = createBrowserRouter(
    [
        {
            element: <PageIndex />,
            ErrorBoundary: NotFoundPage,
            children: [
                {
                    id: 'root',
                    path: '/',
                    Component: Home,
                    loader: async () => {
                        const state = useUser.getState();

                        const data = await baseFetch<GetMeResponse>(ME_ROUTE);

                        if (!data?.me) {
                            state.setUser(null);
                            return redirect(LOGIN);
                        }
                        state.setUser(data.me);
                        return data;
                    },
                    children: [
                        {
                            index: true,
                            Component: Onboarding,
                            // If the user has completed onboarding, redirect to requests
                            loader: async () => {
                                // XXX - not sure how to get around refetching. The parent loader is not waiting before running children loaders.
                                const data = await baseFetch<GetMeResponse>(ME_ROUTE);
                                const membership = getOrgMembership(data.me);

                                if (membership?.onboardingStatus === OrgOnboardingStatus.HIDDEN) {
                                    return redirect(REQUESTS);
                                }
                                return null;
                            },
                        },
                        {
                            path: ROI_ROOT,
                            Component: ROIPage,
                        },
                        {
                            path: ROI,
                            Component: ROIPage,
                        },
                        {
                            path: APPS,
                            Component: Apps,
                        },
                        {
                            path: EVENTS,
                            Component: Events,
                        },
                        {
                            path: GROUPS,
                            Component: Groups,
                        },
                        {
                            path: ACTIONS,
                            Component: Playbooks,
                        },
                        {
                            path: PLAYBOOK_TEMPLATES,
                            Component: Playbooks,
                        },
                        {
                            path: ACTIONS_DETAILS,
                            Component: ActionDetails,
                        },
                        {
                            path: ACTIONS_CREATE,
                            Component: ActionCreate,
                        },
                        {
                            path: PLAYBOOKS,
                            Component: Playbooks,
                        },
                        {
                            path: PLAYBOOK_DETAILS,
                            Component: PlaybookDetails,
                        },
                        {
                            path: POLICIES,
                            Component: Policies,
                        },
                        {
                            path: POLICY_DETAILS_APP,
                            Component: Policies,
                        },
                        {
                            path: POLICY_DETAILS_V2,
                            Component: Policies,
                        },
                        {
                            path: POLICY_DETAILS,
                            Component: PolicyDetails,
                        },
                        {
                            path: POLICY_CREATE,
                            Component: PolicyDetails,
                        },
                        {
                            path: ARTICLES,
                            Component: Articles,
                        },
                        {
                            path: ARTICLE_DETAILS,
                            Component: ArticleDetails,
                        },
                        {
                            path: SETTINGS,
                            Component: Settings,
                            children: [
                                {
                                    index: true,
                                },
                                {
                                    path: ':group',
                                },
                                {
                                    path: ':group/:setting',
                                },
                                {
                                    path: ':group/:setting/:subsetting',
                                },
                            ],
                        },
                        {
                            path: USERS,
                            Component: Users,
                        },
                        {
                            path: `${USER_DETAILS}/*`,
                            Component: UserDetails,
                        },
                        {
                            path: `${APP_DETAILS}/*`,
                            Component: AppDetails,
                        },
                        {
                            path: `${GROUP_DETAILS}`,
                            Component: GroupDetails,
                        },
                        {
                            path: REQUESTS,
                            Component: Requests,
                        },
                        {
                            path: REQUESTS_DETAILS,
                            Component: RequestDetails,
                        },
                        {
                            path: INSIGHTS,
                            Component: Insights,
                        },
                        {
                            path: ONBOARDING_SELF_SERVICE,
                            lazy: async () => {
                                const { SelfService } = await import('@/pages/onboarding/SelfService');
                                return {
                                    Component: SelfService,
                                };
                            },
                        },
                        // internal routes use _
                        {
                            path: '_stickersheet',
                            Component: StickerSheet,
                        },
                    ],
                },
                {
                    path: LOGIN,
                    loader: async () => {
                        const state = useUser.getState();

                        /**
                         * Handle auth logic
                         */
                        const data = await baseFetch<GetMeResponse>(ME_ROUTE);

                        if (data.me) {
                            state.setUser(data.me);
                            return redirect(ROOT);
                        }
                        return data;
                    },
                    Component: Login,
                },
                {
                    path: SIGNUP,
                    loader: async () => {
                        const state = useUser.getState();

                        /**
                         * Handle auth logic
                         */
                        const data = await baseFetch<GetMeResponse>(ME_ROUTE);

                        if (data.me) {
                            state.setUser(data.me);
                            return redirect(SETUP_STEP_ORG);
                        }
                        return data;
                    },
                    Component: SignUp,
                },
                {
                    path: SETUP,
                    Component: SignUp,
                    loader: async () => {
                        const state = useUser.getState();

                        const data = await baseFetch<GetMeResponse>(ME_ROUTE);

                        if (!data?.me) {
                            state.setUser(null);
                            return redirect(SIGNUP);
                        }
                        state.setUser(data.me);
                        return data;
                    },
                },
                {
                    path: SETUP_STEP,
                    id: SETUP_STEP,
                    Component: SignUp,
                    loader: async () => {
                        const state = useUser.getState();
                        if (!state.user) {
                            const data = await baseFetch<GetMeResponse>(ME_ROUTE);

                            if (!data?.me) {
                                state.setUser(null);
                                return redirect(SIGNUP);
                            }
                            state.setUser(data.me);
                            return data;
                        }

                        return null;
                    },
                },
                {
                    path: SLACK_CALLBACK,
                    async loader(d) {
                        const search = d.request.url.split('?')[1];

                        return baseFetch(`/api/v1/integrations/slack/callback?${search}`).then(() =>
                            redirect(SETTINGS_ORGANIZATION_INTEGRATION),
                        );
                    },
                },
                {
                    path: GOOGLE_OAUTH_CALLBACK,
                    async loader(d) {
                        const search = d.request.url.split('?')[1];
                        const url = new URL(d.request.url);
                        const state = url.searchParams.get('state');
                        if (state === 'signup') {
                            const resp = await baseFetch<
                                | {
                                      error: {
                                          code: number;
                                      };
                                  }
                                | undefined
                            >(`/auth/google/callback?${search}`);
                            if (resp?.error?.code === 400) {
                                return redirect(`${LOGIN}?error=not_work_email`);
                            }
                            return redirect('/');
                        }

                        return baseFetch(`/api/v1/integrations/google/callback?${search}`).then(() =>
                            redirect(SETTINGS_ORGANIZATION_INTEGRATION),
                        );
                    },
                },
                {
                    path: OKTA_OAUTH_CALLBACK,
                    async loader(d) {
                        const search = d.request.url.split('?')[1];

                        return baseFetch(`/api/v1/integrations/okta/callback?${search}`).then(() =>
                            redirect(SETTINGS_ORGANIZATION_INTEGRATION),
                        );
                    },
                },
                {
                    path: NOTION_OAUTH_CALLBACK,
                    async loader(d) {
                        const search = d.request.url.split('?')[1];
                        const url = new URL(d.request.url);
                        const state = url.searchParams.get('state');

                        const redirectPath =
                            state === 'onboarding' ? '/onboarding/slackbot' : SETTINGS_ORGANIZATION_INTEGRATION;

                        return baseFetch(`/api/v1/integrations/notion/callback?${search}`).then(() =>
                            redirect(redirectPath),
                        );
                    },
                },
                {
                    path: JIRA_OAUTH_CALLBACK,
                    async loader(d) {
                        const search = d.request.url.split('?')[1];
                        const { redirectPath } = await baseFetch<{ redirectPath: string }>(
                            `/api/v1/integrations/jira/callback?${search}`,
                        );
                        return redirect(redirectPath ?? SETTINGS_ORGANIZATION_INTEGRATION);
                    },
                },
                {
                    path: INITIALIZE_ORG,
                    Component: InitializeOrg,
                },
            ],
        },
    ],
    {},
);

export default function Routes() {
    useSubscribeOnboardingFlow();

    return <RouterProvider router={router} />;
}
