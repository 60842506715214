import { Loader } from '@/components/loader';
import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { usePlaybookUpdate } from '@/hooks/mutations/usePlaybook';
import { baseFetch } from '@/lib/baseFetch';
import { getHeaders } from '@/lib/getHeaders';
import type { ApiError } from 'lib/models/error';
import type { PlaybookRewriteResponse, RichPlaybook } from 'lib/models/playbook';
import type { PlaybookInstructionsTree } from 'lib/models/tiptap';
import { Check, FileCheck, Sparkles, Undo2 } from 'lucide-react';
import { useState } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { toast } from 'sonner';
import { addIdToNodes } from '.';

const fetchPlaybookRewrite = (slug: string, model?: 'o1-preview') => {
    const queryParams = new URLSearchParams();
    if (model) {
        queryParams.set('model', model);
    }
    return baseFetch<PlaybookRewriteResponse | ApiError>(
        `/api/v1/playbooks/${slug}/rewrite?${queryParams.toString()}`,
        {
            headers: getHeaders(),
        },
    );
};

export const ProposalRewrite = ({
    form,
    slug,
    setDisableForm,
}: {
    form: UseFormReturn<RichPlaybook>;
    slug: string;
    setDisableForm: (disabled: boolean) => void;
}) => {
    const playbookUpdate = usePlaybookUpdate(slug);

    const [proposalLoading, setProposalLoading] = useState(false);

    const [previousInstructionsData, setPreviousInstructionsData] = useState<PlaybookInstructionsTree>();

    const proposeRewrite = ({ useO1Preview }: { useO1Preview: boolean }) => {
        const values = form.getValues();
        playbookUpdate.mutate(values, {
            onSuccess: () => {
                setProposalLoading(true);
                setDisableForm(true);

                fetchPlaybookRewrite(slug, useO1Preview ? 'o1-preview' : undefined).then(res => {
                    if ('playbook' in res && res.playbook) {
                        setPreviousInstructionsData(values.instructionsData);

                        form.reset({
                            ...values,
                            instructionsData: addIdToNodes(res.playbook.instructionsData),
                        });
                    } else {
                        toast.error('Failed to optimize playbook');
                        setDisableForm(true);
                    }

                    setProposalLoading(false);
                });
            },
        });
    };

    const handleProposedRewrite = (accept?: boolean) => {
        if (!accept && previousInstructionsData) {
            form.reset({
                ...form.getValues(),
                instructionsData: previousInstructionsData,
            });
        }
        const values = form.getValues();
        playbookUpdate.mutate(values);

        setPreviousInstructionsData(undefined);
        setDisableForm(false);
    };

    if (proposalLoading) {
        return (
            <span className="text-body-subtle text-xs flex items-center gap-sm">
                Rewriting ... this may take up to a minute{' '}
                <Loader className="text-body-blue-primary bg-bg-blue-primary p-[4px] rounded-full" />
            </span>
        );
    }

    if (previousInstructionsData) {
        return (
            <>
                <div className="bg-bg border-grey border-[0.5px] shadow flex items-center rounded-[6px] overflow-hidden">
                    <div className="font-medium text-xs px-[5px] bg-bg h-full flex items-center">Re-write complete</div>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <Button
                                size="sm"
                                mode="borderless"
                                className="text-body-green-primary rounded-none"
                                onClick={() => handleProposedRewrite(true)}
                            >
                                <Check />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent>Accept re-write</TooltipContent>
                    </Tooltip>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <Button
                                size="sm"
                                mode="borderless"
                                onClick={() => handleProposedRewrite(false)}
                                className="text-body-subtle-hover rounded-none"
                            >
                                <Undo2 />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent>Revert to original</TooltipContent>
                    </Tooltip>
                </div>
                <div className="bg-bg-blue-secondary text-body-blue-secondary size-6 flex items-center justify-center rounded-full p-0">
                    <FileCheck className="size-3.5" />
                </div>
            </>
        );
    }

    return (
        <Tooltip>
            <TooltipTrigger asChild>
                <Button
                    size="sm"
                    variant="blue"
                    className="rounded-full grow-0 p-0"
                    onClick={() => {
                        proposeRewrite({ useO1Preview: true });
                    }}
                >
                    <Sparkles className="size-3.5" />
                </Button>
            </TooltipTrigger>
            <TooltipContent>Re-write Playbook</TooltipContent>
        </Tooltip>
    );
};
