import { Button } from '@/components/ui/button';
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog';
import { Label } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { SelectAsync } from '@/components/ui/select';
import {
    type CnslUserOption,
    UserResLinkOption,
    UserResLinkValueContainer,
} from '@/components/ui/select/UserResLinkComponents';
import { useAppPost } from '@/hooks/mutations/apps/useAppPost';
import { baseFetch } from '@/lib/baseFetch';
import { getHeaders } from '@/lib/getHeaders';
import { AppPostSchema } from 'lib/models/app';
import type { PaginatedListResponse } from 'lib/models/pagination';
import type { CnslUser } from 'lib/prisma/types';
import { CloudUpload, PackagePlus, Plus, Trash2 } from 'lucide-react';
import qs from 'qs';
import type React from 'react';
import { useMemo, useState } from 'react';
import { safeParse } from 'valibot';
// remove this one we want to allow custom logos
const ALLOW_LOGO = false;

export const AppCreate: React.FC = () => {
    const [appName, setAppName] = useState('');
    const [appUrl, setAppUrl] = useState('');
    const [ownerOptions, setOwnerOptions] = useState<readonly CnslUserOption[]>([]);
    const [logo, setLogo] = useState<File | null>(null);

    const handleLogoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setLogo(event.target.files[0]);
        }
    };

    const valid = useMemo(() => {
        const result = safeParse(AppPostSchema, {
            app: {
                name: appName,
                url: appUrl,
                owners: ownerOptions.map(o => o.value.id),
                logo: logo ?? '',
            },
        });
        return result.success;
    }, [appName, appUrl, ownerOptions, logo]);

    const post = useAppPost();
    const handleSubmit = async () => {
        // Convert logo file to base64 string
        let logoDataString = '';
        if (logo) {
            const reader = new FileReader();
            reader.readAsDataURL(logo);
            await new Promise<void>(resolve => {
                reader.onload = () => {
                    logoDataString = reader.result as string;
                    resolve();
                };
            });
        }
        await post.mutateAsync({
            app: {
                name: appName,
                url: appUrl,
                owners: ownerOptions.map(o => o.value.id),
                logo: logoDataString,
            },
        });
        setAppName('');
        setAppUrl('');
        setOwnerOptions([]);
        setLogo(null);
    };

    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button size="sm">
                    <Plus />
                    New app
                </Button>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader className="border-grey border-b-[0.5px]">
                    <div className="flex items-center gap-md">
                        <PackagePlus className="size-6" />
                        <DialogTitle>New app {valid ? 'valid' : 'invalid'}</DialogTitle>
                    </div>
                </DialogHeader>
                <DialogDescription hidden={true}>Create a new app</DialogDescription>
                <form onSubmit={handleSubmit} className="p-lg flex flex-col gap-lg">
                    <div>
                        <Label htmlFor="app-name" className="text-grey font-semibold">
                            App name
                        </Label>
                        <Input
                            id="app-name"
                            value={appName}
                            onChange={e => setAppName(e.target.value)}
                            placeholder="Enter app name"
                            required
                        />
                    </div>

                    <div>
                        <Label htmlFor="app-url" className="text-grey font-semibold">
                            App URL
                        </Label>
                        <Input
                            id="app-url"
                            type="url"
                            value={appUrl}
                            onChange={e => setAppUrl(e.target.value)}
                            placeholder="https://example.com"
                            required
                        />
                    </div>

                    <div>
                        <Label className="text-grey font-semibold">App Owner</Label>
                        <SelectAsync
                            isMulti
                            value={ownerOptions}
                            components={{
                                Option: UserResLinkOption,
                                MultiValue: () => null,
                                ValueContainer: UserResLinkValueContainer,
                            }}
                            placeholder={
                                <div className="flex items-center gap-sm">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                    >
                                        <title>Select an owner</title>
                                        <path
                                            d="M3.79395 12.7351C4.69334 11.4213 6.17327 10.5676 7.99998 10.5676C9.8267 10.5676 11.3066 11.4213 12.206 12.7351M10.3333 6.66671C10.3333 7.95537 9.28865 9.00004 7.99998 9.00004C6.71132 9.00004 5.66665 7.95537 5.66665 6.66671C5.66665 5.37804 6.71132 4.33337 7.99998 4.33337C9.28865 4.33337 10.3333 5.37804 10.3333 6.66671Z"
                                            stroke="#9CA6B4"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M14.3334 8.00008C14.3334 9.8836 13.5112 11.5751 12.2061 12.7352C11.0876 13.7295 9.61437 14.3334 8.00008 14.3334C6.3858 14.3334 4.91257 13.7295 3.79404 12.7352C2.48896 11.5751 1.66675 9.8836 1.66675 8.00008C1.66675 4.50228 4.50228 1.66675 8.00008 1.66675C11.4979 1.66675 14.3334 4.50228 14.3334 8.00008Z"
                                            stroke="#9CA6B4"
                                            strokeLinejoin="round"
                                            strokeDasharray="2 2"
                                        />
                                    </svg>
                                    <span>Select an owner</span>
                                </div>
                            }
                            loadOptions={async (search, _options, additional) => {
                                const res = await baseFetch<PaginatedListResponse<CnslUser>>(
                                    `/api/v1/users/search?${qs.stringify({ search, cursor: additional?.cursor })}`,
                                    { headers: getHeaders() },
                                );

                                return {
                                    options: res.items.map(value => ({
                                        label: value.displayName || value.email,
                                        avatar: value.avatar,
                                        value,
                                    })),
                                    hasMore: !!res.pagination.nextCursor,
                                    additional: {
                                        cursor: res.pagination.nextCursor,
                                    },
                                };
                            }}
                            onChange={i => {
                                if (i) {
                                    setOwnerOptions(i);
                                }
                            }}
                        />
                    </div>

                    {ALLOW_LOGO && (
                        <div>
                            <Label htmlFor="logo-upload" className="text-grey font-semibold">
                                App Logo
                            </Label>
                            <div
                                className="flex items-center space-x-4 bg-bg-surface border-grey border-[0.5px] rounded-md p-lg border-dashed"
                                onDragOver={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                onDrop={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                                        const file = e.dataTransfer.files[0];
                                        if (file.size <= 5 * 1024 * 1024) {
                                            // 5MB limit
                                            setLogo(file);
                                        } else {
                                            alert('File size exceeds 5MB limit. Please choose a smaller file.');
                                        }
                                    }
                                }}
                            >
                                {!logo && (
                                    <div className="flex shrink-0 items-center justify-center size-10 border border-grey rounded-full bg-gradient-to-b from-[#F1F5F9] to-[#E2E8F0]">
                                        <div className="flex items-center justify-center size-8 bg-white border border-grey rounded-full">
                                            <CloudUpload className="size-4 text-body-subtle" />
                                        </div>
                                    </div>
                                )}
                                {logo && (
                                    <div className="flex items-center gap-md w-full">
                                        <img
                                            src={URL.createObjectURL(logo)}
                                            alt="Logo preview"
                                            className="size-10 object-cover rounded-md border border-grey"
                                        />
                                        <div className="flex items-center justify-between w-full">
                                            <div>
                                                <h3>File uploaded</h3>
                                                <span className="text-body-subtle">{logo.name}</span>
                                            </div>
                                            <Button onClick={() => setLogo(null)} mode="borderless" size="sm">
                                                <Trash2 className="size-4 text-body-subtle" />
                                            </Button>
                                        </div>
                                    </div>
                                )}
                                {!logo && (
                                    <span>
                                        Drag and drop your file here or{' '}
                                        <button
                                            type="button"
                                            className="p-0 h-auto text-body-blue-primary"
                                            onClick={() => document.getElementById('logo-upload')?.click()}
                                        >
                                            browse
                                        </button>
                                    </span>
                                )}
                                <input
                                    id="logo-upload"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleLogoUpload}
                                    className="hidden"
                                />
                            </div>
                        </div>
                    )}
                </form>
                <DialogFooter className="border-grey border-t-[0.5px]">
                    <div className="flex items-center justify-between w-full pt-lg">
                        <DialogClose asChild>
                            <Button mode="borderless">Cancel</Button>
                        </DialogClose>
                        <DialogClose asChild>
                            <Button variant="blue" mode="dark" onClick={handleSubmit} disabled={!valid}>
                                Add app
                            </Button>
                        </DialogClose>
                    </div>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
