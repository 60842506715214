import type { IntegrationIdType as EnumIntegrationIdType } from 'lib/prisma/enums';
import invariant from 'tiny-invariant';

import type { Instruction } from '@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item';
import type { JSONContent } from '@tiptap/core';

export type TreeItem = {
    id: string | number;
    name?: string;
    schema?: JSONContent | null;
    type?: 'file' | 'directory';
    children?: TreeItem[];
    integration?: EnumIntegrationIdType;
    isDraft?: boolean;
    isOpen?: boolean;
};

export type TreeState = {
    lastAction: TreeAction | null;
    data: TreeItem[];
};

export const defaultIsOpen = (data: TreeItem[]) => {
    const traverse = (item: TreeItem): TreeItem => {
        return {
            ...item,
            isOpen: true,
            children: item.children?.map(traverse),
        };
    };
    return data.map(traverse);
};

export const getInitialTreeState = (data: TreeItem[]) => () => {
    // Default to open
    return { data: defaultIsOpen(data), lastAction: null };
};

export type TreeAction =
    | {
          type: 'instruction';
          instruction: Instruction;
          itemId: string | number;
          targetId: string;
      }
    | {
          type: 'toggle';
          itemId: string | number;
      }
    | {
          type: 'expand';
          itemId: string | number;
      }
    | {
          type: 'collapse';
          itemId: string | number;
      }
    | {
          type: 'hydrate';
          data: TreeItem[];
      }
    | {
          type: 'reparent-flatten-children';
          itemId: string | number;
          targetId: string | number;
      };

export type BaseTreeItem<T> = { id: string | number; children?: T[] };

export const tree = {
    remove<T extends BaseTreeItem<T>>(data: T[], id: string | number): T[] {
        return data
            .filter(item => item.id !== id)
            .map(item => {
                if (tree.hasChildren(item)) {
                    return {
                        ...item,
                        children: tree.remove(item.children, id),
                    };
                }
                return item;
            });
    },
    insertBefore<T extends BaseTreeItem<T>>(data: T[], targetId: string | number, newItem: T): T[] {
        return data.flatMap(item => {
            if (item.id === targetId) {
                return [newItem, item];
            }
            if (tree.hasChildren(item)) {
                return {
                    ...item,
                    children: tree.insertBefore(item.children, targetId, newItem),
                };
            }
            return item;
        });
    },
    insertAfter<T extends BaseTreeItem<T>>(data: T[], targetId: string | number, newItem: T | T[]): T[] {
        return data.flatMap(item => {
            if (item.id === targetId) {
                if (Array.isArray(newItem)) {
                    return [item, ...newItem];
                } else {
                    return [item, newItem];
                }
            }

            if (tree.hasChildren(item)) {
                return {
                    ...item,
                    children: tree.insertAfter(item.children, targetId, newItem),
                };
            }

            return item;
        });
    },
    insertChild<T extends BaseTreeItem<T>>(data: T[], targetId: string | number, newItem: T | T[]): T[] {
        return data.flatMap(item => {
            if (item.id === targetId) {
                // already a parent: add as first child
                if (Array.isArray(newItem)) {
                    return {
                        ...item,
                        // opening item so you can see where item landed
                        isOpen: true,
                        children: [...newItem, ...(item.children ?? [])],
                    };
                } else {
                    return {
                        ...item,
                        // opening item so you can see where item landed
                        isOpen: true,
                        children: [newItem, ...(item.children ?? [])],
                    };
                }
            }

            if (!tree.hasChildren(item)) {
                return item;
            }

            return {
                ...item,
                children: tree.insertChild(item.children, targetId, newItem),
            };
        });
    },
    find<T extends BaseTreeItem<T>>(data: T[], itemId: string | number): T | undefined {
        for (const item of data) {
            if (item.id === itemId) {
                return item;
            }

            if (tree.hasChildren(item)) {
                const result = tree.find(item.children, itemId);
                if (result) {
                    return result;
                }
            }
        }
        return;
    },
    getPathToItem<T extends BaseTreeItem<T>>({
        current,
        targetId,
        parentIds = [],
    }: {
        current: T[];
        targetId: string | number;
        parentIds?: (string | number)[];
    }): (string | number)[] | undefined {
        for (const item of current) {
            if (item.id === targetId) {
                return parentIds;
            }
            const nested = tree.getPathToItem({
                current: item.children ?? [],
                targetId: targetId,
                parentIds: [...parentIds, item.id],
            });
            if (nested) {
                return nested;
            }
        }
        return;
    },
    getIndexPathToItem<T extends BaseTreeItem<T>>({
        current,
        targetId,
        parentIndexes = [],
    }: {
        current: T[];
        targetId: string | number;
        parentIndexes?: number[];
    }): number[] | undefined {
        for (const [index, item] of current.entries()) {
            if (item.id === targetId) {
                return [...parentIndexes, index];
            }
            const nested = tree.getIndexPathToItem({
                current: item.children ?? [],
                targetId: targetId,
                parentIndexes: [...parentIndexes, index],
            });
            if (nested) {
                return nested;
            }
        }
        return;
    },
    hasChildren<T extends BaseTreeItem<T>>(item?: T): item is T & { children: T[] } {
        return (item?.children?.length ?? 0) > 0;
    },
    getNodeByPath<T extends BaseTreeItem<T>>(data: T[], path: number[]) {
        let node: T | undefined | null;

        path.forEach(index => {
            if (node) {
                node = node?.children?.[index] ?? null;
            } else if (node === undefined) {
                node = data[index];
            }
        });

        return node ?? undefined;
    },
    flattenChildren<T extends BaseTreeItem<T>>(data: T[]): T[] {
        return data.flatMap(item => {
            if (tree.hasChildren(item)) {
                return tree.flattenChildren(item.children);
            }
            return item;
        });
    },
};

export function treeStateReducer(state: TreeState, action: TreeAction): TreeState {
    return {
        data: dataReducer(state.data, action),
        lastAction: action,
    };
}

const dataReducer = (data: TreeItem[], action: TreeAction) => {
    if (action.type === 'hydrate') {
        return defaultIsOpen(action.data);
    }

    const item = tree.find(data, action.itemId);
    if (!item) {
        return data;
    }

    if (action.type === 'instruction') {
        const instruction = action.instruction;

        if (instruction.type === 'reparent') {
            const path = tree.getPathToItem({
                current: data,
                targetId: action.targetId,
            });
            invariant(path);
            const desiredId = path[instruction.desiredLevel];
            let result = tree.remove(data, action.itemId);
            result = tree.insertAfter(result, desiredId, item);
            return result;
        }

        // the rest of the actions require you to drop on something else
        if (action.itemId === action.targetId) {
            return data;
        }

        if (instruction.type === 'reorder-above') {
            let result = tree.remove(data, action.itemId);
            result = tree.insertBefore(result, action.targetId, item);
            return result;
        }

        if (instruction.type === 'reorder-below') {
            let result = tree.remove(data, action.itemId);
            result = tree.insertAfter(result, action.targetId, item);
            return result;
        }

        if (instruction.type === 'make-child') {
            let result = tree.remove(data, action.itemId);
            result = tree.insertChild(result, action.targetId, item);
            return result;
        }

        console.warn('TODO: action not implemented', instruction);

        return data;
    }

    if (action.type === 'reparent-flatten-children') {
        const node = structuredClone(tree.find(data, action.itemId));
        invariant(node);

        const updatedData = tree.remove(data, action.itemId);

        const flattenedChildren = tree.flattenChildren(node.children ?? []);
        node.children = [];

        const result = tree.insertChild(updatedData, action.targetId, [node, ...flattenedChildren]);
        return result;
    }

    function toggle(item: TreeItem): TreeItem {
        if (!tree.hasChildren(item)) {
            return item;
        }

        if ('itemId' in action && item.id === action.itemId) {
            return { ...item, isOpen: !item.isOpen };
        }

        return { ...item, children: item.children.map(toggle) };
    }

    if (action.type === 'toggle') {
        const result = data.map(toggle);
        return result;
    }

    if (action.type === 'expand') {
        if (tree.hasChildren(item) && !item.isOpen) {
            const result = data.map(toggle);
            return result;
        }
        return data;
    }

    if (action.type === 'collapse') {
        if (tree.hasChildren(item) && item.isOpen) {
            const result = data.map(toggle);
            return result;
        }
        return data;
    }

    return data;
};
