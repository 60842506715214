import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { CalendarDays, CircleUserRound, MapPin, NotebookTabs, UsersRound } from 'lucide-react';
import { useState } from 'react';

import type { SingleCnslUser } from 'lib/models/cnsl_user';

import { Copy } from '@/components/Copy';
import { KV } from '@/components/KV';
import { ResLink } from '@/components/ResLink';
import { Stacked } from '@/components/Stacked';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { fmtDate } from '@/lib/dates';

export const Overview = ({ user }: { user: SingleCnslUser }) => {
    const [open, setOpen] = useState(false);

    return (
        <div className="mx-2xl gap-4xl py-4xl flex max-w-screen-sm flex-col min-[1260px]:m-auto">
            <div className="gap-lg flex flex-col">
                <div className="gap-sm flex flex-col">
                    <div className="gap-md flex items-center">
                        <NotebookTabs className="size-6" />
                        <h1 className="text-xl">Overview</h1>
                    </div>
                    <Copy text={user.email} className="text-base" />
                </div>
                <div className="flex flex-col">
                    <KV
                        label={<div>Manager</div>}
                        value={
                            <div className="gap-md px-md py-sm flex items-center">
                                {user.manager ? (
                                    <ResLink
                                        size="sm"
                                        entity="users"
                                        id={user.manager.email}
                                        label={user.manager.displayName}
                                        src={user.manager.avatar}
                                    />
                                ) : (
                                    <div className="text-body-subtle flex items-center gap-md">
                                        <CircleUserRound />
                                        No manager found
                                    </div>
                                )}
                            </div>
                        }
                    />
                    <KV
                        label={<div>Direct reports</div>}
                        value={
                            <div className="gap-md px-md py-sm flex items-center">
                                {user.subordinates && user.subordinates.length > 0 ? (
                                    <Dialog open={open} onOpenChange={setOpen}>
                                        <DialogTrigger asChild>
                                            <div className="flex gap-md cursor-pointer">
                                                <Stacked
                                                    items={user.subordinates}
                                                    renderItem={(item, index) => {
                                                        if (!item) return null;
                                                        return (
                                                            <ResLink
                                                                avatarOnly
                                                                label={item.displayName}
                                                                key={index}
                                                                src={item.avatar ?? undefined}
                                                                size="sm"
                                                            />
                                                        );
                                                    }}
                                                    getTooltipContent={item => item.displayName}
                                                />
                                                <Tooltip>
                                                    <TooltipTrigger>
                                                        <div>{`${user.subordinates.length} direct report${user.subordinates.length > 1 ? 's' : ''}`}</div>
                                                    </TooltipTrigger>
                                                    <TooltipContent>
                                                        {user.subordinates.map(sub => sub.displayName).join(', ')}
                                                    </TooltipContent>
                                                </Tooltip>
                                            </div>
                                        </DialogTrigger>
                                        <DialogContent className="max-w-[400px]">
                                            <DialogHeader className="border-b-[0.5px] border-grey">
                                                <DialogTitle>
                                                    <UsersRound />
                                                    Direct reports
                                                </DialogTitle>
                                            </DialogHeader>
                                            <div className="flex flex-col gap-md p-lg">
                                                {user.subordinates.map((subordinate, index) => (
                                                    <ResLink
                                                        key={index}
                                                        entity="users"
                                                        id={subordinate.email}
                                                        label={subordinate.displayName}
                                                        src={subordinate.avatar ?? undefined}
                                                        description={subordinate.title ?? undefined}
                                                        size="lg"
                                                    />
                                                ))}
                                            </div>
                                        </DialogContent>
                                    </Dialog>
                                ) : (
                                    <div className="text-body-subtle flex items-center gap-md">
                                        <UsersRound />
                                        No direct reports found
                                    </div>
                                )}
                            </div>
                        }
                    />
                    <KV
                        label={<div>Location</div>}
                        value={
                            <div className="px-md py-sm flex items-center">
                                {user.location ? (
                                    <div className="gap-md flex items-center">
                                        <MapPin />
                                        {user.location}
                                    </div>
                                ) : (
                                    <div className="text-body-subtle flex items-center gap-md">
                                        <MapPin />
                                        No location found
                                    </div>
                                )}
                            </div>
                        }
                    />
                    <KV
                        label={<div>Started</div>}
                        value={
                            <div className="px-md py-sm flex items-center">
                                {user.startedAt ? (
                                    <div className="gap-md flex items-center">
                                        <CalendarDays />
                                        {fmtDate(user.startedAt)}
                                    </div>
                                ) : (
                                    <div className="text-body-subtle flex items-center gap-md">
                                        <CalendarDays />
                                        No start date found
                                    </div>
                                )}
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    );
};
