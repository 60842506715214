import { SelectAsync } from '@/components/ui/select/index';
import { baseFetch } from '@/lib/baseFetch';
import { getHeaders } from '@/lib/getHeaders';
import type { CnslAppList } from 'lib/models/app';
import type { PaginatedListResponse } from 'lib/models/pagination';
import type { CnslApp } from 'lib/prisma/types';
import qs from 'qs';
import type { MultiValue, SingleValue } from 'react-select';
import { AppResLinkOption, AppResLinkSingleValue, AppResLinkValuesContainer } from './ui/select/AppResLinkOptions';

export interface AppSelectOpt {
    label: string;
    value: string;
    logo: string;
    app?: CnslApp;
}

export type AppSelectChangePayload = MultiValue<AppSelectOpt> | SingleValue<AppSelectOpt>;

interface AppSelectProps {
    selected?: AppSelectOpt | Array<AppSelectOpt>;
    isMulti?: boolean;
    onChange?: (v: AppSelectChangePayload) => void;
}

export const AppSelect = ({ selected, isMulti, onChange }: AppSelectProps) => {
    const selectComponents = () => {
        if (isMulti) {
            return {
                Option: AppResLinkOption,
                ValueContainer: AppResLinkValuesContainer,
                MultiValue: () => null,
            };
        }
        return {
            Option: AppResLinkOption,
            SingleValue: AppResLinkSingleValue,
        };
    };

    const key = () => {
        if (selected) {
            if ('value' in selected) {
                return selected.value;
            }
            return selected[0]?.value ?? 'none';
        }
        return 'none';
    };

    return (
        <SelectAsync
            key={key()}
            isMulti={isMulti}
            defaultValue={selected}
            placeholder="Select an app"
            components={selectComponents()}
            loadOptions={async (search: string, _options, additional) => {
                const { cursor } = additional ?? {};
                const res = await baseFetch<PaginatedListResponse<CnslAppList>>(
                    `/api/v1/apps?${qs.stringify({ search, cursor })}`,
                    {
                        headers: getHeaders(),
                    },
                );

                return {
                    options: res.items.map(value => ({
                        label: value.name ?? '',
                        value: value.slug ?? '',
                        logo: value.logo ?? '',
                        app: value,
                    })),
                    hasMore: !!res.pagination.nextCursor,
                    additional: {
                        cursor: res.pagination.nextCursor,
                    },
                };
            }}
            onChange={onChange}
        />
    );
};
