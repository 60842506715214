import { useMutation } from '@/lib/hooks/useMutation';
import { type DefaultError, useMutation as useMutationRaw, useQueryClient } from '@tanstack/react-query';
import type { AppPolicyMatrixPost } from 'lib/models/app_policy';

export const useAppMatrixUpload = () => {
    return useMutationRaw({
        mutationFn: (formData: FormData) => {
            // biome-ignore lint/style/noRestrictedGlobals: can't use baseFetch since it doesn't support multipart/form-data
            return fetch(`${window.location.origin.replace('app', 'api')}/api/v1/app-matrix`, {
                method: 'POST',
                mode: 'cors',
                credentials: 'include',
                body: formData,
            });
        },
    });
};

export const useAppMatrixProcess = () => {
    const queryClient = useQueryClient();
    return useMutation<void, DefaultError, AppPolicyMatrixPost>({
        url: `/api/v1/policies/app-matrix`,
        method: 'POST',
        onSuccess: async () => {
            await Promise.all([
                queryClient.refetchQueries({ queryKey: [`/api/v1/app-matrix`], exact: true }),
                queryClient.refetchQueries({ queryKey: [`/api/v1/policies`], exact: true }),
                queryClient.refetchQueries({ queryKey: ['policies'] }),
            ]);
        },
        successToast: {
            message: 'App matrix processed',
        },
    });
};
