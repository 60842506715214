import { Select } from '@/components/ui/select';
import { useAction } from '@/hooks/queries/useActions';
import type { PStepSelectedOption } from '@/stores/usePolicy';
import type { DynamicPolicyFieldOption } from 'lib/field_maps/policies';
import { ArrowRightLeft } from 'lucide-react';
import { useState } from 'react';

const mappingOptions: { label: string; value: DynamicPolicyFieldOption }[] = [
    { label: 'App Name', value: 'app.name' },
    { label: "Requester's Email", value: 'user.email' },
    { label: "Requester's Full Name", value: 'user.full_name' },
    { label: 'Reason', value: 'request.reason' },
    { label: 'Policy Name', value: 'policy.name' },
    { label: 'Policy Description', value: 'policy.description' },
];

interface ConfigureCustomActionProps {
    selectedOption: PStepSelectedOption;
    onUpdate: (config: Record<string, string>) => void;
}

export const ConfigureCustomAction = ({ selectedOption, onUpdate }: ConfigureCustomActionProps) => {
    const { data } = useAction('custom', selectedOption.value);
    const parameters = data?.action?.config?.parameters ?? [];

    const [paramMappings, setParamMappings] = useState<Record<string, string>>(selectedOption.config ?? {});

    const title = `Map "${selectedOption.label}" Parameters`;

    return (
        <div className="mt-md bg-bg-grey-primary-hover p-md rounded-md">
            <h3 className="flex items-center gap-sm">
                <ArrowRightLeft /> {title}
            </h3>
            <p className="text-body-subtle-hover">
                Map the parameters from your custom action to a value from the access request
            </p>
            <div className="mt-lg flex flex-col gap-md">
                {parameters.map(p => (
                    <div className="grid grid-cols-2 gap-lg items-start" key={p.key}>
                        <div className="text-xs">
                            <div className="text-body-grey-primary font-medium font-mono">{p.key}</div>
                            <div className="text-body-subtle-hover">{p.instructions}</div>
                        </div>
                        <Select
                            options={mappingOptions}
                            className="bg-white"
                            menuPlacement="auto"
                            value={mappingOptions.find(o => o.value === paramMappings[p.key])}
                            onChange={i => {
                                if (i) {
                                    paramMappings[p.key] = i.value;
                                    setParamMappings(paramMappings);
                                    onUpdate(paramMappings);
                                }
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};
