import { Confirmation } from '@/components/Confirmation';
import {
    useIntegrationReSync,
    useIntegrationSettingsError,
    useIntegrationSettingsStatus,
    useIntegrationSettingsSync,
    useIntegrationSetupWebhooks,
} from '@hooks/queries/integrations/useIntegrationSettingsStatus';
import { Button } from '@ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@ui/dropdown-menu';
import { SkeletonRows } from '@ui/skeleton';
import { formatDistance } from 'date-fns';
import { IntegrationId, type IntegrationIdValues } from 'lib/integration';
import { type SyncSetting, SyncState } from 'lib/models/sync';
import {
    ArrowDownUp,
    CircleX,
    CloudDownload,
    Ellipsis,
    RefreshCw,
    SquareDashedMousePointer,
    Trash2,
    TriangleAlert,
} from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import { Loader } from '@/components/loader';
import { useIntegrationSettingDelete } from '@/hooks/mutations/integrations/useIntegrationSettingDelete';
import { useElapsedTime } from '@/hooks/useElapsedTime';
import { cn } from '@/lib/styling';
import { ProviderAssets } from 'lib/3p';

import FlaggedFeature from '@/components/FlaggedFeature';
import { useJiraGetOAuthUrl } from '@/hooks/queries/useOAuth';
import { FlagKey } from 'lib/flags/keys';
import type { IntegrationErrorInterface } from 'lib/integration_settings/types';
import { ProviderDetails } from './ProviderDetails';
import IntegrationSettings from './ProviderForm';

const SyncStatus = ({
    syncing,
}: {
    syncing: SyncSetting;
}) => {
    const { elapsedTime } = useElapsedTime({ updateInterval: 5 });
    const [now, setNow] = useState(Date.now());

    // biome-ignore lint/correctness/useExhaustiveDependencies:
    useEffect(() => {
        setNow(Date.now());
    }, [elapsedTime, setNow]);

    const since = syncing.lastSync ? formatDistance(new Date(syncing.lastSync), now, { addSuffix: true }) : '';
    return (
        <div className="flex items-center gap-1 text-xs text-body-subtle ">
            {syncing.status === SyncState.Synced && (
                <>
                    <CloudDownload className="size-3" />
                    {since}
                </>
            )}
            {syncing.status === SyncState.Syncing && (
                <>
                    <Loader size="sm" /> Syncing
                </>
            )}
            {syncing.status === SyncState.Error && (
                <>
                    <CircleX className="size-3" />
                    {since}
                </>
            )}
        </div>
    );
};

const Row = ({
    name,
    logo,
    enabled,
    id,
    sync,
    error: defaultError,
    additionalDropdownItems,
}: {
    name: string;
    logo: string;
    enabled: boolean;
    id: IntegrationId;
    sync?: SyncSetting;
    error?: IntegrationErrorInterface;
    additionalDropdownItems?: (hidePop: () => void) => React.ReactNode[];
}) => {
    const { pathname } = useLocation();
    const [showPop, setShowPop] = useState(false);

    /* TODO replace with websocket */
    const [syncing, setSyncing] = useState(sync || { status: SyncState.Syncing, lastSync: null });
    const [error, setError] = useState(defaultError);
    const { data: jiraOAuthUrl } = useJiraGetOAuthUrl();

    useEffect(() => {
        setError(defaultError);
    }, [defaultError]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (sync) {
            setSyncing(sync);
        }
        if (sync?.status === SyncState.Synced) {
            setError(defaultError);
        }
    }, [sync?.status, sync?.lastSync]);

    const hasError = error?.authZ || error?.authN || error?.misc;
    const errorDescription = [
        error?.authZ && 'Authorization Error',
        error?.authN && 'Permissions Error',
        error?.misc && 'Miscellaneous Error',
    ]
        .filter(Boolean)
        .join(', ');

    let providerPath = `${pathname}/${name.toLowerCase()}`;

    // Short term hack to handle slack and notion.
    if (id === IntegrationId.Slack && !enabled) {
        const clientId = import.meta.env.VITE_SLACK_CLIENT_ID;
        const redirectUri = import.meta.env.VITE_SLACK_REDIRECT_URI;
        providerPath = `https://slack.com/oauth/v2/authorize?client_id=${clientId}&scope=chat:write,chat:write.customize,commands,im:history,im:read,im:write,metadata.message:read,users:read,users:read.email,channels:history,channels:join,groups:history,groups:read,groups:write,groups:write.invites,mpim:history,mpim:read,mpim:write,channels:read,channels:manage,channels:write.invites&user_scope=`;
        if (redirectUri) {
            providerPath += `&redirect_uri=${redirectUri}`;
        }
    }

    if (id === IntegrationId.Notion && !enabled) {
        providerPath = import.meta.env.VITE_NOTION_AUTHORIZATION_URL;
    }
    if (id === IntegrationId.Jira && !enabled) {
        providerPath = jiraOAuthUrl?.url ?? '';
    }

    const deleteMutation = useIntegrationSettingDelete({ provider: id });
    const syncMutation = useIntegrationReSync({ provider: id });

    return (
        <div key={name} className="p-md border-subtle flex items-center justify-between border-b last:border-none">
            <Link className="gap-md flex items-center capitalize" to={providerPath}>
                {!enabled ? (
                    <>
                        <div className="border-grey flex size-8 items-center justify-center rounded-full border-[0.5px]">
                            <img className="size-4" src={logo} alt={name} />
                        </div>
                        <h3>{name}</h3>
                    </>
                ) : (
                    <div className="gap-md flex items-center capitalize">
                        <div className="border-grey flex size-8 items-center justify-center rounded-full border-[0.5px]">
                            <img className="size-4" src={logo} alt={name} />
                        </div>
                        <div className="flex flex-col h-8 justify-center">
                            <div className="flex items-center gap-sm">
                                <h3>{name}</h3>
                            </div>
                            {!hasError && sync && <SyncStatus syncing={syncing} />}
                            {hasError && (
                                <div className="text-body-red-primary flex items-center gap-sm">
                                    <TriangleAlert className=" size-3" />
                                    <span className="text-xs">{errorDescription}</span>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </Link>
            <div className="gap-md flex items-center">
                <div className="gap-md flex items-center">
                    {enabled && (
                        <>
                            <div className="bg-bg-green-secondary size-md rounded-full" />
                            <p>Connected</p>
                        </>
                    )}
                </div>
                {enabled ? (
                    <DropdownMenu open={showPop}>
                        <DropdownMenuTrigger asChild>
                            <Button mode="borderless" onClick={() => setShowPop(true)}>
                                <Ellipsis />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent side="bottom" align="end" onInteractOutside={() => setShowPop(false)}>
                            {additionalDropdownItems ? additionalDropdownItems(() => setShowPop(false)) : []}
                            <DropdownMenuItem
                                onClick={() => {
                                    syncMutation.mutate({});
                                    setSyncing({ status: SyncState.Syncing, lastSync: null });
                                    setError(undefined);
                                    setShowPop(false);
                                }}
                            >
                                <RefreshCw
                                    className={cn(
                                        'mr-2 size-4',
                                        syncing.status === SyncState.Syncing && 'animate-spin',
                                    )}
                                />
                                Re-sync
                            </DropdownMenuItem>
                            <Confirmation
                                ctaLabel="Delete Integration"
                                title={`Delete "${name}" integration?`}
                                subtitle="Once this integration is deleted, you will have to reconnect it to view that data. "
                                onClose={() => setShowPop(false)}
                                onConfirm={() => deleteMutation.mutate({ provider: name })}
                            >
                                <DropdownMenuItem className="text-body-red-primary">
                                    <Trash2 className="mr-2 size-4" />
                                    Delete Integration
                                </DropdownMenuItem>
                            </Confirmation>
                        </DropdownMenuContent>
                    </DropdownMenu>
                ) : (
                    <Link to={providerPath}>
                        <Button>Connect</Button>
                    </Link>
                )}
            </div>
        </div>
    );
};

const ZendeskSetupTwoWaySync = ({ hidePop }: { hidePop: () => void }) => {
    const mutation = useIntegrationSetupWebhooks({ provider: IntegrationId.Zendesk });

    return (
        <FlaggedFeature flag={FlagKey.TwoWaySync}>
            <DropdownMenuItem
                onClick={() => {
                    mutation.mutate({});
                    hidePop();
                }}
                disabled={mutation.isPending}
            >
                <ArrowDownUp className={cn('mr-2 size-4', mutation.isPending && 'animate-spin')} />
                Setup Comments Syncing
            </DropdownMenuItem>
        </FlaggedFeature>
    );
};

const getDropdownItemsForProvider = (provider: IntegrationId, hidePop: () => void) => {
    if (provider === IntegrationId.Zendesk) {
        return [<ZendeskSetupTwoWaySync key="zendesk-setup-two-way-sync" hidePop={hidePop} />];
    }
    return [];
};

const Organization = () => {
    const enabled: {
        name: string;
        logo: string;
        id: IntegrationId;
        sync?: SyncSetting;
        error?: IntegrationErrorInterface;
    }[] = [];
    const disabled: { name: string; logo: string; id: IntegrationId }[] = [];
    const { subsetting } = useParams() as { subsetting: IntegrationIdValues };

    const { data, isLoading } = useIntegrationSettingsStatus();
    const { data: syncData } = useIntegrationSettingsSync();
    const { data: errorData } = useIntegrationSettingsError();

    if (isLoading) {
        return <SkeletonRows />;
    }

    if (subsetting) {
        if (data?.status && !data.status[subsetting]) {
            return <IntegrationSettings provider={subsetting} />;
        }
        return <ProviderDetails />;
    }

    if (!data?.status) {
        return;
    }

    Object.keys(data.status)
        .sort()
        .forEach(key => {
            const config = ProviderAssets[key as keyof typeof ProviderAssets];
            if (config) {
                if (data.status[key]) {
                    enabled.push({
                        ...config,
                        id: key as IntegrationId,
                        sync: syncData && syncData[key as IntegrationId],
                        error: errorData && errorData[key as IntegrationId],
                    });
                } else {
                    disabled.push({ ...config, id: key as IntegrationId });
                }
            }
        });

    return (
        <div>
            <div className="border-grey rounded-md border-[0.5px]">
                <div>
                    <div className="bg-bg-overlay p-md border-grey border-b-[0.5px] text-xs font-medium rounded-t-md">
                        Connected
                    </div>
                    {enabled.length > 0 ? (
                        enabled.map(({ name, logo, id, sync, error }) => {
                            return (
                                <Row
                                    name={name}
                                    logo={logo}
                                    key={name}
                                    id={id}
                                    enabled
                                    sync={sync}
                                    error={error}
                                    additionalDropdownItems={hidePop => getDropdownItemsForProvider(id, hidePop)}
                                />
                            );
                        })
                    ) : (
                        <div className="px-md py-3xl gap-md text-body-subtle flex h-12 items-center justify-center">
                            <SquareDashedMousePointer />
                            No connected integrations
                        </div>
                    )}
                </div>
                {disabled.length > 0 && (
                    <div>
                        <div className="bg-bg-overlay p-md border-grey border-y-[0.5px] text-xs font-medium">
                            Not connected
                        </div>
                        {disabled.map(({ name, logo, id }) => {
                            return <Row id={id} name={name} logo={logo} key={name} enabled={false} />;
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Organization;
