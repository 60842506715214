import type { ActionsListResponse, ActionsResponse } from 'lib/models/actions';

import { useQuery } from '@/lib/hooks/useQuery';
import qs from 'qs';

export const useActions = (search?: string) => {
    return useQuery<ActionsListResponse>({
        url: `/api/v1/actions${search ? `?${qs.stringify({ search })}` : ''}`,
    });
};

export const useAction = (provider: string, slug: string) => {
    return useQuery<ActionsResponse>({
        url: `/api/v1/actions/${provider}/${slug}`,
    });
};
