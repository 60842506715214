import * as SeparatorPrimitive from '@radix-ui/react-separator';
import { separatorVariants } from '@ui/separator';
import type { VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@/lib/styling';

export interface SeparatorProps
    extends Omit<React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>, 'orientation' | 'decorative'>,
        VariantProps<typeof separatorVariants> {}

const Separator = React.forwardRef<React.ElementRef<typeof SeparatorPrimitive.Root>, SeparatorProps>(
    ({ className, variant, orientation, ...props }, ref) => (
        <SeparatorPrimitive.Root
            ref={ref}
            className={cn(separatorVariants({ variant, orientation }), className)}
            {...props}
        />
    ),
);
Separator.displayName = SeparatorPrimitive.Root.displayName;

export { Separator };
