import { type QueryKey, keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import type { ColumnDef, Row, TableOptions } from '@tanstack/react-table';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { SkeletonTable } from '@ui/skeleton';
import type { PaginatedListResponse } from 'lib/models/pagination';
import * as React from 'react';

import { useWindowSize } from '@/hooks/useWindowSize';
import { baseFetch } from '@/lib/baseFetch';
import { getHeaders } from '@/lib/getHeaders';
import { cn } from '@/lib/styling';

import { getResizeHandler } from './customEventHandlers';

import './index.css';

type BeforeRowFunc<T> = (row: Row<T>, prior?: Row<T>, next?: Row<T>) => React.ReactNode | null;

interface TableProps<TData = unknown> extends React.HTMLAttributes<HTMLTableElement> {
    data?: TData[];
    columns: ColumnDef<TData>[];
    tableRef?: React.Ref<HTMLTableElement>;
    // "loading" is used in cases when data is fetched and passed into the table instead of fetched by the table
    loading?: boolean;
    reactTableOpts?: Omit<TableOptions<TData>, 'data' | 'columns' | 'getCoreRowModel'>;
    pagination?: { queryKey: QueryKey; limit: number; threshold: number; pathname: string; filters?: string };
    onPaginationCountChange?: (count: number) => void;
    emptyState?: React.ReactNode;
    beforeRow?: BeforeRowFunc<TData>;
}

const TableHead = React.forwardRef<HTMLTableCellElement, React.ThHTMLAttributes<HTMLTableCellElement>>(
    ({ className, ...props }, ref) => (
        <th
            ref={ref}
            className={cn('relative px-md py-sm first:pl-lg h-10 text-left font-medium', className)}
            {...props}
        />
    ),
);
TableHead.displayName = 'TableHead';

const TableRow = React.forwardRef<HTMLTableRowElement, React.HTMLAttributes<HTMLTableRowElement>>(
    ({ className, ...props }, ref) => {
        const [isHovered, setIsHovered] = React.useState(false);

        return (
            <tr
                ref={ref}
                className={cn(
                    'border-b-[0.5px] border-subtle-hover hover:bg-bg-overlay [data-rowHover]={isHovered}',
                    className,
                )}
                data-rowHover={isHovered}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                {...props}
            />
        );
    },
);
TableRow.displayName = 'TableRow';

const TableCell = React.forwardRef<
    HTMLTableCellElement,
    React.TdHTMLAttributes<HTMLTableCellElement> & { expand?: boolean }
>(({ className, expand, ...props }, ref) => (
    <td
        ref={ref}
        className={cn(
            'h-10 last:border-none hover:bg-[#F3F5F7] overflow-hidden group',
            !expand && 'px-md py-sm first:pl-lg',
            className,
        )}
        {...props}
    />
));
TableCell.displayName = 'TableCell';

interface TableBodyProps<TData> extends React.HTMLAttributes<HTMLTableSectionElement> {
    table: ReturnType<typeof useReactTable<TData>> | null;
    headerRef?: React.Ref<HTMLTableSectionElement>;
    isInfiniteScrollEnabled?: boolean;
}

const TableHeader = <T,>({ className, table, headerRef, isInfiniteScrollEnabled, ...props }: TableBodyProps<T>) => {
    const baseClassName = cn('sticky top-0 z-10 bg-bg shadow-[0_0.5px_0_0_rgba(223,227,230,1)]', className);
    if (!table) {
        return <thead ref={headerRef} className={baseClassName} {...props} />;
    }

    return (
        <thead ref={headerRef} className={baseClassName} {...props}>
            {table.getHeaderGroups().map(headerGroup => (
                <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map(header => {
                        const handleResize = () => {
                            // use smoother resizing when table is infinitely scrolling (table layout is set to fixed)
                            return getResizeHandler(table, header)
                                ? getResizeHandler(table, header)
                                : header.getResizeHandler();
                        };

                        const resizeOptions = header.column.getCanResize()
                            ? {
                                  onDoubleClick: () => header.column.resetSize(),
                                  onMouseDown: handleResize(),
                                  onTouchStart: handleResize(),
                                  className: `resizer ${table.options.columnResizeDirection} ${header.column.getIsResizing() ? 'isResizing' : ''}`,
                              }
                            : {};

                        return (
                            <TableHead key={header.id} colSpan={header.colSpan} style={{ width: header.getSize() }}>
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(header.column.columnDef.header, header.getContext())}
                                <div {...resizeOptions} />
                            </TableHead>
                        );
                    })}
                </TableRow>
            ))}
        </thead>
    );
};
TableHeader.displayName = 'TableHeader';

interface TableBodyProps<TData> extends React.HTMLAttributes<HTMLTableSectionElement> {
    table: ReturnType<typeof useReactTable<TData>> | null;
    bodyRef?: React.Ref<HTMLTableSectionElement>;
    beforeRow?: BeforeRowFunc<TData>;
}

const TableBody = <T,>({ className, bodyRef, table, beforeRow, ...props }: TableBodyProps<T>) => {
    const baseClassName = cn(className);

    if (!table) {
        return <tbody ref={bodyRef} className={baseClassName} {...props} />;
    }

    const { rows } = table.getRowModel();

    return (
        <tbody ref={bodyRef} className={baseClassName} {...props}>
            {rows.map((row, idx) => (
                <React.Fragment key={`${row.id}-fragment`}>
                    {beforeRow && beforeRow(row, rows.at(idx - 1), rows.at(idx + 1))}
                    <TableRow key={row.id}>
                        {row.getVisibleCells().map(cell => (
                            <TableCell
                                key={cell.id}
                                style={{ width: cell.column.getSize() }}
                                expand={cell.column.columnDef.meta?.expand}
                            >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </TableCell>
                        ))}
                    </TableRow>
                </React.Fragment>
            ))}
        </tbody>
    );
};
TableBody.displayName = 'TableBody';

const TableFooter = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
    ({ className, ...props }, ref) => (
        <tfoot
            ref={ref}
            className={cn('border-t-[0.5px] border-grey bg-bg font-medium [&>tr]:last:border-b-none', className)}
            {...props}
        />
    ),
);
TableFooter.displayName = 'TableFooter';

const TableCaption = React.forwardRef<HTMLTableCaptionElement, React.HTMLAttributes<HTMLTableCaptionElement>>(
    ({ className, ...props }, ref) => (
        <caption ref={ref} className={cn('mt-4 text-body-subtle', className)} {...props} />
    ),
);
TableCaption.displayName = 'TableCaption';

const Table = <T,>({
    className,
    data,
    columns,
    reactTableOpts,
    pagination,
    tableRef,
    loading,
    onPaginationCountChange,
    emptyState,
    beforeRow,
    ...props
}: TableProps<T>) => {
    const baseClassName = cn('caption-bottom border-white border-r-[0.5px]', className);

    const [, windowHeight] = useWindowSize();
    const tableContainerRef = React.useRef<HTMLDivElement>(null);
    const [pagniationMeta, setPaginationMeta] = React.useState({
        remaining: 0,
        total: 0,
    });

    const isInfiniteScrollEnabled = !data && !!pagination;

    const {
        data: queryData,
        fetchNextPage,
        isFetching,
        isLoading,
        refetch,
    } = useInfiniteQuery({
        queryFn: async ({ pageParam = 0 }) => {
            if (!pagination) {
                return [];
            }

            const start = pageParam * pagination.limit;
            const headers = getHeaders();

            const { items = [], pagination: pReturn } = await baseFetch<PaginatedListResponse<T>>(
                `${pagination.pathname}?offset=${start}&limit=${pagination?.limit}&${pagination?.filters}`,
                {
                    headers,
                },
            );
            setPaginationMeta(pReturn);
            onPaginationCountChange?.(pReturn.total);

            return items;
        },
        refetchOnWindowFocus: false,
        placeholderData: keepPreviousData,
        enabled: isInfiniteScrollEnabled,
        queryKey: pagination?.queryKey ?? [],
        initialPageParam: 0,
        getNextPageParam: (_lastPage, pages) => pages.length,
    });

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    React.useEffect(() => {
        void refetch();
    }, [pagination?.filters, refetch]);

    const flatData = React.useMemo(() => queryData?.pages?.flat() ?? [], [queryData]);

    const table = useReactTable({
        data: isInfiniteScrollEnabled ? flatData : (data ?? []),
        columns,
        getCoreRowModel: getCoreRowModel(),
        columnResizeMode: 'onChange',
        ...(reactTableOpts ?? {}),
    });

    // handles scrolling when infinite query is enabled
    const fetchMoreOnBottomReached = React.useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
            if (containerRefElement && pagniationMeta.remaining > 0 && pagination?.threshold && !isFetching) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                if (scrollHeight - scrollTop - clientHeight < pagination.threshold) {
                    void fetchNextPage();
                }
            }
        },
        [fetchNextPage, isFetching, pagination?.threshold, pagniationMeta.remaining],
    );

    // a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
    React.useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached]);

    // calculate the height of the container and assign it to the table. This is so the useInfiniteQuery can work. When window size is
    // updated we need to recalculate the height.
    React.useEffect(() => {
        if (tableContainerRef.current && windowHeight > 0) {
            const { top } = tableContainerRef.current.getBoundingClientRect();
            const height = window.innerHeight - top;
            tableContainerRef.current.style.height = `${height}px`;
        }
    }, [windowHeight]);

    const isReactTable = (data && columns) || (flatData && columns);

    if (isLoading || loading) {
        return <SkeletonTable columns={columns.length} />;
    }

    const dataIsEmpty = !data?.length;
    const flatDataIsEmpty = !flatData?.length;
    const hasNoFilters = !pagination?.filters?.length;

    if (dataIsEmpty && flatDataIsEmpty && hasNoFilters && emptyState) {
        return emptyState;
    }

    /**
     * Any time an event fires from table (e.g. resize), useReactTable hooks needs to rerun and table
     * needs to be update and tell the children components to rerender. This is why we need to disable unable context value.
     */
    return (
        <>
            {isReactTable ? (
                <>
                    <div
                        onScroll={e => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
                        ref={tableContainerRef}
                        style={{
                            overflow: 'auto', // our scrollable table container
                            position: 'relative', // needed for sticky header
                            height: '100%', // should be a fixed height
                        }}
                    >
                        <table
                            className={baseClassName}
                            ref={tableRef}
                            {...{
                                style: {
                                    // width: table.getCenterTotalSize(),
                                    // When using infinite scroll, we need to set the layout to fix, so that the columns don't resize
                                    tableLayout: isInfiniteScrollEnabled ? 'fixed' : 'auto',
                                    width: '100%',
                                },
                            }}
                            {...props}
                        >
                            <TableHeader
                                isInfiniteScrollEnabled={isInfiniteScrollEnabled}
                                table={isReactTable ? table : null}
                            />
                            <TableBody table={isReactTable ? table : null} beforeRow={beforeRow} />
                        </table>
                    </div>
                </>
            ) : (
                <table ref={tableRef} className={baseClassName} {...props} />
            )}
        </>
    );
};
Table.displayName = 'Table';

export { Table, TableHeader, TableBody, TableFooter, TableHead, TableRow, TableCell, TableCaption };
