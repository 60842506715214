import type { MentionOptions } from '@tiptap/extension-mention';
import { ReactRenderer } from '@tiptap/react';
import tippy, { type GetReferenceClientRect, type Instance } from 'tippy.js';

import type { ActionsResponse, PlaybookReferencesResponse } from 'lib/actions/actions';
import { PLAYBOOK_KEY } from 'lib/actions/constants';
import type { PlaybookListResponse, RichPlaybookVersion } from 'lib/models/playbook';
import type { PlaybookInstructionsTree } from 'lib/models/tiptap';
import { getActionMentionConfig, getReferenceMentionConfig } from 'lib/tiptap';

import { TiptapSchemaInstanceCounter } from '@/lib/tiptap';
import { getActionName } from '@/pages/playbooks/actions/utils';

import { ActionList } from './ActionList';
import { PlaybookReferences } from './PlaybookReferences';
import { ReferenceList } from './ReferenceList';
import { usePlaybookDetailsStore } from './store/usePlaybookDetailsStore';
import type { Option } from './store/usePlaybookDetailsStore';

export const mentionReferenceConfig: Partial<MentionOptions> = {
    ...getReferenceMentionConfig({ allowLabel: true }),
    renderHTML({ options, node }) {
        const { references } = usePlaybookDetailsStore.getState();

        const [type, typeId] = node.attrs.id.split(':');

        const reference = references?.references?.[type as keyof PlaybookReferencesResponse['references']]?.find(
            r => r.id === typeId,
        );

        let updatedName: string | undefined;
        if (reference) {
            if (PlaybookReferences.isUser(reference)) {
                updatedName = reference.displayName;
            } else {
                updatedName = reference.name ?? undefined;
            }
        }

        const label = updatedName ? `${node.attrs.label.split(':')[0]}:${updatedName}` : node.attrs.label;
        return [
            'span',
            // some of the coloring depends on the id, so changing the id will result in different styling
            {
                'data-type': 'mention-reference',
                class: 'mention-reference',
                'data-id': node.attrs.id,
                'data-label': node.attrs.label,
            },
            `${options.suggestion.char}${label}`,
        ];
    },
    suggestion: {
        ...getReferenceMentionConfig({ allowLabel: true }).suggestion,
        items: ({ query }: { query: string }) => {
            const { setQueryString } = usePlaybookDetailsStore.getState();

            const str = query.toLowerCase();
            setQueryString(str);

            return [];
        },
        render: () => {
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            let component: ReactRenderer<any>;
            let popup: Instance<unknown>[];

            return {
                onStart: props => {
                    component = new ReactRenderer(ReferenceList, {
                        props,
                        editor: props.editor,
                    });

                    if (!props.clientRect) {
                        return;
                    }

                    popup = tippy('body', {
                        getReferenceClientRect: props.clientRect as GetReferenceClientRect,
                        appendTo: () => document.body,
                        content: component.element,
                        showOnCreate: true,
                        interactive: true,
                        trigger: 'manual',
                        placement: 'bottom-start',
                    });
                },
                onExit: () => {
                    popup?.[0].destroy();
                    component?.destroy();
                },
                onUpdate(props) {
                    component.updateProps(props);

                    if (!props.clientRect) {
                        return;
                    }

                    popup[0].setProps({
                        getReferenceClientRect: props.clientRect,
                    });
                },
                onKeyDown: props => {
                    if (props.event.key === 'Escape') {
                        popup[0].hide();

                        return true;
                    }

                    return component.ref?.onKeyDown(props);
                },
            };
        },
    },
};

export const mentionActionConfig: Partial<MentionOptions> = {
    ...getActionMentionConfig({ allowLabel: true }),
    renderHTML({ options, node }) {
        const state = usePlaybookDetailsStore.getState();
        const initLabel = state.options.find(o => o.id === node.attrs.id)?.label ?? 'Missing';
        const label = node.attrs?.instanceCount > 0 ? `${initLabel} (${node.attrs.instanceCount})` : initLabel;

        return [
            'span',
            // some of the coloring depends on the id, so changing the id will result in different styling
            {
                'data-type': 'mention-action',
                class: 'mention-action',
                'data-id': node.attrs.id,
                'data-referenceId': node.attrs.referenceId,
            },
            `${options.suggestion.char}${label}`,
        ];
    },
    suggestion: {
        ...getActionMentionConfig({ allowLabel: true }).suggestion,
        items: ({ query }: { query: string }) => {
            const { setQueryString, options } = usePlaybookDetailsStore.getState();
            const str = query.toLowerCase();
            setQueryString(str);

            return getFilteredOptions(options, str);
        },
        render: () => {
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            let component: ReactRenderer<any>;
            let popup: Instance<unknown>[];

            return {
                onStart: props => {
                    component = new ReactRenderer(ActionList, {
                        props,
                        editor: props.editor,
                    });

                    if (!props.clientRect) {
                        return;
                    }

                    popup = tippy('body', {
                        getReferenceClientRect: props.clientRect as GetReferenceClientRect,
                        appendTo: () => document.body,
                        content: component.element,
                        showOnCreate: true,
                        interactive: true,
                        trigger: 'manual',
                        placement: 'bottom-start',
                    });
                },
                onExit: () => {
                    popup?.[0].destroy();
                    component?.destroy();
                },
                onUpdate(props) {
                    component.updateProps(props);

                    if (!props.clientRect) {
                        return;
                    }

                    popup[0].setProps({
                        getReferenceClientRect: props.clientRect,
                    });
                },
                onKeyDown: props => {
                    if (props.event.key === 'Escape') {
                        popup[0].hide();

                        return true;
                    }

                    return component.ref?.onKeyDown(props);
                },
            };
        },
    },
};

export const getFilteredOptions = (options: Option[], query?: string) => {
    const { queryString } = usePlaybookDetailsStore.getState();
    return options.filter(o => o.label.toLowerCase().includes((query ?? queryString).toLowerCase()));
};

export const getPlaybooksOptions = (playbooks: PlaybookListResponse['playbooks']) => {
    return playbooks
        .filter(playbook => playbook.state === 'PUBLISHED' && playbook.name)
        .map(
            playbook =>
                ({
                    type: 'playbook',
                    name: playbook.name,
                    id: playbook.id,
                    description: playbook.description,
                    label: `${PLAYBOOK_KEY}:${playbook.name}`,
                    provider: 'Playbook',
                }) satisfies Option,
        );
};

export const getSlackChannelOptions = (channels: PrismaJson.SlackChannel[]) => {
    return channels.map(
        channel =>
            ({
                type: 'slack-channel',
                name: channel.name,
                id: channel.id,
                description: channel.purpose.value,
                label: `App:Slack:Channel:${channel.name}`,
                provider: 'Playbook',
            }) satisfies Option,
    );
};

export const getActionOptions = (actions: ActionsResponse['actions']) => {
    return actions.map(
        action =>
            ({
                type: 'action',
                name: action.name,
                provider: action.provider,
                id: action.id,
                description: action.description ?? '',
                label: getActionName(action, action.org.name),
                parameters: action.parameters,
                slug: action.slug,
            }) satisfies Option,
    );
};

// Maps references to playbooks and actions, and adds reference instance count to the name
export const mapReference = ({
    references,
    schema,
    actions,
    playbooks,
}: {
    schema: PlaybookInstructionsTree;
    references: RichPlaybookVersion['references'];
    actions: ActionsResponse['actions'];
    playbooks: PlaybookListResponse['playbooks'];
}) => {
    const counterUtil = new TiptapSchemaInstanceCounter(node => node?.attrs?.id, schema);

    const actionsNotInPlaybook = references.actionReference
        .filter(r => !r.existsInPlaybook)
        .map(r => {
            const a = actions.find(e => e.id === r.actionId);
            if (a) {
                return { ...a, referenceId: r.id };
            }
            return;
        })
        .filter(a => a !== undefined);

    const referencesMap: {
        actions: (ActionsResponse['actions'][number] & { referenceId: string })[];
        playbooks: (PlaybookListResponse['playbooks'][number] & { referenceId: string })[];
    } = {
        actions: actionsNotInPlaybook,
        playbooks: [],
    };

    const getName = (name: string, count: number) => {
        return `${name} ${count >= 1 ? `(${count})` : ''}`;
    };

    const nodes = counterUtil.getNodes('mention-action');

    nodes.forEach(node => {
        if (node.type === 'mention-action' && node?.attrs?.id) {
            if (node.attrs.referenceType === 'action') {
                const a = actions.find(a => a.id === node?.attrs?.id);
                if (a) {
                    referencesMap.actions.push({
                        ...a,
                        referenceId: node.attrs.referenceId,
                        name: getName(a.name, node.attrs.instanceCount),
                    });
                }
            } else if (node.attrs.referenceType === 'playbook') {
                const p = playbooks.find(p => p.id === node?.attrs?.id);
                if (p) {
                    referencesMap.playbooks.push({
                        ...p,
                        referenceId: node.attrs.referenceId,
                        name: getName(p.name, node.attrs.instanceCount),
                    });
                }
            }
        }
    });

    return referencesMap;
};
