import { useMutation } from '@lib/hooks/useMutation';
import { type DefaultError, useQueryClient } from '@tanstack/react-query';
import type { MappingDeleteSchema, MappingPostSchema } from 'lib/models/provisioning_ticket_field_maps';

export const useProvisioningTicketFieldMappingPost = () => {
    const queryClient = useQueryClient();

    return useMutation<MappingPostSchema, DefaultError>({
        url: `/api/v1/settings/provisioning-tickets/field-mappings`,
        method: 'POST',
        successToast: {
            message: 'Field mapping created',
        },
        errorToast: {
            message: 'Problem creating field mapping',
        },
        onSuccess: async () => {
            await queryClient.refetchQueries({ queryKey: ['/api/v1/settings/provisioning-tickets/field-mappings'] });
        },
    });
};

export const useProvisioningTicketFieldMappingDelete = () => {
    const queryClient = useQueryClient();

    return useMutation<MappingDeleteSchema, DefaultError>({
        url: `/api/v1/settings/provisioning-tickets/field-mappings`,
        method: 'DELETE',
        successToast: {
            message: 'Field mapping deleted',
        },
        errorToast: {
            message: 'Problem deleting field mapping',
        },
        onSuccess: async () => {
            await queryClient.refetchQueries({ queryKey: ['/api/v1/settings/provisioning-tickets/field-mappings'] });
        },
    });
};
