import { valibotResolver } from '@hookform/resolvers/valibot';
import { Box, Boxes, Braces, Ellipsis, Plus, Trash2, TriangleAlert } from 'lucide-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { type UseFormReturn, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { Brow, type Crumb } from '@/components/Brow';
import ComboboxPopover from '@/components/ComboboxPopover';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useActionCreate, useActionUpdate } from '@/hooks/mutations/useActions';
import { useAction } from '@/hooks/queries/useActions';
import { useEditorParameterStore } from '@/pages/playbooks/actions/useEditorParameterStore';
import { ACTIONS } from '@/routes/paths';
import {
    ActionSchema,
    type ActionSchemaType,
    type ActionsResponse,
    getParametersFromErrorMessage,
} from 'lib/models/actions';

import type { Action } from 'lib/prisma/types';

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Separator } from '@/components/ui/separator';
import { Textarea } from '@/components/ui/textarea';
import { useActionDelete } from '@/hooks/mutations/useActions';
import { getProviderAssets } from 'lib/3p';
import { EditorField } from './EditorField';

const ActionForm = ({
    existingAction,
    form,
}: { existingAction: Action | null; form: UseFormReturn<ActionSchemaType> }) => {
    const setParameters = useEditorParameterStore(s => s.setParameters);

    const queryParams = useFieldArray({ control: form.control, name: 'config.queryParams' });
    const headers = useFieldArray({ control: form.control, name: 'config.headers' });

    const parameterKeys = form.watch('config.parameters').map(({ key }) => key);
    // biome-ignore lint/correctness/useExhaustiveDependencies: biome can't deduce join relevance
    useEffect(() => {
        setParameters(parameterKeys);
    }, [parameterKeys.join(''), setParameters]);

    const errors = form.formState.errors;

    return (
        <div className="w-[640px] mx-2xl py-4xl">
            <div className="flex flex-col border-[0.5px] border-grey rounded-md">
                <div className="flex flex-col gap-lg bg-bg-overlay p-lg rounded-t-md border-b-[0.5px] border-grey relative overflow-hidden">
                    <Box className="size-56 text-steel-300 absolute -right-14 -top-7" />
                    <div className="flex flex-col gap-sm z-0">
                        <h2 className="text-base">Configure your new action</h2>
                        <p className="text-body-subtle-hover text-sm">
                            Select the HTTP method and enter your URL to get started
                        </p>
                    </div>
                    <div className="flex gap-lg z-0">
                        <FormField
                            control={form.control}
                            name="config.method"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Method</FormLabel>
                                    <FormControl>
                                        <ComboboxPopover
                                            value={field.value}
                                            options={[
                                                { label: 'GET', value: 'GET' },
                                                { label: 'POST', value: 'POST' },
                                                { label: 'PUT', value: 'PUT' },
                                                { label: 'PATCH', value: 'PATCH' },
                                                { label: 'DELETE', value: 'DELETE' },
                                            ]}
                                            onChange={v => {
                                                field.onChange({ target: { value: v } });
                                            }}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <EditorField
                            form={form}
                            content={existingAction?.config?.endpointJson ?? null}
                            label="URL"
                            field="config.endpointJson"
                            placeholder="Enter your URL"
                        />
                    </div>
                </div>
                <Tabs defaultValue="qp">
                    <TabsList className="w-full px-lg">
                        <TabsTrigger value="qp" className="flex gap-sm">
                            {!!errors.config?.queryParams && <TriangleAlert size={16} color="#FFA01C" />}
                            Query Parameters
                        </TabsTrigger>
                        <TabsTrigger value="headers" className="flex gap-sm">
                            {!!errors.config?.headers && <TriangleAlert size={16} color="#FFA01C" />}
                            Headers
                        </TabsTrigger>
                        <TabsTrigger value="body" className="flex gap-sm">
                            {!!errors.config?.body && <TriangleAlert size={16} color="#FFA01C" />}
                            Body
                        </TabsTrigger>
                    </TabsList>
                    <TabsContent value="qp" asChild>
                        <div className="flex flex-col gap-lg p-lg pt-2xl">
                            <div className="flex justify-between gap-lg items-center">
                                <div>
                                    <h3 className="text-sm">Query Parameters</h3>
                                    <p className="text-body-subtle-hover">
                                        Add query parameters to include in your API call
                                    </p>
                                </div>
                                <Button
                                    type="button"
                                    onClick={() => {
                                        queryParams.append({ value: null, key: null });
                                    }}
                                >
                                    <Plus />
                                </Button>
                            </div>
                            {queryParams.fields.map((value, idx) => (
                                <div
                                    key={value.id}
                                    className="flex flex-col p-lg gap-lg bg-bg-overlay rounded-md border-[0.5px] border-grey relative"
                                >
                                    <div className="max-w-[400px]">
                                        <EditorField
                                            form={form}
                                            content={existingAction?.config.queryParams[idx]?.key ?? null}
                                            label="Key"
                                            field={`config.queryParams.${idx}.key`}
                                            placeholder="Key"
                                        />
                                    </div>
                                    <EditorField
                                        form={form}
                                        content={existingAction?.config.queryParams[idx]?.value ?? null}
                                        label="Value"
                                        field={`config.queryParams.${idx}.value`}
                                        placeholder="Value"
                                    />
                                    <Button
                                        tabIndex={-1}
                                        className="text-body-subtle hover:text-body-red-primary hover:bg-bg-red-primary-hover absolute top-lg right-lg"
                                        size="sm"
                                        mode="borderless"
                                        onClick={() => queryParams.remove(idx)}
                                    >
                                        <Trash2 className="size-3.5" />
                                    </Button>
                                </div>
                            ))}
                        </div>
                    </TabsContent>
                    <TabsContent value="headers" asChild>
                        <div className="flex flex-col gap-lg p-lg pt-2xl">
                            <div className="flex justify-between gap-lg items-center">
                                <div>
                                    <h3 className="text-sm">Headers</h3>
                                    <p className="text-body-subtle-hover">
                                        Set Headers to provide meta data about your request and the desired response
                                    </p>
                                </div>
                                <Button
                                    type="button"
                                    onClick={() => {
                                        headers.append({ value: null, key: null });
                                    }}
                                >
                                    <Plus />
                                </Button>
                            </div>
                            {headers.fields.map(({ id }, idx) => (
                                <div
                                    key={id}
                                    className="flex flex-col p-lg gap-lg bg-bg-overlay rounded-md border-[0.5px] border-grey relative"
                                >
                                    <div className="max-w-[400px]">
                                        <EditorField
                                            form={form}
                                            content={existingAction?.config.headers[idx]?.key ?? null}
                                            label="Key"
                                            field={`config.headers.${idx}.key`}
                                            placeholder="Key"
                                        />
                                    </div>
                                    <EditorField
                                        form={form}
                                        content={existingAction?.config.headers[idx]?.value ?? null}
                                        label="Value"
                                        field={`config.headers.${idx}.value`}
                                        placeholder="Value"
                                    />
                                    <Button
                                        tabIndex={-1}
                                        className="text-body-subtle hover:text-body-red-primary hover:bg-bg-red-primary-hover absolute top-lg right-lg"
                                        size="sm"
                                        mode="borderless"
                                        onClick={() => headers.remove(idx)}
                                    >
                                        <Trash2 className="size-3.5" />
                                    </Button>
                                </div>
                            ))}
                        </div>
                    </TabsContent>
                    <TabsContent value="body" asChild>
                        <div className="flex flex-col gap-lg p-lg pt-2xl">
                            <div>
                                <h3 className="text-sm">Body</h3>
                                <p className="text-body-subtle-hover">Define the JSON request</p>
                            </div>
                            <div className="flex flex-col p-lg gap-lg bg-bg-overlay rounded-md border-[0.5px] border-grey">
                                <EditorField
                                    newLineEnabled
                                    form={form}
                                    content={existingAction?.config?.body?.json ?? null}
                                    label="Body"
                                    field="config.body.json"
                                    placeholder="Paste your JSON here"
                                />
                            </div>
                        </div>
                    </TabsContent>
                </Tabs>
            </div>
        </div>
    );
};

const ActionMenu = ({ actionSlug, provider }: { actionSlug: string; provider: string }) => {
    const onDelete = useActionDelete(provider, actionSlug);

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button size="sm" mode="borderless">
                    <Ellipsis />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent side="bottom" align="end">
                <DropdownMenuItem
                    onSelect={() => {
                        onDelete.mutate();
                    }}
                    className="gap-sm text-body-red-primary"
                >
                    <Trash2 />
                    Delete Action
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );
};

const ActionInternal = ({
    existingAction,
    onSubmit,
}: { existingAction: ActionsResponse['action'] | null; onSubmit: (_: ActionSchemaType) => void }) => {
    const navigate = useNavigate();
    const defaultValues = existingAction
        ? { ...existingAction }
        : {
              name: '',
              description: '',
              config: {
                  method: 'GET' as const,
                  endpointJson: {},
                  parameters: [{ key: '', instructions: '' }],
                  queryParams: [],
                  headers: [],
                  body: {
                      json: null,
                  },
              },
          };

    const form = useForm<ActionSchemaType>({
        resolver: valibotResolver(ActionSchema),
        defaultValues,
    });

    const browBreadcrumbs: Crumb[] = [
        {
            label: (
                <div className="gap-sm flex items-center">
                    <Boxes />
                    <span>Actions</span>
                </div>
            ),
            href: `${ACTIONS}`,
        },
        {
            label: (
                <div className="gap-sm flex items-center">
                    {existingAction?.provider === 'custom' || !existingAction?.provider ? (
                        <Box />
                    ) : (
                        <img
                            src={getProviderAssets(existingAction?.provider).logo}
                            alt={existingAction?.provider}
                            className="size-4"
                        />
                    )}
                    <span>{existingAction ? existingAction.name : 'New Action'}</span>
                </div>
            ),
            bold: true,
        },
    ];

    const actions = [
        <Button
            key="cancel"
            mode="borderless"
            size="sm"
            onClick={() => (existingAction ? form.reset() : navigate(ACTIONS))}
        >
            Cancel
        </Button>,
        <Button key="submit" type="submit" size="sm" mode="dark" variant={'blue'} disabled={!form.formState.isDirty}>
            {existingAction ? 'Update action' : 'Save action'}
        </Button>,
    ];

    if (existingAction?.slug) {
        actions.push(<ActionMenu key="menu" actionSlug={existingAction.slug} provider={existingAction.provider} />);
    }

    const handleSubmit = (body: ActionSchemaType) => {
        onSubmit(body);
    };

    const parameters = useFieldArray({ control: form.control, name: 'config.parameters' });

    // Loops through all Tiptap schemas and checks if a parameter is undefined
    const missingParameterErrors = useMemo(() => {
        const { queryParams, headers, body, endpointJson } = form.formState.errors?.config ?? {};
        let params: string[] = [
            ...getParametersFromErrorMessage(body?.json?.message as string),
            ...getParametersFromErrorMessage(endpointJson?.message as string),
        ];

        if (Array.isArray(queryParams)) {
            queryParams.forEach(q => {
                params = [
                    ...params,
                    ...getParametersFromErrorMessage(q?.value?.message),
                    ...getParametersFromErrorMessage(q?.key?.message),
                ];
            });
        }

        if (Array.isArray(headers)) {
            headers.forEach(q => {
                params = [
                    ...params,
                    ...getParametersFromErrorMessage(q?.value?.message),
                    ...getParametersFromErrorMessage(q?.key?.message),
                ];
            });
        }

        return params.reduce(
            (errors, param) => {
                if (errors[param]) {
                    errors[param] = errors[param] + 1;
                } else {
                    errors[param] = 1;
                }
                return errors;
            },
            {} as Record<string, number>,
        );
    }, [form.formState.errors?.config]);

    return (
        <>
            {/* if users is creating a new action or they are editing a custom action, then show form */}
            {existingAction === null || existingAction.provider === 'custom' ? (
                <Form {...form}>
                    <form
                        className="flex flex-col overflow-hidden size-full"
                        onSubmit={form.handleSubmit(handleSubmit)}
                    >
                        <Brow data={browBreadcrumbs} actions={actions} />
                        <div className="flex overflow-y-auto size-full">
                            <div className="w-[350px] shrink-0 bg-bg-overlay overflow-y-auto border-r-[0.5px] border-grey">
                                <div className="flex flex-col p-lg py-2xl border-b-[0.5px] border-grey">
                                    <FormField
                                        control={form.control}
                                        name="name"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Textarea
                                                        autoResize
                                                        minHeight="30px"
                                                        className="border-none text-xl font-medium !px-0 bg-bg-overlay"
                                                        placeholder="Add a title"
                                                        {...field}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={form.control}
                                        name="description"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Textarea
                                                        autoResize
                                                        className="text-body-subtle-hover border-none text-base !px-0 bg-bg-overlay"
                                                        placeholder="Write a short description"
                                                        {...field}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                                <div className="flex flex-col gap-xl p-lg py-2xl">
                                    <div className="flex justify-between items-center">
                                        <span>
                                            <div className="flex items-center gap-md font-medium text-base">
                                                <Braces />
                                                Parameters
                                            </div>
                                        </span>
                                        <Button
                                            type="button"
                                            size="sm"
                                            onClick={() => {
                                                parameters.append({ key: '', instructions: '' });
                                            }}
                                        >
                                            <Plus />
                                        </Button>
                                    </div>
                                    <div className="flex flex-col gap-md">
                                        <div className="flex flex-col gap-sm">
                                            <h5>Adding parameters</h5>
                                            <div className="text-body-subtle-hover">
                                                Create parameters and provide instructions to Console AI on how to find
                                                or use it
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-sm">
                                            <h5>Using parameters</h5>
                                            <div className="text-body-subtle-hover">
                                                You can use these parameters (ie.{' '}
                                                <span className="rounded-sm bg-bg-blue-primary text-body-blue-primary px-sm py-[2px] border-blue border-[0.5px] font-medium">
                                                    {'${MY_PARAM}'}
                                                </span>
                                                ) anywhere in your config by typing a $ in the field
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-md">
                                        {parameters.fields.map(({ id }, idx) => (
                                            <div
                                                key={id}
                                                className="flex flex-col rounded-md border-grey border-[0.5px] bg-bg overflow-hidden shadow-sm"
                                            >
                                                <FormField
                                                    control={form.control}
                                                    name={`config.parameters.${idx}.key`}
                                                    render={({ field }) => {
                                                        const { value, onChange, ref: oldRef, ...rest } = field;
                                                        const ref = useRef<HTMLInputElement>(null);
                                                        const [inputState, setInputState] = useState(
                                                            value ? `\${${value}}` : '',
                                                        );

                                                        useEffect(() => {
                                                            if (ref.current) {
                                                                ref.current.focus();

                                                                const currentPosition = ref?.current?.selectionStart;
                                                                if (currentPosition === inputState.length) {
                                                                    const position = inputState.length - 1;
                                                                    ref?.current?.setSelectionRange(position, position);
                                                                }
                                                            }
                                                        }, [inputState]);

                                                        const handleInputChange: React.ChangeEventHandler<
                                                            HTMLInputElement
                                                        > = event => {
                                                            const value = event.target.value;
                                                            const cursorPosition = event.target.selectionStart;
                                                            let newValue = event.target.value.replace(/[\$\{\}]/g, '');

                                                            if (
                                                                cursorPosition &&
                                                                cursorPosition < 2 &&
                                                                ref.current &&
                                                                newValue.length > 1
                                                            ) {
                                                                const extra = value
                                                                    .slice(0, 2)
                                                                    .replace(/[\$\{\}]/g, '');
                                                                newValue = newValue.slice(1, newValue.length) + extra;
                                                            }

                                                            field.onChange(newValue);
                                                            setInputState(`\${${newValue}}`);
                                                        };
                                                        return (
                                                            <div className="flex relative">
                                                                <FormItem className="w-full">
                                                                    <FormControl>
                                                                        <Input
                                                                            ref={ref}
                                                                            value={inputState}
                                                                            onChange={handleInputChange}
                                                                            className="rounded-none shadow-none font-medium uppercase border-t-0 border-x-0"
                                                                            placeholder="{MY_PARAM}"
                                                                            {...rest}
                                                                        />
                                                                    </FormControl>
                                                                    <FormMessage />
                                                                </FormItem>
                                                                <Button
                                                                    tabIndex={-1}
                                                                    className="absolute top-sm right-sm text-body-subtle hover:text-body-red-primary hover:bg-bg-red-primary-hover"
                                                                    size="sm"
                                                                    mode="borderless"
                                                                    onClick={() => parameters.remove(idx)}
                                                                >
                                                                    <Trash2 className="size-3" />
                                                                </Button>
                                                            </div>
                                                        );
                                                    }}
                                                />
                                                <FormField
                                                    control={form.control}
                                                    name={`config.parameters.${idx}.instructions`}
                                                    render={({ field }) => (
                                                        <FormItem>
                                                            <FormControl>
                                                                <Textarea
                                                                    className="resize-none rounded-none border-none shadow-none"
                                                                    placeholder="Instructions"
                                                                    {...field}
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                />
                                            </div>
                                        ))}
                                        {Object.entries(missingParameterErrors).map(([name, referenceNumber]) => (
                                            <div
                                                key={name}
                                                className="px-md py-[6px] bg-bg-red-primary border-red rounded-md border-[0.5px] flex justify-between items-center"
                                            >
                                                <div className="flex items-center gap-md">
                                                    <Trash2 className="text-body-red-primary" />
                                                    <div>
                                                        <div className="uppercase font-medium">{`\${${name}}`}</div>
                                                        <div className="text-black-40 mt-[-2px]">
                                                            {referenceNumber === 1
                                                                ? `1 reference`
                                                                : `${referenceNumber} references`}
                                                        </div>
                                                    </div>
                                                </div>
                                                <Badge
                                                    variant="red"
                                                    className="bg-bg-red-secondary text-body-red-secondary hover:bg-bg-red-secondary-hover hover:text-body-red-secondary-hover cursor-pointer"
                                                    onClick={() => {
                                                        const paramConfig = existingAction?.config?.parameters.find(
                                                            p => p.key === name,
                                                        );
                                                        if (paramConfig) {
                                                            parameters.append({
                                                                key: name,
                                                                instructions: paramConfig.instructions,
                                                            });
                                                            form.trigger();
                                                        }
                                                    }}
                                                >
                                                    Restore
                                                </Badge>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="flex grow justify-center overflow-y-scroll">
                                <ActionForm form={form} existingAction={existingAction} />
                            </div>
                        </div>
                    </form>
                </Form>
            ) : (
                <>
                    <Brow data={browBreadcrumbs} />
                    <div className="flex justify-center w-full">
                        <div className="w-[640px] max-w-screen-lg mx-auto py-4xl">
                            <div className="gap-2xl flex flex-col">
                                <div>
                                    <span className="flex items-center gap-md font-medium text-base">
                                        {existingAction?.provider && (
                                            <img
                                                src={getProviderAssets(existingAction?.provider).logo}
                                                alt={existingAction?.provider}
                                                className="w-4 h-4"
                                            />
                                        )}
                                        <h1 className="text-xl">{existingAction?.name}</h1>
                                    </span>
                                    <span className="text-body-subtle-hover text-base">
                                        {existingAction?.description}
                                    </span>
                                </div>
                                <Separator />
                                <div className="flex flex-col gap-md">
                                    {existingAction?.parameters?.properties &&
                                        Object.entries(
                                            existingAction.parameters.properties as Record<
                                                string,
                                                Record<string, unknown>
                                            >,
                                        ).map(([key, value]) => (
                                            <div className="flex flex-col gap-sm" key={key}>
                                                <span className="flex items-center justify-between">
                                                    <span className="flex items-center gap-sm">
                                                        <h5>{key}</h5>
                                                        {existingAction.parameters?.required?.includes(key) && (
                                                            <span className="text-body-red-primary">*</span>
                                                        )}
                                                    </span>
                                                    {typeof value === 'object' && 'type' in value && (
                                                        <div className="border-grey border-[0.5px] rounded-sm px-sm text-xs font-medium">
                                                            {value.type as string}
                                                        </div>
                                                    )}
                                                </span>
                                                {typeof value === 'object' && 'description' in value && (
                                                    <div className="text-body-subtle-hover">
                                                        {value.description as string}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export const ActionDetails = () => {
    const navigate = useNavigate();
    const { provider, slug } = useParams();

    if (!provider || !slug) {
        navigate(ACTIONS);
        return <span />;
    }
    const actionUpdate = useActionUpdate(provider, slug);
    const { data } = useAction(provider, slug);

    return data ? (
        <ActionInternal existingAction={data.action} onSubmit={(body: ActionSchemaType) => actionUpdate.mutate(body)} />
    ) : (
        <span />
    );
};

export const ActionCreate = () => {
    const actionCreate = useActionCreate();

    return <ActionInternal onSubmit={(body: ActionSchemaType) => actionCreate.mutate(body)} existingAction={null} />;
};
