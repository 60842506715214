import type { ColumnDef } from '@tanstack/react-table';
import type { SingleCnslUser, UserApp } from 'lib/models/cnsl_user';

import { ResLink } from '@/components/ResLink';
import { TableEmptyState } from '@/components/TableEmptyState';
import { Button } from '@/components/ui/button';
import { Table } from '@/components/ui/table/table';
import { fmtDateFull } from '@/lib/dates';
import { useNavigate } from 'react-router-dom';

const columns: ColumnDef<UserApp>[] = [
    {
        accessorKey: 'name',
        header: () => 'Group name',
        cell: props => (
            <ResLink
                className="pl-lg h-full"
                entity="groups"
                id={props.row.original.slug}
                src={props.row.original.logo}
                label={props.row.original.name ?? ''}
                bold
            />
        ),
        meta: {
            expand: true,
        },
    },
    // {
    //     accessorKey: 'members',
    //     header: () => 'Members',
    //     cell: props => props.getValue(),
    // },
    {
        accessorKey: 'description',
        header: () => 'Description',
        cell: props => <div className="truncate">{props.row.original.description}</div>,
    },
    {
        accessorKey: 'addedAt',
        header: () => 'Access granted',
        cell: props => (
            <div className="truncate">{fmtDateFull(props.row.original.addedAt ?? props.row.original.createdAt)}</div>
        ),
    },
];

const EmptyState = () => {
    const navigate = useNavigate();

    return (
        <TableEmptyState
            title="Groups"
            description="No groups found for this user"
            imageSrc="/emptyStates/empty-groups.svg"
        >
            <Button onClick={() => navigate('/settings/organization/integrations/okta')}>
                <img src="/3p/okta-logo.png" alt="Okta logo" className="size-4" />
                Connect Okta
            </Button>
            <Button onClick={() => navigate('/settings/organization/integrations/google')}>
                <img src="/3p/google-logo.png" alt="Google logo" className="size-4" />
                Connect Google
            </Button>
        </TableEmptyState>
    );
};

export const Groups = ({ user }: { user: SingleCnslUser }) => {
    return <Table data={user.groups} columns={columns} emptyState={<EmptyState />} />;
};
