import { useMutation } from '@lib/hooks/useMutation';
import { type DefaultError, useQueryClient } from '@tanstack/react-query';
import type { CnslRequest } from 'lib/prisma/types';

export const useRequestCategoryUpdate = (requestId: string, slug: string) => {
    const queryClient = useQueryClient();

    return useMutation<{ request: CnslRequest }, DefaultError, { categoryId: string }>({
        url: `/api/v1/requests/${requestId}/category`,
        method: 'PUT',
        onSuccess: async () => {
            await queryClient.refetchQueries({ queryKey: [`/api/v1/requests/${slug}`] });
        },
    });
};
